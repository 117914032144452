.header_block {
    height: 60px;
    width: 100%;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    box-shadow: 0px 4px 24px rgb(184, 184, 184, 0.25);
    transition: .2s ease-in-out;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.header__menu {
    display: flex;
}
.header__menu_item {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #868080;
    margin: 0 20px;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.header__menu_item:hover {
    color: #000;
}
.header__login {
    display: flex;
    align-items: center;
}
.header__login_btn {
    color: #282828;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}
.header__login_reg {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    height: 60px;
    width: 214px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(92.44deg, #B92655 5.76%, #B92655 5.77%, rgba(228, 47, 129, 0) 107.53%), #EE4777;
    /* border-radius: 10px; */
    margin-left: 30px;
    cursor: pointer;
}
.header_btn {
    display: none;
}
.header__logo {
    cursor: pointer;
}
.header__login_mob, .header__reg_mob {
    display: none;
}

@media(max-width: 1199px) {
    .header__menu_item {
        margin: 0 10px;
    }
}
@media(max-width: 991px) {
    .header__logo {
        flex: 0 1 65%;
        max-width: 65%;
    }
    .header__menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        padding-bottom: 30px;
        background-color: #fff;
        z-index: -1;
        margin-top: 60px;
        padding-top: 20px;
        flex-direction: column;
        align-items: center;
        transform: translateY(calc(-100% - 60px));
        transition: .3s ease-in-out;
    }
    .header_block.active .header__menu {
        transform: translateY(0);
    }
    .header__menu_item {
        color: #000;
        border-bottom: 1px solid #E0DDDD;
        text-align: center;
        width: 90%;
        padding: 10px 0;
    }
    .header__menu_item:last-of-type {
        border-bottom: none;
    }
    .header_btn {
        display: block;
        background-color: #ECECEC;
        width: 36px;
        height: 36px;
        min-width: 36px;
        margin-left: 20px;
        position: relative;
        border-radius: 100%;
    }
    .header_btn span {
        background-color: #000;
        width: 18px;
        height: 2px;
        position: absolute;
        left: 50%;
        margin-left: -9px;
        top: 50%;
        margin-top: -1px;
        transition: .2s ease-in-out;
    }
    .header_btn span:first-of-type {
        margin-top: -6px;
    }
    .header_btn span:last-of-type {
        margin-top: 4px;
    }
    .header_btn.active span:nth-last-of-type(1) {
        transform: translate(0,10px) scale(.01);
        opacity: 0;
    }
    .header_btn.active span:nth-last-of-type(2) {
        transform: rotate(45deg);
    }
    .header_btn.active span:nth-last-of-type(3) {
        transform: rotate(-45deg);
    }
    .header_btn.active span:nth-last-of-type(4) {
        transform: translate(0,-10px) scale(.01);
        opacity: 0;
    }
}
@media(max-width: 575px) {
    .header__login_btn {
        display: none;
    }
    .header__login_mob {
        display: block;
    }
    .header__reg_mob {
        display: block;
        background: linear-gradient(92.44deg, #B92655 5.76%, #B92655 5.77%, rgba(228, 47, 129, 0) 107.53%), #EE4777;
        color: #fff;
    }
    .header__login_reg {
        font-size: 12px;
        width: 150px;
    }
}