.form {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/online2024/form_bg.jpg');
    background-size: cover;
    padding: 75px 0;
}
.block {
    background-color: #fff;
    max-width: 750px;
    margin: 0 auto;
    border-radius: 16px;
    padding: 40px 30px;
}
.pretitle {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #FF8787;
    margin-bottom: 10px;
}
.title {
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 52px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
}
.title span {
    color: #FF8787;
}
.subtitle {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.2;
}
[meta-id="5072.3px"],
[meta-id="8031px"],
[meta-id="8031.5px"],
[meta-id="8009px"] {
    width: 100%!important;
}
[meta-id="8050px"] {
    border: 1px solid #000000ff!important;
}
.btn {
    border-radius: 90px;
    background: linear-gradient(90deg, #FF8686 0%, #E85A5A 100%);
    box-shadow: 0px 4px 32px 0px rgba(255, 123, 163, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 1px;
    cursor: pointer;
    transition: .3s ease-in-out;
    margin-bottom: 16px;
}
.btn:hover {
    transform: translateY(-4px);
}
.remain {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.55;
    text-align: center;
}
.remain span {
    color: #FF8686;
}

@media(max-width: 575px) {
    .form {
        padding: 50px 0;
    }
    .title {
        font-size: 26px;
    }
    .btn {
        font-size: 18px;
        height: 76px;
    }
    .pretitle {
        font-size: 20px;
    }
    .remain {
        font-size: 20px;
    }
}