.faq {
    padding: 50px 0;
    position: relative;
}
.faq_block {
    display: flex;
    justify-content: center;
    gap: 45px;
}
.faq_left {
    flex: 0 1 300px;
    max-width: 300px;
    /* flex: 1; */
}
.faq_right {
    flex: 0 1 680px;
    max-width: 680px;
}
.faq_titles {
    position: sticky;
    top: 80px;
}
.faq_title {
    /* margin-bottom: 40px; */
    font-family: Nface;
    font-size: 35px;
    text-transform: uppercase;
    line-height: 1.3;
}
.accordion {
    max-width: 685px;
    margin: 0 auto;
}
.question {
    padding: 24px 50px 24px 0;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #E8E8E8;
    transition: .2s ease-in-out;
}
.question::before {
    content: '';
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="%23FF4E53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    width: 24px;
    height: 24px;
    /* border: 2px solid #fff; */
    /* border-radius: 100%; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 0;
    /* font-size: 17px; */
    /* font-weight: 300; */
    transition: .2s ease-in-out;
}
.question:hover {
    border-color: #FF4E53;
}
.active .question {
    color: #FF4E53;
    border-color: #FF4E53;
}
.active .question::before {
    transform: rotate(45deg);
    color: #FF4E53;
    border-color: #FF4E53;
}
.question:hover::before {
    color: #FF4E53;
    border-color: #FF4E53;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 1.5;
    padding-left: 0;
    color: rgba(40, 40, 40, 0.8);
    transition: .2s ease-in-out;
}
.active .answer {
    padding-top: 14px;
    max-height: 550px;
    padding-bottom: 0;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}
.faq ul li {
    list-style-type: disc;
    margin-bottom: 10px;
    margin-left: 30px;
}
.answer a {
    color: #FF4E53;
    font-weight: 500;
}
.promo_btn {
    transition: .2s ease-in-out;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-family: Manrope;
    border-radius: 60px;
    background: #FF4E53;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 40px auto 90px;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.oferta {
    display: flex;
    flex-direction: column;
}
.oferta a {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 600;
    max-width: max-content;
    margin-top: 10px;
    color: #6A6A6A;
    transition: .2s ease-in-out;
}
.oferta a:hover {
    color: #FF4E53;
}
.faq_btn {
    border-radius: 18px;
    background-color: #764BFF;
    display: flex;
    gap: 12px;
    padding: 22px 70px 22px 26px;
    width: max-content;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 60px auto 0;
    transition: .2s ease-in-out;
}
.faq_btn p {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.05;
    letter-spacing: -0.04rem;
}
.faq_btn:hover {
    transform: translateY(-4px);
}
.faq_right .promo_btn {
    display: none;
}
@media(max-width: 767px) {
    .oferta {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .oferta a {
        font-size: 16px;
    }
    .faq_btn {
        width: 100%;
    }
    .faq {
        padding: 0 0 40px;
    }
    .faq_title {
        margin-bottom: 30px;
        padding-left: 0;
    }
    .faq_title img {
        display: none!important;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
    }
    .btn {
        width: 100%;
    }
}
@media(max-width: 575px) {
    .faq {
        background-position: 50% 50%;
        padding: 50px 0 0;
    }
    .question {
        padding: 10px 64px 10px 0;
    }
    .question::before {
        right: 0;
    }
    .answer {
        padding-left: 0;
    }
    .faq_block {
        flex-wrap: wrap;
    }
    .faq_left, .faq_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .faq_title {
        position: relative;
        top: 0;
        font-size: 22px;
        margin-bottom: 0px;
    }
    .faq_title br {
        display: none;
    }
    .faq ul li {
        margin-left: 17px;
    }
    .faq::before {
        content: none;
    }
    .faq_titles {
        position: relative;
        top: 0;
    }
    .promo_btn {
        width: 100%;
        font-size: 20px;
        padding: 25px 20px;
    }
    .faq_right .promo_btn {
        display: flex;
    }
    .faq_left .promo_btn {
        display: none;
    }
}

@media(max-width:344px) {
    .faq_btn p {
        font-size: 18px;
    }
}