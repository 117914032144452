.presale_blackfriday {
    font-family: Manrope;
    position: relative;
    color: #fff;
    background-color: #171717;
}

.presale_blackfriday_start {
    width: 100%;
}
.presale_blackfriday_start h2 {
    display: none;
}
.presale_blackfriday_start .sales-widget__offers {
    margin-top: 0;
    width: 100%;
}
.presale_blackfriday_start .chakra-button {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #171717;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 600px;
    background: #EBFF5E;
    cursor: pointer;
    position: relative;
    padding: 0 40px;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
}
.presale_blackfriday_start .chakra-button:hover {
    transform: translateY(-4px);
    background: #EBFF5E;
}
.presale_blackfriday_start .chakra-button:active {
    background: #EBFF5E;
}
.presale_blackfriday_start .chakra-button p {
    position: relative;
    font-size: 24px;
    font-family: 'Manrope';
    font-weight: 600;
}
.presale_blackfriday_start form .chakra-button {
    margin-bottom: 5px;
}
.presale_blackfriday_start .sales-widget__user-form button > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-size: 12px;
    line-height: normal;
    text-transform: lowercase;
}
.presale_blackfriday_start .gt-offer {
    transition: .3s ease-in-out;
    background-color: #fff;
    border-radius: 12px;
    padding: 10px;
    margin-top: 0!important;
    margin-bottom: 12px;
    display: none;
}
.presale_blackfriday_start .gt-offer:has(.chakra-radio__control[data-checked]) {
  background-color: #ED3046;  
}
.presale_blackfriday_start .gt-offer:has(.chakra-radio__control[data-checked]) .gt-offer__title {
  color: #fff;
}
.presale_blackfriday_start .gt-offer:has(.chakra-radio__control[data-checked]) .gt-offer__price {
  color: #fff;
}
.presale_blackfriday_start .gt-offer > label {
    margin-bottom: 0!important;
    display: flex;
    align-items: center;
}
.presale_blackfriday_start .gt-offer > label > .chakra-checkbox__control {
    background: #BC9375;
    border-color: #BC9375;
}
.presale_blackfriday_start .chakra-radio__control {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    background-color: #C1C1C1!important;
    position: relative;
    border: none!important;
    outline: none!important;
    border: 1px solid #C1C1C1!important;
    outline: none!important;
    transition: .2s ease-in-out;
}
.presale_blackfriday_start .chakra-radio__control[data-checked] {
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #fff!important;
    border-color: #fff!important; 
    flex: 0 1 28px;
    max-width: 28px;
}
.presale_blackfriday_start .chakra-radio__label {
    flex: 1;
    margin-left: 12px;
}
.presale_blackfriday_start .chakra-radio__control::before {
    content: '';
    position: absolute;
    width: 14px!important;
    height: 14px!important;
    background-color: #fff!important;
    border-radius: 100%!important;
    transition: .2s ease-in-out;
}
.presale_blackfriday_start .chakra-radio__control[data-checked]::before {
  background-color: #ED3046!important;
}
.presale_blackfriday_start .gt-offer__title {
    font-size: 18px;
    line-height: 1.2;
    font-family: Manrope;
    font-weight: 500;
    padding-left: 0;
    transition: .3s ease-in-out;
    color: #2A2A2A;
}
.presale_blackfriday_start .gt-offer__title ~ .chakra-text {
    padding-left: 0;
    /* margin-left: -26px; */
    font-family: Manrope;
    font-size: 14px;
    line-height: 1.6;
    color: #777879;
    display: none!important;
}
.presale_blackfriday_start span.chakra-radio__label > .chakra-stack {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    align-items: center;
}
.presale_blackfriday_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 0;
}
.presale_blackfriday_start .gt-offer__marketing-price {
    font-family: Commissioner;
    font-size: 18px;
    line-height: 1.3;
    font-weight: normal;
    text-decoration: line-through;
    order: 2;
    color: rgba(255, 255, 255, 0.3);
    display: none!important;
}
.presale_blackfriday_start .gt-offer__price {
    font-family: Manrope;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: #ED3046;
    margin-bottom: 0;
    transition: .3s ease-in-out;
}
.presale_blackfriday_start .sales-widget__selected-offers {
    max-width: 800px;
    background-color: transparent;
    padding: 0 0 0px 0;
}
.presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack {
    display: flex;
    align-items: flex-start;
}
.presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
    font-size: 20px;
    line-height: 26px;
    font-family: Manrope;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 20px;
    margin-bottom: 10px;
    /* color: #fff; */
}
.presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2) {
    padding-left: 20px;
    font-family: Manrope;
    font-size: 16px;
    line-height: 26px;
    display: none!important;
}
.presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack {
    display: flex;
    flex-direction: column;
}
.presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(1) {
    font-family: Manrope;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: -0.01em;
    order: 2;
    color: #676C72;
}
.presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-family: Manrope;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 5px;
}
.presale_blackfriday_start .chakra-form-control > label, .presale_blackfriday_start label {
    /* color: #fff; */
}
.presale_blackfriday_start .sales-widget__user-form {
    max-width: 800px;
}
.presale_blackfriday_start .sales-widget__user-form input {
    /* background-color: #fff; */
    border-radius: 8px;
    text-align: left;
}
.presale_blackfriday_start .sales-widget__offers > .chakra-stack {
    min-width: 100%;
}
.presale_blackfriday_start .sales-widget__offers > .chakra-radio-group > .chakra-stack {
    max-width: 100%;
    margin-bottom: 20px;
}
.presale_blackfriday_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-end;
}
.presale_blackfriday_start .sales-widget__offers > .chakra-stack .chakra-divider {
    display: none;
}
.presale_blackfriday_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div {
    margin-bottom: 15px;
}
.presale_blackfriday_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-marketing-price {
    font-family: Manrope;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    color: rgba(22, 19, 19, 0.5);
}
.presale_blackfriday_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-price {
    color: #1C1C1A;
}
@media(max-width: 575px) {
    .presale_blackfriday_start .gt-offer, .presale_blackfriday_start .sales-widget__selected-offers {
        /* padding: 0; */
    }
    .presale_blackfriday_start span.chakra-radio__label > .chakra-stack, .presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack {
        /* flex-direction: column; */
    }
    .presale_blackfriday_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
        /* margin-left: -25px; */
        /* align-items: flex-start; */
        /* width: 100%; */
        padding-left: 0;
    }
    .presale_blackfriday_start .gt-offer__title {
        font-size: 16px;
        padding-left: 0;
    }
    .presale_blackfriday_start .gt-offer__title ~ .chakra-text {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
        padding-top: 10px;
    }
    .presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2), .presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
        padding-left: 0;
        font-size: 16px;
        line-height: 22px;
    }
    .presale_blackfriday_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .presale_blackfriday_start .sales-widget__user-form button {
        margin-top: 15px;
    }
    .presale_blackfriday_start .sales-widget__user-form {
        padding: 0;
    }
    .presale_blackfriday_start .chakra-button {
        height: 60px;
        width: 90%;
        font-size: 18px;
        line-height: 1;
    }
    .presale_blackfriday_start form .chakra-button {
        width: 100%;
        margin-top: 0;
    }
    .presale_blackfriday_start .chakra-button p {
        top: 0;
        font-size: 18px;
    }
    .presale_blackfriday_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .presale_blackfriday_start .gt-offer__marketing-price {
        margin-left: 8px;
    }
    .velvet br {
        display: none;
    }
}
@media(max-width: 360px) {
  .presale_blackfriday_start span.chakra-radio__label > .chakra-stack {
    flex-direction: column;
    align-items: flex-start;
  }
  .presale_blackfriday_start .gt-offer .gt-offer__price {
    margin-left: 0;
    margin-top: 4px;
  }
}