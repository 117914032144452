.faq {
    padding: 80px 0;
    position: relative;
    margin-top: -2px;
    background-color: #FAFAFA;
}
.faq_block {
    display: flex;
}
.faq_left {
    flex: 1;
}
.faq_right {
    flex: 0 1 582px;
    max-width: 582px;
}
.faq_title {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 56px;
    font-family: Qanelas;
    line-height: 62px;
}
.faq_subtitle {
    font-family: Roboto;
    font-size: 24px;
    line-height: 34px;
    color: #4D5761;
    margin-bottom: 32px;
}
.accordion {
    max-width: 685px;
    margin: 0 auto;
}
.question {
    padding: 17px 50px 20px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid rgba(255,255,255,.1);
    transition: .2s ease-in-out;
    color: rgba(17, 25, 39, 0.7);
}
.question::before {
    content: '+';
    width: 24px;
    height: 24px;
    border: 2px solid #000;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 20px;
    font-size: 26px;
    font-weight: 300;
    font-family: Qanelas;
    transition: .2s ease-in-out;
}
.question:hover {
    border-color: #D8376A;
}
.active .question {
    color: #D8376A;
}
.active .question::before {
    transform: rotate(45deg);
    color: #D8376A;
    border-color: #D8376A;
}
.question:hover::before {
    color: #D8376A;
    border-color: #D8376A;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 30px;
    transition: .2s ease-in-out;
    color: rgba(77, 87, 97, 0.75);
}
.active .answer {
    padding-top: 14px;
    max-height: 550px;
    padding-bottom: 25px;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}
.faq ul li {
    list-style-type: disc;
    margin-bottom: 10px;
    margin-left: 30px;
}
.answer a {
    color: #D8376A;
    font-weight: 500;
}
.btn {
    width: 585px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(89.69deg, #26CBAD 14.2%, #0CA488 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    margin: 30px auto 0;
    transition: .2s ease-in-out;
}
.btn::before {
    content: '';
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.6);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .2s ease-in-out;
}
.btn:active::before {
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4), inset 0px 4px 4px #24B79C;
}
.btn:hover {
    transform: translateY(-6px);
}
.btn:hover::before {
    opacity: 1;
}
.btn p {
    position: relative;
}
.faq_more__links {
    display: flex;
}
.faq_more__link {
    margin-right: 12px;
}
@media(max-width: 767px) {
    .faq {
        padding: 45px 0;
    }
    .faq_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 30px;
        padding-left: 0;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
    }
    .btn {
        width: 100%;
    }
}
@media(max-width: 575px) {
    .faq {
        background-position: 50% 50%;
        padding-top: 0;
    }
    .question {
        padding: 10px 50px 10px 15px;
    }
    .answer {
        padding-left: 15px;
    }
    .faq_block {
        flex-wrap: wrap;
    }
    .faq_left, .faq_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .faq_left {
        order: 2;
        border-radius: 16px;
        border: 0.546px solid rgba(255, 255, 255, 0.7);
        background: #FFF;
        padding: 25px 15px;
        text-align: center;
        box-shadow: 0px 2.18368px 6.55105px -1.09184px rgba(16, 24, 40, 0.02), 0px 6.55105px 13.1021px -2.18368px rgba(16, 24, 40, 0.04);
    }
    .faq_title {
        font-size: 26px;
        line-height: 28px;
    }
    .faq_title br, .faq_subtitle br {
        display: none;
    }
    .faq_subtitle {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 25px;
    }
    .faq_more__links {
        justify-content: center;
    }
    .faq_right {
        margin-bottom: 40px;
    }
}