.nomination {
    background-color: #F6F4FA;
    padding-top: 84px;
}
.nomination_title {
    font-family: ProximaN;
    font-weight: bold;
    font-size: 60px;
    line-height: 1;
    margin-bottom: 30px;
    letter-spacing: -0.12rem;
    text-align: center;
    color: #000019;
    text-transform: uppercase;
    padding-top: 100px;
}
.nomination_subtitle {
    margin-bottom: 20px;
    text-align: center;
    color: #4D4D4D;
    font-size: 18px;
    line-height: 1.45;
    letter-spacing: 0.02rem;
}
.nomination_subtitle2 {
    margin-bottom: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #EE42DD;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.35;
}
.nomination_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -20px;
}
.nomination_item {
    flex: 0 1 400px;
    max-width: 400px;
    padding: 0 20px;
    margin-bottom: 40px;
}
.nom_img {
    cursor: pointer;
}
.nom_img img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.nomination_item_info {
    /* border: 1px solid #764BFF4F; */
    border-radius: 16px;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.nom_info {
    background-color: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-top: auto;
}
.nom_top {
    display: flex;
    border-bottom: 1px solid #FDE7ED;
    /* padding: 12px 16px; */
    /* padding: 12px 0; */
}
.nom_name {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-weight: 600;
    font-size: 20px;
    color: #111927;
    width: 100%;
    line-height: 1.4;
    flex: 1;
    padding-left: 16px;
}
.nom_place {
    flex: 0 1 179px;
    max-width: 179px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #60862F;
    border-radius: 15px;
    height: 54px;
    font-weight: 500;
    color: #fff;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    transition: .3s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 0.84px;
}
.nom_name:last-of-type {
    /* justify-content: space-between; */
    gap: 6px;
}
.nom_name span {
    /* color: #E9624D; */
}
.nom_btn {
    flex: 0 1 179px;
    max-width: 179px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #942AFF;
    /* border-radius: 15px; */
    height: 54px;
    font-weight: 500;
    color: #fff;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    transition: .3s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 0.84px;
}
.nom_btn:hover {
    opacity: 0.9;
}
.nom_btn_closed {
    pointer-events: none;
    background: #EAEAEA;
    border-radius: 8px;
    flex: 0 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;;
    height: 54px;
    font-weight: 500;
    color: #959595;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    transition: .3s ease-in-out;
}
.nom_sizes {
    padding: 16px 16px 20px;
    display: flex;
    flex-wrap: wrap;
}
.nom_sizes div {
    flex: 0 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    color: #4D4D4D;
    font-family: Inter;
    font-size: 16px;
}
.nom_sizes div b {
    font-weight: 600;
}
.nom_sizes div:nth-of-type(1), .nom_sizes div:nth-of-type(2) {
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
    margin-bottom: 8px;
    padding-bottom: 8px;
}
.nom_sizes div img {
    margin-right: 6px;
    filter: grayscale(1);
}

.t1 {
    text-align: center;
    margin-bottom: 76px;
    text-transform: uppercase;
    font-family: Unisans;
    font-size: 60px;
    font-weight: 900;
    line-height: 1;
    color: #000019;
}
.t2 {
    text-align: center;
    margin-bottom: 46px;
}
.t2 p {
    color: #4D4D4D;
    font-family: Manrope;
    font-size: 18px;
    line-height: 1.45;
    text-align: center;
    letter-spacing: 0.36px;
    opacity: 0.7;
}

@media(max-width: 575px) {
    .t1 {
        font-size: 30px;
    }
    .t2 {
        align-items: flex-start;
    }
    .t2 p {
        font-size: 16px;
    }
    .nomination {
        padding: 30px 0 0;
    }
    .nomination_title {
        font-size: 36px;
        margin-bottom: 12px;
    }
    .nomination_subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .nomination_subtitle2 {
        margin-bottom: 24px;
        font-size: 18px;
    }
    .nomination_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .nomination_item:last-of-type {
        margin-bottom: 0;
    }
    .nom_name {
        font-size: 16px;
        /* padding: 12px; */
    }
    .nom_btn {
        width: 100%;
        font-size: 12px;
    }
    .nom_sizes {
        padding: 16px 12px 20px;
    }
    .nom_sizes > div {
        font-size: 14px;
    }
}
@media(max-width: 359px) {
    .nom_sizes > div {
        font-size: 12px;
    }
}
@media(max-width: 355px) {
    .nomination_title {
        font-size: 32px;
    }
}


.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.79);
    backdrop-filter: blur(8px);
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    z-index: 99;
    transition: .3s ease-in-out;
    padding: 60px;
    overflow: auto;
}
.modal_active {
    opacity: 1;
    pointer-events: all;
}
.close_btn {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #060606;
    border: 0.804px solid #656565;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto 30px;
    transition: .3s ease-in-out;
}
.close_btn svg {
    width: 16px;
    height: 17px;
}
.close_btn svg path {
    transition: .3s ease-in-out;
}
.close_btn:hover {
    background-color: #EE42DD;
    border-color: #EE42DD;
}
.modal_img {
    max-width: 700px;
    margin: 0 auto;
    transform: scale(0);
    transition: .3s ease-in-out;
}
.modal_active .modal_img {
    transform: scale(1);
}
@media(max-width: 575px) {
    .modal {
        padding: 35px 15px;
    }
}