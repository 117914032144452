.author {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/author_bg.png');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    padding: 85px 0;
    position: relative;
    overflow: hidden;
}
.author_title {
    color: #F7720C;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.author_subtitle {
    font-size: 60px;
    line-height: 66px;
    font-weight: 800;
    margin-bottom: 10px;
}
.author_info p {
    font-size: 20px;
    line-height: 26px;
    max-width: 510px;
}
.author_info p:last-of-type {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 25px;
    font-weight: bold;
    margin-top: 40px;
}
.author_info p strong {
    font-weight: 600;
}
.author_info img {
    position: absolute;
    top: 50px;
    left: 50%;
    mix-blend-mode: darken;
}
.author_block {
    display: flex;
    align-items: flex-end;
    margin: 0 -35px;
}
.author_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 35px;
}
.author_info ul li {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px;
    padding-left: 29px;
    position: relative;
}
.author_info ul li strong {
    font-weight: 600;
}
.author_info ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 19px;
    height: 19px;
    background-size: cover;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/marker_gr.png');
}

@media(max-width: 991px) {
    .author_info img {
        position: relative;
        left: 0;
        top: 0;
    }
    .author_info p {
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .author {
        padding: 45px 0;
    }
    .author_title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .author_subtitle {
        font-size: 30px;
        line-height: 33px;
        margin-bottom: 15px;
    }
    .author_subtitle br {
        display: none;
    }
    .author_info {
        text-align: center;
    }
    .author_info p {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
    }
    .author_info p:last-of-type {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
    }
    .author_block {
        flex-wrap: wrap;
        margin: 0;
    }
    .author_item {
        padding: 0;
        flex: 0 1 100%;
        max-width: 100%;
        text-align: left;
    }
    .author_item ul li {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 20px;
    }
    .author_item ul li br {
        display: none;
    }
    .author_info ul li::before {
        top: 4px;
    }
}
@media(max-width: 374px) {
    .author_subtitle {
        font-size: 26px;
        line-height: 30px;
    }
}