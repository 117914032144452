.oferta {
  max-width: 970px;
  margin: 15px auto 80px;

  font-size: 20px;
  line-height: 1.55;
  font-family: "Open Sans", sans-serif, sans, arial;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  color: #000;
}
.oferta h1 {
  text-align: center;
  font-size: 36px;
  line-height: 1.23;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  margin-bottom: 50px;
}
.oferta > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  font-weight: bold;
}
.oferta > p {
  margin-bottom: 30px;
}
.oferta h2 {
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.oferta a {
  color: #337ab7;
  text-decoration: none;
}
.oferta a:hover,
.oferta a:focus {
  color: #23527c;
  text-decoration: underline;
}
.oferta ul {
  display: block;
  list-style-type: disc;
  padding-inline-start: 40px;
}

@media (max-width: 767px) {
  .oferta {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .oferta h1 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .oferta p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .oferta h2 {
    margin-top: 20px;
    line-height: 1.1;
  }
}
