.youget {
    position: relative;
    background-color: var(--pink-bg);
    padding-top: 76px;
    padding-bottom: 35px;
}
.youget_arrow {
    position: absolute;
    left: 50%;
    margin-left: -39px;
    top: -20px;
}
.youget_face {
    position: absolute;
    top: 94%;
    left: 50%;
    z-index: 1;
    margin-left: 70px;
}
.youget::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 76px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/pregnancy/bg_before.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}
.youget::after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 97px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/pregnancy/bg_after.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.youget_title {
    font-family: Bebas;
    font-size: 64px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    margin-top: 30px;
}
.yuoget_block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.youget_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 10px;
    text-align: center;
    transition: .2s ease-in-out;
}
.youget_item:hover {
    transform: translateY(-10px);
}
.youget_item-number {
    font-size: 64px;
    line-height: 1.2;
    font-weight: 500;
    font-family: Bebas;
    letter-spacing: -1px;
    margin-bottom: 10px;
    color: var(--main-p-color);
}
.youget_item-description {
    font-weight: 500;
    margin-bottom: 20px;
    opacity: 0.7;
    line-height: 1.3;
}
.youget .main_btn_p {
    height: 70px;
    display: block;
    margin: 10px auto 0;
}

@media(max-width: 767px) {
    .youget_item {
        flex: 0 1 50%;
        max-width: 50%;
    }
    .youget_title {
        font-size: 60px;
    }
}
@media(max-width: 575px) {
    .youget_title {
        font-size: 38px;
    }
    .youget_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .youget_face {
        margin-left: -105px;
        top: 98%;
    }
}