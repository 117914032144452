.onmarafon {
    padding: 80px 0 60px;
    background-color: #f5f5f5;
}
.onmarafon_title {
    font-size: 50px;
    line-height: 55px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 40px;
}
.onmarafon_title span {
    color: var(--removefat-purple)
}
.onmarafon_block {
    padding-top: 22px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.onmarafon_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 20px;
    margin-bottom: 40px;
}
.onmarafon_item_info {
    padding: 45px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: .2s ease-in-out;
    border-radius: 7px;
}
.onmarafon_item_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(90, 90, 253, 0.5);
}
.onmarafon_item_title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
}

@media(max-width: 991px) {
    .onmarafon_item_title br {
        display: none;
    }
}
@media(max-width: 767px) {
    .onmarafon {
        padding: 45px 0;
    }
    .onmarafon_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 25px;
    }
    .onmarafon_block {
        padding-top: 0;
    }
    .onmarafon_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 15px;
        text-align: center;
    }
    .onmarafon_item_title {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
    }
}
@media(max-width: 575px) {
    .onmarafon_block {
        margin: 0 -10px;
    }
    .onmarafon_item {
        padding: 0 10px;
    }
    .onmarafon_title br {
        display: none;
    }
}
@media(max-width: 374px) {
    .onmarafon_title {
        font-size: 30px;
        line-height: 34px;
    }
}