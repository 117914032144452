.press {
    padding: 90px 0 100px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_2.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
}
.press::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 450px;
    top: -145px;
    width: 330px;
    height: 386px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/fire_3.png');
    pointer-events: none;
}
.press::after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -1000px;
    bottom: 0;
    width: 579px;
    height: 442px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/fire_4.png');
    pointer-events: none;
}
.press_title {
    text-align: center;
    text-transform: uppercase;
    font-size: 50px;
    line-height: 55px;
    font-weight: 800;
    margin-bottom: 20px;
}
.press_title span {
    color: var(--abs-green);
}
.press_subtitle {
    text-align: center;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 50px;
}
.press_subtitle strong {
    font-weight: 600;
}
.press_first {
    background-color: var(--abs-green);
    color: #fff;
    font-size: 30px;
    line-height: 39px;
    padding: 32px 32px 32px 39px;
    border-radius: 20px 0px 20px 20px;
    max-width: 551px;
    margin-left: 115px;
    position: relative;
}
.press_second {
    background-color: var(--abs-pink);
    color: #fff;
    font-size: 30px;
    line-height: 39px;
    padding: 32px 25px 32px 39px;
    border-radius: 0px 20px 20px 20px;
    max-width: 598px;
    margin-left: 385px;
    margin-top: -35px;
    position: relative;
}

@media(max-width: 991px) {
    .press {
        overflow: hidden;
    }
    .press_subtitle br, .press_title br {
        display: none;
    }
    .press_first {
        margin-left: 45px;
    }
    .press_second {
        margin-left: 295px;
    }
}
@media(max-width: 767px) {
    .press::before, .press::after {
        content: none;
    }
    .press_first {
        padding: 15px;
        font-size: 18px;
        line-height: 22px;
        margin-left: 15px;
    }
    .press_second {
        padding: 15px;
        font-size: 18px;
        line-height: 22px;
        margin-left: 165px;
        margin-top: -10px;
    }
    .press_title {
        font-size: 32px;
        line-height: 35px;
    }
    .press_subtitle {
        font-size: 18px;
        line-height: 22px;
    }
}
@media(max-width: 575px) {
    .press {
        padding: 45px 0;
    }
    .press_first {
        margin-left: 0;
    }
    .press_second {
        margin-left: 30px;
        margin-top: -5px;
    }
}
@media(max-width: 374px) {
    .press_title {
        font-size: 30px;
        line-height: 32px;
    }
    .press_subtitle {
        font-size: 16px;
    }
    .press_second, .press_first {
        font-size: 16px;
        line-height: 20px;
    }
}