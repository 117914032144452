.knows {
    
    background-color: #140d0d;
    padding: 65px 0 100px;
    position: relative;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/knows_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
}
.moving_line {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: max-content;
    animation: scroll 10s linear infinite;
    background-color: #051413;
    padding: 20px 0;
    z-index: 1;
}
.moving_line div {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    margin: 0 10px;
    display: flex;
    align-items: center;
}
.moving_line div img {
    margin-right: 15px;
}
@keyframes scroll {
    0% {
        transform: translateX(-440px);
    }
    100% {
        transform: translateX(0);
    }
}
.knows_title {
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    font-family: Dela;
    font-size: 52px;
    line-height: 58px;
    color: #fff;
}
.knows_subtitle {
    margin-bottom: 60px;
    text-align: center;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
}
.knows_video {
    height: 480px;
    max-width: 855px;
    margin: 0 auto;
    position: relative;
}
.knows_vid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/preview2.jpg');
    background-size: cover;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}
.knows_vid::before {
    /* content: ''; */
    position: absolute;
    left: 50%;
    margin-left: -30px;
    top: 50%;
    margin-top: -30px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/play.png');
    width: 60px;
    height: 60px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.vid_hide {
    opacity: 0;
    pointer-events: none;
}
.knows_video iframe {
    border-radius: 17px;
}
@media(max-width: 575px) {
    .knows {
        padding: 80px 0 40px;
        background-image: none;
    }
    .moving_line {
        padding: 10px 0;
    }
    .moving_line div {
        font-size: 16px;
        line-height: 20px;
    }
    .knows_title {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .knows_subtitle {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    .knows_video {
        height: 100%;
        width: 100%;
    }
    .knows_vid::before {
        width: 30px;
        height: 30px;
        background-size: cover;
        margin-top: -15px;
        margin-left: -15px;
    }
}