.promo {
    padding-top: 110px;
    overflow: hidden;
    height: 776px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/promo_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
}
.promo_logo img {
    height: 130px;
    margin-left: -27px;
    margin-bottom: -40px;
    margin-top: -40px;
}
@media(max-width: 767px) {
    .promo_logo img {
        height: 90px;
        margin-left: -17px;
        margin-bottom: -35px;
        margin-top: -30px;
    }
}
.promo_pretitle {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.1em;
    display: inline-block;
    margin-bottom: 30px;
}
.promo_pretitle img {
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.promo_pretitle br {
    display: none;
}
.promo_pretitle img:nth-of-type(1) {
    display: none!important;
}
.promo_title {
    color: #fff;
    font-family: Dela;
    font-size: 48px;
    line-height: 50px;
    text-transform: uppercase;
    margin-bottom: 25px;
}
.promo_title span {
    color: #25B1FF;
}
.promo_subtitle {
    font-size: 22px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: 300;
}
.promo_subtitle span {
    font-weight: 500;
}
.promo_icons {
    display: flex;
    flex-wrap: wrap;
    max-width: 670px;
}
.promo_icon {
    flex: 0 1 222px;
    margin-bottom: 40px;
    padding: 18px 20px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 3.15718px 7.89294px rgba(0, 0, 0, 0.03), inset 0px 0.789294px 1.57859px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(3.94647px);
    border-radius: 8px;
    margin-right: 20px;
}
.promo_icon div img:last-of-type {
    display: none!important;
}
.promo_icon div img:first-of-type {
    margin-left: -30px;
    margin-top: -30px;
    margin-bottom: -16px;
}
.promo_icon p {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    opacity: .9;
}
.promo_bottom {
    display: flex;
    align-items: center;
}
.start_countdown {
    margin-left: 30px;
}
.start_countdown > p {
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}
.start_countdown_timer {
    font-family: Dela;
    font-size: 30px;
    line-height: 38px;
    color: #EF3F34;
}
.start_countdown_timer > div {
    display: flex;
}
.start_countdown_timer span {
    position: relative;
    left: -3px;
    top: 0px;
}
.promo_btn {
    width: 383px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: #0D8ED7;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 4px 12px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: .3s ease-in-out;
}
.promo_btn::before {
    content: '';
    background: #21A3EC;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 4px 12px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.promo_btn:active::before {
    background: #0E85C8;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 12px 24px #135A83;
}
.promo_btn:hover {
    transform: translateY(-6px);
}
.promo_btn:hover::before {
    opacity: 1;
}
.promo_btn p {
    position: relative;
}
.promo_logo {
    margin-bottom: 30px;
}
@media(max-width: 991px) {
    .promo {
        background-position: 40% 50%;
        height: auto;
    }
}
@media(max-width: 767px) {
    .promo_logo {
        margin-bottom: 25px;
    }
}
@media(max-width: 575px) {
    .promo {
        padding: 35px 0;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/promo_bg_mob.jpg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 50% 30%;
        background-color: #1A1717;
    }
    .promo_pretitle {
        text-align: center;
    }
    .promo_pretitle img:nth-of-type(1) {
        display: inline-block!important;
    }
    .promo_pretitle img:nth-of-type(2) {
        display: none!important;
    }
    .promo_pretitle br {
        display: block;
    }
    .promo_title br {
        display: none;
    }
    .promo_title {
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 680px;
    }
    .promo_subtitle {
        background-color: #fff;
        border-radius: 5px;
        text-align: center;
        color: #494848;
        font-size: 22px;
        line-height: 28px;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 15px;
        padding-bottom: 20px;
    }
    .promo_subtitle span {
        font-weight: bold;
    }
    .promo_block {
        display: flex;
        flex-direction: column;
    }
    .promo_icons {
        background-color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .promo_icon {
        flex: 0 1 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 0;
        margin-right: 0;
        padding-left: 35px;
        padding-right: 35px;
        box-shadow: none;
    }
    .promo_icon div img:last-of-type {
        display: inline-block!important;
        filter: hue-rotate(330deg);
    }
    .promo_icon div img:first-of-type {
        display: none!important;
    }
    .promo_icon p {
        text-align: center;
        color: #494848;
        font-size: 18px;
        line-height: 22px;
        margin-left: 0;
        margin-top: 5px;
    }
    .promo_icon p br {
        display: none;
    }
    .promo_bottom {
        flex-wrap: wrap;
    }
    .promo_btn {
        width: 100%;
        letter-spacing: 0;
        order: 1;
    }
    .start_countdown {
        text-align: center;
        margin-left: 0;
        margin-bottom: 20px;
        font-family: Dela;
        color: #fff;
        font-size: 22px;
        line-height: 28px;
        margin-top: 20px;
    }
    .start_countdown_timer > div {
        justify-content: center;
    }
    .promo_subtitle {
        font-size: 20px;
        line-height: 24px;
    }
}
@media(max-width: 490px) {
    .promo_title {
        margin-bottom: 545px;
    }
}
@media(max-width: 347px) {
    .promo_title {
        margin-bottom: 500px;
    }
}