.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa2/promo_bg.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    padding-top: 25px;
    height: 740px;
    background-color: #02020F;
}
.promo_logo img {
    height: 130px;
    margin-left: -27px;
    margin-bottom: -40px;
    margin-top: -40px;
}
@media(max-width: 767px) {
    .promo_logo img {
        height: 90px;
        margin-left: -17px;
        margin-bottom: -35px;
        margin-top: -30px;
    }
}
.promo_pretitle {
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
.promo_title {
    font-size: 56px;
    line-height: 62px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 15px;
}
.promo_title span {
    color: var(--popa2-yellow);
}
.promo_subtitle {
    font-size: 25px;
    line-height: 30px;
    color: rgba(255,255,255,.75);
    margin-bottom: 20px;
}
.promo_subtitle strong {
    font-weight: 600;
    color: #fff;
}
.promo_list ul li {
    position: relative;
    font-size: 16px;
    line-height: 21px;
    color: rgba(255,255,255,.75);
    padding-left: 20px;
    margin-bottom: 10px;
}
.promo_list ul li br {
    display: none;
}
.promo_list ul li:last-of-type {
    margin-bottom: 0;
}
.promo_list ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 8px;
    height: 8px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa2/marker_yellow.svg');
}
.promo_btns {
    display: flex;
    align-items: center;
}
.promo_date {
    font-size: 33px;
    line-height: 42px;
    font-weight: 600;
    color: #fff;
    margin-left: 30px;
}
.promo_date br {
    display: none;
}
.promo_items {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
    margin-top: 15px;
}
.promo_item1 {
    width: 182px;
    height: 118px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item2 {
    width: 182px;
    height: 118px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item3 {
    width: 184px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
    top: -1px;
}
.promo_item1 p, .promo_item2 p, .promo_item3 p {
    position: absolute;
    bottom: 10px;
}
.promo_item1 p {
    bottom: 15px;
}
.promo_point1 {
    color: #fff;
    background: linear-gradient(180deg, #6EC652 0%, #42832D 100%);
    border-radius: 60px;
    width: 191px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -90px;
    margin-left: 60px;
    font-weight: 600;
}
.promo_point2 {
    color: #fff;
    background: linear-gradient(180deg, #FE6C8D 0%, #FF275F 100%);
    border-radius: 60px;
    width: 181px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -100px;
    margin-left: 383px;
    font-weight: 600;
}
.promo_point3 {
    color: #fff;
    background: linear-gradient(180deg, #51504D 0%, #0E0E0E 100%);
    border-radius: 60px;
    width: 199px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 60px;
    margin-left: 340px;
    font-weight: 600;
}

@media(min-width: 2100px) {
    .promo {
        background-size: cover;
        background-position: 50% 60%;
    }
}
@media(max-width: 1199px) {
    .promo {
        height: auto;
        padding-bottom: 15px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        background: transparent;
        margin-left: 0;
        margin-top: 0;
        left: 0;
        top: auto;
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        margin-left: 17px;
        margin-top: 35px;
    }
    .promo_point1 span {
        color: rgba(255, 237, 52, 1)
    }
    .promo_point2 span {
        color: rgba(107, 196, 84, 1)
    }
    .promo_point3 span {
        color: rgba(235, 10, 117, 1)
    }
    .promo_btns {
        flex-direction: column;
        align-items: flex-start;
    }
    .promo_btns > div:first-of-type {
        order: 1;
    }
    .promo_date {
        margin-left: 0;
        margin-bottom: 20px;
    }
}
@media(max-width: 991px) {
    .promo {
        overflow: hidden;
    }
    .promo_subtitle {
        margin-bottom: 15px;
    }
}
@media(max-width: 767px) {
    .promo {
        padding-top: 45px;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 50% 0%;
        background-color: #090909;
        position: relative;
    }
    .promo_pretitle {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 10px;
    }
    .promo_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .promo_subtitle {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .promo_block_order {
        display: flex;
        flex-direction: column;
    }
    .promo_list {
        order: 1;
    }
    .promo_list ul li {
        padding-left: 0;
    }
    .promo_list ul li::before {
        content: none;
    }
    .promo_btns {
        margin-top: 15px;
    }
    .promo_date {
        margin-bottom: 10px;
        position: relative;
    }
    .promo::before {
        content: none;
    }
    .promo_items {
        flex-wrap: wrap;
        gap: 0;
        margin-bottom: 15px;
    }
    .promo_item1, .promo_item2 {
        flex: 0 1 33.333333%;
        max-width: 33.333333%;
        margin-right: 20px;
        justify-content: flex-start;
        /* width: 115px; */
        /* height: 115px; */
        margin-bottom: 20px;
    }
    .promo_item3 {
        /* width: 115px; */
        /* height: 115px; */
        flex: 0 1 100%;
        max-width: 100%;
        justify-content: flex-start;
    }
    .promo_item1 p {
        padding-left: 20px;
    }
    .promo_item2 p {
        padding-left: 35px;
    }
    .promo_item3 p {
        padding-left: 40px;
    }
    .promo_list ul li {
        color: #fff;
        font-size: 14px;
        line-height: 18px;
    }
}
@media(max-width: 575px) {
    .promo {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa2/promo_bg_m.jpg');
        background-position: 100% 50%;
        background-size: cover;
        padding-bottom: 0;
    }
    .promo_pretitle br {
        display: none;
    }
    .promo_list ul li br {
        display: block;
    }
    .promo_items {
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
    }
    .promo_item1, .promo_item2, .promo_item3 {
        /* width: 186px; */
        /* height: 150px; */
        flex: 0 1 50%;
        max-width: 50%;
        margin-right: 0;
        justify-content: center;
        margin-bottom: 5px;
    }
    .promo_item3 {
        top: 0;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 14px;
        padding-left: 0;
    }
    .promo_date {
        font-size: 25px;
        line-height: 30px;
    }
    .promo_date br {
        display: block;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 14px;
        margin: 0 5px;
    }
    .promo_subtitle br {
        display: none;
    }
    .promo_items {
        padding-left: 0;
    }
    .promo_btns {
        margin-top: 30px;
    }
    .promo_points_block {
        text-align: center;
        background-color: #000;
        padding: 5px 0;
        margin-top: 5px;
    }
}
@media(max-width: 440px) {
    .promo {
        background-size: auto;
    }
}
@media(max-width: 374px) {
    .promo {
        padding-top: 20px;
    }
    .promo_title {
        font-size: 30px;
        line-height: 32px;
    }
    .promo_subtitle {
        font-size: 16px;
        line-height: 21px;
    }
    .promo_items {
        margin-top: 0;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 12px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 12px;
    }
    .promo_date {
        font-size: 28px;
        margin-bottom: 10px;
    }
}
.promo_logo {
    margin-bottom: 30px;
}
@media(max-width: 767px) {
    .promo_logo {
        margin-bottom: 25px;
    }
}