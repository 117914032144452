.startsection {
    padding: 75px 0 60px;
}
.start_title {
    font-family: Bebas;
    font-weight: bold;
    font-size: 64px;
    line-height: 1;
    text-align: center;
    margin-bottom: 10px;
}
.start_title span {
    color: var(--main-p-color);
}
.start_subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.start_subtitle img {
    width: 22px;
}
.start_subtitle p {
    margin-left: 8px;
    font-size: 20px;
    line-height: 1.5;
}
.start_info {
    text-align: center;
}
.start_info-img {
    margin-bottom: 10px;
    padding-top: 60px;
}
.start_info-img img {
    width: 60px;
    margin: 0 10px;
}
.start_info-description p {
    font-size: 20px;
    line-height: 1.55;
    font-weight: bold;
    margin-bottom: 5px;
}
.start_info-description span {
    font-size: 16px;
    line-height: 1.3;
    color: #878787;
}

@media(max-width: 767px) {
    .start_title {
        font-size: 60px;
    }
    .start_title br {
        display: none;
    }
}
@media(max-width: 575px) {
    .start_title {
        font-size: 38px;
    }
}