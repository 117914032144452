.block {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    padding: 0 15px;
}
.title {
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 1.15;
    text-align: center;
}
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 24px;
    padding: 32px 44px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    font-family: Qanelas;
    color: #F6F6F6;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    max-width: max-content;
    margin: 0 auto;
}
.btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}

@media(max-width: 575px) {
    .title {
        font-size: 42px;
    }
    .btn {
        padding: 24px;
    }
}