footer {
    background-color: #F2F2F2;
    padding: 30px 0 45px;
    font-family: Qanelas;
}
.footer_top {
    border-bottom: 1px solid #D9D9D9;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
}
.footer_top__left {
    display: flex;
    align-items: center;
    color: #878787;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
}
.footer_top__left > div {
    margin-right: 25px;
}
.footer_top__left a {
    color: #878787;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
}
.footer_top__left > a {
    margin: 0 5px;
}
.footer_top__right a {
    margin-left: 10px;
}
.footer_top__right a img {
    height: 40px;
}
.footer_bottom {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}
.footer_bottom__left {
    display: flex;
    align-items: center;
}
.footer_bottom__year {
    font-size: 18px;
    line-height: 20px;
    color: #878787;
    font-weight: 500;
    margin-right: 35px;
}
.footer_bottom__policy a {
    font-size: 18px;
    line-height: 20px;
    color: #878787;
    font-weight: 500;
    padding: 0 12px;
    border-right: 1px solid #d9d9d9;
}
.footer_bottom__policy a:last-of-type {
    border-right: none;
}
.footer_other {
    display: none;
}
.email_ch {
    display: none;
}

.footer_black {
    background-color: #20201F;
}
.footer_black .footer_bottom__policy a, .footer_black .footer_bottom__year, .footer_black .footer_top__left a, .footer_black .footer_top__left {
    color: #fff;
}

.footer_white .footer_bottom__policy a, .footer_white .footer_bottom__year, .footer_white .footer_top__left a, .footer_white .footer_top__left {
    color: #000;
}

@media(max-width: 1199px) {
    .footer_top {
        flex-wrap: wrap;
    }
    .footer_bottom {
        flex-wrap: wrap;
    }
}
@media(max-width: 991px) {
    .footer_top__right a {
        margin-right: 10px;
        margin-left: 0;
        margin-top: 15px;
    }
    .footer_bottom__left {
        flex-wrap: wrap;
    }
    .footer_bottom__policy {
        margin: 10px 0;
    }
    .footer_bottom__policy a:first-of-type {
        margin-left: 0;
        padding-left: 0;
    }
}
@media(max-width: 767px) {
    .footer_top {
        padding-bottom: 25px;
    }
    .footer_bottom {
        padding-top: 25px;
    }
    .footer_top__left > a, .footer_top__right, .footer_bottom__sk {
        display: none;
    }
    .footer_bottom__policy {
        display: flex;
        flex-direction: column;
    }
    .footer_bottom__policy a {
        margin-bottom: 15px;
        margin-left: 0;
        padding-left: 0;
        border: none
    }
    .footer_other {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
    .footer_other__icons {
        display: flex;
        justify-content: space-between;
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .footer_other__soc a {
        margin-right: 10px;
    }
    .footer_bottom__policy {
        margin: 15px 0;
    }

    .footer_white .footer_other__icons img {
        width: 155px;
    }
}
@media(max-width: 440px) {
    .footer_top__left > div, .footer_bottom__year {
        margin-right: 0;
    }
    .footer_top__left, .footer_top__left a {
        font-size: 18px;
        line-height: 20px;
    }
    .footer_bottom__year {
        font-size: 16px;
        line-height: 18px;
    }
    .footer_bottom__policy a {
        font-size: 16px;
        line-height: 18px;
    }
}
@media(max-width: 390px) {
    .footer_other__icons a:first-of-type {
        margin-right: 5px;
    }
    .footer_other__icons a:last-of-type {
        margin-left: 5px;
    }
}