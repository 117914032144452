.um2main {
  background-color: #f6faff;
  font-family: Inter;
  color: #484343;
}
.metod2_preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://gymteam.kinescopecdn.net/img/l/2024/um2/preview.png");
  z-index: 1;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  /* transition: .3s ease-in-out; */
}
.metod2_preview.hide {
  opacity: 0;
  pointer-events: none;
}

.um2main_start .sales-widget__offers > .chakra-radio-group {
  display: none;
}
.um2main_start {
  width: 100%;
}
.um2main_start h2 {
  display: none;
}
.um2main_start .sales-widget__offers {
  margin-top: 0;
  width: 100%;
}
.um2main_start .chakra-button {
  width: 100%;
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  letter-spacing: 0.06rem;
  border-radius: 80px;
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
  cursor: pointer;
  position: relative;
  padding: 0 25px;
  text-align: center;
  transition: 0.2s ease-in-out;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto;
}
.um2main_start .chakra-button:hover {
  transform: translateY(-3px);
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
}
.um2main_start .chakra-button:active {
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
}
.um2main_start .chakra-button p {
  position: relative;
  font-size: 24px;
  /* font-family: ProximaN; */
  /* font-weight: bold; */
  /* letter-spacing: 0.06rem; */
  letter-spacing: -0.4px;
}
.um2main_start form .chakra-button {
  margin-bottom: 5px;
}
.um2main_start
  .sales-widget__user-form
  button
  > .chakra-stack
  > .chakra-text:nth-of-type(2) {
  font-size: 12px;
  line-height: normal;
  text-transform: lowercase;
}
.um2main_start .gt-offer {
  transition: 0.3s ease-in-out;
  /* background-color: #F4F4F4; */
  border-radius: 12px;
  /* padding: 14px 10px 10px; */
  margin-top: 0 !important;
  margin-bottom: 12px;
}
.um2main_start .gt-offer:has(.chakra-radio__control[data-checked]) {
  /* background-color: #EE42DD;   */
}
.um2main_start
  .gt-offer:has(.chakra-radio__control[data-checked])
  .gt-offer__title {
  /* color: #fff; */
}
.um2main_start
  .gt-offer:has(.chakra-radio__control[data-checked])
  .gt-offer__price {
  /* color: #fff; */
}
.um2main_start .gt-offer > label {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}
.um2main_start .gt-offer > label > .chakra-checkbox__control {
  background: #bc9375;
  border-color: #bc9375;
}
.um2main_start .chakra-radio__control {
  border-radius: 100%;
  width: 28px;
  height: 28px;
  background-color: #676c72 !important;
  position: relative;
  border: none !important;
  outline: none !important;
  border: 1px solid #676c72 !important;
  outline: none !important;
  transition: 0.2s ease-in-out;
  display: none;
}
.um2main_start .chakra-radio__control[data-checked] {
  background: linear-gradient(
      98deg,
      rgba(255, 255, 255, 0.25) -26.64%,
      rgba(255, 255, 255, 0.1) 37.88%,
      rgba(255, 255, 255, 0) 78.59%
    ),
    #fff !important;
  border-color: #fff !important;
  flex: 0 1 28px;
  max-width: 28px;
  display: none;
}
.um2main_start .chakra-radio__label {
  flex: 1;
  margin-left: 0;
}
.um2main_start .chakra-radio__control::before {
  content: "";
  position: absolute;
  width: 14px !important;
  height: 14px !important;
  background-color: #fff !important;
  border-radius: 100% !important;
  transition: 0.2s ease-in-out;
}
.um2main_start .chakra-radio__control[data-checked]::before {
  background-color: #ee42dd !important;
}
.um2main_start .gt-offer__title {
  font-size: 18px;
  line-height: 1.2;
  font-family: ProximaN;
  font-weight: 500;
  padding-left: 0;
  transition: 0.3s ease-in-out;
  color: #242928;
}
.um2main_start .gt-offer__title ~ .chakra-text {
  padding-left: 12px;
  /* margin-left: -26px; */
  font-family: ProximaN;
  font-size: 14px;
  line-height: 1.3;
  color: #e9e9e9;
  display: none !important;
}
.um2main_start span.chakra-radio__label > .chakra-stack {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  justify-content: space-between;
  /* align-items: center; */
}
.um2main_start
  span.chakra-radio__label
  .chakra-stack
  .chakra-stack:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 0;
}
.um2main_start .gt-offer__marketing-price {
  font-family: Commissioner;
  font-size: 18px;
  line-height: 1.3;
  font-weight: normal;
  text-decoration: line-through;
  order: 2;
  /* color: rgba(255, 255, 255, 0.3); */
  /* display: none!important; */
}
.um2main_start .gt-offer__price {
  font-family: ProximaN;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  color: #242928;
  margin-bottom: 0;
  transition: 0.3s ease-in-out;
}
.um2main_start .sales-widget__selected-offers {
  max-width: 800px;
  background-color: transparent;
  padding: 0 0 0px 0;
}
.um2main_start .sales-widget__selected-offers > .chakra-stack {
  display: flex;
  align-items: flex-start;
}
.um2main_start
  .sales-widget__selected-offers
  > .chakra-stack
  > .chakra-stack:nth-of-type(1)
  > .chakra-text:nth-of-type(1) {
  font-size: 20px;
  line-height: 26px;
  font-family: ProximaN;
  font-weight: 600;
  letter-spacing: -0.01em;
  padding-left: 20px;
  margin-bottom: 10px;
  /* color: #fff; */
}
.um2main_start
  .sales-widget__selected-offers
  > .chakra-stack
  > .chakra-stack:nth-of-type(1)
  > .chakra-text:nth-of-type(2) {
  padding-left: 20px;
  font-family: ProximaN;
  font-size: 16px;
  line-height: 26px;
  display: none !important;
}
.um2main_start
  .sales-widget__selected-offers
  > .chakra-stack
  > .chakra-stack:nth-of-type(2)
  > .chakra-stack {
  display: flex;
  flex-direction: column;
}
.um2main_start
  .sales-widget__selected-offers
  > .chakra-stack
  > .chakra-stack:nth-of-type(2)
  > .chakra-stack
  > .chakra-text:nth-of-type(1) {
  font-family: ProximaN;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.01em;
  order: 2;
  color: #676c72;
}
.um2main_start
  .sales-widget__selected-offers
  > .chakra-stack
  > .chakra-stack:nth-of-type(2)
  > .chakra-stack
  > .chakra-text:nth-of-type(2) {
  font-family: ProximaN;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  color: #ef3f9e;
  margin-bottom: 5px;
}
.um2main_start .chakra-form-control > label,
.um2main_start label {
  /* color: #fff; */
}
.um2main_start .sales-widget__user-form {
  max-width: 800px;
}
.um2main_start .sales-widget__user-form input {
  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  color: #000;
}
.um2main_start .sales-widget__offers > .chakra-stack {
  min-width: 100%;
}
.um2main_start .sales-widget__offers > .chakra-radio-group > .chakra-stack {
  max-width: 100%;
  margin-bottom: 20px;
}
.um2main_start
  .chakra-checkbox__label
  > .chakra-stack
  > .chakra-stack:nth-of-type(2) {
  flex-direction: column;
  align-items: flex-end;
}
.um2main_start .sales-widget__offers > .chakra-stack .chakra-divider {
  display: none;
}
.um2main_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div {
  margin-bottom: 15px;
}
.um2main_start
  .sales-widget__offers
  > .chakra-stack
  .chakra-divider
  ~ div
  .gt-offer__total-marketing-price {
  font-family: ProximaN;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  letter-spacing: -0.01em;
  text-decoration: line-through;
  color: rgba(22, 19, 19, 0.5);
}
.um2main_start
  .sales-widget__offers
  > .chakra-stack
  .chakra-divider
  ~ div
  .gt-offer__total-price {
  color: #1c1c1a;
}
@media (max-width: 575px) {
  .um2main_start .gt-offer,
  .um2main_start .sales-widget__selected-offers {
    /* padding: 0; */
  }
  .um2main_start span.chakra-radio__label > .chakra-stack,
  .um2main_start .sales-widget__selected-offers > .chakra-stack {
    /* flex-direction: column; */
  }
  .um2main_start
    span.chakra-radio__label
    .chakra-stack
    .chakra-stack:nth-of-type(2) {
    /* margin-left: -25px; */
    /* align-items: flex-start; */
    /* width: 100%; */
    padding-left: 0;
  }
  .um2main_start .gt-offer__title {
    font-size: 16px;
    padding-left: 0;
  }
  .um2main_start .gt-offer__title ~ .chakra-text {
    font-size: 16px;
    line-height: 22px;
    padding-left: 5px;
    padding-top: 10px;
  }
  .um2main_start
    .sales-widget__selected-offers
    > .chakra-stack
    > .chakra-stack:nth-of-type(1)
    > .chakra-text:nth-of-type(2),
  .um2main_start
    .sales-widget__selected-offers
    > .chakra-stack
    > .chakra-stack:nth-of-type(1)
    > .chakra-text:nth-of-type(1) {
    padding-left: 0;
    font-size: 16px;
    line-height: 22px;
  }
  .um2main_start
    .sales-widget__selected-offers
    > .chakra-stack
    > .chakra-stack:nth-of-type(2) {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
  .um2main_start .sales-widget__user-form button {
    margin-top: 15px;
  }
  .um2main_start .sales-widget__user-form {
    padding: 0;
  }
  .um2main_start .chakra-button {
    height: 60px;
    width: 90%;
    font-size: 18px;
    line-height: 1;
  }
  .um2main_start form .chakra-button {
    width: 100%;
    margin-top: 0;
  }
  .um2main_start .chakra-button p {
    top: 0;
    font-size: 22px;
  }
  .um2main_start
    .chakra-checkbox__label
    > .chakra-stack
    > .chakra-stack:nth-of-type(2) {
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }
  .um2main_start .gt-offer__marketing-price {
    margin-left: 8px;
  }
  .velvet br {
    display: none;
  }
}
@media (max-width: 360px) {
  .um2main_start span.chakra-radio__label > .chakra-stack {
    flex-direction: column;
    align-items: flex-start;
  }
  .um2main_start .gt-offer .gt-offer__price {
    margin-left: 0;
    margin-top: 4px;
  }
}
