.wegift {
    background-color: var(--main-bg);
    padding: 75px 0 60px;
}
.wegift_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 25px;
}
.wegift_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.wegift_subtitle {
    font-size: 26px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
}
.wegift_block {
    display: flex;
    justify-content: center;
}
.wegift_item {
    margin: 0 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wegift_item p {
    background-color: #fff;
    color: #000;
    margin: 2px 0;
    width: fit-content;
    font-weight: 500;
    padding: 0 3px;
    font-size: 18px;
    position: relative;
}
.wegift_item h3 {
    background-color: var(--main-color);
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    padding: 0 3px;
    margin-bottom: 4px;
    position: relative;
}
.wegift_item img {
    margin-bottom: -125px;
    width: 300px;
    height: 336px;
    mix-blend-mode: darken;
}

@media(max-width: 991px) {
    .wegift_title {
        font-size: 42px;
    }
}
@media(max-width: 767px) {
    .wegift_title {
        font-size: 36px;
    }
    .wegift_subtitle {
        font-size: 22px;
    }
    .wegift_block {
        flex-wrap: wrap;
    }
}
@media(max-width: 575px) {
    .wegift_item {
        margin-left: 0;
        margin-right: 0;
    }
    .wegift_title {
        font-size: 24px;
    }
    .wegift_subtitle {
        font-size: 18px;
    }
    .wegift_title span {
        padding-bottom: 5px;
    }
}