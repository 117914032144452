.points {
    padding: 80px 0;
    background-color: #f5f5f5;
}
.points_title {
    font-size: 50px;
    line-height: 62px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
}
.points_title span {
    color: var(--removefat-purple);
}
.points_block {
    display: flex;
    margin: 0 -20px;
}
.points_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 20px;
}
.points_info {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 35px;
    height: 100%;
    transition: .2s ease-in-out;
}
.points_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(90, 90, 253, 0.5);
}
.points_info p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
}
.points_info p strong {
    font-weight: 600;
}
@media(max-width: 991px) {
    .points_title {
        line-height: 55px;
    }
    .points_block {
        margin: 0 -15px;
    }
    .points_item {
        padding: 0 15px;
    }
    .points_info {
        padding: 35px 15px;
    }
    .points_info p br {
        display: none;
    }
}
@media(max-width: 767px) {
    .points {
        padding: 45px 0;
    }
    .points_title {
        font-size: 32px;
        line-height: 36px;
    }
    .points_block {
        flex-wrap: wrap;
        justify-content: center;
    }
    .points_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .points_info p {
        font-size: 16px;
        line-height: normal;
    }
}
@media(max-width: 575px) {
    .points_title {
        font-size: 30px;
        line-height: 32px;
    }
    .points_block {
        margin: 0;
    }
    .points_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 15px;
        padding: 0;
    }
}