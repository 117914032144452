.preims {
    background-color: #F2F2F2;
    height: 3700px;
    padding-top: 110px;
    padding-bottom: 90px;
}
.preims_block {
    position: sticky;
    top: 110px;
    max-width: 1060px;
    margin: 0 auto;
    display: flex;
}
.preims_block__left {
    flex: 0 1 400px;
    max-width: 400px;
    height: 680px;
    position: relative;
    overflow: hidden;
}
.preims_block__left::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 360px;
    height: 680px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_phonen.png');
    z-index: 7;
}
.preims_images {
    height: 100%;
}
.preims_images img {
    position: relative;
    left: 38px;
    top: 30px;
}
.preims_images img:nth-of-type(1) {z-index: 6;}
.preims_images img:nth-of-type(2) {z-index: 5;}
.preims_images img:nth-of-type(3) {z-index: 4;}
.preims_images img:nth-of-type(4) {z-index: 3;}
.preims_images img:nth-of-type(5) {z-index: 2;}
.preims_images img:nth-of-type(6) {z-index: 1;}
.preims_block__right {
    flex: 1;
    height: 640px;
    overflow: hidden;
}
.preims_info {
    height: 100%;
}
.preims_info__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.preims_info__title {
    font-weight: 800;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 20px;
}
.preims_info__text:first-of-type .preims_info__title {
    text-transform: uppercase;
}
.preims_info__subtitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #4A4A4A;
}
.preims_info__icon img {
    position: relative;
    bottom: -25px;
    left: -50px;
}

@media(max-width: 991px) {
    .preims_block__left {
        flex: 0 1 375px;
        max-width: 375px;
    }
    .preims_info__title {
        font-size: 36px;
        line-height: 40px;
    }
    .preims_info__subtitle br {
        display: none;
    }
}
@media(max-width: 767px) {
    .preims {
        height: auto;
    }
    .preims_block {
        flex-wrap: wrap;
        justify-content: center;
        top: 80px;
    }
    .preims_block__left {
        flex: 0 1 200px;
        max-width: 200px;
        height: 375px;
    }
    .preims_block__right {
        flex: 0 1 100%;
        max-width: 100%;
        height: auto;
    }
    .preims_info {
        height: auto;
    }
    .preims_block__left::before {
        height: 375px;
        width: 200px;
        background-size: cover;
    }
    .preims_info__text {
        justify-content: flex-start;
        text-align: center;
        margin-bottom: 40px;
    }
    .preims_info__icon img {
        left: 0;
        width: 100px;
        height: 100px;
        bottom: 0;
    }
    .preims_images img {
        width: 160px;
        left: 20px;
        top: 15px;
    }
    .preims_info__title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 14px;
        margin-top: -15px;
    }
    .preims_info__subtitle {
        font-size: 20px;
        line-height: 26px;
    }
    .preims_info__text:first-of-type .preims_info__title {
        margin-top: 40px;
    }
    .preims {
        padding: 45px 0 0;
    }
}
@media(max-width: 575px) {
    .preims_info__title {
        font-size: 22px;
        line-height: 26px;
    }
    .preims_info__subtitle {
        font-size: 18px;
        line-height: 24px;
    }
}