.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/promo_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 924px;
    padding-top: 130px;
}
.promo_block {
    padding-left: 620px;
    position: relative;
}
.promo_block::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -175px;
    top: 20px;
    width: 128px;
    height: 128px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/rem.png');
}
.promo_pretitle {
    border-radius: 104px;
    border: 1px solid #474747;
    background: #262626;
    margin-bottom: 45px;
    padding: 10px 40px;
    color: #A4A4A4;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 2px;
    text-transform: uppercase;
    max-width: max-content;
}
.promo_title {
    font-family: Unisans;
    margin-bottom: 6px;
    font-size: 96px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.4px;
}
.promo_text {
    margin-bottom: 70px;
    font-size: 22px;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.8);
}
.promo_text b {
    font-weight: 600;
}
.promo_btns {
    position: relative;
    margin-bottom: 50px;
}
.promo_btns::before {
    content: '';
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/promo_figure.png');
    width: 1108px;
    height: 219px;
    position: absolute;
    right: 0;
    top: -94px;
    pointer-events: none;
}
.promo_btns_text {
    position: absolute;
    left: -505px;
    display: flex;
    align-items: center;
    gap: 20px;
    top: -78px;
    font-size: 19px;
    font-weight: 500;
    line-height: 1.3;
}
.promo_btn {
    position: relative;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 533px;
    height: 100px;
    padding: 10px;
    border-radius: 600px;
    background-color: #EBFF5E;
    color: #171717;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.promo_remains {
    display: flex;
    gap: 12px;
    align-items: center;
}
.promo_remains_text {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
}
.promo_remains_number {
    font-family: Unisans;
    font-size: 58px;
    font-weight: bold;
    line-height: 1;
}
.mob {
    display: none!important;
}
@media(max-width:575px) {
    .promo {
        padding-top: 40px;
        height: auto;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/promo_mob_bg.jpg');
        padding-bottom: 62px;
        overflow: hidden;
    }
    .promo_block {
        padding-left: 0;
    }
    .promo_block::before {
        content: none;
    }
    .promo_pretitle {
        padding: 7px 10px;
        text-align: center;
        font-size: 17px;
        margin-bottom: 20px;
        max-width: 100%;
    }
    .promo_title {
        font-size: 68px;
    }
    .promo_text {
        margin-bottom: 50px;
        font-size: 18px;
        line-height: 1.4;
    }
    .promo_btns {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding-top: 15px;
        max-width: 335px;
    }
    .promo_btns::before {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/promo_figure_mob.svg');
        width: 335px;
        height: 210px;
        top: 0;
        right: auto;
        left: -12px;
    }
    .promo_btns_text {
        position: relative;
        left: 0;
        margin-left: 0;
        top: 0;
        font-size: 16px;
    }
    .promo_btn {
        width: 100%;
        max-width: 308px;
        height: 70px;
        font-size: 18px;
        margin: 0;
    }
    .promo_btns_text img {
        max-width: 64px;
    }
    .promo_remains_text {
        font-size: 20px;
    }
    .mob {
        display: block!important;
        position: relative;
        margin: -100px -140px -280px -150px;
        pointer-events: none;
    }
    .mob::before {
        content: '';
        position: absolute;
        right: 135px;
        top: 120px;
        width: 92px;
        height: 92px;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/rem.png');
        background-size: cover;
    }
}