.block4 {
    padding: 70px 0 90px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/block4_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
}
.b1 {
    border-radius: 36px;
    border: 1px solid #A6898F;
    background: radial-gradient(234.71% 125.91% at 5.78% 3.33%, #784A53 3.73%, #181818 100%);
    padding: 64px 80px 55px;
    position: relative;
    overflow: hidden;
    margin-bottom: 120px;
}
.b1::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 45px;
    top: 0px;
    width: 506px;
    height: 490px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/4/prize.png');
    pointer-events: none;
}
.b1_1 {
    color: #fff;
    font-family: Bebas;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 1.1;
    margin-bottom: 5px;
}
.b1_2 {
    margin-bottom: 4px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    color: #fff;
}
.b1_3 {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 1.3;
    color: #fff;
}
.b1_4 {
    border-radius: 13px;
    background: #FFF;
    color: #ED3052;
    padding: 4px 13px;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2;
    max-width: max-content;
    margin-bottom: 14px;
}
.b1_5 {
    color: #fff;
    font-family: InterT;
    font-size: 40px;
    line-height: 1.13;
}
.b1_5 span {
    color: #FF869C;
}

.t1 {
    text-align: center;
    text-transform: uppercase;
    font-family: Bebas;
    margin-bottom: 37px;
    color: #131313;
    font-size: 60px;
    line-height: 1.1;
}
.b2 {
    display: flex;
    gap: 35px;
    margin-bottom: 100px;
}
.b2_item {
    flex: 0 1 calc(33.333333% - 23.333333px);
    max-width: calc(33.333333% - 23.333333px);
    border-radius: 30px;
    border: 1px solid #F9B6C2;
    background: #FFF4F6;
    padding: 26px;
    text-align: center;
}
.b2_item img {
    margin-bottom: 10px;
}
.b2_item p {
    font-size: 18px;
    line-height: 1.3;
}

.sticky {
    display: flex;
    gap: 35px;
    justify-content: space-between;
}
.sticky_left {
    flex: 0 1 535px;
    max-width: 535px;
}
.sticky_right {
    flex: 0 1 560px;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.sticky_sticky {
    position: sticky;
    top: 20px;
}
.s1 {
    margin-bottom: 16px;
    color: #EBFF5E;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
}
.s2 {
    font-family: Unisans;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 16px;
}
.s3 {
    font-size: 20px;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.8);
}
.s3 b {
    color: #fff;
    font-weight: 600;
}
.s3 em {
    font-style: normal;
    text-decoration: line-through;
}
.s4 {
    margin-bottom: 24px;
    border-radius: 5000px;
    border: 1px solid #474747;
    background: #1D1E1A;
    padding: 4px 20px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    color: #EBFF5E;
    max-width: max-content;
}
.promo_btn {
    transition: .2s ease-in-out;
    width: 440px;
    height: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 18px;
    background: #FE8787;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.425px;
    padding: 22px 26px;
    cursor: pointer;
    margin-bottom: 33px;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.sticky_item {
    border-radius: 30px;
    border: 1px solid #474747;
    background: #262626;
    padding: 22px 32px;
    min-height: 185px;
    position: relative;
    overflow: hidden;
}
.sticky_item span {
    display: block;
    margin-bottom: 22px;
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    letter-spacing: 0.48px;
}
.sticky_item span em {
    font-style: normal;
    text-decoration: line-through;
    padding-left: 20px;
}
.sticky_item p {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
}
.sticky_item p b {
    font-weight: normal;
    font-size: 16px;
    margin-top: 10px;
    display: block;
    max-width: 230px;
}
.sticky_item img {
    position: absolute;
    right: 32px;
    bottom: 0;
    pointer-events: none;
}
.b1_mob {
    display: none;
}
.st_mob {
    display: none;
}
.promo_countdown p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #2B3137;
}
.promo_countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #FE8787;
    font-size: 66px;
    font-family: Bebas;
    line-height: 1.35;
    letter-spacing: -0.478px;
    font-weight: normal;
}
.promo_countdown_timer > div div {
    display: flex;
    gap: 3px;
}
@media(max-width: 575px) {
    .block4 {
        padding-top: 40px;
        padding-bottom: 0;
    }
    .b1 {
        padding: 35px 20px;
        margin-bottom: 40px;
    }
    .b1::before {
        content: none;
    }
    .b1_1 {
        font-size: 30px;
        margin-bottom: 11px;
    }
    .b1_2 {
        margin-bottom: 4px;
        font-size: 20px;
    }
    .b1_3 {
        margin-bottom: 10px;
        font-size: 18px;
    }
    .b1_4 {
        margin-bottom: 10px;
        font-size: 22px;
        letter-spacing: 0;
    }
    .b1_5 {
        font-size: 20px;
    }
    .b1_mob {
        display: block;
        /* margin: -35px -50px -115px; */
    }
    .t1 {
        font-size: 30px;
        margin-bottom: 24px;
    }
    .b2 {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 64px;
    }
    .b2_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .sticky {
        flex-direction: column;
    }
    .s1 {
        font-size: 20px;
        margin-bottom: 16px;
    }
    .s2 {
        margin-bottom: 10px;
        font-size: 24px;
    }
    .s3 {
        margin-bottom: 0;
        font-size: 18px;
    }
    .promo_btn {
        width: 100%;
        font-size: 20px;
        display: none;
    }
    .st_mob {
        display: block;
    }
    .st_mob .promo_btn {
        display: flex;
    }
    .sticky_left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .sticky_item {
        padding: 20px;
        min-height: 0;
    }
    .sticky_item p {
        font-size: 18px;
    }
    .sticky_item p br {
        display: block!important;
    }
    .sticky_item img {
        max-width: 145px;
        right: -40px;
    }
    .promo_countdown {
        flex-direction: column;
        align-items: center;
        display: none;
    }
    .promo_countdown p {
        text-align: center;
    }
    .st_mob .promo_countdown {
        display: flex;
    }
}