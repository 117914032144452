.platformgt {
    padding: 0 0 64px;
}
.platformgt_block {
    background-color: #F4F4F4;
    /* color: #171717; */
    border-radius: 32px;
    position: relative;
    padding: 68px 60px 60px;
    margin-bottom: 45px;
}
.platformgt_logo {
    margin-bottom: 30px;
    position: relative;
}
.s1 {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 40px;
}
.s1 p {
    border-radius: 5000px;
    background: #9069D4;
    padding: 4px 20px;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.3;
    letter-spacing: 1px;
    font-weight: 500;
    max-width: max-content;
}
.s1 span {
    font-size: 20px;
    color: #A0A0A0;
    /* font-weight: bold; */
    line-height: 1.3;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.s1 span em {
    font-style: normal;
    text-decoration: line-through;
}
.platformgt_title {
    font-family: Unisans;
    font-weight: bold;
    font-size: 46px;
    line-height: 1.1;
    margin-bottom: 28px;
    text-transform: uppercase;
    max-width: 585px;
    position: relative;
}
.platformgt_title span {
    color: #FF2E5B;
}
.platformgt_title p {
    font-family: Manrope;
    display: inline;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 2px;
    padding-left: 20px;
}
.platformgt_title p em {
    font-style: normal;
    text-decoration: line-through;
    color: #776D6D;
}
.platformgt_subtitle {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 28px;
    max-width: 585px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: rgba(40, 40, 40, 0.8);
}
.platformgt_image {
    position: absolute;
    right: 40px;
    bottom: 60px;
    max-width: 460px;
}
.platformgt_image_m {
    display: none!important;
}
.platformgt_info {
    display: flex;
    flex-wrap: wrap;
    max-width: 630px;
    margin: 0 -15px;
    position: relative;
}
.platformgt_info__item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
}
.platformgt_info__item > div {
    display: flex;
    gap: 10px;
    align-items: center;
}
.platformgt_info__item > div div {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 1;
    align-items: center;
}
.platformgt_info__item > div div img {
    /* filter: invert(1); */
}
.platformgt_info__item_number {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.1;
    font-family: Unisans;
    text-transform: uppercase;
    margin-bottom: 4px;
}
.platformgt_info__item p {
    line-height: 1.2;
    font-size: 18px;
    color: rgba(40, 40, 40, 0.8);
}
.platformgt_info__item p strong {
    font-weight: normal;
}
.platform_black {
    background-color: #1C1C1A;
    margin-top: -2px;
}
.platform_black .platformgt_block, .platform_gray .platformgt_block {
    transform: rotate(-3deg);
    border-radius: 90px;
}
.platform_black .platformgt_block > *, .platform_gray .platformgt_block > * {
    transform: rotate(3deg);
}
.platform_gray {
    background-color: #F9F9F9;
    margin-top: -2px;
}
.btn {
    margin: 0 auto 35px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 415px;
    height: 115px;
    padding: 10px;
    border-radius: 15px;
    background: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 15.07%, rgba(255, 255, 255, 0.20) 148.09%), #FF2E5B;
    box-shadow: 0px 4px 25.5px 0px rgba(255, 46, 91, 0.43);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
    cursor: pointer;
}
.countdown {
    text-align: center;
}
.countdown > p {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
}
.countdown_timer div {
    display: flex;
    justify-content: center;
    font-family: Unisans;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
}

@media(max-width: 1199px) {
    .platformgt_block {
        padding: 30px;
    }
    .platformgt_image {
        right: 0;
    }
    .platformgt_title, .platformgt_subtitle, .platformgt_info {
        max-width: 525px;
    }
}
@media(max-width: 991px) {
    .platformgt_block {
        /* text-align: center; */
    }
    .platformgt_image {
        position: relative;
        bottom: 0;
    }
    .platformgt_title, .platformgt_subtitle, .platformgt_info {
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .platformgt_title {
        font-size: 32px;
        line-height: 36px;
    }
    .platformgt_subtitle {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 22px;
    }
    .platformgt_info__item {
        /* flex: 0 1 100%; */
        /* max-width: 100%; */
    }
    .platform_black .platformgt_block, .platform_black .platformgt_block > *, .platform_gray .platformgt_block, .platform_gray .platformgt_block > * {
        transform: rotate(0deg);
    }
}
.n_prices {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 30px;
}
.n_prices_1 {
    color: rgba(40, 40, 40, 0.31);
    font-family: Unisans;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: 1.75px;
    text-decoration: line-through;
}
.n_prices_2 {
    position: relative;
    font-family: Unisans;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: 2.5px;
}
.n_prices_1 em, .n_prices_2 em {
    font-style: normal;
    font-family: Manrope;
}
.n_prices_2::before {
    content: '- 89%';
    position: absolute;
    right: -62px;
    top: 0;
    color: #FF2E5B;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 0;
}
.remains_n_place {
    font-family: Unisans;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.3px;
    margin-bottom: 3px;
    text-align: center;
}
.remains_n_place span {
    color: #FF2E5B;
}
.remains_n_text {
    color: #FF2E5B;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
}
@media(max-width: 575px) {
    .remains_n_place {
        font-size: 20px;
        text-align: center;
    }
    .remains_n_text {
        font-size: 20px;
        text-align: center;
    }
    .n_prices {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
    .n_prices_1 {
        font-size: 22px;
    }
    .n_prices_2 {
        font-size: 28px;
    }
    .platformgt {
        padding: 45px 0;
    }
    .platformgt_block {
        padding: 30px 10px;
    }
    .platformgt_logo {
        margin-bottom: 23px;
    }
    .platformgt_title {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 15px;
    }
    .platformgt_image {
        margin-bottom: 20px;
    }
    .platformgt_image_d {
        display: none!important;
    }
    .platformgt_image_m {
        display: inline-block!important;
    }
    .platformgt_info {
        margin: 0;
    }
    .platformgt_info__item {
        padding: 0;
    }
    .s1 {
        /* margin: 0 auto 20px; */
    }
    .platformgt_subtitle {
        font-size: 16px;
    }
    .platformgt_info__item > div {
        /* justify-content: center; */
    }
    .btn {
        width: 100%;
        font-size: 18px;
        height: 80px;
        padding: 10px;
    }
    .platformgt_title p {
        padding-left: 0;
        display: block;
        font-size: 24px;
    }
    .s1 {
        margin-bottom: 18px;
        flex-direction: column;
        align-items: flex-start;
    }
    .s1 p {
        font-size: 18px;
    }
}