:root {
    --fat-red: #F02A2C;
    --fat-blue: #46B1FF;
}
.fatburning {
    font-family: Qanelas, sans-serif;
    font-size: 18px;
    color: #242424;
}

.gallery_block__fatburning {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
}
.l2212_fat_btn {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(94.5deg, #FF0F11 -28.1%, #9A0002 147.97%);
    border-radius: 10px;
    width: 360px;
    height: 90px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 0 15px;
}
.l2212_fat_btn p {
    position: relative;
    z-index: 1;
}
.l2212_fat_btn::before {
    content: '';
    background: linear-gradient(6.83deg, #FF0F11 5.98%, #D60D0F 138.55%);
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10.5px;
    transition: .2s ease-in-out;
}
.l2212_fat_btn::after {
    content: '';
    background: linear-gradient(6.83deg, #C10002 5.98%, #440001 138.55%);
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    box-shadow: none;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10.5px;
    transition: .2s ease-in-out;
}
.l2212_fat_btn:hover::before {
    content: '';
    opacity: 1;
}
.l2212_fat_btn:active::before {
    content: '';
    opacity: 0;
}
.l2212_fat_btn:hover::after {
    content: '';
    opacity: 0;
}
.l2212_fat_btn:active::after {
    content: '';
    opacity: 1;
}
.l2212_fat_btn_faq {
    margin: 55px auto 0;
    width: 556px;
}
.l2212_fat_btn_more {
    margin: 55px auto 0;
}

@media(max-width: 767px) {
    .l2212_fat_btn {
        margin-top: 0;
    }
    .l2212_fat_btn_faq {
        margin-top: 45px;
    }
}
@media(max-width: 575px) {
    .l2212_fat_btn {
        max-width: 100%;
        margin: 0 auto;
    }
    .l2212_fat_btn_faq {
        margin-top: 30px;
    }
}
.fatburning_start {
    width: 100%;
    padding: 0 30px;
}
.fatburning_start h2 {
    display: none;
}
.fatburning_start .sales-widget__offers {
    margin-top: 0;
    width: 100%;
}
.fatburning_start .chakra-button {
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Qanelas;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    border-radius: 20px;
    background: linear-gradient(95deg, #FF0F11 -28.1%, #9A0002 147.97%);
    cursor: pointer;
    position: relative;
    padding: 0 40px;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
}
.fatburning_start .chakra-button:hover {
    transform: translateY(-4px);
    background: linear-gradient(95deg, #FF0F11 -28.1%, #9A0002 147.97%);
}
.fatburning_start .chakra-button:active {
    background: linear-gradient(95deg, #FF0F11 -28.1%, #9A0002 147.97%);
}
.fatburning_start .chakra-button p {
    position: relative;
    font-size: 22px;
    font-family: 'Qanelas';
    font-weight: 600;
    letter-spacing: 0.12rem;
}
.fatburning_start form .chakra-button {
    margin-bottom: 5px;
}
.fatburning_start .sales-widget__user-form button > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-size: 12px;
    line-height: normal;
    text-transform: lowercase;
}
.fatburning_start .gt-offer {
    transition: .3s ease-in-out;
    background-color: #fff;
    border-radius: 12px;
    padding: 10px;
    margin-top: 0!important;
    margin-bottom: 12px;
    display: none;
}
.fatburning_start .gt-offer:has(.chakra-radio__control[data-checked]) {
  background-color: #ED3046;  
}
.fatburning_start .gt-offer:has(.chakra-radio__control[data-checked]) .gt-offer__title {
  color: #fff;
}
.fatburning_start .gt-offer:has(.chakra-radio__control[data-checked]) .gt-offer__price {
  color: #fff;
}
.fatburning_start .gt-offer > label {
    margin-bottom: 0!important;
    display: flex;
    align-items: center;
}
.fatburning_start .gt-offer > label > .chakra-checkbox__control {
    background: #BC9375;
    border-color: #BC9375;
}
.fatburning_start .chakra-radio__control {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    background-color: #C1C1C1!important;
    position: relative;
    border: none!important;
    outline: none!important;
    border: 1px solid #C1C1C1!important;
    outline: none!important;
    transition: .2s ease-in-out;
}
.fatburning_start .chakra-radio__control[data-checked] {
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #fff!important;
    border-color: #fff!important; 
    flex: 0 1 28px;
    max-width: 28px;
}
.fatburning_start .chakra-radio__label {
    flex: 1;
    margin-left: 12px;
}
.fatburning_start .chakra-radio__control::before {
    content: '';
    position: absolute;
    width: 14px!important;
    height: 14px!important;
    background-color: #fff!important;
    border-radius: 100%!important;
    transition: .2s ease-in-out;
}
.fatburning_start .chakra-radio__control[data-checked]::before {
  background-color: #ED3046!important;
}
.fatburning_start .gt-offer__title {
    font-size: 18px;
    line-height: 1.2;
    font-family: Inter;
    font-weight: 500;
    padding-left: 0;
    transition: .3s ease-in-out;
    color: #2A2A2A;
}
.fatburning_start .gt-offer__title ~ .chakra-text {
    padding-left: 0;
    font-family: Inter;
    font-size: 14px;
    line-height: 1.6;
    color: #777879;
    display: none!important;
}
.fatburning_start span.chakra-radio__label > .chakra-stack {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    align-items: center;
}
.fatburning_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 0;
}
.fatburning_start .gt-offer__marketing-price {
    font-family: Commissioner;
    font-size: 18px;
    line-height: 1.3;
    font-weight: normal;
    text-decoration: line-through;
    order: 2;
    color: rgba(255, 255, 255, 0.3);
    display: none!important;
}
.fatburning_start .gt-offer__price {
    font-family: Inter;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: #ED3046;
    margin-bottom: 0;
    transition: .3s ease-in-out;
}
.fatburning_start .sales-widget__selected-offers {
    max-width: 800px;
    background-color: transparent;
    padding: 0 0 0px 0;
}
.fatburning_start .sales-widget__selected-offers > .chakra-stack {
    display: flex;
    align-items: flex-start;
}
.fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
    font-size: 20px;
    line-height: 26px;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 20px;
    margin-bottom: 10px;
}
.fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2) {
    padding-left: 20px;
    font-family: Inter;
    font-size: 16px;
    line-height: 26px;
    display: none!important;
}
.fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack {
    display: flex;
    flex-direction: column;
}
.fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(1) {
    font-family: Inter;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: -0.01em;
    order: 2;
    color: #676C72;
}
.fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-family: Inter;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #ED3046;
    margin-bottom: 5px;
}
.fatburning_start .sales-widget__user-form {
    max-width: 800px;
}
.fatburning_start .sales-widget__user-form input {
    border-radius: 8px;
    text-align: left;
}
.fatburning_start .sales-widget__offers > .chakra-stack {
    min-width: 100%;
}
.fatburning_start .sales-widget__offers > .chakra-radio-group > .chakra-stack {
    max-width: 100%;
    margin-bottom: 20px;
}
.fatburning_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-end;
}
.fatburning_start .sales-widget__offers > .chakra-stack .chakra-divider {
    display: none;
}
.fatburning_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div {
    margin-bottom: 15px;
}
.fatburning_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-marketing-price {
    font-family: Inter;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    color: rgba(22, 19, 19, 0.5);
}
.fatburning_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-price {
    color: #1C1C1A;
}
@media(max-width: 575px) {
    .fatburning_start {
        padding: 0;
        order: 3;
    }
    .fatburning_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
        padding-left: 0;
    }
    .fatburning_start .gt-offer__title {
        font-size: 16px;
        padding-left: 0;
    }
    .fatburning_start .gt-offer__title ~ .chakra-text {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
        padding-top: 10px;
    }
    .fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2), .fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
        padding-left: 0;
        font-size: 16px;
        line-height: 22px;
    }
    .fatburning_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .fatburning_start .sales-widget__user-form button {
        margin-top: 15px;
    }
    .fatburning_start .sales-widget__user-form {
        padding: 0;
    }
    .fatburning_start .chakra-button {
        height: 60px;
        width: 90%;
        font-size: 18px;
        line-height: 1;
    }
    .fatburning_start form .chakra-button {
        width: 100%;
        margin-top: 0;
    }
    .fatburning_start .chakra-button p {
        top: 0;
        font-size: 18px;
    }
    .fatburning_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .fatburning_start .gt-offer__marketing-price {
        margin-left: 8px;
    }
    .velvet br {
        display: none;
    }
}
@media(max-width: 360px) {
  .fatburning_start span.chakra-radio__label > .chakra-stack {
    flex-direction: column;
    align-items: flex-start;
  }
  .fatburning_start .gt-offer .gt-offer__price {
    margin-left: 0;
    margin-top: 4px;
  }
}