.prize {
    padding: 50px 0;
}
.pt3 {
    text-align: center;
    color: #FF4E53;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 2px;
}
.t3 {
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
}
.st3 {
    text-align: center;
    margin-bottom: 45px;
    font-size: 20px;
    line-height: 1.4;
    color: #6A6A6A;
}
.st3 span {
    white-space: nowrap;
}
.b3 {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 100px;
}
.b3_item {
    flex: 0 1 380px;
    max-width: 380px;
    border-radius: 24px;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
}
.b3_item_info {
    background-color: #E3FF4D;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 130px;
}
.b3_item_img {
    padding: 46px 0;
}
.b3_item:nth-of-type(1) .b3_item_img {
    padding: 0;
}
.b3_item_place {
    color: #9B9B9B;
    line-height: 1.4;
    letter-spacing: -0.293px;
}
.b3_item_prize {
    color: #565656;
    font-size: 24px;
    line-height: 1.4;
}

.b4 {
    max-width: 1160px;
    margin: 0 auto 45px;
    display: flex;
    gap: 20px;
}
.b4_left {
    flex: 0 1 480px;
    max-width: 480px;
}
.b4_left p {
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
    position: sticky;
    top: 20px;
}
.b4_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.b4_right_item {
    display: flex;
    gap: 40px;
    align-items: flex-end;
}
.b4_right_item_info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    order: 2;
    flex: 1;
}
.b4_right_item_num {
    color: #FF4E53;
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
    text-transform: uppercase;
}
.b4_right_item_text {
    line-height: 1.4;
    color: #6A6A6A;
    flex: 1;
}
.b4_right_item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.b4_right_item_img {
    overflow: hidden;
    border-radius: 21px;
    flex: 0 1 160px;
    max-width: 160px;
    height: max-content;
}
.btn {
    transition: .2s ease-in-out;
    width: 500px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 15px;
    background: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 15.07%, rgba(255, 255, 255, 0.20) 148.09%), #FF2E5B;
    box-shadow: 0px 4px 25.5px 0px rgba(255, 46, 91, 0.43);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    padding: 32px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 0 auto 90px;
}
.btn:hover {
    transform: translateY(-4px);
}

@media(max-width: 575px) {
    .prize {
        padding: 40px 0;
    }
    .pt3 {
        font-size: 18px;
    }
    .t3 {
        font-size: 22px;
    }
    .st3 {
        font-size: 16px;
    }
    .b3 {
        flex-direction: column;
        /* gap: 80px; */
    }
    .b3_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b3_item_place {
        font-size: 14px;
    }
    .b3_item_prize {
        /* font-size: 26px; */
    }
    .b4 {
        flex-direction: column;
        margin-bottom: 0;
    }
    .b4_left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b4_left p {
        font-size: 22px;
    }
    .b4_right_item {
        gap: 0;
    }
    .b4_right_item_num {
        font-size: 30px;
    }
    .b4_right_item_text {
        font-size: 16px;
    }
    .b4_right_item_img {
        display: none;
    }
    .btn {
        font-size: 18px;
        text-align: center;
        width: 100%;
        height: 80px;
    }
}