.hasSubscribe {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}
.hasSubscribe p {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 42px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
    text-transform: uppercase;
}
.hasSubscribe a {
    font-size: 26px;
    line-height: 30px;
    color: #EE4777;
    font-weight: bold;
}
@media(max-width:575px) {
    .hasSubscribe p {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 25px;
    }
    .hasSubscribe a {
        font-size: 22px;
        line-height: 26px;
    }
}