.createbody {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_2.jpg');
    background-size: cover;
    background-position: center;
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}
.createbody_title {
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
}
.createbody_title span {
    color: var(--cpopa3-red);
}
.createbody_weight {
    position: absolute;
    left: -250px;
    bottom: 0;
    width: 558px;
    height: 363px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/coursepop2/weight_2.png');
}
.createbody_wheel {
    position: absolute;
    left: 50%;
    margin-left: -815px;
    bottom: 0;
    width: 387px;
    height: 192px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/coursepop3/wheel.png');
}
.create_pink {
    color: #fff;
    background-color: var(--cpopa3-purple);
    font-size: 24px;
    line-height: 32px;
    padding: 32px 50px 32px 39px;
    border-radius: 20px 20px 0 20px;
    max-width: 560px;
    margin-left: 130px;
    position: relative;
}
.create_yellow {
    background-color: var(--cpopa3-red);
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    padding: 32px 25px 32px 39px;
    border-radius: 0px 20px 20px 20px;
    max-width: 460px;
    margin-left: 590px;
    margin-top: -70px;
    position: relative;
}
.create_pink strong, .create_yellow strong {
    font-weight: 600;
}
.create_pink span, .create_yellow span {
    font-size: 25px;
    line-height: 32px;
    display: block;
    margin-bottom: 15px;
    font-weight: bold;
}

@media(max-width: 1199px) {
    .createbody::after {
        width: 300px;
        height: 180px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .create_yellow {
        margin-left: 490px;
    }
}
@media(max-width: 991px) {
    .createbody {
        margin-top: 0;
    }
    .create_pink {
        margin-left: 0;
        font-size: 22px;
        line-height: 32px;
        padding-right: 32px;
    }
    .create_yellow {
        font-size: 22px;
        line-height: 32px;
        padding-right: 32px;
        margin-left: 130px;
        margin-top: -20px;
    }
    .createbody::after {
        width: 200px;
        height: 150px;
        margin-left: -385px;
    }
    .create_pink::before {
        content: '';
        filter: blur(5px);
        transform: matrix(-0.98, 0.2, 0.2, 0.98, 0, 0);
        top: 35px;
        left: 10px;
        margin-left: 0;
        z-index: -1;
    }
}
@media(max-width: 767px) {
    .createbody {
        padding: 45px 0;
    }
    .createbody_title {
        font-size: 32px;
        line-height: 36px;
    }
    .create_pink {
        max-width: 100%;
        font-size: 16px;
        line-height: 21px;
        margin-top: 35px;
    }
    .create_yellow {
        margin-top: -8px;
        font-size: 16px;
        line-height: 21px;
    }
    .createbody::after {
        width: 135px;
        height: 95px;
    }
    .create_pink span, .create_yellow span {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .createbody_weight {
        display: none;
    }
}
@media(max-width: 575px) {
    .createbody_title br {
        display: none;
    }
    .create_pink {
        padding: 15px;
    }
    .create_yellow {
        margin-left: 15px;
        padding: 15px;
    }
    .createbody {
        background-position: 35% 100%;
        background-size: auto;
        padding-top: 45px;
        overflow: hidden;
    }
    .createbody::after {
        margin-left: 0;
        left: 0;
    }
    .create_yellow br {
        display: none;
    }
}
@media(max-width: 374px) {
    .createbody_title {
        font-size: 30px;
        line-height: 32px;
    }
}