.points {
    padding: 40px 0 55px;
}
.points_date {
    color: #EE4777;
    padding: 6px 12px;
    border-radius: 51px;
    background-color: #FFE5ED;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    max-width: fit-content;
    margin: 0 auto 22px;
}
.points_title {
    text-align: center; 
    text-transform: uppercase;
    margin-bottom: 24px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
}
.points_subtitle {
    text-align: center;
    color: rgba(77, 87, 97, 0.75);
    margin-bottom: 36px;
    letter-spacing: 0.01rem;
    font-size: 28px;
    line-height: 34px;
}
.points_block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.points_item {
    flex: 0 1 350px;
    max-width: 350px;
    text-align: center;
    margin-bottom: 30px;
}
.points_item_img {
    margin-bottom: 8px;
}
.points_item_title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}
.points_item_text {
    color: rgba(77, 87, 97, 0.75);
    font-size: 22px;
    font-size: 14px;
}
.points_item:first-of-type {
    border-right: 1px solid rgba(122, 129, 136, 0.1);
    padding-right: 46px;
    padding-left: 30px;
}
.points_item:last-of-type {
    padding-left: 46px;
    padding-right: 30px;
}
.points_title2 {
    text-align: center; 
    text-transform: uppercase;
    margin-bottom: 30px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
}
.points_block2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 15px;
}
.points_item2 {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 20px;
    margin-bottom: 40px;
}
.points_info {
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 20px;
    height: 100%;
    text-align: center;
}
.points_info_number {
    background-color: #EE4777;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin: 0 auto 8px;
}
.points_info_title {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px;
}
.points_info_text {
    color: rgba(77, 87, 97, 0.75);
    font-size: 16px;
    line-height: 22px;
}
.points_pretitle3 {
    text-align: center;
    color: rgba(77, 87, 97, 0.75);
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.01rem;
}
.points_title3 {
    margin-bottom: 14px;
    text-align: center;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 36px;
    line-height: 50px;
}
.points_btn {
    width: 292px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    font-family: Qanelas;
    color: #F8F8F8;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    margin: 0 auto;
}
.points_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
@media(max-width:767px) {
    .points_block2 {
        justify-content: center;
    }
    .points_item2 {
        flex: 0 1 50%;
        max-width: 50%;
    }
}
@media(max-width:575px) {
    .points_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .points_subtitle {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    .points_item {
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .points_item:first-of-type {
        border-right: none;
    }
    .points_item_title {
        font-size: 18px;
        line-height: 24px;
    }
    .points_item_text {
        font-size: 16px;
        line-height: 22px;
    }
    .points_title2 {
        font-size: 32px;
        line-height: 36px;
    }
    .points_item2 {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .points_pretitle3 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
    }
    .points_title3 {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 15px;
    }
    .points_btn {
        width: 100%;
        height: 65px;
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: normal;
    }
}