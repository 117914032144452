.studentres {
    padding: 90px 0 100px;
    overflow: hidden;
    background-color: #131114;
    position: relative;
}
.studentres::after {
    content: '';
    position: absolute;
    width: 1218px;
    height: 1218px;
    right: -391px;
    bottom: -717px;
    background: radial-gradient(50% 50% at 50% 50%, #4242FF 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
    filter: blur(250px);
    pointer-events: none;
}
.studentres_title {
    margin-bottom: 20px;
    color: #fff;
    text-transform: uppercase;
    font-size: 50px;
    line-height: 55px;
    font-weight: 800;
    position: relative;
    z-index: 1;
}
.studentres_subtitle {
    color: #fff;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
}
.studentres_subtitle span {
    color: var(--cpopa3-red);
    font-weight: 600;
}
.gallery_item {
    flex: 0 1 20%;
    max-width: 20%;
    padding: 0 10px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.gallery-item img {
    cursor: pointer;
}
.slider_nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    z-index: 1;
}
.slider_btn {
    font-size: 50px;
    line-height: normal;
}
.slider_btn_prev {
    border: 20px solid transparent; border-right: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-left: -15px;
}
.slider_btn_next {
    border: 20px solid transparent; border-left: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-right: -15px;
}
.lg-sub-html {
    display: none;
}

@media(max-width: 767px) {
    .studentres {
        padding: 45px 0;
    }
    .studentres_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .studentres_subtitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
        text-align: center;
    }
    .studentres_subtitle br {
        display: none;
    }
    .studentres_subtitle span span {
        display: inline-block;
    }
}
@media(max-width: 374px) {
    .studentres_title {
        font-size: 25px;
        line-height: 31px;
    }
    .studentres_subtitle {
        font-size: 16px;
    }
}