.programs {
    background-color: #041121;
    margin-top: -2px;
    padding-bottom: 190px;
    position: relative;
}
.programs::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 150%;
    height: 150px;
    background: #fff;
    transform: rotate(-3deg);
    pointer-events: none;
}
.title {
    color: #fff;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
    margin-bottom: 35px;
}
.title span {
    color: #D8376A;
}
.item {
    display: flex;
    margin-bottom: 80px;
}
.left {
    flex: 1;
}
.text {
    margin-bottom: 35px;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    padding-right: 10px;
}
.lessons {
    display: flex;
    flex-wrap: wrap;
}
.item2 {
    flex: 0 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.item2 img {
    border-radius: 2px;
    cursor: pointer;
    width: 68px;
}
.item2 p {
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    cursor: pointer;
}
.item2 p span {
    display: block;
    color: #8C919A;
    font-size: 14px;
    line-height: 14px;
    margin-top: 8px;
}
.item2 > div:last-of-type {
    padding-left: 12px;
}
.right {
    flex: 0 1 475px;
    padding-left: 38px;
    padding-top: 10px;
}
.right_img {
    position: relative;
}
.right_img img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.right_img p {
    position: absolute;
    bottom: 30px;
    left: 30px;
    background: #FFE5ED;
    border-radius: 64px;
    padding: 7.5px 15.5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #EE4777;
}
.right_info {
    background-color: #fff;
    padding: 25px 30px 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.right_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 10px;
}
.right_text {
    color: #4D5761;
    font-size: 20px;
    line-height: 30px;
}
.btn {
    width: 462px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.59deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #F8F8F8;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    margin: 0 auto;
}
.btn:hover {
    background: linear-gradient(90.59deg, rgba(185, 38, 85, 0.7) 6.98%, rgba(228, 47, 129, 0.7) 99.58%), #FF99B6;
}
@media(max-width: 991px) {
    .programs {
        padding-bottom: 85px;
    }
    .item {
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    .left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .title {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 12px;
    }
    .text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .item2 p {
        font-size: 12px;
        line-height: 12px;
    }
    .item2 p span {
        font-size: 10px;
        line-height: 10px;
        margin-top: 5px;
    }
    .programs::before {
        left: -30px;
        height: 75px;
    }
    .right {
        display: none;
    }
}
@media(max-width: 575px) {
    .item2 > div:first-of-type {
        flex: 0 1 47px;
        max-width: 47px;
    }
    .item2 > div:last-of-type {
        padding-left: 8px;
        flex: 1;
    }
    .right {
        padding-left: 0;
        flex: 0 1 100%;
    }
    .right_info {
        padding: 15px 20px;
    }
    .right_title {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 29px;
    }
    .right_text {
        font-size: 16px;
        line-height: 24px;
    }
    .right_img p {
        font-size: 16px;
        line-height: 24px;
        padding: 6px 12px;
        left: 15px;
        bottom: 15px;
    }
    .btn {
        width: 100%;
        text-align: center;
        font-size: 18px;
    }
}