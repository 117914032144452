.studentres {
    padding: 75px 0 85px;
    overflow: hidden;
    position: relative;
    background-color: #101010;
}
.studentres_title {
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: uppercase;
}
.studentres_subtitle {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #fff;
    text-align: center;
}
.studentres_subtitle span {
    color: #F7720C;
    font-weight: 600;
}
.studentres_btn {
    width: 358px;
    height: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    letter-spacing: 0.05em;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(89.69deg, #26CBAD 14.2%, #0CA488 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: .2s ease-in-out;
    margin: 30px auto 0;
}
.studentres_btn::before {
    content: '';
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.6);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .2s ease-in-out;
}
.studentres_btn:active::before {
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4), inset 0px 4px 4px #24B79C;
}
.studentres_btn:hover {
    transform: translateY(-2px);
}
.studentres_btn:hover::before {
    opacity: 1;
}
.studentres_btn p {
    position: relative;
}
.gallery_item {
    flex: 0 1 20%;
    max-width: 20%;
    padding: 0 10px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.gallery-item img {
    cursor: pointer;
}
.slider_nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    z-index: 1;
}
.slider_btn {
    font-size: 50px;
    line-height: normal;
}
.slider_btn_prev {
    border: 20px solid transparent; border-right: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-left: -15px;
}
.slider_btn_next {
    border: 20px solid transparent; border-left: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-right: -15px;
}
.lg-sub-html {
    display: none;
}

@media(max-width: 767px) {
    .studentres {
        padding: 45px 0;
    }
    .studentres_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .studentres_title br {
        display: none;
    }
    .studentres_subtitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
        text-align: center;
    }
    .studentres_subtitle br {
        display: none;
    }
    .studentres_subtitle span span {
        display: inline-block;
    }
}
@media(max-width: 374px) {
    .studentres_title {
        font-size: 25px;
        line-height: 31px;
    }
    .studentres_subtitle {
        font-size: 16px;
    }
}