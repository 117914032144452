.onmarafon {
    padding: 90px 0 60px;
}
.onmarafon_block {
    padding-top: 22px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.onmarafon_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 20px;
    margin-bottom: 40px;
}
.onmarafon_item_info {
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 40px 22px;
    height: 100%;
    transition: .2s ease-in-out;
}
.onmarafon_item_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(235, 10, 117, 0.7);
}
.onmarafon_item_title {
    font-size: 30px;
    line-height: 39px;
    margin-top: 20px;
}

@media(max-width: 991px) {
    .onmarafon_item_title {
        font-size: 20px;
        line-height: 29px;
    }
}
@media(max-width: 767px) {
    .onmarafon {
        padding: 45px 0;
    }
    .onmarafon_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }
    .onmarafon_item_title {
        font-size: 16px;
        line-height: 21px;
    }
}
@media(max-width: 575px) {
    .onmarafon_block {
        margin: 0 -10px;
    }
    .onmarafon_item {
        padding: 0 10px;
    }
}