.createbody {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_2.jpg');
    background-size: cover;
    background-position: center;
    padding: 80px 0 70px;
    position: relative;
    overflow: hidden;
}
.createbody::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 455px;
    top: 0;
    width: 383px;
    height: 267px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_removefat/weight_1.png');
    pointer-events: none;
}
.createbody::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 384px;
    height: 194px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_removefat/weight_2.png');
    pointer-events: none;
}
.createbody_title {
    margin-bottom: 45px;
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    text-transform: uppercase;
}
.createbody_title span {
    color: var(--removefat-purple);
}
.createbody_subtitle {
    margin-bottom: 34px;
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
}
.createbody_block {
    display: flex;
    margin: 0 -40px;
    position: relative;
    z-index: 1;
}
.createbody_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 40px;
}
.createbody_item ul li {
    position: relative;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
    padding-left: 27px;
}
.createbody_item ul li strong {
    font-weight: 600;
}
.createbody_item ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 13px;
    height: 12px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_removefat/marker_yellow.svg');
}
@media(max-width: 767px) {
    .createbody {
        padding: 45px 0;
    }
    .createbody_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 10px;
    }
    .createbody_subtitle {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
    }
    .createbody_title br, .createbody_subtitle br {
        display: none;
    }
    .createbody_block {
        flex-wrap: wrap;
        margin: 0;
    }
    .createbody_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0;
    }
    .createbody_item ul li {
        font-size: 16px;
        line-height: 20px;
    }
    .createbody_item ul li::before {
        top: 4px;
    }
}
@media(max-width: 374px) {
    .createbody_title {
        font-size: 30px;
        line-height: 34px;
    }
    .createbody_subtitle {
        
    }
}