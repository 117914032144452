.promo {
    padding: 70px 0 100px;
    background-color: #EBEBEB;
}
.promo_title {
    text-align: center;
    margin-bottom: 13px;
    font-family: Actay;
    font-weight: bold;
    color: #FB4F54;
    font-size: 80px;
    line-height: 1;
}
.promo_subtitle {
    text-align: center;
    margin-bottom: 40px;
    font-family: Actay;
    font-size: 25px;
    line-height: 1.2;
}
.promo_btn {
    padding: 35px 67px;
    border-radius: 8px;
    background-color: #FB4F54;
    margin: 0 auto 40px;
    font-family: Actay;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    max-width: max-content;
    cursor: pointer;
    text-align: center;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.promo_img {
    text-align: center;
    margin-bottom: 30px;
}
.promo_note {
    margin-bottom: 12px;
    font-family: Actay;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.2;
}
.promo_countdown div {
    display: flex;
    justify-content: center;
    font-family: Actay;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
}
@media(max-width:767px) {
    .promo {
        padding: 60px 0;
    }
    .promo_title {
        margin-bottom: 16px;
        font-size: 33px;
    }
    .promo_subtitle {
        font-size: 18px;
        margin-bottom: 25px;
    }
    .promo_btn {
        max-width: 100%;
        padding: 32px;
        margin-bottom: 30px;
    }
    .promo_img {
        margin-bottom: 20px;
    }
    .promo_note {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .promo_countdown div {
        font-size: 30px;
    }
}