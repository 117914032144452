.guide {
    padding: 80px 0 30px;
}
.guide_block {
    background-color: #111927;
    padding: 48px 64px;
    border-radius: 30px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/vitrina/guide.png');
    background-repeat: no-repeat;
    background-position: bottom right;
}
.guide_block_title {
    color: #fff;
    margin-bottom: 10px;
    font-family: Bebas;
    font-size: 77px;
    line-height: 1;
}
.guide_block_text {
    margin-bottom: 40px;
    font-size: 22px;
    color: rgba(255, 255, 255, .9);
    line-height: 1.35;
}
.guide_block_btn {
    border-radius: 18px;
    background-color: #E90973;
    padding: 32px;
    max-width: 433px;
    font-family: Inter;
    font-size: 25px;
    line-height: 1.2;
    text-align: center;
    color: #fff;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.guide_block_btn:hover {
    transform: translateY(-4px);
}
.mob {
    display: none!important;
}
@media(max-width: 767px) {
    .guide {
        padding: 40px 0 30px;
    }
    .guide_block {
        padding: 35px 15px 0;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/vitrina/guide_zap.svg');
        background-position: left bottom;
        background-size: contain;
    }
    .guide_block_title {
        font-size: 35px;
        margin-bottom: 10px;
    }
    .guide_block_text {
        font-size: 18px;
        margin-bottom: 26px;
    }
    .guide_block_btn {
        font-size: 20px;
    }
    .mob {
        display: block!important;
        text-align: center;
    }
}