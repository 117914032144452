.contraindications {
    padding: 75px 0;
}
.contraindications_title {
    font-size: 26px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 20px;
}
.contraindications_title span {
    display: block;
    font-size: 64px;
    line-height: 1;
    color: var(--main-color);
}
.contraindications_block {
    display: flex;
}
.contraindications_item {
    flex: 0 1 50%;
    max-width: 50%;
}
.contraindications_item:first-of-type ul {
    padding-right: 25px;
}
.contraindications_item:last-of-type ul {
    padding-left: 25px;
}
.contraindications_item ul li {
    border-bottom: 1px #e1e1e1 solid;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    padding-bottom: 15px;
    margin: 15px 0;
    position: relative;
    transition: .2s ease-in-out;
}
.contraindications_item ul li:hover {
    transform: translateX(5px);
}
.contraindications_item ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    background-color: #f34848;
}

@media(max-width: 991px) {
    .contraindications_title span {
        font-size: 52px;
    }
}
@media(max-width: 831px) {
    .contraindications_block {
        flex-wrap: wrap;
    }
    .contraindications_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .contraindications_item ul {
        padding: 0!important;
    }
    .contraindications_item:last-of-type ul li:first-of-type {
        margin-top: 0;
    }
}
@media(max-width: 767px) {
    .contraindications_title br {
        display: none;
    }
    .contraindications_title span {
        font-size: 44px;
    }
}
@media(max-width: 575px) {
    .contraindications_title {
        font-size: 20px;
        text-align: center;
    }
    .contraindications_title span {
        font-size: 30px;
    }
}