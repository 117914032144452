.whaityou {
    background-color: #040404;
    padding: 90px 0 60px;
    position: relative;
    overflow: hidden;
}
.whaityou::before {
    content: '';
    position: absolute;
    width: 1218px;
    height: 1218px;
    right: -809px;
    top: -809px;
    background: radial-gradient(50% 50% at 50% 50%, #329F87 0%, rgba(12, 50, 95, 0) 100%);
    filter: blur(250px);
    pointer-events: none;
}
.whaityou::after {
    content: '';
    position: absolute;
    width: 1218px;
    height: 1218px;
    left: -809px;
    bottom: -809px;
    background: radial-gradient(50% 50% at 50% 50%, #C86D82 0%, rgba(12, 50, 95, 0) 100%);
    filter: blur(250px);
    pointer-events: none;
}
.whaityou_title {
    margin-bottom: 50px;
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
}
.whaityou_title span {
    font-size: 30px;
    line-height: 39px;
    font-weight: 600;
    text-transform: lowercase;
}
.whaityou_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.whaityou_block_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 10px;
    margin-bottom: 30px;
}
.whaityou_info {
    padding: 40px 33px;
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
    position: relative;
    z-index: 1;
    transition: .2s ease-in-out;
}
.whaityou_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(235, 10, 117, 0.7);
}
.whaityou_info__title {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 31px;
    font-weight: bold;
    text-transform: uppercase;
}
.whaityou_info__text {
    line-height: 25px;
    margin-bottom: 20px;
}
.whaityou_info__number {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 67px;
    height: 67px;
    text-align: center;
    border-radius: 10px 0px;
    font-size: 50px;
    line-height: 62px;
    color: #fff;
    font-weight: bold;
    background-color: var(--abs-green);
}

@media(max-width: 1199px) {
    .whaityou_block {
        flex-wrap: wrap;
        justify-content: center;
    }
    .whaityou_block_item {
        flex: 0 1 50%;
        max-width: 50%;
    }
}
@media(max-width: 991px) {
    .whaityou_title {
        font-size: 40px;
        line-height: 52px;
    }
    .whaityou_info__title {
        font-size: 25px;
        line-height: 32px;
    }
    .whaityou_info__title br {
        display: none;
    }
    .whaityou_block_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .whaityou {
        padding: 45px 0;
    }
    .whaityou_block_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .whaityou_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 40px;
        text-align: center;
    }
    .whaityou_title span {
        font-size: 16px;
        line-height: 21px;
        display: block;
    }
    .whaityou_info__title {
        font-size: 18px;
        line-height: 22px;
    }
    .whaityou_info__text {
        font-size: 16px;
        line-height: 22px;
    }
    .whaityou_info__number {
        font-size: 30px;
        line-height: 38px;
        width: 40px;
        height: 40px;
    }
    .whaityou_block_item {
        margin-bottom: 15px;
    }
    .whaityou_info {
        padding: 20px;
    }
}
@media(max-width: 575px) {
    .whaityou_block {
        margin: 0 -10px;
    }
    .whaityou_block_item {
        padding: 0 10px;
    }
}
@media(max-width: 374px) {
    .whaityou_title {
        font-size: 30px;
        line-height: 32px;
    }
}