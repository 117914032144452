.physical {
    padding: 75px 0;
}
.physical_title {
    font-family: Bebas;
    font-weight: bold;
    font-size: 72px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1;
}
.physical_title span {
    color: var(--main-p-color);
}
.physical_item {
    background-color: var(--gray-bg);
    padding: 40px 30px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.physical_item__left {
    flex: 0 1 50%;
    max-width: 50%;
    padding-right: 30px;
}
.physical_item__right {
    flex: 0 1 50%;
    max-width: 50%;
    padding-left: 30px;
}
.physical_item-img {
    margin-bottom: 15px;
}
.physical_item-title {
    font-family: Bebas;
    font-weight: bold;
    font-size: 55px;
    line-height: 1.23;
}
.physical_item__left p {
    font-size: 20px;
    line-height: 1.55;
}
.physical_item__right p {
    color: var(--main-p-color);
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1.55;
    font-weight: bold;
}
.physical_item__right ul li {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    position: relative;
    font-size: 18px;
    line-height: 1.2;
    transition: .2s ease-in-out;
}
.physical_item__right ul li:hover {
    transform: translateX(5px);
}
.physical_item__right ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 16.5px;
    width: 6px;
    height: 6px;
    background-color: var(--main-p-color);
}
.physical_item__right ul li:last-of-type {
    margin-bottom: 0;
}
.physical_item__pink {
    background-color: var(--pink-bg);
}
.physical_item__pink .physical_item__left p {
    font-size: 64px;
    font-family: Bebas;
    line-height: 1;
    font-weight: bold;
    color: var(--main-p-color);
}
.physical_item__pink .physical_item__right p {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.55;
    font-weight: normal;
    color: #000;
}
.physical_item__pink .physical_item__right p:last-of-type {
    margin-top: 20px;
}

@media(max-width: 991px) {
    .physical_item-title {
        font-size: 50px;
    }
    .physical_item__left p {
        font-size: 18px;
        line-height: 24px;
    }
}
@media(max-width: 767px) {
    .physical_title {
        font-size: 60px;
    }
    .physical_title br {
        display: none;
    }
    .physical_item {
        flex-wrap: wrap;
    }
    .physical_item__left {
        flex: 0 1 100%;
        max-width: 100%;
        padding-right: 0;
    }
    .physical_item__right {
        flex: 0 1 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;
    }
    .physical_item__right ul li {
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .physical_item__right ul li::before {
        top: 12px;
    }
}
@media(max-width: 575px) {
    .physical_title {
        font-size: 38px;
    }
    .physical_item__pink .physical_item__left p {
        font-size: 34px;
    }
    .physical_item {
        padding: 20px;
    }
}