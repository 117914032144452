.start {
    background-color: #FAFAFA;
    position: relative;
    padding: 140px 0 15px;
    overflow: hidden;
}
.start::after {
    content: '';
    position: absolute;
    background-color: #fff;
    bottom: -55px;
    left: -10%;
    width: 130%;
    height: 100px;
    transform: rotate(-2.36deg);
}
.start_img {
    text-align: center;
}
.start_img img {
    mix-blend-mode: multiply;
}
.start_title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
    font-weight: 800;
    font-size: 52px;
    line-height: 1.1;
    color: #111927;
}
.start_title span {
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.start_subtitle {
    text-align: center;
    margin-bottom: 36px;
    font-family: Roboto;
    font-size: 24px;
    line-height: 1.4;
    color: rgba(77, 87, 97, 0.75);
}
.start_block {
    max-width: 560px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 32px 40px 40px;
}
.start_block_title {
    margin-bottom: 28px;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    color: #111927;
    line-height: 1.25;
    font-weight: 500;
}
.start_block_form iframe {
    display: flex;
    height: 100%;
    width: 100%;
}
@media(max-width:575px) {
    .start {
        padding-top: 40px;
    }
    .start_title {
        font-size: 26px;
    }
    .start_subtitle {
        font-size: 16px;
        margin-bottom: 30px;
    }
    .start_block {
        padding: 32px 20px 20px;
    }
    .start_block_title {
        font-size: 20px;
    }
}