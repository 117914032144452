.faq {
  padding: 60px 0;
  position: relative;
  /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/mu2/faq_bg.jpg'); */
  /* background-size: cover; */
  /* background-position: 50% 50%; */
  background-color: #fff;
}
.faq_block {
  display: flex;
}
.faq_left {
  flex: 1;
}
.faq_right {
  flex: 0 1 630px;
  max-width: 630px;
}
.faq_titles {
  position: sticky;
  top: 80px;
}
.faq_title {
  margin-bottom: 32px;
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  color: #333844;
}
.accordion {
  max-width: 685px;
  margin: 0 auto;
}
.question {
  padding: 24px 50px 24px 0;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(19, 19, 19, 0.1);
  transition: 0.2s ease-in-out;
  /* color: #717da1; */
}
.question::before {
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="%23131313" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 24px;
  height: 24px;
  /* border: 2px solid #fff; */
  /* border-radius: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  position: absolute;
  top: 50%;
  margin-top: -13px;
  right: 0;
  /* font-size: 17px; */
  /* font-weight: 300; */
  transition: 0.2s ease-in-out;
}
.question:hover {
  border-color: #e92a91;
}
.active .question {
  color: #e92a91;
  border-color: #e92a91;
}
.active .question::before {
  transform: rotate(45deg);
  color: #e92a91;
  border-color: #e92a91;
}
.question:hover::before {
  color: #e92a91;
  border-color: #e92a91;
}
.answer {
  max-height: 0px;
  overflow: hidden;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 1.5;
  padding-left: 0;
  color: #717da1;
  transition: 0.2s ease-in-out;
}
.active .answer {
  padding-top: 14px;
  max-height: 550px;
  padding-bottom: 0;
}
.answer p {
  margin-bottom: 15px;
}
.answer p span {
  display: inline-block;
}
.faq ul li {
  list-style-type: disc;
  margin-bottom: 10px;
  margin-left: 30px;
}
.answer a {
  color: #e92a91;
  font-weight: 500;
}
.promo_btn {
  transition: 0.2s ease-in-out;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 70px;
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.44px;
  padding: 20px 35px;
  cursor: pointer;
  min-width: 340px;
}
.promo_btn:hover {
  transform: translateY(-4px);
}
.oferta {
  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 70px;
}
.oferta a {
  line-height: 1.1;
  font-weight: 500;
  /* color: #717da1; */
  max-width: max-content;
  margin-top: 10px;
  transition: 0.2s ease-in-out;
}
.oferta a:hover {
  color: #e92a91;
}
.faq_btn {
  border-radius: 18px;
  background-color: #764bff;
  display: flex;
  gap: 12px;
  padding: 22px 70px 22px 26px;
  width: max-content;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin: 60px auto 0;
  transition: 0.2s ease-in-out;
}
.faq_btn p {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.05;
  letter-spacing: -0.04rem;
}
.faq_btn:hover {
  transform: translateY(-4px);
}
.faq_right .promo_btn {
  display: none;
}
@media (max-width: 767px) {
  .faq_btn {
    width: 100%;
  }
  .faq {
    padding: 50px 0;
  }
  .faq_title {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 30px;
    padding-left: 0;
  }
  .faq_title img {
    display: none !important;
  }
  .question {
    font-size: 16px;
    line-height: 22px;
  }
  .answer {
    font-size: 14px;
    line-height: 21px;
  }
  .btn {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .faq {
    background-position: 50% 50%;
  }
  .question {
    padding: 10px 45px 10px 0;
  }
  .question::before {
    right: 0;
  }
  .answer {
    padding-left: 0;
  }
  .faq_block {
    flex-wrap: wrap;
  }
  .faq_left,
  .faq_right {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .faq_title {
    position: relative;
    top: 0;
    font-size: 30px;
  }
  .faq_title br {
    display: none;
  }
  .faq ul li {
    margin-left: 17px;
  }
  .faq::before {
    content: none;
  }
  .faq_titles {
    position: relative;
    top: 0;
  }
  .promo_btn {
    width: 100%;
    font-size: 16px;
    padding: 16px;
  }
  .faq_right .promo_btn {
    display: flex;
  }
  .faq_left .promo_btn {
    display: none;
  }
}

@media (max-width: 344px) {
  .faq_btn p {
    font-size: 18px;
  }
}
