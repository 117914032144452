.authorr {
    padding: 45px 0;
}
.author_block {
    background-color: #F8F9FB;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.author_block__info {
    padding: 50px 40px;
    flex: 0 1 50%;
    max-width: 50%;
}
.author_block__info p {
    margin-bottom: 24px;
    line-height: 1.3;
    font-size: 18px;
}
.author_block__info p:last-of-type {
    margin-bottom: 0;
}
.author_block__info-title {
    font-size: 24px;
    line-height: 1.55;
    font-weight: 500;
    color: var(--main-p-color);
}
.author_block__info-name {
    font-size: 64px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: Bebas;
}
.author_block__video2 {
    flex: 0 1 50%;
    max-width: 50%;
    /* padding: 0 40px; */
    height: 295px;
    position: relative;
    max-width: 520px;
    margin: 0 auto;
}
.author_block__video-preview2 {
    position: absolute;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/pregnancy/pregnancy_preview2.jpg');
    background-color: #F8F9FB;
    background-size: cover;
    transition: .2s ease-in-out .4s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.author_block__video-preview2::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    background-color: #00b2ff;
    border-radius: 100%;
    margin-top: -24px;
    margin-left: -24px;
    border: 1px solid transparent;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.author_block__video-preview2::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -4px;
    border: 10px solid transparent; border-left: 10px solid #fff;
    width: 10px;
    height: 10px;
    cursor: pointer;
}
.author_block__video-preview2.hide {
    opacity: 0;
    pointer-events: none;
}

@media(max-width: 1199px) {
    .author_block {
        flex-wrap: wrap;
    }
    .author_block__info, .author_block__video2 {
        flex: 0 1 100%;
        max-width: 100%;
        padding-top: 0;
    }
    .author_block__info {
        padding-bottom: 20px;
    }
    .author_block__video2 {
        height: 570px;
        margin-bottom: 20px;
    }
}
@media(max-width: 767px) {
    .author_block__info-name {
        font-size: 60px;
    }
    .author_block__video2 {
        height: 370px;
    }
}
@media(max-width: 575px) {
    .author_block__info-name {
        font-size: 38px;
    }
    .author_block__video2 {
        height: auto;
    }
    .author_block__video-preview2 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .author_block__info {
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }
}