.stretch_container {width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}
@media (min-width:576px){.stretch_container{max-width:748px}}
@media (min-width:768px){.stretch_container{max-width:972px}}
@media (min-width:992px){.stretch_container{max-width:1180px}}
@media (min-width:1200px){.stretch_container{max-width:1190px}}

.stretch_reg_form > .chakra-stack {
    background-color: transparent;
    padding: 0;
}
.stretch_reg_form > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack > p {
    display: none;
}
.stretch_subs_form {
    font-family: Qanelas;
}
.stretch_subs_form > div > div > .chakra-stack:first-of-type > p {
    display: none;
}
.stretch_subs_form > div > div > .chakra-stack:first-of-type > .chakra-stack > .chakra-stack > p:first-of-type {
    font-size: 24px;
    font-weight: 700;
}
.stretch_subs_form .gt-subsription__payment {
    margin-top: 10px;
}
.stretch_subs_form .gt-subsription__payment > .chakra-stack > .chakra-stack {
    margin-top: 10px;
    padding: 15px;
}
.stretch_subs_form .gt-subsription__payment > .chakra-stack > .chakra-stack svg {
    font-size: 35px;
}
.stretch_subs_form .gt-subsription__payment > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack > p:first-of-type {
    font-size: 22px;
    line-height: normal;
}
.stretch_subs_form .gt-subsription__payment > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack > p:last-of-type {
    margin-top: 5px;
}
@media(max-width: 575px) {
    .stretch_subs_form > div > div > .chakra-stack:first-of-type > .chakra-stack > .chakra-stack > p:first-of-type {
        font-size: 18px;
    }
    .stretch_subs_form > div > div > .chakra-stack:first-of-type > .chakra-stack {
        padding: 20px;
    }
    .stretch_subs_form .gt-subsription__payment > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack > p:first-of-type {
        font-size: 18px;
    }
    .stretch_subs_form .gt-subsription__payment > .chakra-stack > .chakra-stack {
        padding: 10px;
    }
}