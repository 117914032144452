.promo {
    background-color: #f5f5f5;
    padding: 190px 0;
    height: 874px;
    position: relative;
}
.promo::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: 90px;
    width: 583px;
    height: 742px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/promo_bg.jpg');
    pointer-events: none;
    z-index: 1;
    mix-blend-mode: darken;
    filter: saturate(0.8);
}
.promo::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/zap.svg');
    opacity: 0.25;
    pointer-events: none;
}
.promo_block {
    position: relative;
    z-index: 1;
}
.promo_title {
    font-weight: 800;
    font-size: 72px;
    line-height: 80px;
    margin-bottom: 10px;
}
.promo_subtitle {
    color: #EE4777;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.01em;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
}
.promo_text {
    color: #4A4A4A;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 60px;
}
.promo_btns {
    display: flex;
}
.promo_btn {
    width: 322px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(275.94deg, #B92655 -15.09%, #B92655 -15.08%, rgba(228, 47, 129, 0) 102.69%), #EE4777;
    border-radius: 20px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
.promo_btn__more {
    width: 296px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(275.94deg, #1C1C1C -15.09%, #232323 -15.08%, #3E3E3E 102.69%), #232323;
    border-radius: 20px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 20px;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    transition: .2s ease-in-out;
}
.promo_btn__more:hover {
    box-shadow: 0px 4px 20px rgba(44, 44, 44, 0.75);
    transform: translateY(-2px);
}

@media(max-width: 1199px) {
    .promo {
        height: auto;
    }
    .promo::before {
        margin-left: 0;
    }
    .promo_btns {
        flex-direction: column;
    }
    .promo_btn__more {
        margin-left: 0;
        margin-top: 20px;
        width: 322px;
    }
}
@media(max-width: 991px) {
    .promo {
        padding-top: 140px;
        padding-bottom: 55px;
    }
    .promo::before {
        margin-left: -291.5px;
        bottom: 350px;
    }
    .promo_title {
        text-align: center;
    }
    .promo_subtitle {
        margin-bottom: 650px;
        text-align: center;
    }
    .promo_text {
        padding: 15px;
        background-color: #fff;
        border-radius: 6px;
    }
    .promo_text br {
        display: none;
    }
    .promo_btns {
        align-items: center;
    }
}
@media(max-width: 767px) {
    .promo_title {
        font-size: 62px;
        line-height: 70px;
    }
    .promo_subtitle {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 550px;
    }
    .promo::before {
        width: 410px;
        height: 522px;
        background-size: cover;
        margin-left: -205px;
        bottom: 450px;
    }
    .promo_text {
        font-size: 20px;
        line-height: 30px;
    }
}
@media(max-width: 575px) {
    .promo {
        padding-top: 95px;
    }
    .promo_title {
        font-size: 44px;
        line-height: 48px;
        margin-bottom: 5px;
    }
    .promo_subtitle {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 420px;
    }
    .promo_text {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 25px;
    }
    .promo_btn, .promo_btn__more {
        width: 100%;
        height: 80px;
    }
    .promo_btn__more {
        margin-top: 15px;
    }
    .promo::before {
        width: 320px;
        height: 407px;
        margin-left: -160px;
        bottom: 350px;
    }
}
@media(max-width: 499px) {
    .promo::before {
        bottom: 390px;
    }
}
@media(max-width: 390px) {
    .promo_title {
        font-size: 40px;
        line-height: 44px;
    }
    .promo_subtitle {
        font-size: 20px;
        line-height: 26px;
    }
}
@media(max-width: 375px) {
    .promo::before {
        bottom: 420px;
    }
}
@media(max-width: 357px) {
    .promo_title {
        font-size: 34px;
        line-height: 38px;
    }
    .promo_subtitle {
        font-size: 18px;
        line-height: 24px;
    }
}