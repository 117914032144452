.what {
    position: relative;
    padding-top: 130px;
    padding-bottom: 50px;
    background-color: #FAFAFA;
    overflow: hidden;
}
.what::before {
    content: '';
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    height: 75px;
    transform: rotate(-1.5deg);
    background-color: #fff;
}
.what_title {
    position: relative;
    margin-bottom: 64px;
    text-align: center;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
}
.what_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.what_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px;
    margin-bottom: 56px;
}
.what_info {
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    text-align: center;
    padding: 40px 30px 30px;
    height: 100%;
}
.what_img {
    margin-top: -96px;
    margin-bottom: -10px;
}
.what_text {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
}
.what_text2 {
    color: rgba(77, 87, 97, 0.75);
    font-size: 16px;
    line-height: 22px;
}
@media(max-width:575px) {
    .what {
        padding: 80px 0 40px;
    }
    .what_title {
        font-size: 32px;
        line-height: 36px;
    }
    .what_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 50px;
    }
}