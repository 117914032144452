.what {
    padding: 100px 0 50px;
}
.title {
    text-align: center;
    font-family: Nface;
    margin-bottom: 45px;
    font-size: 35px;
    line-height: 1.3;
    text-transform: uppercase;
}
.block {
    padding: 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 45px 24px;
    background-color: #fff;
    border-radius: 24px;
}
.item {
    flex: 0 1 calc(33.333333% - 16px);
    max-width: calc(33.333333% - 16px);
}
.item_img {
    margin-bottom: 35px;
}
.item_img img {
    border-radius: 12px;
}
.item_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.3;
}
.item_tag {
    padding: 10px 20px;
    line-height: 1.3;
    border-radius: 80px;
    background-color: #E3FF4D;
    margin-bottom: 10px;
    width: max-content;
}
.item_big {
    flex: 0 1 100%;
    max-width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 24px;
}
.item_big .item_tag {
    width: auto;
}
.item_big .item_img {
    margin-bottom: 0;
    flex: 0 1 714px;
    max-width: 714px;
}
.item_big_info {
    flex: 1;
}
@media(max-width: 575px) {
    .what {
        padding: 40px 0;
    }
    .title {
        font-size: 22px;
        margin-bottom: 25px;
    }
    .block {
        padding: 40px;
    }
    .item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .item_tag {
        max-width: 100%;
        width: 100%;
        text-align: center;
    }
    .item_title {
        font-size: 20px;
        text-align: center;
    }
    .item_img {
        margin-bottom: 15px;
    }
    .item_big {
        flex-direction: column;
    }
    .item_big .item_img {
        flex: 0 1 100%;
        max-width: 100%;
    }
}