.programs {
    padding: 60px 0;
    position: relative;
    background-color: #fff;
    font-family: Gilroy;
}
.t1 {
    font-family: Muller;
    color: #534E59;
    font-size: 46px;
    font-weight: 500;
    line-height: 1.12;
    margin-bottom: 48px;
    text-align: center;
}
.programs_block {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1550px;
    padding: 0 15px;
}
.programs_item {
    max-width: 362px;
}
.programs_info {
    border-radius: 31.714px;
    border: 1px solid #E1E1FA;
    background: linear-gradient(0deg, #F6F6FF 0%, #F6F6FF 100%), #93A1E3;
    box-shadow: 0px 10.873px 21.747px -3.624px rgba(16, 24, 40, 0.04), 0px 3.624px 10.873px -1.812px rgba(16, 24, 40, 0.02);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: .2s ease-in-out;
}
.programs_info:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}
.programs_img {
    position: relative;
    text-align: right;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr_bg2.png');
    background-size: cover;
    background-position: 50% 50%;
    /* height: 224px; */
}
.programs_img img {
    pointer-events: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.programs_img__info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.programs_tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: auto;
}
.programs_tag {
    padding: 0 8px;
    background-color: #ED53AE;
    border-radius: 19.333px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    color: #fff;
    line-height: 2;
}
.programs_tag_new {
    color: #fff;
    background-color: #FF5C00;
}
.programs_img__info_title {
    letter-spacing: 0.03rem;
    color: #534E59;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}
.programs_img__info_title span {
    display: block;
    margin-top: 4px;
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 600;
}
.programs_img__info_price {
    position: relative;
    max-width: max-content;
    color: #fff;
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
    display: none!important;
}
.programs_img__info_price span {
    font-family: Manrope;
}
.programs_img__info_price::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    height: 3px;
    width: 100%;
    background-color: #8298E6;
}
.programs_text {
    padding: 16px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.programs_text ul {
    padding-bottom: 20px;
    margin-bottom: auto;
}
.programs_text ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.54;
    color: #534E59;
}
.programs_more {
    border-top: 1px solid #E1E1FA;
    padding: 15px 0 2px;
    overflow: hidden;
}
.programs_more input {
    display: none;
}
.programs_more label {
    position: relative;
    width: 100%;
    display: block;
    text-transform: uppercase;
    color: #534E59;
    letter-spacing: 0.015rem;
    font-size: 16px;
    line-height: 1;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
}
.programs_more label::before {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 14px;
    height: 2px;
    background-color: #8298E6;
}
.programs_more label::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 2px;
    width: 2px;
    height: 14px;
    background-color: #8298E6;
}
.programs_more p, .programs_more label div {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: normal;
    color: #534E59;
    font-size: 16px;
    line-height: 1.45;
    padding-top: 12px;
    font-family: Manrope;
    text-transform: initial;
    transition: .2s ease-in-out;
}
.programs_more p b {
    font-weight: 600;
}
.programs_more label div ul {
    padding-bottom: 0;
}
.programs_more label div ul li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
}
.programs_more label div ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #8298E6;
    border-radius: 100%;
}
.programs_more input[type=checkbox]:checked + label p, 
.programs_more input[type=checkbox]:checked + label div {
    height: max-content;
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
}
.programs_more input[type=checkbox]:checked + label::after {
    opacity: 0;
}

@media(max-width: 575px) {
    .programs {
        padding: 40px 0;
    }
    .programs_block {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    .t1 {
        font-size: 24px;
        margin-bottom: 16px;
    }
}
@media(max-width: 380px) {
    .programs_img__info_title span {
        font-size: 20px;
    }
}