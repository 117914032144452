.start_parts {
    text-align: center;
    color: #3D3D3D;
    margin: 60px 0;
}
.start_parts__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 7px;
    font-weight: bold;
    margin-top: 15px;
}
.start_parts__text {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 40px;
}
.start_parts_block {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 75px;
    border-bottom: 1px solid #A2A2A2;
}
.start_parts_number {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: inline-block;
    border-top: 3px solid transparent;
    border-left: 3px solid #3D3D3D;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transform: rotate(45deg);
    font-size: 31px;
    line-height: 38px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.start_parts_number > * {
    transform: rotate(-45deg);
}
.start_parts_number2 {
    border-top: 3px solid #3D3D3D;
}
.start_parts_number3 {
    border-top: 3px solid #3D3D3D;
    border-right: 3px solid #3D3D3D;
}
.start_parts_number4 {
    border: 3px solid #3D3D3D;
}
.start_parts_number::before {
    content: '';
    position: absolute;
    left: 92px;
    top: 50%;
    margin-top: -82px;
    width: 65px;
    height: 2px;
    background-color: #3D3D3D;
    transform: rotate(-45deg);
}
.start_parts_number4::before {
    content: none;
}
.start_parts_item {
    flex: 0 1 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.start_parts_item > p {
    margin-top: 20px;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
}
.start_parts_item > p span {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}
@media(max-width: 991px) {
    .start_parts_number::before {
        content: none;
    }
}
@media(max-width: 767px) {
    .start_parts__title {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 5px;
    }
    .start_parts__text {
        font-size: 16px;
        line-height: 22px;
    }
    .start_parts_block {
        flex-wrap: wrap;
        padding-bottom: 25px;
    }
    .start_parts_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .start_parts_item > p {
        margin-top: 5px;
        font-size: 21px;
        line-height: normal;
    }
    .start_parts_item > p span {
        font-size: 16px;
    }
}
.countdown_timer {
    text-align: center;
    padding-top: 32px;
    display: none!important;
}
.countdown_timer p {
    margin-bottom: 10px;
    color: #845df6;
    font-family: Qanelas;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.1;
}
.countdown_timer div {
    display: flex;
    justify-content: center;
    font-family: Qanelas;
    font-size: 54px;
    font-weight: bold;
    line-height: 1.1;
    color: #845df6;
    gap: 4px;
}
.start_form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 16px;
    max-width: 850px;
    margin: 0 auto;
}
.start_form_left {
    position: relative;
    padding-left: 51px;
}
.start_form_left::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="16" fill="%23EB0A75"/><circle cx="16" cy="16" r="7.5" fill="white"/></svg>');
}
.start_form_title {
    margin-bottom: 3px;
    font-family: Qanelas;
    color: #383838;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
}
.start_form_subtitle {
    font-family: Qanelas;
    font-size: 18px;
    line-height: 1.4;
    color: #383838;
    text-align: left;
}
.start_form_right {
    position: relative;
}
.start_form_discount {
    position: absolute;
    right: 0;
    top: -52px;
    width: 80px;
    height: 56px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="56" viewBox="0 0 80 56" fill="none"><g filter="url(%23filter0_d_2014_43)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8993 23.4465C10.838 23.4702 10.7749 23.4886 10.7104 23.5016C10.0854 23.6271 9.68046 24.2356 9.80601 24.8607L11.9419 35.4939C13.247 41.9916 19.5724 46.2009 26.0701 44.8957L60.3847 38.0031C66.8823 36.698 71.0916 30.3725 69.7865 23.8749L68.7486 18.7079C68.0167 15.0639 64.4693 12.7033 60.8253 13.4352L58.719 13.8583C56.7243 14.259 54.731 13.1652 53.9974 11.2675C53.1651 9.11449 50.745 8.04379 48.592 8.87607L10.8993 23.4465Z" fill="%23F1719D"/></g><defs><filter id="filter0_d_2014_43" x="0.136248" y="0.152786" width="79.5345" height="55.8331" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1.20587"/><feGaussianBlur stdDeviation="4.82348"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.486275 0 0 0 0 0.215686 0 0 0 0 0.468235 0 0 0 0.31 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2014_43"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2014_43" result="shape"/></filter></defs></svg>');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none!important;
}
.start_form_discount p {
    color: #fff;
    transform: rotate(-15deg);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
}
.start_form_prices {
    display: flex;
    gap: 12px;
    align-items: baseline;
    text-align: right;
}
.start_form_prices span {
    text-decoration: line-through;
    font-family: Qanelas;
    color: #BBBBBB;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    display: none!important;
}
.start_form_prices p {
    font-family: Qanelas;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.3;
    color: #383838;
}
.start_form_ot {
    color: #EA0D74;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    font-family: Qanelas;
    text-align: right;
    width: max-content;
}
@media(max-width: 575px) {
    .countdown_timer {
        order: 4;
    }
    .countdown_timer p {
        font-size: 18px;
    }
    .countdown_timer div {
        font-size: 42px;
    }
    .start_form {
        padding: 0;
        flex-direction: column;
        order: 2;
        margin-bottom: 0;
        padding-top: 16px;
    }
    .start_form_left {
        padding-left: 24px;
    }
    .start_form_left::before {
        width: 18px;
        height: 18px;
        background-size: cover;
        top: 2px;
    }
    .start_form_title {
        font-size: 18px;
    }
    .start_form_subtitle {
        font-size: 14px;
    }
    .start_form_prices {
        justify-content: center;
    }
    .start_form_ot {
        text-align: center;
    }
    .start_form_discount {
        display: none;
    }
}