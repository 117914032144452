.about {
    background: linear-gradient(272deg, #B92655 22.7%, #E42F81 107.89%), #EE4777;
    padding: 170px 0;
    position: relative;
    overflow: hidden;
}
.about::before {
    content: '';
    position: absolute;
    background-color: #fff;
    top: -60px;
    left: -10%;
    width: 130%;
    height: 100px;
    transform: rotate(-2.36deg);
}
.about::after {
    content: '';
    position: absolute;
    background-color: #FAFAFA;
    bottom: -55px;
    left: -10%;
    width: 130%;
    height: 100px;
    transform: rotate(-2.36deg);
}
.about_title {
    text-align: center;
    margin-bottom: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 800;
    font-size: 52px;
    line-height: 1.1;
}
.about_subtitle {
    text-align: center;
    margin-bottom: 55px;
    font-size: 24px;
    line-height: 1.4;
    color: #fff;
    font-family: Roboto;
}
.about_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}
.about_item {
    flex: 0 1 360px;
    max-width: 360px;
    padding: 20px;
    background-color: #EA296D;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.about_text {
    background-color: #fff;
    border: 1px solid rgba(24, 24, 24, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    padding: 15px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    color: #111927;
    margin-top: 16px;
    text-align: center;
}
.about_info_1 div:last-of-type {
    margin-top: 8px;
    width: 100%;
    text-align: right;
}
.about_info_2 div:last-of-type {
    width: 100%;
    text-align: center;
    margin-top: 12px;
}
.about_info_4 div{
    width: 100%;
    text-align: center;
}
.about_info_6 div:first-of-type {
    width: 100%;
    text-align: center;
}
.about_info_6 div:last-of-type {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
@media(max-width:991px) {
    .about_block {
        gap: 18px;
    }
}
@media(max-width:767px) {
    .about {
        padding: 115px 0;
    }
    .about_title {
        font-size: 44px;
    }
    .about_subtitle {
        font-size: 20px;
    }
    .about_item {
        margin: 0 auto;
        padding: 0;
        background-color: transparent;
        min-height: 326px;
    }
    .slider_nav {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: -15px;
        position: relative;
        z-index: 1;
    }
    .slider_btn_prev {
        transform: rotate(180deg);
    }
    .about_text {
        font-weight: 500;
        color: #fff;
        font-size: 16px;
        border: 1px solid rgba(24, 24, 24, 0.06);
        background: rgba(0, 0, 0, 0.12);
        padding: 32px;
    }
}
@media(max-width:575px) {
    .about_text {
        padding: 15px;
    }
    .about_title {
        font-size: 20px;
    }
    .about_subtitle {
        display: none;
    }
}