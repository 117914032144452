.mainnote {
    background-color: var(--main-bg);
    padding: 75px 0;
}
.mainnote_pretitle {
    text-align: center;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 10px;
    color: var(--main-color);
    font-weight: bold;
}
.mainnote_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 35px;
}
.mainnote_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.mainnote_block {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mainnote_item {
    border: 1px solid var(--hover-bg);
    background-color: var(--main-bg);
    border-radius: 15px;
    padding: 17px 25px;
    transition: .2s ease-in-out;
    line-height: 1;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    max-width: 770px;
    margin-bottom: 15px;
}
.mainnote_item:hover {
    background-color: var(--hover-bg);
}

@media(max-width: 991px) {
    .mainnote_title {
        font-size: 42px;
    }
}
@media(max-width: 767px) {
    .mainnote_title {
        font-size: 36px;
    }
    .mainnote_title br {
        display: none;
    }
}
@media(max-width: 575px) {
    .mainnote_title, .mainnote_pretitle {
        font-size: 24px;
    }
    .mainnote_item {
        padding: 15px;
        font-size: 16px;
    }
    .mainnote_title span {
        padding-bottom: 5px;
    }
}