.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .1s ease-in-out;
}
.modal.active {
    opacity: 1;
    pointer-events: all;
}
.modal::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.54);
    backdrop-filter: blur(30px);
}
.modal_content {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 36px;
    max-width: 530px;
    margin: 0 auto;
    text-align: center;
    padding: 45px;
}
.modal_content_title {
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 15px;
}
.modal_content_other {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-top: 15px;
}
.modal_content_other a {
    text-decoration: underline;
}
.modal_content_close {
    position: absolute;
    right: 25px;
    top: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.modal_content_close::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #eee;
    transform: rotate(45deg);
}
.modal_content_close::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #eee;
    transform: rotate(-45deg);
}