.start {
    padding: 50px 0;
}
.block {
    max-width: 980px;
    margin: 0 auto;
}
.title {
    text-align: center;
    text-transform: uppercase;
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.subtitle {
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.4;
    font-size: 20px;
    color: #6A6A6A;
}
.remains_n_place {
    font-size: 20px;
    font-weight: 600;
    color: #FF4E53;
    line-height: 1.4;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 45px;
    text-align: center;
}
.remains {
    max-width: 640px;
    margin: 0 auto 50px;
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 20px 30px;
    border-radius: 32px;
    background: #DDD4EC;
}
.timer2 > div {
    display: flex;
    font-family: Unisans;
    font-weight: bold;
    font-size: 74px;
    line-height: 1.1;
}
.timer2 > div span {
    margin-left: -4px;
    margin-right: 4px;
}
.remains_text {
    flex: 0 1 230px;
    max-width: 230px;
    color: rgba(40, 40, 40, 0.8);
    font-size: 22px;
    line-height: 1.4;
}
.remains_text b {
    font-weight: 600;
    color: rgba(40, 40, 40, 1);
}
.remains_number {
    flex: 1;
    font-size: 90px;
    font-family: Unisans;
    font-weight: bold;
    line-height: 1.1;
}
.dts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    flex: 1;
    margin: 0 -25px 24px;
}
.dts_item {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5000px;
    border: 1px solid #DDD4EC;
    box-shadow: 0px 4px 20px 0px rgba(49, 29, 25, 0.08);
    padding: 8px 14px;
}
.dts_item_text {
    font-size: 19px;
    line-height: 1.3;
    font-weight: 500;
}
.dts_item img {
    max-width: 32px;
}
.info_all {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 24px;
}
.info_all_name {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
}
.info_all_prices {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0 12px;
    color: #9C0096;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    text-align: right;
    flex: 0 1 232px;
    max-width: 232px;
}
.info_all_prices p {
    /* font-size: 24px; */
}
.info_all_prices em {
    text-decoration: line-through;
    color: #282828;
    font-size: 18px;
    font-style: normal;
}
.info_all_prices span {
    display: block;
    color: #282828;
    font-size: 18px;
    flex: 0 1 100%;
}
.info {
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.info_item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.info_item p {
    font-size: 20px;
    line-height: 1.4;
    color: #6A6A6A;
}
.info_item p b {
    font-weight: 600;
}
.info_item span {
    text-decoration: line-through;
    font-size: 18px;
    line-height: 1.3;
}
.info_more {
    font-size: 18px;
    margin-bottom: 38px;
    line-height: 1.3;
}
.pay {
    /* padding-top: 50px; */
    display: flex;
    /* flex-direction: column; */
    gap: 20px;
    order: 2;
}
.pay_item {
    /* text-align: center; */
    background-color: #C0D5E2;
    padding: 20px;
    border-radius: 24px;
    display: flex;
    gap: 10px;
}
.pay_item img {
    margin-bottom: 20px;
    max-width: 48px;
    order: 2;
    width: 48px;
    height: 48px;
}
.pay_item p {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 15px;
}
.pay_item span {
    color: #6A6A6A;
    font-size: 16px;
    line-height: 1.4;
}
.oh {
    overflow: hidden;
}
.timer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 0px auto 25px;
}
.timer > p {
    text-align: left;
}
.timer > div {
    display: flex;
    gap: 5px;
    color: #FF4E53;
    font-size: 50px;
    font-family: Nface;
    line-height: 1.35;
    font-weight: normal;
}
.timer > div span {
    margin-right: 5px;
    margin-left: -5px;
}
.btn {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 15px;
    background: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 15.07%, rgba(255, 255, 255, 0.20) 148.09%), #9C0096;
    box-shadow: 0px 4px 25.5px 0px rgba(156, 0, 150, 0.43);
    cursor: pointer;
    position: relative;
    padding: 0 40px;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
}
.btn:hover {
    transform: translateY(-4px);
}
.inf {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 45px;
}
.inf_item {
    flex: 1;
    border-radius: 24px;
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 30px;
    background-color: #fff;
    max-width: 374px;
}
.inf_item p:nth-of-type(1) {
    font-size: 16px;
    line-height: 1.4;
    color: #6A6A6A;
}
.inf_item p:nth-of-type(2) {
    font-size: 30px;
    line-height: 1.4;
    font-weight: 600;
}
.choose {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 45px;
}
.choose_item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: #fff;
    border-radius: 24px;
    padding: 20px;
}
.choose_title {
    padding-left: 42px;
    position: relative;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.choose_title p {
    font-weight: normal;
    font-size: 16px;
    color: #6A6A6A;
}
.choose_title ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.choose_title ul li {
    font-size: 14px;
    color: #6A6A6A;
    font-weight: normal;
}
.choose_title b {
    font-weight: 600;
}
.choose_title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border: 6px solid #D3D3D3;
    border-radius: 100%;
} 
.choose_item_active .choose_title::before {
    border-color: #FF4E53;
}
.choose_prices {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 14px;
    align-items: flex-end;
}
.credit {
    color: #747373;
}
.choose_prices b {
    font-weight: normal;
}
.choose_prices p:nth-of-type(2) {
    color: #CCCCCC;
    font-size: 18px;
    text-decoration: line-through;
    font-weight: 600;
    line-height: 1.3;
}
.choose_prices p:nth-of-type(1) {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
}
.fgf {
    display: flex;
    /* align-items: center; */
    gap: 20px;
}
.fgf span {
    color: #FF4E53;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.4;
}
.rowstart {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.rowstart > div {
    /* flex: 0 1 480px; */
    /* max-width: 480px; */
}

@media(max-width:575px) {
    .pay {
        flex-direction: column;
    }
    .credit {
        /* text-align: center; */
    }
    .inf_item p {
        flex: 1;
    }
    .inf_item p:nth-of-type(1) {
        line-height: 1;
        letter-spacing: 0;
    }
    .inf {
        flex-direction: column;
    }
    .inf_item {
        width: 100%;
        max-width: 100%;
        padding: 16px;
        display: flex;
        align-items: center;
        /* align-items: flex-start; */
        /* flex-direction: column; */
        gap: 10px;
        border-radius: 16px;
        min-height: auto;
    }
    .inf_item img {
        /* display: none!important; */
    }
    .choose_prices span {
        margin-left: 0;
    }
    .inf_item p:nth-of-type(2) {
        font-size: 22px;
        letter-spacing: 0;
        max-width: max-content;
        text-align: right;
    }
    .remains_n_place {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
    }
    .btn {
        height: 70px;
        font-size: 18px;
        line-height: 1;
        text-align: center;
    }
    .start {
        padding: 40px 0;
    }
    .block {
        /* padding: 40px 15px; */
        border-radius: 40px;
    }
    .choose {
        gap: 32px;
    }
    .choose_item {
        flex-direction: column;
        gap: 16px;
    }
    .choose_prices {
        justify-content: center;
    }
    .choose_title {
        font-size: 16px;
    }
    .pretitle {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .title {
        margin-bottom: 26px;
        font-size: 22px;
    }
    .timer {
        flex-direction: column;
        align-items: center;
    }
    .timer > p {
        text-align: center;
    }
    .subtitle {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .prices {
        font-size: 26px;
    }
    .remains {
        padding: 15px;
        flex-direction: column;
        /* align-items: flex-start; */
        text-align: center;
        gap: 0;
    }
    .remains_text {
        flex: 0 1 100%;
        max-width: 100%;
        font-size: 16px;
    }
    .remains_number {
        font-size: 60px;
    }
    .dts {
        gap: 8px;
        padding: 0;
        flex-direction: column;
        align-items: center;
        margin: 0 0 24px;
    }
    .dts_item_text {
        font-size: 14px;
    }
    .info_all_name {
        font-size: 18px;
        line-height: 1.2;
    }
    .info_all_prices {
        font-size: 18px;
    }
    .info_all_prices em,
    .info_all_prices span {
        font-size: 16px;
    }
    .info_item p,
    .info_item span {
        font-size: 16px;
    }
    .info_more {
        font-size: 16px;
    }
    .pay_item p {
        font-size: 18px;
    }
    .pay_item span {
        font-size: 16px;
    }

    .timer2 > div {
        font-size: 50px;
    }
    .fgf {
        position: relative;
    }
    .fgf span {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 28px;
    }
}