.trainings {
    position: relative;
    padding-bottom: 925px;
}
.title {
    margin-bottom: 12px;
    text-align: center;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
}
.subtitle {
    text-align: center;
    color: #4D5761;
    font-size: 24px;
    line-height: 34px;
}
.programs_row {
    display: flex;
    align-items: center;
    gap: 22px;
    position: absolute;
    left: 0;
    width: max-content;
}
.programs_row1 {
    bottom: 700px;
    transform: translateX(-240px);
    animation: scroll 70s linear infinite;
}
.programs_row2 {
    bottom: 500px;
    animation: scroll2 66s linear infinite;
}
.programs_row3 {
    bottom: 300px;
    transform: translateX(-240px);
    animation: scroll 70s linear infinite;
}
.programs_row4 {
    bottom: 100px;
    animation: scroll2 66s linear infinite;
}
@keyframes scroll {
    0% {
        transform: translateX(-240px);
    }
    100% {
        transform: translateX(-6548px);
    }
}
@keyframes scroll2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-5976px);
    }
}

 .programs_item {
    position: relative;
    width: 310px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.programs_item:hover {
    transform: scale(1.05);
}
.programs_item img {
    border-radius: 6px;
} 
 .programs_item p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
.logo {
    text-align: center;
}
.logo img {
    margin-bottom: 40px;
    width: 168px;
}
@media(max-width: 991px) {
    .title {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 12px;
    }
    .subtitle {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}
@media(max-width: 575px) {
    .trainings {
        padding-bottom: 446px;
    }
    .programs_item {
        width: 160px;
    }
    .programs_item p {
        font-size: 10px;
        line-height: 12px;
    }
    .programs_row {
        gap: 12px;
    }
    .programs_row1 {
        bottom: 356px;
    }
    .programs_row2 {
        bottom: 254px;
    }
    .programs_row3 {
        bottom: 152px;
    }
    .programs_row4 {
        bottom: 50px;
    }
    .logo img {
        margin-bottom: 16px;
        width: 112px;
    }

    @keyframes scroll {
        0% {
            transform: translateX(-240px);
        }
        100% {
            transform: translateX(-3508px);
        }
    }
    @keyframes scroll2 {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-3096px);
        }
    }
}