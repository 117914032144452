.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/online2024/promo_bg.jpg');
    background-size: cover;
    padding-top: 60px;
    height: 743px;
}
.pretitle {
    border-radius: 2px;
    background-color: rgba(100, 146, 241, 0.20);
    margin-bottom: 25px;
    padding: 5px 10px;
    width: max-content;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.55;
}
.t1 {
    text-transform: uppercase;
    color: #fff;
    font-size: 80px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
}
.t2 {
    font-size: 46px;
    margin-bottom: 40px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}
.t2 span {
    color: #FF8787;
}
.text {
    margin-bottom: 22px;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
}
.block {
    display: flex;
    gap: 30px;
    margin-bottom: 46px;
}
.block_text {
    display: none!important;
}
.item {
    flex: 0 1 196px;
    max-width: 196px;
}
.item img {
    margin-bottom: 4px;
}
.item p {
    font-family: Gilroy;
    font-weight: 500;
    line-height: 1.3;
    color: #fff;
}
.btns {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
}
.btn {
    border-radius: 90px;
    background: linear-gradient(91deg, #FF8686 0%, #E85A5A 100%);
    box-shadow: 0px 4px 32px 0px rgba(255, 123, 163, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    width: 446px;
    height: 100px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    color: #fff;
    transition: .3s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
.btn_text p {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
}
.remain {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.55;
}
.remain span {
    color: #FF8686;
}
@media(max-width: 575px) {
    .promo {
        height: auto;
        background-image: none;
        background-color: #000;
        padding-top: 45px;
        padding-bottom: 50px;
        overflow: hidden;
    }
    .pretitle {
        font-size: 18px;
        margin: 0 auto 10px;
        width: auto;
        text-align: center;
    }
    .t1 {
        text-align: center;
        font-size: 42px;
    }
    .t2 {
        text-align: center;
        font-size: 26px;
        margin-bottom: 480px;
    }
    .promo_info {
        display: flex;
        flex-direction: column;
    }
    .text {
        display: none!important;
    }
    .btns {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 16px;
    }
    .remain {
        font-size: 20px;
        text-align: center;
        margin-bottom: 24px;
    }
    .btn {
        font-size: 18px;
        width: 300px;
        height: 76px;
        position: relative;
        margin: 0 auto;
    }
    .btn::before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -220px;
        bottom: 76px;
        width: 440px;
        height: 457px;
        background-size: cover;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/online2024/promo_mob.png');
        pointer-events: none;
    }
    .block {
        order: 2;
        flex-direction: column;
        gap: 20px;
    }
    .block_text {
        display: block!important;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 22px;
        line-height: 1.3;
        font-weight: bold;
    }
    .block_text span {
        color: #FF8787;
    }
    .item {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
    }
}