.promo {
    padding: 30px 0 50px;
}
.promo_block {
    display: flex;
    gap: 20px;
    position: relative;
}
.promo_block__left {
    flex: 0 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    background-color: #F1F1F1;
    padding: 85px 50px 100px;
    border-radius: 30px;
}
.promo_block__left_title {
    font-family: Bebas;
    text-align: center;
    color: #171717;
    font-size: 76px;
    line-height: 0.9;
    letter-spacing: -0.381px;
    margin-bottom: 12px;
}
.promo_block__left_subtitle {
    margin-bottom: 85px;
    color: #454545;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.4px;
    text-align: center;
}
.promo_block__left_btn {
    border-radius: 18px;
    background: #E90973;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    max-width: max-content;
    padding: 7px 7px 7px 47px;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.5px;
    margin: 0 auto 42px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.promo_block__left_btn:hover {
    transform: translateY(-4px);
}
.promo_block__left_people {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
}
.promo_block__left_people p {
    color: #171717;
    font-size: 18px;
    line-height: 1.3;
    text-transform: uppercase;
}
.promo_block__left_people p span {
    color: #E90973;
}
.promo_block__right {
    flex: 0 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    background-color: #E90973;
    border-radius: 30px;
    position: relative;
}
.promo_block__right_title {
    padding: 16px;
    color: #fff;
    font-family: Bebas;
    font-size: 105px;
    line-height: 1.28;
    text-transform: uppercase;
    text-align: center;
}
.promo_block__right_img {
    margin-top: -85px;
    pointer-events: none;
}
.promo_block__right_info {
    position: absolute;
    right: 25px;
    bottom: 25px;
    border-radius: 16.246px;
    border: 0.956px solid rgba(255, 255, 255, 0.80);
    background: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(7.836181163787842px);
    padding: 17px 28px;
    max-width: 375px;
}
.promo_block__right_info ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.promo_block__right_info ul li {
    position: relative;
    padding-left: 32px;
}
.promo_block__right_info ul li::before {
    content: '';
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><g clip-path="url(%23clip0_70_3428)"><path d="M-3.89844 9.24414L16.6127 9.24414" stroke="%23ED3052" stroke-width="1.75199" stroke-linecap="square" stroke-linejoin="round"/><mask id="path-2-inside-1_70_3428" fill="white"><path d="M10.4609 2.57617L18.6654 9.24229L10.4609 15.9084"/></mask><path d="M11.5657 1.21643L10.206 0.111634L7.9964 2.83112L9.35614 3.93592L11.5657 1.21643ZM18.6654 9.24229L19.7702 10.602C20.1796 10.2693 20.4174 9.76986 20.4174 9.24229C20.4174 8.71471 20.1796 8.21523 19.7702 7.88254L18.6654 9.24229ZM9.35614 14.5487L7.9964 15.6534L10.206 18.3729L11.5657 17.2681L9.35614 14.5487ZM9.35614 3.93592L17.5606 10.602L19.7702 7.88254L11.5657 1.21643L9.35614 3.93592ZM17.5606 7.88254L9.35614 14.5487L11.5657 17.2681L19.7702 10.602L17.5606 7.88254Z" fill="%23ED3052" mask="url(%23path-2-inside-1_70_3428)"/></g><defs><clipPath id="clip0_70_3428"><rect width="21.0239" height="21.0239" fill="white" transform="translate(0.203125 0.185547)"/></clipPath></defs></svg>');
    width: 22px;
    height: 22px;
    position: absolute;
    left: 0;
    top: 3px;
}
.promo_block__next {
    position: absolute;
    bottom: -39px;
    left: 50%;
    margin-left: -38.5px;
}
.promo_block__next::before {
    content: '';
    position: absolute;
    width: 106px;
    height: 106px;
    background-color: #fff;
    border-radius: 100%;
    left: 50%;
    margin-left: -54px;
    top: 50%;
    margin-top: -53px;
    pointer-events: none;
}
.promo_block__next img {
    position: relative;
    cursor: pointer;
}

.programs {
    padding: 60px 0;
}
.programs_title {
    text-align: center;
    margin-bottom: 25px;
    font-family: Bebas;
    color: #181818;
    font-size: 77px;
    line-height: 1.1;
}
.programs_block {
    display: flex;
    gap: 64px;
    background-color: #F1F1F1;
    border-radius: 30px;
    position: relative;
    padding: 40px 40px 40px 50px;
    margin-bottom: 20px;
}
.programs_block__left {
    flex: 0 1 325px;
    max-width: 325px;
}
.programs_block__left_title {
    margin-bottom: 20px;
    font-family: Bebas;
    color: #222;
    font-size: 45px;
    line-height: 1.05;
    text-transform: uppercase;
}
.programs_block__left_subtitle {
    color: #484343;
    font-size: 21px;
    line-height: 1.3;
}
.programs_block__right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.programs_block__right_item {
    flex: 0 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    position: relative;
    border-radius: 28px;
    border: 1px solid #111927;
    background: #111927;
    box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 4px 12px -2px rgba(16, 24, 40, 0.02);
    height: 444px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
}
.programs_block__right_item_img {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
}
.programs_block__right_item_info {
    padding: 24px 20px;
    position: relative;
}
.programs_block__right_item_info_discount {
    position: absolute;
    right: 5px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none!important;
}
.programs_block__right_item_info_discount p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    color: #fff;
    position: absolute;
    transform: rotate(-15deg);
}
.programs_block__right_item_info_discount p span {
    display: block;
    font-size: 11px;
    font-weight: normal;
}
.programs_block__right_item_info_t1 {
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.54px;
    margin-bottom: 4px;
}
.programs_block__right_item_info_t2 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.1;
    text-transform: uppercase;
}
.programs_block__right_item_info_t3 {
    color: #CDCDCD;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 35px;
}
.programs_block__right_item_info_t3b {
    margin-bottom: 60px;
}
.programs_block__right_item_info_btn {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    border-radius: 9px;
    background: #E90973;
    font-size: 22px;
    color: #fff;
    line-height: 1.2;
    letter-spacing: -0.44px;
    text-align: center;
}
.programs_block__right_item_info_btn:hover {
    transform: translateY(-4px);
}
.programs_block__img {
    position: absolute;
    left: 0;
    bottom: 0;
}
.programs_block__img::before {
    content: '';
    position: absolute;
    width: 96px;
    height: 96px;
    background-color: #fff;
    border-radius: 100%;
    left: 50%;
    margin-left: -48px;
    top: 50%;
    margin-top: -48px;
    pointer-events: none;
}
.programs_block__img img {
    position: relative;
    cursor: pointer;
}

@media(max-width: 767px) {
    .promo_block {
        flex-wrap: wrap;
    }
    .promo_block__left {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 30px 15px 40px;
    }
    .promo_block__right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .promo_block__left_title {
        font-size: 47px;
        margin-bottom: 14px;
    }
    .promo_block__left_subtitle {
        margin-bottom: 37px;
        font-size: 18px;
    }
    .promo_block__left_btn {
        font-size: 20px;
        gap: 6px;
        padding: 20px;
        margin-bottom: 25px;
    }
    .promo_block__left_btn img {
        width: 66px;
    }
    .promo_block__left_people {
        flex-direction: column;
        text-align: center;
    }
    .promo_block__next {
        display: none;
    }
    .promo_block__right_title {
        font-size: 64px;
    }
    .promo_block__right_img {
        margin-top: -45px;
    }
    .promo_block__right_info {
        padding: 12px;
        max-width: calc(100% - 30px);
        left: 15px;
        width: 100%;
        bottom: 15px;
    }
    .promo_block__right_info ul {
        gap: 8px;
    }
    .promo_block__right_info ul li {
        font-size: 12px;
        padding-left: 26px;
    }
    .promo_block__right_info ul li::before {
        background-size: cover;
        width: 16px;
        height: 16px;
    }
    .programs {
        padding-bottom: 0;
    }
    .programs_title {
        margin-bottom: 15px;
        font-size: 35px;
    }
    .programs_block {
        padding: 35px 15px;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
    .programs_block__left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_block__right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_block__left_title {
        margin-bottom: 4px;
        font-size: 30px;
    }
    .programs_block__left_subtitle {
        font-size: 18px;
    }
    .programs_block__right_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_block__right_item_info {
        padding: 16px;
        max-width: 100%;
    }
    .programs_block__right_item_info_t2 {
        font-size: 24px;
    }
    .programs_block__right_item_info_t3 {
        font-size: 16px;
    }
    .programs_block__img {
        right: -10px;
        top: -20px;
        left: auto;
        bottom: auto;
        width: 44px;
        height: 44px;
    }
    .programs_block__img::before {
        width: 56px;
        height: 56px;
        margin-left: -28px;
        margin-top: -28px;
    }
}
@media(max-width: 389px) {
    .promo_block__right_title {
        font-size: 50px;
    }
}