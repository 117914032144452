.info {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/boxes_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: -3px;
}
.title {
    font-family: Dela;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 25px;
    font-size: 52px;
    line-height: 58px;
    letter-spacing: 0.02em;
}
.subtitle {
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
    font-size: 25px;
    line-height: 32px;
    font-weight: 500;
}
.newcourse_t1 {
    margin: 0 auto 10px;
    max-width: 416px;
    background-color: #EF3F34;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 4px;
    color: #fff;
}
.newcourse_t1 img {
    margin-right: 10px;
}
.newcourse_t2 {
    margin: 0 auto 45px;
    max-width: 416px;
    background-color: #0D8ED7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 4px;
    color: #fff;
}
.newcourse_t2 img {
    margin-right: 10px;
}
.block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    justify-content: center;
}
.item {
    flex: 0 1 500px;
    max-width: 500px;
    padding: 0 20px;
    margin-bottom: 35px;
}
.first {
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(17, 16, 16, 0.31);
    border-radius: 13px;
    padding: 25px;
}
.second {
    background: #262828;
    box-shadow: 0px 4px 32px rgba(17, 16, 16, 0.31);
    border-radius: 13px;
    height: 100%;
    padding: 25px;
    color: #fff;
}
.name {
    margin-bottom: 15px;
    font-family: Dela;
    font-size: 23px;
    line-height: 26px;
    text-transform: uppercase;
}
.boxes {
    display: flex;
    flex-wrap: wrap;
}
.boxes div {
    margin-right: 10px;
    border-radius: 5px;
    background-color: #0D8ED7;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    margin-bottom: 15px;
}
.second .boxes div {
    background-color: #3E3D3D;
}
.ul li {
    border-bottom: 1px solid rgba(72,72,72,.1);
    padding: 15px 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}
.ul li:last-of-type {
    margin-bottom: 20px;
    border-bottom: none;
}
.res {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
}
.price {
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    color: #EF3F34;
}
.price span {
    color: #bbb;
    text-decoration: line-through;
    font-size: 18px;
    line-height: 1;
    font-weight: normal;
}
.second .price {
    color: #fff;
}
.second .ul li {
    border-color: #3E3F3F;
}
.more {
    padding: 15px;
    background: #3E3D3D;
    border-radius: 9px;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
}
.more p {
    margin-bottom: 10px;
}

.btn {
    width: 442px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: #0D8ED7;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 4px 12px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: .3s ease-in-out;
    margin: 0 auto;
}
.btn::before {
    content: '';
    background: #21A3EC;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 4px 12px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.btn:active::before {
    background: #0E85C8;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 12px 24px #135A83;
}
.btn:hover {
    transform: translateY(-6px);
}
.btn:hover::before {
    opacity: 1;
}
.btn p {
    position: relative;
}

@media(max-width: 575px) {
    .info {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .title {
        font-size: 29px;
        line-height: 32px;
    }
    .subtitle {
        font-size: 20px;
        line-height: 26px;
    }
    .newcourse_t1, .newcourse_t2 {
        font-size: 16px;
        line-height: 18px;
    }
    .btn {
        width: 100%;
        letter-spacing: 0;
    }
    .boxes div {
        margin-bottom: 10px;
    }
    .ul li {
        font-size: 16px;
        padding: 10px 0;
    }
    .res {
        font-size: 18px;
        line-height: 24px;
    }
    .more {
        font-size: 18px;
    }
}