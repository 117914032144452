.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa1/promo_bg.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    padding-top: 25px;
    padding-bottom: 63px;
    height: 639px;
    background-color: #02020F;
}
.promo_logo img {
    height: 130px;
    margin-left: -27px;
    margin-bottom: -40px;
    margin-top: -40px;
}
@media(max-width: 767px) {
    .promo_logo img {
        height: 90px;
        margin-left: -17px;
        margin-bottom: -35px;
        margin-top: -30px;
    }
}
.promo_title {
    font-size: 54px;
    line-height: 60px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 15px;
}
.promo_title span {
    color: var(--popa1-green);
}
.promo_subtitle {
    font-size: 25px;
    line-height: 32px;
    color: rgba(255,255,255,.75);
    margin-bottom: 23px;
}
.promo_subtitle strong {
    font-weight: 600;
    color: #fff;
}
.promo_btns {
    display: flex;
    align-items: center;
}
.promo_date {
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
    color: #fff;
    margin-left: 30px;
}
.promo_date br {
    display: none;
}
.promo_items {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
}
.promo_item1 {
    width: 181px;
    height: 123px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item2 {
    width: 181px;
    height: 123px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item3 {
    width: 181px;
    height: 123px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item1 p, .promo_item2 p, .promo_item3 p {
    position: absolute;
    bottom: 10px;
}
.promo_point1 {
    color: #fff;
    background: linear-gradient(180deg, #434343 0%, #1F1F1F 100%);
    border-radius: 60px;
    width: 241px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: 60px;
    font-weight: 600;
}
.promo_point2 {
    color: #fff;
    background: linear-gradient(180deg, #DE64F5 0%, #CF34EC 100%);
    border-radius: 60px;
    width: 181px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -115px;
    margin-left: 370px;
    font-weight: 600;
}
.promo_point3 {
    color: #282828;
    background: linear-gradient(180deg, #177B9F 0%, #BEF648 0.01%, #9DD527 100%);
    border-radius: 60px;
    width: 210px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 145px;
    margin-left: 295px;
    font-weight: 600;
}

@media(max-width: 1199px) {
    .promo {
        height: auto;
        padding-bottom: 15px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        background: transparent;
        margin-left: 0;
        margin-top: 0;
        left: 0;
        top: auto;
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        margin-left: 17px;
        margin-top: 35px;
        color: #fff;
    }
    .promo_point1 span {
        color: rgba(255, 237, 52, 1)
    }
    .promo_point2 span {
        color: rgba(107, 196, 84, 1)
    }
    .promo_point3 span {
        color: rgba(235, 10, 117, 1)
    }
    .promo_btns {
        flex-direction: column;
        align-items: flex-start;
    }
    .promo_btns > div:first-of-type {
        order: 1;
    }
    .promo_date {
        margin-left: 0;
        margin-bottom: 20px;
    }
}
@media(max-width: 991px) {
    .promo {
        overflow: hidden;
    }
    .promo_subtitle {
        margin-bottom: 15px;
    }
}
@media(max-width: 767px) {
    .promo {
        padding-top: 30px;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 65% 100%;
        background-color: #242424;
        position: relative;
    }
    .promo_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .promo_subtitle {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
    }
    .promo_subtitle br, .promo_title br {
        display: none;
    }
    .promo_date {
        margin-bottom: 10px;
        position: relative;
    }
    .promo::before {
        content: none;
    }
    .promo_items {
        flex-direction: column;
        gap: 0;
        margin-bottom: 25px;
    }
    .promo_item1, .promo_item2, .promo_item3 {
        margin-right: 20px;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
    .promo_item1 p {
        padding-left: 20px;
    }
    .promo_item2 p {
        padding-left: 35px;
    }
    .promo_item3 p {
        padding-left: 40px;
    }
}
@media(max-width: 575px) {
    .promo {
        background-position: 60% 100%;
    }
    .promo_item1, .promo_item2, .promo_item3 {
        margin-right: 0;
        width: 164px;
        height: 110px;
        justify-content: center;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 14px;
        padding-left: 0;
    }
    .promo_date {
        font-size: 25px;
        line-height: 30px;
    }
    .promo_date br {
        display: block;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 12px;
        margin: 0 4px;
    }
    .promo_subtitle br {
        display: none;
    }
    .promo_points_block {
        text-align: center;
        background-color: #000;
        padding: 5px 0;
        margin-top: 20px;
    }
}
@media(max-width: 440px) {
    .promo {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa1/promo_bg_m.jpg');
        background-position: 100% 100%;
        background-size: cover;
        padding-bottom: 0;
    }
}
@media(max-width: 374px) {
    .promo {
        padding-top: 20px;
    }
    .promo_title {
        font-size: 30px;
        line-height: 32px;
    }
    .promo_subtitle {
        font-size: 16px;
        line-height: 21px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 12px;
    }
    .promo_date {
        font-size: 28px;
        margin-bottom: 10px;
    }
}
.promo_logo {
    margin-bottom: 30px;
}
@media(max-width: 767px) {
    .promo_logo {
        margin-bottom: 25px;
    }
}