.gymteamsale_23_01 {
    font-family: Qanelas, sans-serif;
    font-size: 16px;
    color: #121212;
}

.swiper_gt .swiper-pagination-progressbar {
    /* width: 1200px!important;
    left: 50%!important;
    margin-left: -600px;
    top: inherit!important;
    bottom: 0;
    background-color: #2E2E2E;
    border-radius: 4px; */
    display: none!important;
}
.swiper_gt .swiper-pagination-progressbar-fill {
    /* background-color: #fff!important;
    border-bottom: 4px; */
    display: none!important;
}

.gtsale-reviews_item {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(184, 184, 184, 0.25);
    padding: 25px;
    border-radius: 16px;
    max-width: 300px;
    /* height: 275px; */
}
.gtsale-reviews_item__img {
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gtv/reviews_avatars.jpg');
}
.gtsale-reviews_item__img p {
    color: #696363;
    font-size: 20px;
    line-height: 26px;
}
.ri1 .gtsale-reviews_item__img {background-position: 0 0;}
.ri2 .gtsale-reviews_item__img {background-position: -100px 0;}
.ri3 .gtsale-reviews_item__img {background-position: -200px 0;}
.ri4 .gtsale-reviews_item__img {background-position: -300px 0;}
.ri5 .gtsale-reviews_item__img {background-position: -400px 0;}

.ri6 .gtsale-reviews_item__img {background-position: 0 -100px;}
.ri7 .gtsale-reviews_item__img {background-position: -100px -100px;}
.ri8 .gtsale-reviews_item__img {background-position: -200px -100px;}
.ri9 .gtsale-reviews_item__img {background-position: -300px -100px;}
.ri10 .gtsale-reviews_item__img {background-position: -400px -100px;}
.ri11 .gtsale-reviews_item__img {background-position: 0 -200px;}
.ri12 .gtsale-reviews_item__img {background-position: -100px -200px;}
.ri13 .gtsale-reviews_item__img {background-position: -200px -200px;}
.ri14 .gtsale-reviews_item__img {background-position: -300px -200px;}
.ri15 .gtsale-reviews_item__img {background-position: -400px -200px;}
.ri16 .gtsale-reviews_item__img {background-position: 0 -300px;}
.ri17 .gtsale-reviews_item__img {background-position: -100px -300px;}
.ri18 .gtsale-reviews_item__img {background-position: -200px -300px;}
.ri19 .gtsale-reviews_item__img {background-position: -300px -300px;}
.ri20 .gtsale-reviews_item__img {background-position: -400px -300px;}
.ri21 .gtsale-reviews_item__img {background-position: 0 -400px;}
.ri22 .gtsale-reviews_item__img {background-position: -100px -400px;}
.ri23 .gtsale-reviews_item__img {background-position: -200px -400px;}
.ri24 .gtsale-reviews_item__img {background-position: -300px -400px;}
.ri25 .gtsale-reviews_item__img {background-position: -400px -400px;}
.ri26 .gtsale-reviews_item__img {background-position: 0 -500px;}
.ri27 .gtsale-reviews_item__img {background-position: -100px -500px;}
.ri28 .gtsale-reviews_item__img {background-position: -200px -500px;}
.ri29 .gtsale-reviews_item__img {background-position: -300px -500px;}
.ri30 .gtsale-reviews_item__img {background-position: -400px -500px;}
.ri31 .gtsale-reviews_item__img {background-position: 0 -600px;}
.ri32 .gtsale-reviews_item__img {background-position: -100px -600px;}
.ri33 .gtsale-reviews_item__img {background-position: -200px -600px;}
.ri34 .gtsale-reviews_item__img {background-position: -300px -600px;}
.ri35 .gtsale-reviews_item__img {background-position: -400px -600px;}
.ri36 .gtsale-reviews_item__img {background-position: 0 -700px;}
.ri37 .gtsale-reviews_item__img {background-position: -100px -700px;}
.ri38 .gtsale-reviews_item__img {background-position: -200px -700px;}
.ri39 .gtsale-reviews_item__img {background-position: -300px -700px;}
.ri40 .gtsale-reviews_item__img {background-position: -400px -700px;}
.ri41 .gtsale-reviews_item__img {background-position: 0 -800px;}
.ri42 .gtsale-reviews_item__img {background-position: -100px -800px;}
.ri43 .gtsale-reviews_item__img {background-position: -200px -800px;}
.ri44 .gtsale-reviews_item__img {background-position: -300px -800px;}
.ri45 .gtsale-reviews_item__img {background-position: -400px -800px;}
.ri46 .gtsale-reviews_item__img {background-position: 0 -900px;}
.ri47 .gtsale-reviews_item__img {background-position: -100px -900px;}
.ri48 .gtsale-reviews_item__img {background-position: -200px -900px;}
.ri49 .gtsale-reviews_item__img {background-position: -300px -900px;}
.ri50 .gtsale-reviews_item__img {background-position: -400px -900px;}

.preim_text_block, .preim_images_block {
    transition: .3s ease-in-out;
}

.gt_preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0 40px;
}
.gt_loader {
    max-width: 395px;
    height: 6px;
    background: #DADADA;
    border-radius: 4px;
    width: 100%;
    position: relative;
    margin-top: 50px;
}
.gt_loader::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #EE4777;
    border-radius: 4px;
    animation: loader 1s linear;
    width: 100%;
}
@keyframes loader {
    0% {
        width: 1%;
    }
    100% {
        width: 100%;
    }
}


.gymteamsale_subscribe {
    max-width: 685px;
    margin: 0 auto;

    background: #ffff;
    padding: 45px 15px;
    border-radius: 24px;
}
.gymteamsale_subscribe .gt-subsription__offer-list > p {
    display: none;
}
.gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack {
    border-radius: 24px;
}
.gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack .chakra-text:first-of-type {
    font-family: Qanelas;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
}
.gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack .chakra-text:last-of-type {
    font-family: Qanelas;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}
.gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack:nth-of-type(3) {
    background-color: #FFDD2D;
}
.gymteamsale_subscribe .gt-subsription__payment > div:first-of-type {
    color: #fff;
}
.gymteamsale_subscribe .gt-subsription__payment > div:first-of-type > .chakra-text {
    font-family: Qanelas!important;
}
.gymteamsale_subscribe .gt-subsription__payment-list > .chakra-stack {
    border-radius: 24px;
}
.gymteamsale_subscribe .gt-subsription__payment-list > .chakra-stack .chakra-text:first-of-type {
    font-family: Qanelas;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
}
.gymteamsale_subscribe .gt-subsription__payment-list > .chakra-stack .chakra-text:last-of-type {
    font-family: Qanelas;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.gymteamsale_subscribe .sales-widget__offers button {
    width: 322px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(275.94deg, #B92655 -15.09%, #B92655 -15.08%, rgba(228, 47, 129, 0) 102.69%), #EE4777;
    border-radius: 20px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    margin-top: 30px;
    transition: .2s ease-in-out;
}
.gymteamsale_subscribe .sales-widget__offers button:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}

@media(max-width: 1199px) {
    .swiper_gt .swiper-pagination-progressbar {
        width: 90%!important;
        left: 5%!important;
        margin-left: 0;
    }
}
@media(max-width: 767px) {
    .gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack, .gymteamsale_subscribe .gt-subsription__payment-list > .chakra-stack {
        padding: 10px 15px;
    }
    .gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack .chakra-text:first-of-type, .gymteamsale_subscribe .gt-subsription__payment-list > .chakra-stack .chakra-text:first-of-type {
        font-size: 18px;
        line-height: 24px;
    }
    .gymteamsale_subscribe .gt-subsription__offer-list > .chakra-stack .chakra-text:last-of-type, .gymteamsale_subscribe .gt-subsription__payment-list > .chakra-stack .chakra-text:last-of-type {
        font-size: 14px;
        line-height: 20px;
    }
    .gymteamsale_subscribe .gt-subsription__payment > div:first-of-type > .chakra-text {
        text-align: right;
    }
}
@media(max-width: 575px) {
    .sales-widget__offers {
        max-width: 100%;
    }
    .gymteamsale_subscribe .sales-widget__offers button {
        width: 100%;
        height: 60px;
    }
    .gymteamsale_subscribe .sales-widget__offers > .chakra-stack {
        min-width: 0;
    }
    /* .swiper_gt .swiper-pagination-progressbar {
        display: none;
    } */
}