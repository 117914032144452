.velvet_prizes_login {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.velvet_prizes_login > div {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 16px;
}
@media(max-width:575px) {
    .velvet_prizes_login > div {
        padding: 15px;
    }
}