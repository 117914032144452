.whaitmore {
    padding: 75px 0;
}
.whaitmore_block {
    display: flex;
}
.whaitmore_img {
    flex: 0 1 25%;
    max-width: 25%;
    padding-right: 15px;
    margin-left: 8.333333%;
    text-align: right;
}
.whaitmore_description {
    flex: 0 1 50%;
    max-width: 50%;
    padding-left: 15px;
}
.whaitmore_description-title {
    font-size: 30px;
    line-height: 1.23;
    margin-bottom: 10px;
    font-weight: 500;
}
.whaitmore_description {
    font-size: 20px;
    line-height: 1.55;
}

@media(max-width: 991px) {
    .whaitmore_description-text br {
        display: none;
    }
    .whaitmore_description {
        flex: 0 1 75%;
        max-width: 75%;
    }
    .whaitmore_img {
        margin-left: 0;
    }
}
@media(max-width: 575px) {
    .whaitmore_block {
        flex-wrap: wrap;
    }
    .whaitmore_img {
        flex: 0 1 100%;
        max-width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    .whaitmore_description {
        flex: 0 1 100%;
        max-width: 100%;
        padding-left: 0;
        text-align: center;
    }
    .whaitmore_description-title {
        font-size: 20px;
    }
    .whaitmore_description-text {
        font-size: 18px;
        line-height: 1.2;
    }
}