.universalpop {
    font-family: Qanelas;
    color: #242424
}
.universalpop_start {
    width: 100%;
}
.universalpop_start h2 {
    display: none;
}
.universalpop_start .sales-widget__offers {
    margin-top: 0;
    width: 100%;
}
.universalpop_start .chakra-button {
    outline: none;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Gilroy;
    font-size: 22px;
    letter-spacing: 0.04em;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(89.69deg, #26CBAD 14.2%, #0CA488 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    margin: 0 auto 50px;
}
.universalpop_start .chakra-button::before {
    content: '';
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.6);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .2s ease-in-out;
}
.universalpop_start .chakra-button:active::before {
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4), inset 0px 4px 4px #24B79C;
}
.universalpop_start .chakra-button:hover::before {
    opacity: 1;
}
.universalpop_start .chakra-button p {
    position: relative;
}
.universalpop_start form .chakra-button {
    margin-bottom: 5px;
}
.universalpop_start .sales-widget__user-form button > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-size: 14px;
    line-height: normal;
    text-transform: lowercase;
}
.universalpop_start .gt-offer {
    background-color: #fff;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(55, 59, 82, 0.2);
}
.universalpop_start .gt-offer:last-of-type {
    border-bottom: none;
    display: none!important;
}
.universalpop_start .chakra-radio__control {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: #fff!important;
    position: relative;
    border: none!important;
    outline: none!important;
    border: 8.5px solid #DED9D5!important;
}
.universalpop_start .chakra-radio__control[data-checked] {
    border-color: #24B79C!important;
}
.universalpop_start .chakra-radio__control::before {
    width: 16px!important;
    height: 16px!important;
    background-color: #fff!important;
    border-radius: 100%!important;
}
.universalpop_start .gt-offer__title {
    font-size: 22px;
    line-height: 26px;
    font-family: Gilroy;
    font-weight: bold;
    letter-spacing: -0.01em;
    /* text-transform: uppercase; */
    padding-left: 0;
    color: #0A0A0D;
}
.universalpop_start .gt-offer__title ~ .chakra-text {
    padding-left: 0;
    font-family: Gilroy;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.universalpop_start span.chakra-radio__label > .chakra-stack {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}
.universalpop_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}
.universalpop_start .gt-offer__marketing-price {
    font-family: Gilroy;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    order: 2;
    color: rgba(22, 19, 19, 0.5);
}
.universalpop_start .gt-offer__price {
    font-family: Gilroy;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #F7720C;
    margin-bottom: 5px;
}
.universalpop_start .sales-widget__selected-offers {
    max-width: 800px;
    background-color: #fff;
    padding: 40px;
}
.universalpop_start .sales-widget__selected-offers > .chakra-stack {
    display: flex;
    align-items: flex-start;
}
.universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
    font-size: 20px;
    line-height: 26px;
    font-family: Gilroy;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 20px;
    margin-bottom: 10px;
    color: #0A0A0D;
}
.universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2) {
    padding-left: 20px;
    font-family: Gilroy;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack {
    display: flex;
    flex-direction: column;
}
.universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(1) {
    font-family: Gilroy;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: -0.01em;
    order: 2;
    color: #EF3F34;
}
.universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-family: Gilroy;
    font-size: 20px;
    line-height: 22px;
    font-weight: 800;
    letter-spacing: -0.01em;
    color: #0A0A0D;
    margin-bottom: 5px;
}
.universalpop_start .sales-widget__user-form {
    max-width: 800px;
}
.universalpop_start .sales-widget__user-form input {
    background-color: #fff;
    border-radius: 0;
}
.universalpop_start .sales-widget__offers > .chakra-stack {
    min-width: 100%;
}
.universalpop_start .sales-widget__offers > .chakra-radio-group > .chakra-stack {
    max-width: 100%;
    margin-bottom: 20px;
}
.universalpop_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-end;
}
.universalpop_start .sales-widget__offers > .chakra-stack .chakra-divider {
    display: none;
}
@media(max-width: 575px) {
    .universalpop_start .gt-offer, .universalpop_start .sales-widget__selected-offers {
        padding: 10px;
    }
    .universalpop_start span.chakra-radio__label > .chakra-stack, .universalpop_start .sales-widget__selected-offers > .chakra-stack {
        flex-direction: column;
    }
    .universalpop_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
        margin-top: 20px;
        align-items: flex-end;
        width: 100%;
    }
    .universalpop_start .gt-offer__title {
        font-size: 18px;
        line-height: 22px;
        padding-left: 5px;
    }
    .universalpop_start .gt-offer__title ~ .chakra-text {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
    }
    .universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2), .universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
        padding-left: 0;
    }
    .universalpop_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .universalpop_start .sales-widget__user-form button {
        margin-top: 15px;
    }
    .universalpop_start .sales-widget__user-form {
        padding: 0;
    }
    .universalpop_start .chakra-button {
        height: 60px;
        width: 90%;
    }
    .universalpop_start form .chakra-button {
        width: 90%;
        margin-top: 0;
    }
    .universalpop_start .chakra-button p {
        top: 0;
    }
    .universalpop_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .universalpop_start .gt-offer__marketing-price {
        margin-left: 20px;
    }
}