.faq {
    padding: 0 0 80px;
    background-color: #fafafa;
    position: relative;
    z-index: 1;
}
.faq_block {
    display: flex;
}
.faq_left {
    flex: 1;
}
.faq_right {
    flex: 0 1 585px;
    max-width: 585px;
}
.faq_title {
    font-family: Qanelas;
    font-weight: 800;
    margin-bottom: 12px;
    font-size: 56px;
    line-height: 62px;
}
.faq_subtitle {
    font-size: 24px;
    line-height: 34px;
    color: #4D5761;
    margin-bottom: 30px;
}
.faq_links a:first-of-type {
    margin-right: 12px;
}
.accordion {
    max-width: 760px;
    margin: 0 auto;
}
.question {
    padding: 10px 50px 20px 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    transition: .2s ease-in-out;
    border-bottom: 1px solid rgba(17, 25, 39, 0.1);
}
.question::before {
    content: '+';
    position: absolute;
    top: 50%;
    margin-top: -16px;
    right: 19px;
    font-size: 20px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: rgba(17, 25, 39, 0.5);
    transition: .2s ease-in-out;
}
.question::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -16px;
    right: 20px;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(17, 25, 39, 0.5);
    border-radius: 100%;
    transition: .2s ease-in-out;
}
.active .question::before {
    content: '-';
    margin-top: -17px;
    color: #D8376A;
}
.active .question::after, .question:hover::after {
    border-color: #D8376A;
}
.question:hover::before {
    color: #D8376A;
}
.active .question, .question:hover {
    color: #D8376A;
}
.accordion > div:last-of-type .question {
    border-bottom: none;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 24px;
    padding-left: 30px;
    transition: .2s ease-in-out;
}
.active .answer {
    padding-top: 14px;
    max-height: 400px;
    padding-bottom: 25px;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}
.answer a {
    color: #D8376A;;
    font-weight: 500;
}
.btn {
    width: 585px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(89.69deg, #26CBAD 14.2%, #0CA488 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    margin: 30px auto 0;
    transition: .3s ease-in-out;
}
.btn::before {
    content: '';
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.6);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.btn:active::before {
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4), inset 0px 4px 4px #24B79C;
}
.btn:hover {
    transform: translateY(-6px);
}
.btn:hover::before {
    opacity: 1;
}
.btn p {
    position: relative;
}

@media(max-width: 767px) {
    .faq {
        padding: 0 0 45px;
    }
    .faq_title {
        font-size: 32px;
        line-height: 36px;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
        padding: 10px 50px 10px 15px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
        padding-left: 15px;
    }
    .btn {
        width: 100%;
    }
    .faq_block {
        flex-direction: column;
    }
    .faq_left {
        order: 2;
        padding: 30px;
        background-color: #fff;
        box-shadow: 0px 6.55105px 13.1021px -2.18368px rgba(16, 24, 40, 0.04), 0px 2.18368px 6.55105px -1.09184px rgba(16, 24, 40, 0.02);
        border-radius: 16px;
        text-align: center;
    }
    .faq_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .faq_title {
        margin-bottom: 8px;
        font-size: 26px;
        line-height: 28px;
    }
    .faq_subtitle {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 20px;
    }
}