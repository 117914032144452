.onmarafon {
    padding: 90px 0 60px;
}
.onmarafon_title {
    font-size: 50px;
    line-height: 55px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.onmarafon_title span {
    color: var(--abs-pink)
}
.onmarafon_subtitle {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 40px;
}
.onmarafon_block {
    padding-top: 22px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.onmarafon_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 20px;
    margin-bottom: 10px;
}
.onmarafon_item_info {
    padding: 15px 0 40px;
    height: 100%;
    transition: .2s ease-in-out;
}
.onmarafon_item_title {
    font-size: 30px;
    line-height: 39px;
    margin-top: 20px;
}

@media(max-width: 991px) {
    .onmarafon_item_title {
        font-size: 20px;
        line-height: 29px;
    }
}
@media(max-width: 767px) {
    .onmarafon {
        padding: 45px 0;
    }
    .onmarafon_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .onmarafon_subtitle {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 30px;
    }
    .onmarafon_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }
    .onmarafon_item_info {
        padding-top: 0;
    }
    .onmarafon_item_title {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
    }
}
@media(max-width: 575px) {
    .onmarafon_block {
        margin: 0 -10px;
    }
    .onmarafon_item {
        padding: 0 10px;
    }
    .onmarafon_title br {
        display: none;
    }
}
@media(max-width: 374px) {
    .onmarafon_title {
        font-size: 30px;
        line-height: 32px;
    }
    .onmarafon_subtitle {
        font-size: 16px;
    }
}