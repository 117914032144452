.stretch_promo {
  height: 100vh;
  overflow-y: auto;
  scroll-snap-align: start;
  -webkit-scroll-snap-align: start;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}
.stretch_promo>div {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #F2F2F2;
  padding-top: 60px;
}
.stretch_promo::-webkit-scrollbar {
  display: none;
}
.stretch_promo_title {
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 76px;
  line-height: 84px;
  text-transform: uppercase;
  color: #121212;
  transform: scale(8);
}
.stretch_promo_subtitle {
  color: #121212;
  letter-spacing: 0.01em;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  transform: translate(0, 200px);
  opacity: 0;
}
.stretch_promo_video {
  margin: 0 auto 50px;
  position: relative;
  min-height: 420px;
  max-width: 830px;
  background-color: #000;
}
.stretch_promo_video::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/stretch/phone_horizontal2.png');
  background-size: cover;
  z-index: 10!important;
}
.stretch_promo_video video  {
  border-radius: 55px;
  position: relative;
  /* z-index: -1!important; */
  object-fit: cover;
}
.stretch_promo_btn {
  width: 325px;
  min-height: 75px;
  background: linear-gradient(275.94deg, #B92655 -15.09%, #B92655 -15.08%, rgba(228, 47, 129, 0) 102.69%), #EE4777;
  border-radius: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translate(0, 300px);
  opacity: 0;
  transition: .2s ease-in-out;
}
.stretch_promo_btn:hover {
  box-shadow: 0px 4px 20px rgb(225 59 115 / 75%);
  transform: translateY(-2px)!important;
}
.stretch_promo_info {
  font-size: 36px;
  line-height: 50px;
  font-weight: 500;
  font-family: Roboto;
  max-width: 873px;
  margin: 130px auto 145px;
}
.stretch_promo_block {
  text-align: center;
  transform: translate(0, 200px);
}
.stretch_promo_logo {
  transform: translate(0, -300px);
  opacity: 0;
}
.stretch_pbg_1 {
  position: absolute;
  left: 90px;
  top: 65px;
  z-index: 4!important;
}
.stretch_pbg_2 {
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 65px;
  font-family: Cera;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  z-index: 4!important;
}
.stretch_pbg_3 {
  position: absolute;
  right: 90px;
  top: 65px;
  z-index: 4!important;
}
.stretch_pbg_4 {
  position: absolute;
  left: 90px;
  top: 260px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: Cera;
  z-index: 4!important;
}
.stretch_pbg_4 span {
  opacity: 0.25;
}
.stretch_pbg_5 {
  position: absolute;
  left: 90px;
  top: 285px;
  display: flex;
  align-items: center;
  max-width: 650px;
  overflow: hidden;
  z-index: 4!important;
}
.stretch_pbg_5_line {
  width: 0px;
  height: 4px;
  background-color: #fff;
  margin-right: 5px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: .2s ease-in-out;
}
.stretch_pbg_5_circle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 100%;
}
.stretch_pbg_5_line2 {
  width: 615px;
  height: 4px;
  background-color: #fff;
  margin-left: 5px;
  opacity: 0.25;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: .2s ease-in-out;
}
.stretch_pbg_6 {
  position: absolute;
  left: 90px;
  bottom: 60px;
  font-family: Cera;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  z-index: 4!important;
}
.stretch_pbg_7 {
  position: absolute;
  right: 135px;
  bottom: 55px;
  width: 306px;
  height: 52px;
  background-color: #EE4777;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cera;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  z-index: 4!important;
}
.stretch_pbg_8 {
  position: absolute;
  right: 90px;
  bottom: 65px;
  z-index: 4!important;
}
.stretch_pbg_5_line.active {
  animation: 66s line1anim linear infinite;
}
.stretch_pbg_5_line2.active {
  animation: 66s line2anim linear infinite;
}
@keyframes line1anim {
  0% {
    width: 0px;
  }
  100% {
    width: 615px;
  }
}
@keyframes line2anim {
  0% {
    width: 615px;
  }
  100% {
    width: 0px;
  }
}
@media(max-width: 991px) {
  .stretch_promo_video {
    min-height: 265px;
    padding-bottom: 15px;
  }
  .stretch_promo_video::before {
    width: 100%;
    height: 100%;
  }
  .stretch_promo_video video {
    width: 100%;
    height: max-content;
  }
  .stretch_pbg_1 {
    left: 65px;
  }
  .stretch_pbg_4 {
    top: 185px;
    left: 65px;
  }
  .stretch_pbg_5 {
    top: 210px;
    left: 65px;
    max-width: 500px;
  }
  .stretch_pbg_6 {
    bottom: 50px;
    left: 65px;
  }
  .stretch_pbg_8 {
    bottom: 50px;
  }
  .stretch_promo_info {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin: 60px 15px;
  }
}
@media(max-width: 767px) {
  .stretch_promo_title {
    font-size: 42px;
    line-height: 48px;
  }
  .stretch_promo_subtitle {
    font-size: 22px;
    line-height: 26px;
  }
  .stretch_promo_video {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media(max-width: 575px) {
  .stretch_promo_btn {
    max-width: 90%;
  }
  .stretch_pbg_1, .stretch_pbg_2, .stretch_pbg_3, .stretch_pbg_4, .stretch_pbg_5, .stretch_pbg_6, .stretch_pbg_7, .stretch_pbg_8 {
    transform: scale(.8);
  }
  .stretch_pbg_1 {
    left: 30px;
    top: 25px;
  }
  .stretch_pbg_2 {
    top: 26px;
  }
  .stretch_pbg_3 {
    right: 30px;
    top: 23px;
  }
  .stretch_pbg_4 {
    top: 100px;
    left: 25px;
    transform: scale(.7);
    display: none;
  }
  .stretch_pbg_5 {
    display: none;
  }
  .stretch_pbg_6 {
    font-size: 12px;
    line-height: 14px;
    bottom: 10px;
    left: 20px;
    display: none;
  }
  .stretch_pbg_8 {
    transform: scale(.7);
    bottom: 10px;
    right: 25px;
  }
  .stretch_promo_video video {
    width: 100%;
    height: max-content;
    border-radius: 40px;
  }
  .stretch_promo_video {
    min-height: 0;
    padding-bottom: 15px;
  }
  .stretch_promo {
    height: auto;
  }
}