.insta {
    padding: 30px 0 45px;
}
.insta_title {
    font-size: 72px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-family: Bebas;
}
.insta_subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
}
.slide {
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    overflow: hidden;
    transition: .2s ease-in-out;
    position: relative;
}
.slide:hover::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000006b;
}
.slide:hover {
    transform: scale(1.2);
}
.slide p {
    position: absolute;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    transform: rotate(15deg);
    font-family: Bebas;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
}
.slide:hover p {
    opacity: 1;
}
.slider_nav {
    display: flex;
    justify-content: space-between;
}
.nav_btn {
    font-size: 54px;
    color: var(--main-p-color);
    font-family: 'Bebas';
}
.slider_nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    z-index: 1;
}
.slider_btn_prev {
    border: 20px solid transparent; border-right: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-left: -15px;
}
.slider_btn_next {
    border: 20px solid transparent; border-left: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-right: -15px;
}

@media(min-width: 1200px) {
    .insta .swiper-wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }
    .insta .swiper-slide {
        flex: 0 1 20%;
        max-width: 20%;
        margin: 1%;
        border-radius: 6px;
        overflow: hidden;
    }
}

@media(max-width: 767px) {
    .insta_title {
        font-size: 60px;
    }
    .slide {
        height: 700px;
    }
}
@media(max-width: 575px) {
    .insta_title {
        font-size: 38px;
    }
    .slide {
        height: 500px;
    }
}