.promo {
    padding: 110px 0;
    height: 920px;
    position: relative;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/corporate/promo_bg_v1.jpg');
    background-size: cover;
    background-position: 50% 50%;
}
.promo_logo {
    margin-bottom: 64px;
}
.promo_block {
    position: relative;
    z-index: 1;
}
.promo_title {
    font-weight: 800;
    font-size: 52px;
    line-height: 1.1;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.promo_title span {
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.promo_text {
    color: rgba(17, 25, 39, 0.75);
    font-family: Roboto;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 56px;
}
.promo_btns {
    display: flex;
}
.promo_btn {
    width: 366px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(279deg, #B92655 -19.72%, #E42F81 118.53%), #EE4777;
    border-radius: 12px;
    font-size: 24px;
    letter-spacing: 0.04rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}

@media(max-width: 1199px) {
    .promo {
        height: auto;
    }
    .promo_btns {
        flex-direction: column;
    }
}
@media(max-width: 991px) {
    .promo_title {
        font-size: 42px;
    }
    .promo_text {
        font-size: 20px;
    }
}
@media(max-width: 767px) {
    .promo {
        background-position: 55% 50%;
    }
    .promo_title {
        font-size: 36px;
    }
    .promo_text {
        font-size: 18px;
    }
}
@media(max-width: 575px) {
    .promo {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/corporate/promo_bg_mob2.jpg');
        padding-top: 58px;
        padding-bottom: 575px;
        background-size: auto;
        background-position: 50% 100%;
        background-repeat: no-repeat;
    }
    .promo_logo {
        margin-bottom: 28px;
    }
    .promo_title {
        margin-bottom: 16px;
        font-size: 30px;
    }
    .promo_text {
        margin-bottom: 28px;
    }
    .promo_btn {
        width: 100%;
        height: 70px;
        font-size: 20px;
    }
}