.line {
    padding-top: 70px;
    position: relative;
    /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/knows_bg.jpg'); */
    background-color: #F3F8FF;
    background-size: cover;
    background-position: 50% 100%;
}
.title {
    color: #EF3F34;
    text-align: center;
    text-transform: uppercase;
    font-family: Dela;
    letter-spacing: 0.02em;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 10px;
}
.subtitle {
    font-family: Dela;
    font-size: 52px;
    line-height: 58px;
    /* color: #fff; */
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 70px;
}
.preims_line {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 68px;
}
.preims_line::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 13px;
    background: #0D8ED7;
    opacity: 0.2;
    border-radius: 85px;
}
.preims_line__item {
    margin-bottom: 45px;
    position: relative;
}
.preims_line__item::before {
    content: '01';
    position: absolute;
    left: 0;
    top: -96px;
    width: 67px;
    height: 67px;
    border-radius: 100%;
    color: #fff;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background: rgba(13, 142, 215, 0.35);
    box-shadow: 0px 4px 20px rgba(49, 29, 25, 0.08);
    backdrop-filter: blur(10px);
}
.preims_line__item:nth-of-type(2)::before {content: '02';}
.preims_line__item:nth-of-type(3)::before {content: '03';}
.preims_line__item:nth-of-type(4)::before {content: '04';}
.preims_line__item:nth-of-type(5)::before {content: '05';}
.preims_line__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* color: #fff; */
}
@media(max-width: 1199px) {
    .preims_line__text br {
        display: block;
    }
    .preims_line__text {
        padding-right: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}
@media(max-width: 991px) {
    .preims_line {
        flex-direction: column;
        padding-top: 0;
    }
    .preims_line::before {
        width: 13px;
        height: 90%;
        left: 50px;
    }
    .preims_line__item::before {
        top: -5px;
    }
}
@media(max-width: 575px) {
    .line {
        padding-top: 50px;
    }
    .title {
        font-size: 24px;
        line-height: 26px;
    }
    .subtitle {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 40px;
    }
    .preims_line::before {
        left: 28px;
    }
    .preims_line__text {
        font-size: 16px;
        line-height: 20px;
        padding-left: 80px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
}