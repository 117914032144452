.start {
    position: relative;
    padding: 120px 0 0;
    overflow: hidden;
    background-color: #fff;
}
.start::before {
    content: '';
    position: absolute;
    left: -2%;
    top: -40px;
    width: 105%;
    height: 75px;
    transform: rotate(-1.5deg);
    background-color: #FAFAFA;
}
.start::after {
    content: none;
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 100%;
    height: 75px;
    transform: rotate(-1.5deg);
    background-color: #FAFAFA;
}
.start > * {
    position: relative;
}
.start_block {
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    display: flex;
    align-items: center;
}
.start_block_new {
    margin-bottom: 40px;
}
.start_left {
    flex: 0 1 486px;
    max-width: 486px;
}
.start_left img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.start_right {
    flex: 1;
    padding: 60px 70px;
}
.start_title {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
}
.start_title span {
    color: #EE4777;
}
.start_subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    color: rgba(77, 87, 97, 0.75);
    font-weight: 500;
    margin-top: 20px;
}
.start_subtitle span {
    color: #EE4777;
}
.start_right ul {
    margin-bottom: 8px;
}
.start_right ul li {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(77, 87, 97, 0.75);
}
.start_btn {
    width: 325px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    font-family: Qanelas;
    color: #F8F8F8;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.start_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
.start_img {
    text-align: center;
}
.start_img img {
    mix-blend-mode: multiply;
}
.start_date {
    font-size: 22px;
    line-height: 30px;
    color: rgba(77, 87, 97, 0.75);
    margin-bottom: 20px;
}
.start_date span {
    font-weight: bold;
    color: #EE4777;
}
.start_trainings {
    color: #EE4777;
    font-weight: 500;
    font-size: 16px;
    padding: 6px 12px;
    background-color: #FFE5ED;
    border-radius: 51px;
    width: fit-content;
}
.start_trainings:first-of-type {
    margin-right: 20px;
}
.start_text {
    font-size: 22px;
    line-height: 30px;
    color: rgba(77, 87, 97, 0.75);
    margin-bottom: 16px;
}
.start_counts {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
}
.auth_title {
    font-family: Qanelas;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 50px;
    line-height: 52px;
    font-weight: 800;
    text-align: center;
    color: #fff;
}
.auth_main {
    position: relative;
    overflow: hidden;
    background-color: #1B1C1F;
}
.auth_main::before {
    z-index: 1;
}
.auth_main::after {
    content: '';
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/zap.svg');
    background-position: 120% 50%;
    background-size: 1096px 468px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.1;
    transform: rotate(0deg);
    pointer-events: none;
    background-color: #1B1C1F;
    filter: grayscale(1);
    pointer-events: none;
}
.auth_main > * {
    position: relative;
    z-index: 1;   
}
.auth_main .start_img {
    background-color: #fff;
}
.auth_block {
    display: flex;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    max-width: 620px;
    margin: 0 auto 120px;
}
.auth_left {
    flex: 0 1 354px;
    max-width: 354px;
    order: 2;
}
.auth_right {
    flex: 0 1 486px;
    max-width: 486px;
}
.auth_left p, .auth_right > p {
    color: rgba(77, 87, 97, 0.75);
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 23.4px;
}
.auth_left p b, .auth_right p b {
    color: #000;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    display: block;
}
.auth_left a {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 9px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.06em;
    font-family: Qanelas;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}
.auth_box {
    background: #FFF;
    padding: 42px 28px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.auth_box > p {
    text-align: center;
    margin-bottom: 25px;
}
.auth_box2 {
    background-color: #EFEFF0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 28px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.start_info {
    margin-bottom: 35px;
}
.start_info p {
    font-size: 22px;
    line-height: 30.8px;
    color: #111927;
    margin-bottom: 4px;
}
.start_info p b {
    color: rgba(77, 87, 97, 0.75);
    font-weight: normal;
}
.start_info p i {
    font-style: normal;
    color: #6EC652;
}
.start_info p a {
    cursor: pointer;
    text-decoration: underline;
    color: #EE4777;
    margin-left: 10px;
}
@media(max-width: 991px) {
    .start_left {
        display: none;
    }
}
@media(max-width:767px) {
    .auth_block {
        flex-wrap: wrap;
        text-align: center;
        margin-bottom: 50px;
    }
    .auth_left {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 0;
    }
    .auth_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .auth_box {
        border-radius: 0;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }
    .auth_box2 {
        border-radius: 0;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
}
@media(max-width: 575px) {
    .start {
        padding-top: 90px;
    }
    .start::after {
        height: 60px;
    }
    .start_right {
        padding: 28px 15px;
    }
    .start_title {
        font-size: 26px;
        line-height: 28px;
    }
    .start_subtitle {
        font-size: 18px;
        line-height: 26px;
    }
    .start_right ul {
        margin-bottom: 16px;
    }
    .start_right ul li {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 8px;
    }
    .start_btn {
        width: 100%;
        height: 65px;
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: normal;
    }
    .start_counts {
        justify-content: center;
    }
    .auth_title {
        font-size: 32px;
        line-height: 36px;
    }
    .auth_left a {
        height: 65px;
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: normal;
    }
    .auth_left a {
        width: 100%;
    }
    .auth_left p, .auth_right > p {
        font-size: 18px;
        line-height: 24px;
    }
    .auth_left p b, .auth_right > p b {
        font-size: 22px;
        line-height: 28px;
    }
    .auth_box > p b {
        font-size: 22px;
        line-height: 28px;
    }
    .auth_box {
        padding: 28px 20px;
    }
    .start_info p {
        font-size: 16px;
        line-height: 22.4px;
    }
    .start_info p a {
        margin-left: 5px;
    }
}
@media(max-width: 367px) {
    .start_info p a {
        margin-left: 0;
    }
}