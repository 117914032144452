.faq {
    padding-bottom: 80px;
}
.faq_title {
    font-family: Qanelas;
    font-size: 40px;
    line-height: 44px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    color: #121212;
}
.accordion {
    max-width: 850px;
    margin: 0 auto;
}
.question {
    padding: 17px 50px 20px 0px;
    font-family: Cera;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    position: relative;
    cursor: pointer;
    border-top: 2px solid #EBEBEB;
    transition: .2s ease-in-out;
}
.question::before {
    content: '';
    width: 22px;
    height: 2px;
    background-color: #121212;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 20px;
    transition: .2s ease-in-out;
}
.question::after {
    content: '';
    width: 2px;
    height: 22px;
    background-color: #121212;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 30px;
    transition: .2s ease-in-out;
}
.active .question::after {
    content: '';
    transform: rotate(90deg);
}
.active .question, .question:hover {
    border-color: #EE4777;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    font-family: Cera;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    padding-left: 0px;
    transition: .2s ease-in-out;
}
.active .answer {
    padding-top: 14px;
    max-height: 400px;
    padding-bottom: 25px;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    color: #EE4777;
    font-weight: 500;
}
.answer ul {
    margin-left: 20px;
}
.answer ul li {
    margin-bottom: 5px;
    list-style-type: circle;
}
.answer a {
    color: #EE4777;
    font-weight: 500;
}
@media(max-width: 767px) {
    .faq_more {
        padding: 45px 0 20px;
    }
    .faq_title {
        font-size: 32px;
        line-height: 36px;
    }
    .question {
        font-size: 18px;
        line-height: 22px;
    }
    .answer {
        font-size: 16px;
        line-height: 22px;
    }
}
@media(max-width: 575px) {
    .question {
        padding: 10px 40px 10px 15px;
    }
    .answer {
        padding-left: 15px;
    }
}