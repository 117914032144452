.onmarafon {
    padding: 80px 0 60px;
    background-color: #F5F5F5;
}
.onmarafon_title {
    font-size: 50px;
    line-height: 55px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 45px;
    text-align: center;
}
.onmarafon_title span {
    color: var(--nutr-blue)
}
.onmarafon_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.onmarafon_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 20px;
    margin-bottom: 40px;
}
.onmarafon_item_info {
    background-color: #fff;
    text-align: center;
    padding: 30px 0 35px;
    height: 100%;
    transition: .2s ease-in-out;
}
.onmarafon_item_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(90, 90, 253, 0.23);
}
.onmarafon_item_title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
}

@media(max-width: 991px) {
    .onmarafon_item_title {
        font-size: 20px;
        line-height: 29px;
    }
}
@media(max-width: 767px) {
    .onmarafon {
        padding: 45px 0;
    }
    .onmarafon_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .onmarafon_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }
    .onmarafon_item_title {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
    }
}
@media(max-width: 575px) {
    .onmarafon_block {
        margin: 0 -10px;
    }
    .onmarafon_item {
        padding: 0 10px;
    }
    .onmarafon_title br {
        display: none;
    }
}
@media(max-width: 374px) {
    .onmarafon_title {
        font-size: 30px;
        line-height: 32px;
    }
}