.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/fatburning/promo_bg.jpg');
    position: relative;
    overflow: hidden;
    padding-top: 120px;
    height: 921px;
    background-size: cover;
    background-position: 50% 50%;
}
.promo_logo {
    margin-bottom: 44px;
}
.promo_title {
    margin-bottom: 16px;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 54px;
    line-height: 60px;
    color: #EE4777;
}
.promo_subtitle {
    margin-bottom: 44px;
    font-size: 24px;
    line-height: 34px;
}
.promo_text {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}
.promo_btn {
    width: 581px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    font-family: Qanelas;
    color: #F8F8F8;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
@media(max-width: 991px) {
    .promo_title {
        font-size: 46px;
        line-height: 56px;
    }
}
@media(max-width: 767px) {
    .promo_title {
        font-size: 36px;
        line-height: 46px;
    }
    .promo_subtitle {
        font-size: 18px;
    }
}
@media(max-width: 575px) {
    .promo {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/fatburning/promo_bg_mob.jpg');
        padding-top: 50px;
        height: auto;
        padding-bottom: 600px;
    }
    .promo_btn {
        width: 100%;
        height: 65px;
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: normal;
    }
    .promo_title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 16px;
    }
    .promo_subtitle {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 28px;
    }
    .promo_text {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
    }
}
@media(max-width:389px) {
    .promo {
        background-position: 50% 0%;
        background-size: auto;
        padding-bottom: 570px;
    }
}