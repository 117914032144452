.faqq {
    padding: 75px 0;
}
.faqq_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 45px;
}
.faqq_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.faqaccordion {
    max-width: 770px;
    margin: 0 auto;
}
.faqquestion {
    padding: 15px;
    font-size: 18px;
    position: relative;
    font-weight: 700;
    line-height: 1.3;
    cursor: pointer;
    border-bottom: 2px solid #e8e8e8;
    transition: .2s ease-in-out;
}
.faqquestion:hover {
    color: var(--main-color);
}
.faqquestion::before {
    content: '+';
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 36px;
}
.active .faqquestion {
    color: var(--main-color);
}
.active .faqquestion::before {
    content: '–';
    top: 2px;
}
.faqanswer {
    max-height: 0px;
    font-size: 18px;
    overflow: hidden;
    line-height: 1.4;
    margin-bottom: 10px;
    padding: 0 15px;
    transition: .2s ease-in-out;
}
.active .faqanswer {
    margin-top: 10px;
    max-height: 1000px;
}
.faqanswer p {
    margin-bottom: 8px;
}
.faqanswer ul li {
    padding-left: 15px;
    position: relative;
}
.faqanswer ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 11px;
    width: 6px;
    height: 6px;
    background-color: #f34848;
}
.faqanswer a {
    color: var(--main-color);
    font-weight: 500;
}

@media(max-width: 991px) {
    .faqq_title {
        font-size: 42px;
    }
}
@media(max-width: 767px) {
    .faqq_title {
        font-size: 36px;
    }
    .faqquestion {
        padding-right: 25px;
    }
}
@media(max-width: 575px) {
    .faqq_title {
        font-size: 24px;
        margin-bottom: 30px;
    }
    .faqquestion {
        font-size: 16px;
    }
    .faqq_title span {
        padding-bottom: 5px;
    }
}