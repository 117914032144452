.what {
    padding: 110px 0 60px;
}
.what_title {
    margin-bottom: 10px;
    font-family: Unisans;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
}
.what_title span {
    color: #8256CF;
}
.what_subtitle {
    margin-bottom: 50px;
    font-size: 22px;
    line-height: 1.3;
    color: rgba(40, 40, 40, 0.80);
}
.what_block {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 10px;
    margin-bottom: 50px;
}
.what_item {
    flex: 0 1 calc(33.333333% - 6.666666px);
    max-width: calc(33.333333% - 6.666666px);
    border-radius: 36px;
    border: 1px solid #282828;
    background: #191919;
    padding: 20px 20px 30px;
    position: relative;
}
.what_tag {
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 60px;
    background-color: #8256CF;
    color: #758028;
    font-weight: 600;
    line-height: 1.25;
    padding: 3px 18px;
    text-transform: uppercase;
}
.what_item_img {
    margin-bottom: 50px;
}
.what_item_name {
    font-size: 25px;
    color: #8256CF;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 10px;
}
.what_item_text {
    font-size: 18px;
    color: rgba(40, 40, 40, 0.7);
    line-height: 1.3;
}
.what_item:nth-of-type(4),
.what_item:nth-of-type(5) {
    flex: 0 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
}
.b2 {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 30px;
    /* margin-bottom: 80px; */
}
.b2_item {
    flex: 0 1 calc(50% - 15px);
    max-width: calc(50% - 15px);
    display: flex;
    gap: 30px;
    align-items: center;
}
.b2_item_img {
    flex: 0 1 130px;
    max-width: 130px;
}
.b2_item_img img {
    max-width: 121px;
}
.b2_item_text {
    flex: 1;
    max-width: 315px;
}
.b2_item_text p:nth-of-type(1) {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 5px;
}
.b2_item_text p:nth-of-type(2) {
    font-size: 18px;
    color: rgba(40, 40, 40, 0.7);
    line-height: 1.3;
}
.b2_item_text p span {
    color: #8256CF;
}
.b3 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.b3 p {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
}
.b3 img {
    cursor: pointer;
}
@media(max-width:575px) {
    .what {
        padding: 60px 0 90px;
    }
    .what_title {
        text-align: center;
        font-size: 28px;
    }
    .what_subtitle {
        text-align: center;
        font-size: 18px;
        margin-bottom: 30px;
    }
    .what_item {
        flex: 0 1 100%!important;
        max-width: 100%!important;
    }
    .what_item_img {
        margin-bottom: 30px;
    }
    .what_item_name {
        font-size: 20px;
    }
    .what_item_text {
        font-size: 15px;
    }
    .b2 {
        gap: 10px;
    }
    .b2_item {
        flex: 0 1 calc(50% - 5px);
        max-width: calc(50% - 5px);
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
    }
    .b2_item_img {
        flex: 0 1 120px;
        max-width: 120px;
    }
    .b2_item_text p:nth-of-type(1) {
        font-size: 18px;
    }
    .b2_item_text p:nth-of-type(2) {
        font-size: 14px;
    }
    .b2_item:nth-of-type(4) {
        /* order: 2; */
        /* flex: 0 1 100%; */
        /* max-width: 100%; */
    }
    .what_tag {
        top: 20px;
        right: 20px;
    }
}