.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/promo_bg.jpg');
    background-repeat: no-repeat;
    background-position: 100% 0%;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 675px;
}
.promo::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.85deg, #161719 67.47%, rgba(22, 23, 25, 0) 99.21%);
    z-index: -1;
}
.promo_logo img {
    height: 130px;
    margin-left: -27px;
    margin-bottom: -40px;
    margin-top: -40px;
}
@media(max-width: 767px) {
    .promo_logo img {
        height: 90px;
        margin-left: 0;
        margin-bottom: -35px;
        margin-top: -30px;
    }
}
.promo_title {
    font-size: 50px;
    line-height: 55px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
}
.promo_title span {
    color: var(--abs-green);
}
.promo_subtitle {
    margin-top: 15px;
    font-size: 21px;
    line-height: 28px;
    color: rgba(255,255,255,.75);
    margin-bottom: 25px;
}
.promo_subtitle strong {
    font-weight: 600;
    color: #fff;
}
.promo_btns {
    display: flex;
    align-items: center;
}
.promo_date {
    font-size: 33px;
    line-height: 42px;
    font-weight: 600;
    color: #fff;
    margin-left: 40px;
}
.promo_items {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}
.promo_item1 {
    width: 181px;
    height: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item2 {
    width: 181px;
    height: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item3 {
    width: 181px;
    height: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item1 p, .promo_item2 p, .promo_item3 p {
    position: absolute;
    bottom: 10px;
}
.promo_point1 {
    color: #fff;
    background: linear-gradient(180deg, #34AA7A 0%, #0E6640 100%);
    border-radius: 60px;
    width: 191px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -93px;
    margin-left: 33px;
}
.promo_point2 {
    color: #fff;
    background: linear-gradient(180deg, #90B343 0%, #53740C 100%);
    border-radius: 60px;
    width: 184px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -121px;
    margin-left: 383px;
}
.promo_point3 {
    color: #fff;
    background: linear-gradient(180deg, #FF7CBB 0%, #FF498B 100%);
    border-radius: 60px;
    width: 174px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 30px;
    margin-left: 310px;
}
.promo_arrow {
    position: absolute;
    left: 50%;
    bottom: -56px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str2/arrow_circle.png');
    width: 102px;
    height: 102px;
    margin-left: -56px;
    z-index: 1;
}

@media(min-width: 2100px) {
    .promo {
        background-size: cover;
        background-position: 50% 10%;
    }
}
@media(max-width: 1919px) {
    .promo {
        background-size: cover;
        background-position: 50% 50%;
    }
}
@media(max-width: 1199px) {
    .promo {
        height: auto;
        background-position: 40% 50%;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        background: transparent;
        margin-left: 0;
        margin-top: 0;
        left: 0;
        top: auto;
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        margin-left: 17px;
        margin-top: 20px;
    }
    .promo_point1 span {
        color: #2AAA8C;
    }
    .promo_point2 span {
        color: #94C529;
    }
    .promo_point3 span {
        color: var(--abs-pink);
    }
}
@media(max-width: 991px) {
    .promo {
        overflow: hidden;
    }
    .promo_subtitle {
        margin-bottom: 15px;
    }
    .promo_btns {
        flex-direction: column;
        align-items: flex-start;
    }
    .promo_btns > div:first-of-type {
        order: 1;
    }
    .promo_date {
        margin-left: 0;
        margin-bottom: 20px;
    }
    .promo_arrow {
        display: none;
    }
}
@media(max-width: 767px) {
    .promo {
        text-align: center;
        padding-top: 40px;
        background-size: auto;
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-color: #161719;
        position: relative;
    }
    .promo_pretitle {
        font-size: 16px;
        line-height: 18px;
    }
    .promo_title {
        font-size: 42px;
        line-height: 42px;
    }
    .promo_subtitle {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 30px;
    }
    .promo_btns {
        align-items: center;
    }
    .promo_date {
        position: relative;
    }
    .promo::before {
        content: '';
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        width: 100%;
        height: 435px;
        left: 0;
        bottom: 0;
        position: absolute;
        pointer-events: none;
    }
    .promo::after {
        background: linear-gradient(180deg, #02020F 0%, rgba(2, 2, 15, 0) 100%);
        height: 400px;
        top: 0;
        z-index: 0;
        pointer-events: none;
    }
    .promo_items {
        flex-direction: column;
        padding-left: 60px;
    }
    .promo > * {
        position: relative;
        z-index: 1;
    }
}
@media(max-width: 575px) {
    .promo_title {
        font-size: 35px;
        margin-bottom: 10px;
    }
    .promo_items {
        flex-wrap: wrap;
        gap: 0;
    }
    .promo_item1, .promo_item2, .promo_item3 {
        width: 50%;
        height: auto;
        padding: 0 10px;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 14px;
    }
    .promo_date {
        text-align: center;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 14px;
        margin: 25px 5px 0;
    }
    .promo {
        background-position: 58% 100%;
        padding-bottom: 10px;
    }
    .promo::before {
        height: 550px;
    }
    .promo_subtitle br {
        display: none;
    }
    .promo_items {
        padding-left: 0;
    }
}
@media(max-width: 374px) {
    .promo {
        padding-top: 20px;
    }
    .promo_title {
        font-size: 30px;
        line-height: 32px;
    }
    .promo_subtitle {
        font-size: 16px;
        line-height: 21px;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 12px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 12px;
        margin-top: 20px;
    }
    .promo_date {
        font-size: 28px;
        margin-bottom: 10px;
    }
}
.promo_logo {
    margin-bottom: 40px;
}
@media(max-width: 767px) {
    .promo_logo {
        margin-bottom: 25px;
    }
}