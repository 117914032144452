.programs {
    position: relative;
    background-color: #0A0A0A;
    padding-bottom: 665px;
    overflow: hidden;
    padding-top: 45px;
}
.programs_title {
    font-weight: 800;
    color: #fff;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
}
.programs_subtitle {
    text-align: center;
    color: #D2D2D2;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 45px;
}

.programs_row {
    display: flex;
    align-items: center;
    gap: 30px;
    position: absolute;
    left: 0;
    width: max-content;
}
.programs_row1 {
    bottom: 360px;
    animation: scroll 55s linear infinite;
}
.programs_row2 {
    bottom: 100px;
    animation: scroll2 50s linear infinite;
}
/* .programs_row:hover {
    animation-play-state: paused;
} */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-8360px);
    }
}
@keyframes scroll2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-7920px);
    }
} 

 .programs_item {
    position: relative;
}
.programs_item img {
    border-radius: 18px;
} 
 .programs_item p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-family: Roboto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.swiper2 {
    margin-top: 30px;
}
.swiper_slide {
    width: 410px!important;
    position: relative;
}
.swiper_slide img {
    border-radius: 18px;
}
.swiper_slide p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-family: Roboto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

@media(max-width: 1199px) {
    .programs_item p {
        font-size: 22px;
        line-height: 26px;
    }
}
@media(max-width: 991px) {
    .programs_title {
        font-size: 44px;
        line-height: 48px;
    }
    .programs_item p {
        font-size: 20px;
        line-height: 24px;
    }
}
@media(max-width: 575px) {
    .programs {
        padding-bottom: 445px;
    }
    .programs_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
        text-align: left;
        padding: 0 15px;
    }
    .programs_subtitle {
        font-size: 20px;
        line-height: 26px;
        text-align: left;
        padding: 0 15px;
    }
    .programs_item img {
        width: 343px;
        height: 192px;
    }
    .programs_row1 {
        bottom: 268px;
    }
    .programs_row2 {
        bottom: 45px;
    }
}
@media(max-width: 322px) {
    .programs_title {
        font-size: 30px;
        line-height: 34px;
    }
}