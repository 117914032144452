.thanks {
    padding: 75px 15px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.thanks_block {
    max-width: 600px;
    padding: 25px 20px 55px;
    background-color: #111927;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}
.thanks_block::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/vitrina/zap.svg');
    pointer-events: none;
    opacity: .7;
}
.thanks_block > * {
    position: relative;
}
.thanks_block_img {
    margin-bottom: -30px;
}
.thanks_block_title {
    color: #fff;
    font-family: Inter;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.thanks_block_subtitle {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.thanks_block_text {
    font-size: 18px;
    font-family: Inter;
    margin-bottom: 25px;
    line-height: 1.5;
    color: #fff;
}
.thanks_block_btn a {
    background-color: #E90973;
    padding: 25px 35px;
    font-family: Inter;
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.1;
    letter-spacing: -0.46px;
    border-radius: 15px;
    transition: .2s ease-in-out;
}
.thanks_block_btn a:hover {
    transform: translateY(-4px);
}
@media(max-width: 767px) {
    .thanks {
        min-height: 100vh;
        height: auto;
        padding: 15px;
    }
    .thanks_block {
        padding: 15px;
    }
    .thanks_block_img {
        max-width: 250px;
    }
}