.promo {
    padding: 75px 0;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/pregnancy/promo_n2.jpg');
    background-size: cover;
    background-position: center center;
    min-height: 71vh;
}
.promo h1 {
    font-family: Bebas;
    font-size: 72px;
    line-height: 1;
    font-weight: 700;
}
.promo_subtitle {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 1.55;
    font-weight: 500;
}
.promo_info {
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 18px;
}
.promo_start {
    font-size: 18px;
    color: var(--main-p-color);
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
}
.promo .main_btn_p {
    min-width: 410px;
    height: 96px;
    margin-bottom: 50px;
}
.promo_points {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
}
.promo_points__item {
    background-color: var(--pink-bg);
    padding: 25px 16px;
    border-radius: 12px;
    flex: 0 1 229px;
    max-width: 229px;
}
.promo_points__item img {
    margin-bottom: 12px;
    max-width: 42px;
}
.promo_points__item p {
    font-size: 15px;
    line-height: 1.2;
}

@media(max-width: 1199px) {
    .promo {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/pregnancy/promo_n_m2.jpg');
        padding-top: 25;
        min-height: 0;
    }
    .promo h1, .promo_subtitle, .promo_info, .promo_start, .promo_points {
        padding-left: 0;
    }
    .promo h1, .promo_subtitle, .promo_info, .promo_start {
        text-align: center;
    }
    .promo h1 {
        font-size: 60px;
    }
    .promo_points {
        justify-content: center;
    }
    .promo .main_btn_p {
        margin: 0 auto 410px;
        display: block;
    }
}
@media(max-width: 991px) {
    .promo_points {
        flex-wrap: wrap;
    }
    .promo_points__item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 16px;
        display: flex;
    }
    .promo_points__item img {
        margin-bottom: 0;
    }
    .promo_points__item p {
        margin-left: 15px;
    }
}
@media(max-width: 575px) {
    .promo {
        padding: 0;
        padding-top: 30px;
    }
    .promo .main_btn_p {
        min-width: 100%;
    }
    .promo h1 {
        font-size: 38px;
    }
    .promo_subtitle {
        font-size: 20px;
        line-height: 1;
        margin: 10px 0 20px;
    }
}