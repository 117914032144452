.prize {
    padding: 50px 0;
}
.b4 {
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    gap: 100px;
}
.b4_left {
    flex: 0 1 580px;
    max-width: 580px;
}
.b4_left > div {
    position: sticky;
    top: 20px;
}
.b4_left p {
    margin-bottom: 30px;
    font-family: Nface;
    font-size: 35px;
    text-transform: uppercase;
    line-height: 1.3;
}
.b4_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px
}
.b4_right_item {
    padding: 30px;
    background-color: #fff;
    border-radius: 24px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.b4_right_item_img {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.b4_right_item_img span {
    background-color: #C0D5E2;
    border-radius: 56px;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    height: max-content;
}
.b4_right_item_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
}
.b4_right_item_text {
    line-height: 1.4;
    color: #6A6A6A;
}
.btn {
    transition: .2s ease-in-out;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-family: Manrope;
    border-radius: 60px;
    background: #FF4E53;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    padding: 32px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 0 auto 90px;
}
.btn:hover {
    transform: translateY(-4px);
}
.btnmob {
    display: none!important;
}

@media(max-width: 575px) {
    .btn {
        display: none!important;
    }
    .btnmob {
        display: flex!important;
    }
    .prize {
        padding: 25px 0;
    }
    .b4 {
        flex-direction: column;
        gap: 30px;
    }
    .b4_left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b4_left p {
        font-size: 22px;
    }
    .b4_right_item {
        gap: 6px;
        padding: 20px;
    }
    .b4_right_item_text {
        font-size: 16px;
    }
    .btn {
        font-size: 18px;
        text-align: center;
        width: 100%;
        height: 80px;
        margin-bottom: 0;
    }
}