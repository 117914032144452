.preims {
  background-image: url("https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
}
.title {
  text-align: center;
  font-weight: 500;
  font-size: 44px;
  line-height: 1.3;
  letter-spacing: -0.5px;
  margin-bottom: 34px;
}
.title span {
  background-color: #f6faff;
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}
.block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 15px;
  max-width: 1550px;
  margin: 0 auto 55px;
}
.item {
  flex: 0 1 360px;
  max-width: 360px;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #deeaff;
  padding: 28px;
}
.item_pretitle {
  margin-bottom: 20px;
  padding: 9px 10px;
  border-radius: 11px;
  background: #f6e0f2;
  color: #dd7db1;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  letter-spacing: -0.5px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_img {
  margin-bottom: 15px;
}
.item_title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.5px;
}
.item_text {
  color: rgba(51, 56, 68, 0.7);
  line-height: 1.4;
  letter-spacing: -0.5px;
}
.btn {
  transition: 0.2s ease-in-out;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 70px;
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.44px;
  padding: 20px 40px;
  cursor: pointer;
  margin: 0 auto;
}
.btn:hover {
  transform: translateY(-4px);
}
.prices {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: 12px;
  padding: 0 15px;
}
.prices div {
  position: absolute;
  left: 50%;
    top: -18px;
    margin-left: 108px;
}
.prices div span {
  position: absolute;
  left: 18px;
    top: 18px;
    transform: rotate(-30deg);
    color: #fff;
}
.prices p:nth-of-type(1) {
  color: #f34fa7;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.37;
  letter-spacing: -1px;
}
.prices p:nth-of-type(2) {
  text-decoration: line-through;
  color: rgb(51, 56, 68, 0.4);
  font-size: 21px;
  line-height: 1.37;
  letter-spacing: -1px;
}
.remain {
  color: #3b4a77;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 60px;
  text-align: center;
}
.remain span {
  color: #ef3f9e;
  font-size: 36px;
  letter-spacing: 0;
}
.countdown {
  padding: 0 15px;
  margin-bottom: 32px;
}
.countdown > p {
  text-transform: uppercase;
  color: #3b4a77;
  font-size: 16px;
  line-height: 1.31;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 12px;
  text-align: center;
}
.countdown_timer div {
  color: #f34fa7;
  font-size: 46px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.752px;
  text-transform: uppercase;
  display: flex;
  gap: 12px;
  align-items: baseline;
  justify-content: center;
}
.countdown_timer div div {
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.countdown_timer div div span {
  font-size: 16px;
  text-transform: lowercase;
  font-weight: normal;
}

@media (max-width: 575px) {
  .prices {
    flex-direction: column;
    gap: 0;
  }
  .prices div {
    margin-left: 60px;
  }
  .countdown {
    margin-top: 20px;
    display: flex;
    order: 2;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .countdown > div {
    display: flex;
    gap: 2px;
    justify-content: space-between;
  }
  .countdown > p {
    font-size: 15px;
    margin-bottom: 0;
    text-align: center;
  }
  .countdown_timer div div {
    font-size: 36px;
    letter-spacing: 0;
  }
  .countdown_timer {
    display: flex;
  }
  .preims {
    padding: 0 15px;
  }
  .title {
    font-size: 26px;
    margin-bottom: 35px;
  }
  .title span {
    display: block;
    margin: 0 auto;
  }
  .item_pretitle {
    font-size: 14px;
    padding: 6px;
    height: auto;
  }
  .item_title {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .item_text {
    font-size: 14px;
  }
  .block {
    padding: 0;
    margin-bottom: 23px;
  }
  .btn {
    font-size: 18px;
    padding: 20px;
  }
  .remain {
    font-size: 16px;
  }
  .remain span {
    font-size: 30px;
  }
}
