.gifts {
    background-color: var(--gray-bg);
    padding: 75px 0 85px;
}
.gifts_block {
    display: flex;
    align-items: center;
}
.gifts_block__left {
    flex: 0 1 50%;
    max-width: 50%;
}
.gifts_block__right {
    flex: 0 1 50%;
    max-width: 50%;
}
.gifts_block-title {
    font-family: Bebas;
    font-size: 64px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 12px;
}
.gifts_block-title span {
    color: var(--main-p-color);
}
.gifts_block__right p {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 12px;
}
.gifts_block__right ul li {
    padding-left: 20px;
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
    font-size: 15px;
    line-height: 1.3;
    transition: .2s ease-in-out;
}
.gifts_block__right ul li:hover {
    transform: translateX(5px);
}
.gifts_block__right ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 15.5px;
    width: 6px;
    height: 6px;
    background-color: var(--main-p-color);
}
.gifts_block-img img {
    mix-blend-mode: multiply;
}

@media(max-width: 991px) {
    .gifts_block {
        flex-wrap: wrap;
    }
    .gifts_block__left, .gifts_block__right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .gifts_block-img {
        text-align: center;
    }
}
@media(max-width: 767px) {
    .gifts_block-title {
        font-size: 60px;
    }
}
@media(max-width: 575px) {
    .gifts_block-title {
        font-size: 38px;
    }
}