.begin {
    background-color: #fff;
    padding-top: 85px;
    padding-bottom: 110px;
}
.begin_title {
    text-align: center;
    font-family: Dela;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #EF3F34;
    margin-bottom: 25px;
}
.begin_title span {
    font-size: 52px;
    line-height: 58px;
    color: #161313;
}
.begin_subtitle {
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 35px;
}
.begin_block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.begin_item {
    padding: 0 8px;
    flex: 0 1 304px;
    max-width: 304px;
    margin-bottom: 60px;
}
.begin_info {
    background-color: #fff;
    border-radius: 13px;
    display: flex;
    height: 100%;
}
.begin_number {
    background-color: #0D8ED7;
    border-top-left-radius: 13px;
    flex: 0 1 64px;
    border-bottom-left-radius: 13px;
    padding-top: 20px;
    text-align: center;
    color: rgba(255,255,255,.1);
    font-family: Dela;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}
.begin_number span {
    color: #fff;
}
.begin_text {
    flex: 1;
    padding: 19px;
    font-size: 18px;
    line-height: 24px;
    border: 1px solid #E2E5F3;
    border-left: none;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
}
.begin_block2 {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/begin_bg.jpg');
    background-size: cover;
    box-shadow: 0px 4px 32px rgba(240, 233, 217, 0.35);
    border-radius: 30px;
    padding-top: 55px;
    padding-bottom: 40px;
    padding-left: 70px;
}
.begin_block2 div {
    margin-bottom: 25px;
    font-family: Dela;
    font-size: 38px;
    line-height: 44px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}
.begin_block2 div span {
    color: #EF3F34;
}
.begin_block2 ul li {
    padding-left: 21px;
    position: relative;
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
}
.begin_block2 ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 8px;
    height: 8px;
    background-color: #0D8ED7;
    border-radius: 100%;
}
@media(max-width: 575px) {
    .begin {
        padding-top: 40px;
        padding-bottom: 10px;
    }
    .begin_title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .begin_title span {
        font-size: 24px;
        line-height: 26px;
    }
    .begin_subtitle {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .begin_item {
        margin-bottom: 10px;
    }
    .begin_text {
        font-size: 16px;
        line-height: 20px;
    }
    .begin_block2 {
        padding: 20px 25px;
        margin-top: 30px;
    }
    .begin_block2 div {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
        text-align: center;
    }
    .begin_block2 ul li {
        font-size: 16px;
        line-height: 20px;
    }
}