.platformgt {
    padding: 80px 0;
    font-family: Inter;
}
.platformgt_block {
    background-color: #ececec;
    border-radius: 40px;
    position: relative;
    padding: 90px 70px 50px;
}
.platformgt_block::before {
    /* content: ''; */
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/zap.svg');
    background-position: 120% 50%;
    background-size: 526px 148px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.5;
    border-radius: 40px;
    pointer-events: none;
}
.platformgt_logo {
    margin-bottom: 40px;
    position: relative;
}
.platformgt_title {
    font-family: Actay;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 28px;
    text-transform: uppercase;
    max-width: 630px;
    position: relative;
}
.platformgt_subtitle {
    font-family: Actay;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    max-width: 630px;
    position: relative;
}
.platformgt_image {
    position: absolute;
    right: 30px;
    bottom: 15px;
    max-width: 478px;
}
.platformgt_image_m {
    display: none!important;
}
.platformgt_info {
    display: flex;
    flex-wrap: wrap;
    max-width: 630px;
    margin: 0 -15px;
    position: relative;
}
.platformgt_info__item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
}
.platformgt_info__item_number {
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
    font-family: Actay;
    margin-bottom: 7px;
}
.platformgt_info__item p {
    line-height: 20px;
    font-family: Actay;
    font-size: 17px;
}
.platformgt_info__item p strong {
    /* font-weight: 600; */
    font-weight: normal;
}
.platform_black {
    background-color: #1C1C1A;
    margin-top: -2px;
}
.platform_black .platformgt_block, .platform_gray .platformgt_block {
    transform: rotate(-3deg);
    border-radius: 90px;
}
.platform_black .platformgt_block::before, .platform_gray .platformgt_block::before {
    border-radius: 90px;
}
.platform_black .platformgt_block > *, .platform_gray .platformgt_block > * {
    transform: rotate(3deg);
}
.platform_gray {
    background-color: #F9F9F9;
    margin-top: -2px;
}

@media(max-width: 1199px) {
    .platformgt_block {
        padding: 30px;
    }
    .platformgt_image {
        right: 0;
    }
    .platformgt_title, .platformgt_subtitle, .platformgt_info {
        max-width: 525px;
    }
}
@media(max-width: 991px) {
    .platformgt_block {
        text-align: center;
    }
    .platformgt_image {
        position: relative;
        bottom: 0;
    }
    .platformgt_title, .platformgt_subtitle, .platformgt_info {
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .platformgt_title {
        font-size: 32px;
        line-height: 36px;
    }
    .platformgt_subtitle {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 22px;
    }
    .platformgt_info__item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .platform_black .platformgt_block, .platform_black .platformgt_block > *, .platform_gray .platformgt_block, .platform_gray .platformgt_block > * {
        transform: rotate(0deg);
    }
    .platform_black .platformgt_block, .platform_black .platformgt_block::before, .platform_gray .platformgt_block, .platform_gray .platformgt_block::before {
        border-radius: 49px;
    }
}
@media(max-width: 575px) {
    .platformgt {
        padding: 45px 0;
    }
    .platformgt_block {
        padding: 30px 10px;
    }
    .platformgt_logo {
        margin-bottom: 23px;
    }
    .platformgt_title {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 15px;
    }
    .platformgt_image {
        margin-bottom: 20px;
        /* margin-left: -10px; */
        /* margin-right: -10px; */
    }
    .platformgt_image_d {
        display: none!important;
    }
    .platformgt_image_m {
        display: inline-block!important;
    }
    .platformgt_info {
        margin: 0;
    }
    .platformgt_info__item {
        padding: 0;
    }
}