.preims {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 85px;
}
.title {
    text-align: center;
    font-weight: 500;
    font-size: 44px;
    line-height: 1.3;
    letter-spacing: -0.5px;
    margin-bottom: 34px;
}
.title span {
    background-color: #F6FAFF;
    padding: 6px 14px;
    border-radius: 25px;
    max-width: max-content;
    border: 1px solid #333844;
}
.block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 55px;
    padding: 0 15px;
}
.item {
    flex: 0 1 360px;
    max-width: 360px;
    border-radius: 40px;
    background-color: #fff;
    border: 1px solid #DEEAFF;
    padding: 28px;
}
.item_img {
    margin-bottom: 20px;
}
.item_title {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.4;
    letter-spacing: -0.5px;
}
.item_text {
    color: rgba(51, 56, 68, 0.7);
    line-height: 1.4;
    letter-spacing: -0.5px;
}
.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
    background: linear-gradient(276.43deg, #E92A91 -51.29%, #FA65B5 131.26%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 40px;
    cursor: pointer;
    margin: 0 auto;
}
.btn:hover {
    transform: translateY(-4px);
}
.btn_off {
    pointer-events: none!important;
    filter: grayscale(1);
}

@media(max-width:575px) {
    .preims {
        padding: 0 15px 50px;
    }
    .title {
        font-size: 26px;
        margin-bottom: 35px;
    }
    .title span {
        display: block;
        margin: 0 auto;
    }
    .item_title {
        font-size: 18px;
        margin-bottom: 6px;
    }
    .item_text {
        font-size: 14px;
    }
    .block {
        padding: 0;
        margin-bottom: 23px;
    }
    .btn {
        font-size: 18px;
        padding: 20px;
    }
}