.noinventory {
    background-color: #101010;
    padding: 90px 0 45px;
}
.noinventory_title {
    font-weight: 800;
    font-size: 65px;
    line-height: 80px;
    color: #fff;
    margin-bottom: 20px;
}
.noinventory_subtitle {
    color: #c0bfbf;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 50px;
}
.noinventory_subtitle span {
    color: #fff;
    display: block;
    font-weight: 600;
}
.noinventory_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -22.5px;
}
.noinventory_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 22.5px;
    margin-bottom: 45px;
}
.noinventory_info {
    border-radius: 10px;
    background-color: #fff;
    height: 100%;
    padding: 28px 32px 70px;
    position: relative;
    transition: .2s ease-in-out;
}
.noinventory_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(245, 15, 17, 0.7);
}
.noinventory_info__title {
    font-size: 35px;
    line-height: 44px;
    font-weight: bold;
    color: var(--fat-red);
    margin-bottom: 10px;
    text-transform: uppercase;
}
.noinventory_info__text {
    line-height: 25px;
    padding-right: 30px;
}
.noinventory_info__number {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 67px;
    height: 67px;
    text-align: center;
    border-radius: 10px 0px;
    font-size: 50px;
    line-height: 62px;
    color: #fff;
    font-weight: bold;
    background-color: var(--fat-red);
}

@media(max-width: 991px) {
    .noinventory_title {
        font-size: 50px;
        line-height: 55px;
    }
    .noinventory_block {
        margin: 0 -15px;
    }
    .noinventory_item {
        padding: 0 15px;
    }
}
@media(max-width: 767px) {
    .noinventory {
        padding: 45px 0;
    }
    .noinventory_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .noinventory_subtitle {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
    .noinventory_subtitle br {
        display: none;
    }
    .noinventory_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .noinventory_info {
        padding: 20px;
    }
    .noinventory_info__title {
        font-size: 18px;
        line-height: 22px;
    }
    .noinventory_info__text {
        font-size: 16px;
        line-height: 22px;
        padding-right: 0;
        margin-bottom: 20px;
    }
    .noinventory_info__number {
        font-size: 30px;
        line-height: 38px;
        width: 40px;
        height: 40px;
    }
}