.studentres {
    overflow: hidden;
    position: relative;
    padding: 40px 0;
}
.title {
    font-family: Bebas;
    text-transform: uppercase;
    color: #181818;
    font-size: 77px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 10px;
}
.subtitle {
    margin-bottom: 40px;
    color: #171717;
    font-size: 18px;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;
}
.subtitle span {
    color: #E90973;
    font-weight: 500;
}
.hjk {
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 0 30px;
}
.slider_nav {
    display: flex;
    gap: 16px;
}
.slider_btn_prev {
    border-radius: 100%;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    cursor: pointer;
}
.slider_btn_next {
    border-radius: 100%;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    cursor: pointer;
}
.slider {
    flex: 1;
}
.slide {
    border: 1px solid #CDCDCD;
    border-radius: 22px;
    overflow: hidden;
}
.slide_info {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
}
.slide_info div {
    flex: 0 1 50%;
    max-width: 50%;
    font-size: 14px;
    color: #535363;
    display: flex;
    align-items: center;
    gap: 2px;
}
.slide_info div p {
    width: 114px;
    flex: 1;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slider .slider_nav {
    display: none;
}
.btn {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    border-radius: 9px;
    background: #E90973;
    font-size: 22px;
    color: #fff;
    line-height: 1.2;
    letter-spacing: -0.44px;
    text-align: center;
    max-width: max-content;
    margin: 32px auto 0;
    cursor: pointer;
}
.btn:hover {
    transform: translateY(-4px);
}

@media(max-width: 575px) {
    .title {
        margin-bottom: 8px;
        font-size: 35px;
    }
    .subtitle {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .hjk {
        padding: 0 15px;
        gap: 16px 6px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .slider {
        margin: 0;
        flex: 0 1 100%;
        max-width: 100%;
    }
    .slide_info {
        padding: 12px;
    }
    .slide_info div {
        font-size: 13px;
        justify-content: center;
    }
    .slider .slider_nav {
        display: flex;
    }
    .slider_nav {
        position: absolute;
        top: 50%;
        margin-top: 20px;
        left: 0;
        z-index: 2;
        width: 100%;
        justify-content: space-between;
    }
    .slider_btn_next {
        width: 42px;
        height: 42px;
        order: 2;
    }
    .slider_btn_prev {
        width: 42px;
        height: 42px;
        order: 2;
    }
    .btn {
        margin: 32px 15px 0;
    }
}