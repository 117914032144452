.main {
    padding: 46px 0 67px;
    margin: 0 auto;
    max-width: 675px;
    text-align: center;
    font-family: Roboto;
    color: #111927;
    min-height: 100vh;
}
.logo {
    text-align: center;
    margin-bottom: 23px;
}
.logo img {
    max-width: 100px;
}
.title {
    margin-bottom: 24px;
    font-weight: 800;
    font-size: 32px;
    font-family: Qanelas;
    line-height: 36px;
    text-transform: uppercase;
}
.title span {
    display: block;
    color: #EE4777;
    font-weight: 600;
}
.title span i {
    font-style: normal;
    text-decoration: line-through;
    color: #FECAD9;
}
.block1 {
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 15px 20px 20px;
    margin-bottom: 28px;
}
.block1_title {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 32px;
    font-family: Qanelas;
    line-height: 41.6px;
    text-transform: uppercase;
}
.block1_subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 12px;
}
.block1_subtitle span {
    color: #EE4777;
}
.block1_countdown p {
    color: #4D5761;
    font-size: 16px;
    line-height: 22.4px;
}
.block1_countdown_timer {
    font-family: Qanelas;
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
}
.block1_countdown_timer > div {
    display: flex;
    justify-content: center;
}
.block1_countdown_timer > div span {
    margin-left: -5px;
    margin-right: 2px;
}
.subtitle {
    font-family: Qanelas;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 22px;
}
.btn1 {
    border-radius: 12px;
    background: linear-gradient(91deg, #116381 6.98%, #177B9F 99.58%), #177B9F;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 370px;
    height: 80px;
    color: #F8F8F8;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 0 auto 48px;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.btn1:hover {
    transform: translateY(-2px);
}
.list {
    text-align: left;
    margin-bottom: 33px;
}
.list p {
    margin-bottom: 16px;
    font-family: Qanelas;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}
.list li {
    padding-left: 12px;
    margin-bottom: 10px;
    color: rgba(77, 87, 97, 0.75);
    font-size: 16px;
    line-height: 22.4px;
    position: relative;
}
.list li::before {
    content: '';
    position: absolute;
    left: 2px;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #7A8189;
}
.list li:last-of-type {
    margin-bottom: 0;
}
.list:last-of-type {
    margin-bottom: 56px;
}
.block2 {
    border-radius: 20px;
    border: 1px solid #EAECF0;
    background: #EE4777;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 30px 45px 40px;
}
.block2_title {
    color: #fff;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.block2_subtitle {
    color: #fff;
    margin-bottom: 20px;
    font-family: Qanelas;
    font-weight: 600;
    font-size: 32px;
}
.block2_subtitle span {
    color: #FECAD9;
    text-decoration: line-through;
}
.block2_countdown {
    margin-bottom: 26px;
}
.block2_countdown p {
    color: #EAA1B6;
    font-size: 18px;
    line-height: 25.2px;
}
.block2_countdown_timer {
    font-family: Qanelas;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #fff;
}
.block2_countdown_timer > div {
    display: flex;
    justify-content: center;
}
.block2_countdown_timer > div span {
    margin-left: -5px;
    margin-right: 2px;
}
.block3 {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.block3_title {
    font-family: Qanelas;
    font-weight: 800;
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.block3_subtitle {
    margin-bottom: 34px;
    font-family: Qanelas;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}
.btn3 {
    width: 437px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    font-family: Qanelas;
    color: #F8F8F8;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    transition: .2s ease-in-out;
}
.btn3:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
@media(max-width: 575px) {
    .main {
        padding: 40px 15px;
    }
    .title {
        font-size: 26px;
        line-height: 32px;
    }
    .block1_title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .subtitle {
        font-size: 18px;
        line-height: 24px;
    }
    .btn1, .btn3 {
        width: 100%;
        height: 60px;
        font-size: 18px;
        margin-bottom: 30px;
    }
    .list li  {
        margin-bottom: 6px;
    }
    .block2 {
        padding: 15px;
    }
    .block2_title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .block2_subtitle {
        font-size: 22px;
        line-height: 28px;
    }
    .block3_title {
        font-size: 24px;
        line-height: 30px;
    }
    .block3_subtitle {
        font-size: 18px;
        line-height: normal;
    }
}