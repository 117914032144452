.aboutcourse {
    padding-top: 240px;
    padding-bottom: 0px;
}
.aboutcourse_title {
    font-size: 64px;
    font-family: Bebas;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;
    text-align: center;
}
.aboutcourse_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.aboutcourse_item {
    flex-basis: calc(28% - 10px);
    margin: 0 15px 15px;
    padding: 25px;
    background: #FFECF5;
    border-radius: 10px;
    transition: .2s ease-in-out;
}
.aboutcourse_item:hover {
    transform: translateY(-10px);
}
.aboutcourse_item-img {
    width: 78px;
    height: 78px;
    margin-bottom: 15px;
}
.aboutcourse_item-title {
    font-family: Bebas;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1;
}
.aboutcourse_item p {
    font-size: 14px;
    line-height: 1.35;
    margin-bottom: 20px;
}

@media(max-width: 1199px) {
    .aboutcourse_item-title {
        font-size: 30px;
    }
}
@media(max-width: 991px) {
    .aboutcourse_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin: 0 0 15px;
    }
}
@media(max-width: 767px) {
    .aboutcourse_title {
        font-size: 60px;
    }
}
@media(max-width: 575px) {
    .aboutcourse_title {
        font-size: 38px;
    }
}