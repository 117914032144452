.faq {
    padding: 70px 0 50px;
    background-color: #fff;
}
.faq_title {
    font-size: 52px;
    line-height: 58px;
    font-family: Dela;
    font-weight: bold;
    margin-bottom: 25px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
}
.accordion {
    max-width: 760px;
    margin: 0 auto;
}
.question {
    padding: 17px 50px 20px 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    /* box-shadow: 0px 0px 32px rgba(170, 170, 170, 0.1); */
    /* background-color: #fff; */
    border-top: 2px solid #EBEBEB;
    /* border-bottom: 2px solid #EBEBEB; */
    /* border-radius: 15px; */
    transition: .2s ease-in-out;
}
.question::before {
    content: '+';
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 20px;
    font-size: 40px;
    transition: .2s ease-in-out;
    font-weight: normal;
}
.active .question::before {
    content: '-';
    margin-top: -14px;
}
.active .question, .question:hover {
    /* color: #fff; */
    border-color: #A027FF;
    /* background-color: #53C1FF; */
}
.question:hover::before {
    /* color: #fff; */
}
.answer {
    max-height: 0px;
    overflow: hidden;
    /* margin-bottom: 18px; */
    font-size: 18px;
    line-height: 24px;
    padding-left: 30px;
    transition: .2s ease-in-out;
}
.active .answer {
    padding-top: 14px;
    max-height: 500px;
    padding-bottom: 25px;
}
.answer p {
    margin-bottom: 15px;
}
.answer ul {
    margin-left: 20px;
}
.answer ul li {
    margin-bottom: 5px;
    list-style-type: circle;
}
.answer a {
    color: #A027FF;
    font-weight: 500;
}
.more {
    margin-top: 50px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 30px;
    color: #C9C9C9;
}
.more2 {
    padding-left: 30px;
    margin-top: 15px;
}
.more2 a {
    color: #EF3F34;
    font-weight: 500;
}
@media(max-width: 767px) {
    .faq {
        padding: 45px 0;
    }
    .faq_more {
        padding: 45px 0 20px;
    }
    .faq_title {
        font-size: 32px;
        line-height: 36px;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
    }
}
@media(max-width: 575px) {
    .question {
        padding: 10px 40px 10px 15px;
    }
    .answer {
        padding-left: 15px;
    }
    .more, .more2 {
        padding-left: 0;
        text-align: center;
    }
}