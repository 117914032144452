.platform {
    padding-top: 65px;
    padding-bottom: 25px;
}
.platform_title {
    text-align: center;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
    margin-bottom: 45px;
    text-transform: uppercase;
}
.platform_title span {
    color: #D8376A;
}
.platform_img {
    text-align: center;
    margin-bottom: 140px;
}
.platform_item {
    display: flex;
    align-items: center;
    max-width: 950px;
    margin: 0 auto 100px;
}
.platform_info {
    flex: 1;
}
.platform_item_title {
    margin-bottom: 12px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 50px;
    line-height: 56px;
    text-transform: uppercase;
}
.platform_item_text {
    font-size: 18px;
    line-height: 28px;
    color: #4D5761;
}
.platform_img1 {
    flex: 0 1 339px;
    margin-left: 60px;
}
.platform_img2 {
    flex: 0 1 374px;
    margin-right: 40px;
    margin-left: -30px;
}
.platform_img3 {
    flex: 0 1 420px;
    margin-left: 40px;
}
@media(max-width: 991px) {
    .platform_title {
        font-size: 40px;
        line-height: 46px;
    }
    .platform_title br {
        display: none;
    }
    .platform_item {
        flex-direction: column;
        margin-bottom: 60px;
        text-align: center;
    }
    .platform_item:last-of-type {
        margin-bottom: 0;
    }
    .platform_item_title {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 12px;
    }
    .platform_item_text {
        font-size: 16px;
        line-height: 24px;
    }
    .platform_info {
        margin-bottom: 40px;
    }
    .platform_item:last-of-type .platform_info {
        margin-bottom: 0;
    }
    .platform_img1, .platform_img2, .platform_img3 {
        flex: 0 1 100%;
        max-width: 100%;
        margin: 0;
    }
    .platform_img1 {
        margin-left: 90px;
    }
    .platform_img2 {
        order: 2;
    }
    .platform_img3 {
        display: none;
    }
}
@media(max-width: 575px) {
    .platform {
        padding-top: 20px;
    }
    .platform_title {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 16px;
        text-align: left;
        text-align: center;
    }
    .platform_img {
        margin-bottom: 70px;
    }
}
@media(max-width: 355px) {
    .platform_title {
        font-size: 22px;
        line-height: 28px;
    }
}