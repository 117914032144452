.year1_login {
    border-radius: 20px;
    border: 1px solid #EAECF0;
    background: #EE4777;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 30px 45px 40px;
}
.year1_login_title {
    color: #fff;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.year1_login > .chakra-stack {
    width: 100%;
    background-color: transparent;
    padding: 0;
    max-width: 380px;
    margin: 0 auto;
}
.year1_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-text, .year1_login > .chakra-stack > .chakra-text {
    color: #fff;
}
.year1_login .chakra-form-control  .chakra-stack > .chakra-stack {
    border-radius: 12px;
}
.year1_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack .chakra-button {
    border-radius: 12px;
    background: #232C2F;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    color: #fff;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
}
.year1_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack .chakra-button p {
    font-weight: bold;
}
.year1_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack .chakra-button svg {
    display: none;
}
.year1_login > .chakra-stack > .chakra-stack {
    margin-top: 25px;
}
.year1_login > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-button:first-of-type {
    color: #fff;
}
.year1_login > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-button:last-of-type {
    background: #fff;
    color: #111927;
}



.year1_start {
    width: 100%;
}
.year1_start h2 {
    display: none;
}
.year1_start .sales-widget__offers {
    margin-top: 0;
    width: 100%;
}
.year1_start .chakra-button {
    outline: none!important;
    border-radius: 12px;
    background: #232C2F;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    color: #fff;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 0 auto;
    transition: .2s ease-in-out;
}
.year1_start .chakra-button:hover {
    background: #232C2F;
    opacity: 1;
    transform: translateY(-2px);
}
.year1_start .chakra-button:active {
    background: #232C2F;
    opacity: 1;
}
.year1_start .chakra-button p {
    position: relative;
}
.year1_start form .chakra-button {
    margin-bottom: 5px;
}
.year1_start .sales-widget__user-form button > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-size: 14px;
    line-height: normal;
    text-transform: lowercase;
}
.year1_start .gt-offer {
    text-align: left;
    /* background-color: #fff; */
    /* padding: 15px 0px; */
    /* border-bottom: 1px solid rgba(55, 59, 82, 0.2); */
}
.year1_start .gt-offer > label {
    margin-bottom: 10px;
    grid-template-columns: 0px 1fr;
}
.year1_start .gt-offer > label > .chakra-checkbox__control {
    background: #4E60C1;
    border-color: #4E60C1;
}
.year1_start .gt-offer > label > span {
    padding-left: 0;
    margin-left: 0;
}
.year1_start .gt-offer:last-of-type {
    border-bottom: none;
}
.year1_start .chakra-radio__control {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    background-color: #fff!important;
    position: relative;
    border: none!important;
    outline: none!important;
    border: 5.5px solid #DED9D5!important;
}
.year1_start .chakra-radio__control[data-checked] {
    /* border-color: #177B9F!important; */
    width: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
    border: none!important;
}
.year1_start .chakra-radio__control::before {
    width: 12px!important;
    height: 12px!important;
    background-color: #fff!important;
    border-radius: 100%!important;
    content: none!important;
}
.year1_start .gt-offer__title {
    font-size: 22px;
    line-height: 26px;
    font-family: Qanelas;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 0;
    color: #fff;
}
.year1_start .gt-offer__title ~ .chakra-text {
    padding-left: 0;
    font-family: Qanelas;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.year1_start span.chakra-radio__label > .chakra-stack {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}
.year1_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}
.year1_start .gt-offer__marketing-price {
    font-family: Qanelas;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    order: 2;
    color: rgba(22, 19, 19, 0.5);
}
.year1_start .gt-offer__price {
    font-family: Qanelas;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #fff;
    margin-bottom: 5px;
}
.year1_start .sales-widget__selected-offers {
    max-width: 800px;
    /* background-color: #fff; */
    /* padding: 0 0 40px 0; */
}
.year1_start .sales-widget__selected-offers p {
    color: #fff;
}
.year1_start .sales-widget__selected-offers > .chakra-stack {
    display: flex;
    align-items: flex-start;
}
.year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
    font-size: 20px;
    line-height: 26px;
    font-family: Qanelas;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 20px;
    margin-bottom: 10px;
    /* color: #0A0A0D; */
    text-align: left;
}
.year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2) {
    padding-left: 20px;
    font-family: Qanelas;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack {
    display: flex;
    flex-direction: column;
}
.year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(1) {
    font-family: Qanelas;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: -0.01em;
    order: 2;
    /* color: #203B51; */
}
.year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-family: Qanelas;
    font-size: 20px;
    line-height: 22px;
    font-weight: 800;
    letter-spacing: -0.01em;
    color: #1C1C1A;
    margin-bottom: 5px;
}
.year1_start .sales-widget__user-form {
    max-width: 800px;
}
.year1_start .sales-widget__user-form input {
    background-color: #fff;
    /* border-radius: 0; */
}
.year1_start .sales-widget__offers > .chakra-stack {
    min-width: 100%;
}
.year1_start .sales-widget__offers > .chakra-radio-group > .chakra-stack {
    max-width: 100%;
    margin-bottom: 20px;
}
.year1_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-end;
}
.year1_start .sales-widget__offers > .chakra-stack .chakra-divider {
    display: none;
}
.year1_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div {
    margin-bottom: 15px;
}
.year1_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-marketing-price {
    font-family: Qanelas;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    color: rgba(22, 19, 19, 0.5);
}
.year1_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-price {
    color: #1C1C1A;
}
.year1_start .chakra-form__error-message, .year1_login .chakra-form__error-message {
    color: #fff;
}
@media(max-width: 575px) {
    .year1_start .gt-offer, .year1_start .sales-widget__selected-offers {
        padding: 10px;
    }
    .year1_start span.chakra-radio__label > .chakra-stack, .year1_start .sales-widget__selected-offers > .chakra-stack {
        flex-direction: column;
    }
    .year1_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
        margin-top: 20px;
        align-items: flex-end;
        width: 100%;
    }
    .year1_start .gt-offer__title {
        font-size: 18px;
        line-height: 22px;
        padding-left: 5px;
    }
    .year1_start .gt-offer__title ~ .chakra-text {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
    }
    .year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2), .year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
        padding-left: 0;
    }
    .year1_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .year1_start .sales-widget__user-form button {
        margin-top: 15px;
    }
    .year1_start .sales-widget__user-form {
        padding: 0;
    }
    .year1_start .chakra-button {
        height: 60px;
        width: 90%;
    }
    .year1_start form .chakra-button {
        width: 90%;
        margin-top: 0;
    }
    .year1_start .chakra-button p {
        top: 0;
    }
    .year1_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .year1_start .gt-offer__marketing-price {
        margin-left: 20px;
    }
    .velvet br {
        display: none;
    }
    .year1_login {
        padding: 15px;
    }
    .year1_login_title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
}
@media(max-width:1023px) {
    .year1_login .chakra-heading {
        display: none;
    }
    .year1_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-text, .year1_login > .chakra-stack > .chakra-stack p {
        color: #fff;
    }

    .year1_login > .chakra-stack > .chakra-stack > .chakra-stack .chakra-button {
        border-radius: 12px;
        background: #232C2F;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        color: #fff;
        letter-spacing: 0.04rem;
        text-transform: uppercase;
        font-family: Qanelas;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
    .year1_login > .chakra-stack > .chakra-stack > .chakra-stack  .chakra-button p {
        font-weight: bold;
        color: #fff;
    }
    .year1_login > .chakra-stack > .chakra-stack > .chakra-stack  .chakra-button svg {
        display: none;
    }
    .year1_login > .chakra-stack > .chakra-stack:nth-of-type(3) > .chakra-button:first-of-type {
        color: #fff;
    }
    .year1_login > .chakra-stack > .chakra-stack:nth-of-type(3) > .chakra-button:last-of-type {
        background: #fff;
    }
    .year1_login > .chakra-stack > .chakra-stack:nth-of-type(3) > .chakra-button:last-of-type p {
        color: #111927;
    }
}