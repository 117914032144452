.reviews {
    padding-top: 25px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
}
.reviews_title {
    text-align: center;
    margin-bottom: 28px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    color: #25232B;
}
.reviews_subtitle {
    margin-bottom: 50px;
    font-family: Roboto;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    color: #4D5761;
}
.reviews_row {
    display: flex;
    gap: 26px;
    left: 30px;
    width: max-content;
    position: relative;
    animation: move 60s linear infinite;
}
.reviews_row2 {
    left: -40px;
    animation: move2 70s linear infinite;
}
.reviews_item {
    margin-bottom: 26px;
    max-width: 348px;
    padding: 15px;
    height: max-content;
    border-radius: 12.362px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
}
.reviews_item__text {
    font-family: Roboto;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 8px;
    text-align: left;
}
.reviews_item__info {
    display: flex;
    justify-content: space-between;
}
.reviews_item__name {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #4D5761;
}
.reviews_item__date {
    font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #4D5761;
}
@keyframes move {
    0% {
        transform: translateX(0);
    } 100% {
        transform: translateX(-1870px);
    }
}
@keyframes move2 {
    0% {
        transform: translateX(0);
    } 100% {
        transform: translateX(-2244px);
    }
}

.reviews_images {
    border-radius: 20px;
    border: 0.373px solid var(--card, #EAECF0);
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    margin-bottom: 60px;
    padding: 40px 15px;
}
.reviews_images img {
    margin-bottom: 50px;
}
.btn1 {
    border-radius: 12px;
    background: linear-gradient(91deg, #116381 6.98%, #177B9F 99.58%), #177B9F;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 370px;
    height: 80px;
    color: #F8F8F8;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 0 auto 48px;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.btn1:hover {
    transform: translateY(-2px);
}
@media(max-width: 575px) {
    .reviews {
        padding-top: 0;
    }
    .reviews_title {
        font-size: 26px;
        line-height: 28px;
    }
    .reviews_subtitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    .reviews_images img {
        margin-bottom: 20px;
    }
    .btn1 {
        width: 100%;
        height: 60px;
        font-size: 18px;
        margin-bottom: 30px;
    }
}