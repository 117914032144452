.signup {
    padding: 45px 0 60px;
}
.signup_title {
    font-size: 72px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Bebas;
    margin-bottom: 15px;
}
.signup_title span {
    color: var(--main-p-color);
}
.signup_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12.5px;
}
.signup_block__item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 12.5px;
    margin-bottom: 30px;
}
.signup_block__item-info {
    background-color: var(--pink-bg);
    border-radius: 10px;
    padding: 25px;
    height: 100%;
    transition: .2s ease-in-out;
}
.signup_block__item-info:hover {
    background-color: var(--pink-bg2);
    transform: translateY(-5px);
}
.signup_block__item-img {
    height: 78px;
    margin: 10px 0 20px;
}
.signup_block__item-img img {
    height: 100%;
}
.signup_block__item-description {
    margin-bottom: 20px;
    font-size: 22px;
}

@media(max-width: 1199px) {
    .signup_block__item-info {
        height: 100%;
    }
}
@media(max-width: 991px) {
    .signup_block__item {
        flex: 0 1 50%;
        max-width: 50%;
    }
}
@media(max-width: 767px) {
    .signup_title {
        font-size: 60px;
    }
}
@media(max-width: 575px) {
    .signup_block__item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .signup_title {
        font-size: 38px;
    }
    .signup_block__item-description {
        line-height: normal;
    }
    .signup_block__item-info {
        padding: 15px;
    }
    .signup_block__item-description br {
        display: none;
    }
}