.reviews {
    padding: 80px 0;
    overflow: hidden;
}
.reviews_title {
    margin-bottom: 35px;
    text-align: center;
    font-family: Actay;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.1;
}
.slider {
    overflow: visible;
}
.slide {
    border-radius: 9.307px;
    background: #FFF;
    box-shadow: 0px 17px 62.9px 0px rgba(0, 0, 0, 0.06);
    padding: 24px 18px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 372px;
    height: auto;
}
.slide_name {
    font-family: Cera;
    color: #8B8B8B;
    font-weight: 500;
}
.slide_text {
    font-size: 19px;
    color: #252525;
}
.slider_nav {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding-top: 20px;
}
.slider_btn_prev {
    width: 52px;
    height: 52px;
    background-size: cover;
    background-image: url('data:image/svg+xml,<svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.7298" cy="25.7298" r="24.6578" transform="matrix(-1 0 0 1 51.459 0)" stroke="%23FB4F54" stroke-width="2.14415"/><path d="M31.0898 12.8649L17.6889 26.2658L31.0898 39.6668" stroke="%23FB4F54" stroke-width="2.14415"/></svg>');
    cursor: pointer;
}
.slider_btn_next {
    width: 52px;
    height: 51px;
    background-size: cover;
    background-image: url('data:image/svg+xml,<svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="26.2709" cy="25.7298" r="24.6578" stroke="%23FB4F54" stroke-width="2.14415"/><path d="M20.9102 12.8649L34.3111 26.2658L20.9102 39.6668" stroke="%23FB4F54" stroke-width="2.14415"/></svg>');
    cursor: pointer;
}

@media(max-width:767px) {
    .reviews {
        padding: 80px 0 60px;
    }
    .reviews_title {
        margin-bottom: 30px;
        font-size: 30px;
    }
}
@media(max-width: 575px) {
    .slide {
        width: 330px;
    }
}