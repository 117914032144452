.calc {
    min-height: 100vh;
    background-color: #F5F5F5;
    position: relative;
    padding-top: 100px;
    font-family: Qanelas;
}
.calc::before {
    content: '';
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/zap.svg');
    background-position: 120% 50%;
    background-size: 526px 148px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.1;
    border-radius: 40px;
    pointer-events: none;
    filter: grayscale(1);
    pointer-events: none;
}
.calc::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    left: calc(50% - 50%/2 - 0px);
    top: 0;
    background: #F5F5F5;
    opacity: 0.85;
    filter: blur(160px);
    pointer-events: none;
}
.calc > div {
    position: relative;
    z-index: 1;
}
.logo {
    text-align: center;
}
.block {
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.title {
    text-align: center;
    color: #070707;
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
}
.subtitle {
    margin-bottom: 30px;
    text-align: center;
    font-weight: 500;
    color: rgba(37, 37, 37, 0.7);
    font-size: 22px;
    line-height: 30px;
}
.btn {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(180deg, #EE4777 -81.62%, #EE4777 193.38%);
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 9px;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: .3s ease-in-out;
    margin-bottom: 35px;
    pointer-events: all;
}
.btn:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(180deg, #EE4777 -81.62%, #EE4777 193.38%);
}
.btn:active {
    background: linear-gradient(0deg, rgba(50, 50, 50, 0.15), rgba(50, 50, 50, 0.15)), linear-gradient(180deg, #EE4777 -81.62%, #EE4777 193.38%);
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.btn.inactive {
    pointer-events: none;
    opacity: .7;
}
.item {
    max-width: 360px;
    width: 100%;
}
.item1 {
    width: 226px;
    margin: 0 auto;
}
.progress {
    position: relative;
    background-color: #EAEAEA;
    border-radius: 5px;
    height: 4px;
    width: 100%;
    margin-bottom: 12px;
}
.progress > div {
    transition: .3s ease-in-out;
    background-color: #EE4777;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 5px;
}
.btn_back {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(37, 37, 37, 0.75);;
    background-color: #E9E9E9;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s ease-in-out;
    margin-bottom: 25px;
}
.btn_back:hover {
    background-color: #EEEEEE;
}
.btn_back:active {
    background: #C7C7C7;
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.btn_back img {
    margin-right: 8px;
}
.name {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    color: #252525;
    text-align: center;
}
.input {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    width: 100%;
    height: 60px;
    text-align: center;
    margin-bottom: 20px;
}
.input::placeholder {
    transition: .2s ease-in-out;
}
.input:focus::placeholder {
    opacity: 0;
}
.hasheight, .hasweight {
    position: relative;
    padding-top: 10px;
}
.hasheight::before {
    content: 'Рост в сантиметрах';
    position: absolute;
    left: 0;
    top: -6px;
    font-size: 12px;
    opacity: 0;
    transition: .3s ease-in-out;
}
.hasweight::before {
    content: 'Вес в килограммах';
    position: absolute;
    left: 0;
    top: -6px;
    font-size: 12px;
    opacity: 0;
    transition: .2s ease-in-out;
}
.hasheight.active::before, .hasweight.active::before {
    opacity: 1;
}
.hasheight:has(> .input:focus)::before, .hasweight:has(> .input:focus)::before {
    opacity: 1;
}
.hidden {
    display: none;
}
.label {
    position: relative;
    padding: 16px;
    display: block;
    border-radius: 8px;
    transition: .3s ease-in-out;
    cursor: pointer;
    margin-bottom: 5px;
}
input:checked+.label {
    background-color: #fff;
}
.label_title {
    position: relative;
    padding-left: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
    margin-bottom: 9px;
}
.label_title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 25px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/calculator/circle.svg');
    background-size: cover;
}
.label_title::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 10px;
    top: 8px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/calculator/ok.svg');
    left: 5px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}
input:checked+label .label_title::after {
    opacity: 1;
}
.label_text {
    font-size: 16px;
    line-height: 20px;
    color: rgba(37, 37, 37, 0.75);
}
.label_text p {
    margin-bottom: 4px;
}
label:last-of-type {
    margin-bottom: 20px;
}
.calc_result {
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(194, 194, 194, 0.15);
    border-radius: 8px;
    padding: 8px 16px; 
}
.result {
    padding: 8px 16px;
    margin-bottom: 20px;
}
.res_item {
    border-bottom: 1px solid rgba(212, 212, 212, 0.5);
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #252525;
}
.calc_result .res_item:last-of-type, .result .res_item:last-of-type {
    border-bottom: none;
}

.banner {
    position: absolute!important;
    background: linear-gradient(180deg, #EE4777 -81.62%, #EE4777 193.38%);
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 15px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
}
.banner p {
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
}
.banner a {
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px 36px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    transition: .3s ease-in-out;
}
.banner a:hover {
    opacity: 0.8;
}
@media(max-width: 767px) {
    .banner {
        flex-direction: column;
        gap: 4px;
        padding: 10px 15px;
    }
    .banner p {
        font-size: 18px;
    }
    .calc {
        /* padding-top: 25px; */
        padding-bottom: 25px;
        padding-top: 110px;
    }
    .logo {
        margin-bottom: 15px;
    }
    .logo img {
        width: 155px;
        height: 45px;
    }
    .block {
        min-height: calc(100vh - 70px);
        padding: 0 15px;
    }
    .title {
        font-size: 44px;
        line-height: 44px;
    }
    .subtitle {
        font-size: 18px;
        line-height: 25px;
    }
}
@media(max-width: 400px) {
    .item1 {
        width: 100%;
    }
}