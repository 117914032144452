.inventory {
    background-color: #F3F3F3;
    padding: 75px 0;
}
.inventory_title {
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 23px;
}
.inventory_title span {
    color: var(--fat-red);
}
.inventory_subtitle {
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    margin-bottom: 55px;
}
.inventory_block {
    display: flex;
}
.inventory_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    text-align: center;
    margin-bottom: 20px;
}
.inventory_item:first-of-type img {
    margin-left: 25px;
}
.inventory_item p {
    font-size: 30px;
    line-height: 33px;
    margin-top: 30px;
}

@media(max-width: 991px) {
    .inventory_item p {
        font-size: 18px;
        line-height: 20px;
    }
}
@media(max-width: 767px) {
    .inventory {
        padding: 45px 0;
    }
    .inventory_title span {
        color: var(--fat-blue);
    }
    .inventory_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .inventory_subtitle {
        font-size: 18px;
        line-height: 22px;
    }
    .inventory_block {
        flex-wrap: wrap;
    }
    .inventory_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .inventory_title br {
        display: none;
    }
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
    .inventory_subtitle {
        font-size: 18px;
    }
    .inventory_item {
        margin-bottom: 40px;
    }
    .inventory_item img {
        max-height: 110px;
    }
    .inventory_item p {
        margin-top: 10px;
    }
    .inventory_item p br {
        display: none;
    }
}
@media(max-width: 374px) {
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
}