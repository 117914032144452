.threecomps {
    padding: 25px 0 75px;
}
.threecomps_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 45px;
}
.threecomps_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.threecomps_block {
    display: flex;
    gap: 30px;
}
.threecomps_item {
    padding: 35px 20px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    margin-bottom: 30px;
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    transition: .2s ease-in-out;
}
.threecomps_item:hover {
    transform: translateY(-10px);
}
.threecomps_item-img {
    width: 70px;
    margin: 0 auto 20px;
}
.threecomps_item-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 3px;
    text-align: center;
    padding: 0 15px;
}
.threecomps_item ul li {
    border-bottom: 1px solid #e1e1e1;
    padding: 7px 0;
    line-height: 1.4;
    opacity: 0.7;
    text-align: center;
}
.threecomps_item ul li:last-of-type {
    border-bottom: none;
}

@media(max-width: 991px) {
    .threecomps_title {
        font-size: 42px;
    }
    .threecomps_block {
        gap: 15px;
    }
    .threecomps_item {
        padding: 30px 10px;
    }
}
@media(max-width: 767px) {
    .threecomps_title {
        font-size: 36px;
    }
    .threecomps_block {
        flex-wrap: wrap;
    }
    .threecomps_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 30px 20px;
    }
}
@media(max-width: 575px) {
    .threecomps_title {
        font-size: 24px;
    }
    .threecomps_title span {
        padding-bottom: 5px;
    }
}