.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/promo.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 900px;
    padding-top: 70px;
}
.promo_block {
    position: relative;
}
.promo_pretitle {
    border-radius: 40px;
    background: #E8E8E8;
    backdrop-filter: blur(6.25px);
    margin-bottom: 45px;
    padding: 10px 20px;
    color: #242424;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 2px;
    text-transform: uppercase;
    max-width: max-content;
}
.promo_title {
    font-family: Unisans;
    margin-bottom: 15px;
    font-size: 55px;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: -0.4px;
    position: relative;
    width: max-content;
}
.promo_title b {
    color: #FF2E5B;
}
.promo_title span {
    position: absolute;
    right: -90px;
    top: 15px;
    color: #8256CF;
    font-family: Unisans;
    font-size: 54px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.2px;
}
.promo_text {
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 1.4;
}
.promo_list {
    margin-bottom: 30px;
}
.promo_list p {
    position: relative;
    padding-left: 28px;
    color: rgba(36, 36, 36, 0.8);
    font-size: 18px;
    line-height: 1.4;
}
.promo_list p::before {
    content: '';
    width: 19px;
    height: 19px;
    position: absolute;
    left: 0;
    top: 3px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"><path d="M9.5 0L9.97203 4.18611C10.2575 6.73282 12.2672 8.73966 14.8139 9.02797L19 9.5L14.8139 9.97203C12.2672 10.2575 10.2603 12.2672 9.97203 14.8139L9.5 19L9.02797 14.8139C8.74249 12.2672 6.73282 10.2603 4.18611 9.97203L0 9.5L4.18611 9.02797C6.73282 8.74249 8.73966 6.73282 9.02797 4.18611L9.5 0Z" fill="%238356CF"/></svg>');
}
.promo_btn {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 415px;
    height: 115px;
    padding: 10px;
    border-radius: 15px;
    background: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 15.07%, rgba(255, 255, 255, 0.20) 148.09%), #FF2E5B;
    box-shadow: 0px 4px 25.5px 0px rgba(255, 46, 91, 0.43);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    margin-bottom: 75px;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.promo_more {
    position: absolute;
    right: 0;
    top: 500px;
    display: flex;
    flex-direction: column;
    gap: 200px;
    /* max-width: 260px; */
}
.promo_remains_more {
    border-radius: 40px;
    background: rgba(234, 233, 235, 0.47);
    backdrop-filter: blur(6.25px);
    padding: 20px 24px;
}
.promo_remains_more p {
    font-size: 17px;
    line-height: 1.3;
    padding-top: 12px;
}
.promo_remains_more p b {
    font-weight: 600;
}
.promo_remains {
    display: flex;
    gap: 18px;
    flex-direction: column;
}
.promo_remains_text {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
}
.promo_remains_number {
    font-family: Unisans;
    font-size: 94px;
    font-weight: bold;
    line-height: 1;
}
.timer > div {
    display: flex;
    font-family: Unisans;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.1;
}
.timer > div span {
    margin-left: -4px;
    margin-right: 4px;
}
.mob {
    display: none!important;
}
.remains_place {
    font-family: Unisans;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.3px;
    margin-bottom: 3px;
}
.remains_place span {
    color: #FF2E5B;
}
.remains_text {
    color: #FF2E5B;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
}
@media(max-width:575px) {
    .promo {
        padding-top: 40px;
        height: auto;
        /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/promo_mob_bg.jpg'); */
        padding-bottom: 62px;
        background-image: none;
        overflow: hidden;
    }
    .promo_block {
        padding-left: 0;
    }
    .promo_pretitle {
        padding: 7px 30px;
        text-align: center;
        font-size: 17px;
        margin-bottom: 20px;
        /* max-width: 100%; */
    }
    .promo_title {
        font-size: 30px;
        width: auto;
    }
    .promo_more {
        position: relative;
        gap: 30px;
        top: 0;
        margin-top: 12px;
    }
    .promo_remains_more {
        display: flex;
        border-radius: 37px;
    }
    .promo_remains_more img {
        max-width: 64px;
        object-fit: contain;
    }
    .promo_remains_more p {
        padding-top: 0;
        padding-left: 12px;
        font-size: 16px;
    }
    .promo_remains {
        gap: 10px
    }
    .timer > div {
        justify-content: center;
        font-size: 50px;
    }
    .promo_title span {
        font-size: 32px;
        right: auto;
        left: 234px;
        top: 14px;
    }
    .promo_list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 85px;
    }
    .promo_list p {
        font-size: 14px;
        padding-left: 0;
        padding-top: 30px;
        max-width: 175px;
    }
    .promo_text {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 24px;
    }
    .promo_btn {
        width: 100%;
        height: 80px;
        font-size: 18px;
        margin-bottom: 25px;
    }
    .mob_btn {
        position: relative;
    }
    .remains_place {
        font-size: 20px;
        text-align: center;
    }
    .remains_text {
        font-size: 20px;
        text-align: center;
    }
    /* .mob_btn::before {
        content: '';
        position: absolute;
        right: -15px;
        bottom: 0;
        width: 222px;
        height: 389px;
        background-size: cover;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/promo_mob.png');
    } */
    .promo_remains_text {
        font-size: 20px;
        text-align: center;
    }
    .mob {
        display: block!important;
        position: relative;
        margin: -30px auto;
        max-width: 254px;
        max-height: 324px;
        /* max-width: 320px; */
        /* max-height: 404px; */
        pointer-events: none;
    }
}