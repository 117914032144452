.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/missbikini/promo_bg.jpg');
    background-size: cover;
    background-position: 50% 100%;
    padding: 100px 0 50px;
}
.header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.header div{
    font-family: Inter;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: -0.699999988079071px;
    text-align: left;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 6px;
}
.header_link {
    position: relative;
    font-size: 14px;
    line-height: 2.3;
    color: #fff;
    cursor: pointer;
}
.header_link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 1px;
    background-color: #4D4B52;
}

.main {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 27px; */
}
.left_main {
    /* max-width: 715px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.main_top {
    display: flex;
    margin-right: -62px;
    gap: 20px;
}
.main_top_left {
    max-width: 744px;
    /* padding: 30px 0px 40px; */
    /* background-color: #F5F5F5; */
    background-image: none;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 30px;
    position: relative;
}
/* .main_top_left::before {
    content: '';
    position: absolute;
    right: 60px;
    top: calc(100% - 1px);
    width: 54px;
    height: 29px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/method/separator.svg');
    pointer-events: none;
} */
.main_top_left_img img {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.main_top_left_img img:nth-of-type(2) {
    display: none!important;
}
.main_top_left_text {
    padding: 24px 40px 34px;
    font-size: 25px;
    line-height: 1.32;
    letter-spacing: -0.05rem;
}
.main_top_left_text span {
    font-weight: 600;
}
.main_top_right {
    flex: 0 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/method/purple_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding: 40px 48px;
    border-radius: 30px;
    position: relative;
}
.main_top_right::before {
    content: '';
    position: absolute;
    right: 16px;
    bottom: 0;
    width: 198px;
    height: 185px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/method/promo_clock.svg');
    pointer-events: none;
}
.main_top_right p {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
    line-height: 0.75;
    letter-spacing: -0.07rem;
}
.main_top_right div {
    font-size: 64px;
    line-height: 1.16;
    color: #fff;
    font-weight: 500;
    letter-spacing: -0.026rem;
    display: flex;
    align-items: baseline;
}
.main_top_right div span {
    position: relative;
    font-size: 24px;
    /* margin-top: -8px; */
}

.main_bottom {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-direction: column;
    margin-left: -30px;
    margin-top: -20px;
}
.main_bottom_left {
    /* max-width: 171px; */
    border-radius: 20px;
    background-color: #E5472F;
    /* width: 100%; */
    width: max-content;
    display: flex;
    position: relative;
    padding: 17px 36px 17px 84px;
    /* justify-content: center; */
    margin-right: 40px;
}
.cheked_voice {
    border-radius: 20px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    margin-left: -130px;
}
.cheked_voice .mbl_2{
/* color: #fff;
font-size: 30px;
line-height: 1.4;
padding-left: 15px;
padding-right: 15px;
text-align: left;
text-transform: uppercase;
display: flex;
align-items: center; */
}
.main_bottom_left img {
    position: absolute;
    left: 0;
    top: 0;
}
.main_bottom_left_t1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 0.5;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin-bottom: 140px;
}
.main_bottom_left_t2 {
    font-size: 110px;
    font-weight: 500;
    line-height: 1.12;
    letter-spacing: -0.058rem;
}
.main_bottom_left_t3 {
    font-size: 38px;
    line-height: 0.35;
    letter-spacing: -0.012rem;
}
.main_bottom_right {
    flex: 1;
    border-radius: 30px;
    background: #F5F5F5;
    position: relative;
    padding: 20px 30px 30px;
}
.main_bottom_right_title {
    margin-bottom: 14px;
    font-size: 80px;
    font-weight: 500;
    line-height: 0.95;
    letter-spacing: -0.025rem;
    position: relative;
    z-index: 1;
}
.main_bottom_right_text {
    font-size: 22px;
    line-height: 1.37;
    letter-spacing: -0.07rem;
    margin-bottom: 36px;
    position: relative;
    z-index: 1;
}
.main_bottom_right_btn {
    border-radius: 18px;
    background-color: #764BFF;
    display: flex;
    gap: 12px;
    padding: 22px 70px 22px 26px;
    width: max-content;
    cursor: pointer;
    margin-bottom: -49px;
    position: relative;
    z-index: 1;
    transition: .2s ease-in-out;
}
.main_bottom_right_btn p {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.05;
    letter-spacing: -0.04rem;
}
.main_bottom_right_btn p:nth-of-type(2) {
    display: none;
}
.main_bottom_right_btn:hover {
    transform: translateY(-4px);
}
.main_bottom_right_img {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
}
.top_fin {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/Promo_finish.png');
    width: 468px;
    height: 675px;
    border-radius: 39px;

}


.mob {
    display: none;
}

.mtl_1 {
    margin-bottom: 20px;
    font-weight: 900;
    font-size: 90px;
    line-height: 1;
    color: #fff;
    letter-spacing: -1.6px;
    font-family: Unisans;
    text-transform: uppercase;
    position: relative;
}
.mtl_1 span {
    position: absolute;
    right: 80px;
    margin-left: -50px;
    top: 16px;
    /* background: linear-gradient(90deg, #FFE 23.53%, #FFE482 62.05%); */
    background-color: #E5472F;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 63px;
    color: #fff;
}
.mtl_2 {
    font-size: 25px;
    line-height: 1.4;
    letter-spacing: -0.8px;
}
.mbl_1 {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    color: #EFEFEF;
}
.mbl_2 {
    text-transform: uppercase;
    font-family: Manrope;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 0.04em;
    color: #E4E4E4;
}

.mbr {
    display: flex;
    gap: 30px;
}
.mbr_item {
    flex: 0 1 240px;
    max-width: 240px;
}
.mbr_0 {
    height: 116px;
    margin-left: -25px;
}
.mbr_1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 8px;
}
.mbr_2 {
    font-size: 17px;
    line-height: 1.2;
}
.mob2 {
    display: none;
}
.title_banner {
    margin: 48px 0px 48px;
    text-transform: uppercase;
    font-family: Inter;
    font-size: 52px;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    color: #fff;
}



.promo_block {
    display: flex;
    border-radius: 24px;
    background: rgba(34, 37, 47, 0.85);
    backdrop-filter: blur(7.5px);
    padding: 40px 50px;
    gap: 32px;
}
.promo_item {
    flex: 1;
}
.promo_item_number {
    margin-top: -60px;
    margin-bottom: 20px;
    width: 40px;
    margin-left: 95px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 8px solid #60862F21;
    background: #60862F;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    animation: promo_pulse__Ymbp- 1.5s infinite;
    box-shadow: 0px 0px 0px rgba(32, 49, 0, 0.13);
}
@keyframes pulse {
    0% {
    }
    70% {
        box-shadow: 0 0 0 16px  rgba(32, 49, 0, 0.13);
    }
    100% {
        box-shadow: 0 0 0 0  rgba(32, 49, 0, 0.13);
    }
}
.promo_item_text {
    font-size: 18px;
    line-height: normal;
    color: #E4E4E4;
}
.promo_item_text b {
    font-weight: 600;
}
.promo_item_tags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: 12px;
}
.promo_item_tags p {
    flex: 0 1 calc(50% - 2px);
    max-width: calc(50% - 2px);
    color: #E4E4E4;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 9px;
    border-radius: 21.937px;
    background: #11111A;
    text-align: center;
}
.prize {
    border-radius: 24px;
    background: rgba(34, 37, 47, 0.85);
    backdrop-filter: blur(7.5px);
    padding: 24px;
    position: relative;
    max-width: 560px;
    margin: 10px 0 50px 30px;
}
.prize img {
    position: absolute;
    right: 0;
    bottom: 0;
}
.prize div {
    border-radius: 21.937px;
    background: #11111A;
    padding: 4px 10px;
    font-size: 30px;
    color: #E4E4E4;
    font-weight: 600;
    max-width: max-content;
    margin-bottom: 12px;
}
.prize_item{
    border-radius: 30px;
    background: #11111A;
    padding: 11px 27px;
    font-size: 20px;
    color: #E4E4E4;
    font-weight: 600;
    max-width: max-content;
    /* margin-bottom: 12px; */
}
.promo_item img {
        position: absolute;
        right: -48px;
        margin-top: -220px;
}
.prize div:nth-of-type(2) {
    font-size: 22px;
}
.prize div:nth-of-type(3) {
    font-size: 16px;
    margin-bottom: 0;
}
.header_logo {
    color: #fff;
    font-size: 28px;
}

@media(max-width:575px) {
    .header_logo {
        color: #fff;
        font-size: 18px;
    }
    .prize {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 30px;
        padding: 16px;
        overflow: hidden;
    }
    .prize div:nth-of-type(1) {
        font-size: 26px;
    }
    .prize div:nth-of-type(2) {
        font-size: 22px;
    }
    .prize img {
        position: absolute;
        right: -28px;
        bottom: -30px;
        max-width: 150px;
    }
    .promo_item img {
        position: absolute;
        right: 50%;
        margin-top: 32px;
        margin-right: -70px;
        width: 140px;
        height: 140px;
        opacity: 0;
    }
    .promo_block {
        flex-direction: column;
        padding: 30px 22px 40px;
        gap: 0;
    }
    .promo_item {
        display: flex;
        margin-bottom: 24px;
        position: relative;
        align-items: center;
        gap: 14px;
    }
    .promo_item:last-of-type {
        margin-bottom: 0;
    }
    .promo_item_number {
        flex: 1 1 34px;
        max-width: 34px;
        margin-top: 0;
        width: 34px;
        height: 34px;
        font-size: 16px;
        position: relative;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    .promo_item::before {
        display: none;
        content: '';
        position: absolute;
        left: 16px;
        top: 44px;
        width: 1px;
        height: calc(100% - 29px);
        border: 1px dashed #535868;
    }
    .promo_item:last-of-type::before {
        content: none;
    }
    .promo_item_text {
        font-size: 16px;
        flex: 1;
        padding-left: 15px;
    }
    .promo {
        height: auto;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/missbikini/promo_mob_bg.jpg');
        padding: 50px 0;
    }
    .mtl_1 {
        position: relative;
    }
    .mtl_1::before {
        content: '';
        position: absolute;
        left: 30%;
        top: 90%;
        margin-left: 0px;
        width: 332px;
        height: 432px;
        background-size: contain;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/missbikini/promo_mob.png');
        pointer-events: none;
    }
    .mob {
        margin-top: -90px;
    }
    .header {
        margin-top: 24px;
        display: none;
    }
    .header div{
        margin-left: 0px;  
        display: flex;
    }
    .header_logo span{
        display: flex;
        max-width: 240px;
    }
    .main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .prize_item {
        margin-bottom: 0px;
        width: 240px;
        font-size: 18px;
        max-width: none;
    }
    .main_bottom_left img {
        width: 60px;
    }
    .left_main {
        width: 100%;
        padding-right: 0px;
        align-items: flex-start;
    }
    .cheked_voice .mbl_2{
        font-size: 18px;
        padding-left: 0px;
        padding-right: 0px;
        max-width: 175px;
    }
    .cheked_voice img {
        /* width: 140px; */
        margin-left: -25px;
    }
    .main_bottom {
        margin: 0;
    }
    .cheked_voice {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0px;
    }
    .main_bottom_left {
        padding: 15px 22px 15px 70px;
        width: 100%;
        
    }
    .top_fin {
        background-position: top;
        background-size: cover;
        width: 100%;
        height: 450px;
        border-radius: 39px;
    }
    .promo_item_tags p {
        flex: 0 1 100%;
        max-width: 100%;
        max-width: max-content;
        text-align: left;
    }
    .title_banner {
        margin: 48px 0px 0px;
        text-transform: uppercase;
        font-family: Inter;
        font-size: 30px;
        font-weight: 500;
        line-height: 1.1;
        text-align: center;
        color: #fff;
    }
    .mtl_1 {
        font-size: 48px;
        margin-bottom: 0px;
        width: 100%;
    }
    .mtl_1 span {
        padding: 6px 12px;
        right: auto;
        font-size: 43px;
        left: 171px;
        margin-left: 0;
        top: 5px;
    }
    .mtl_2 {
        font-size: 18px;
    }
    .mbr {
        display: none;
    }
    .mbl_1 {
        font-size: 16px;
        max-width: 175px;
    }
    .mbl_2 {
        font-size: 19px;
    }
    .mob2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .mob2_i {
        background-color: #F6F6F5;
        padding: 16px;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
    }
    .mbr_0 {
        position: absolute;
        margin-left: 0;
        right: 0;
        top: -45px;
        height: auto;
    }
    .mbr_1 {
        font-size: 24px;
        margin-bottom: 12px;
    }
    .mbr_2 {
        font-size: 17px;
    }
    
    .main_top_right div span {
        font-size: 10px;
    }
    /* .header,
    .main_top_right,
    .main_bottom_left,
    .main_bottom_right_btn p:nth-of-type(1),
    .main_top_left_img img:nth-of-type(1),
    .main_bottom_right_img,
    .main_bottom_right {
        display: none!important;
    } */
    .main_top_left,
    .main_bottom_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .main_bottom_right_btn p:nth-of-type(2) {
        display: block;
    }
    .main_bottom_right_btn p br {
        display: block!important;
    }
    .main_top_left_img img:nth-of-type(2) {
        display: inline-block!important;
    }
    .main_top_left_text {
        padding: 18px 20px;
        font-size: 16px;
        line-height: 1.4;
    }
    .main_bottom_right {
        padding: 24px 20px;
    }
    .main_bottom_right_title {
        font-size: 50px;
        line-height: 1;
        margin-bottom: 10px;
    }
    .main_bottom_right_text {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 1.4;
    }
    .main_bottom_right_btn {
        width: 100%;
        margin-bottom: 0;
        padding: 20px 24px;
        align-items: center;
    }
    .main_bottom_right_btn p {
        font-size: 22px;
    }
    .mob {
        display: flex;
        gap: 18px;
        margin-bottom: 20px;
    }
    .mob_left {
        flex: 0 1 153px;
        max-width: 153px;
    }
    .mob_right {
        flex: 1;
        background-color: #F5F5F5;
        border-radius: 22px;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        min-width: 210px;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/method/promo_main.png');
        background-size: cover;
        background-position: 50% 50%;
    }
    .mob .main_bottom_left {
        display: block!important;
    }
    .mob .main_bottom_left_t1 {
        margin-bottom: 40px;
        font-size: 15px;
    }
    .mob .main_bottom_left_t2 {
        font-size: 95px;
    }
    .mob .main_bottom_left_t3 {
        font-size: 32px;
    }
    .mob .main_top_right {
        display: block!important;
        max-width: 100%;
        background-image: none;
        border: 2px solid #764BFF;
        border-radius: 20px;
        padding: 17px 20px;
    }
    .mob .main_top_right::before {
        content: none;
    }
    .mob .main_top_right p {
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.057rem;
    }
    .mob .main_top_right div {
        font-size: 25px;
        line-height: normal;
    }
}

@media(max-width:411px) {
    .mob_right {
        min-width: 0;
    }
    .mob .main_top_right {
        padding: 15px;
    }
    .mtl_1 {
        font-size: 40px;
    }
    .prize_item {
        width: 200px;
        font-size: 14px;
    }
}
@media(max-width:344px) {
    .main_bottom_right_btn p {
        font-size: 18px;
    }
    .mtl_1 {
        font-size: 37px;
    }
    .mtl_1 span {

        font-size: 30px;
        left: 171px;
    }
}
@media(max-width:339px) {
    .main_bottom_right_title {
        font-size: 42px;
    }
    .mtl_1::before {
        left: 20%;
    }
}