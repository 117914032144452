.inventory {
    background-color: var(--gray-bg);
    padding: 60px 0 45px;
}
.inventory_title {
    font-family: Bebas;
    font-size: 52px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}
.inventory ul {
    flex: 0 1 50%;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
}
.inventory ul li {
    border-bottom: 1px solid #E4E8F1;
    padding: 7px 0;
    font-size: 18px;
    line-height: 1.55;
}
.inventory ul li:last-of-type {
    border-bottom: none;
}

@media(max-width: 991px) {
    .inventory ul {
        flex: 0 1 70%;
        max-width: 70%;
    }
}
@media(max-width: 767px) {
    .inventory ul {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .inventory_title {
        font-size: 38px;
    }
}