.faq {
    padding: 65px 0 80px;
    background-color: #F4F4F4;
}
.faq_title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 800;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: center;
}
.accordion {
    max-width: 760px;
    margin: 0 auto;
}
.question {
    padding: 17px 50px 20px 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 0px 32px rgba(170, 170, 170, 0.1);
    background-color: #fff;
    border-radius: 10px;
    transition: .2s ease-in-out;
}
.question::before {
    content: '+';
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 20px;
    font-size: 40px;
    font-weight: normal;
}
.active .question::before {
    content: '-';
    margin-top: -14px;
}
.active .question, .question:hover {
    color: #fff;
    background-color: #F7720C;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 24px;
    padding-left: 30px;
    transition: .2s ease-in-out;
}
.active .answer {
    padding-top: 14px;
    max-height: 400px;
    padding-bottom: 25px;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}
.answer a {
    color: #F7720C;
    font-weight: 500;
}
.btn {
    width: 585px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(89.69deg, #26CBAD 14.2%, #0CA488 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    margin: 30px auto 0;
    transition: .3s ease-in-out;
}
.btn::before {
    content: '';
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.6);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.btn:active::before {
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4), inset 0px 4px 4px #24B79C;
}
.btn:hover {
    transform: translateY(-6px);
}
.btn:hover::before {
    opacity: 1;
}
.btn p {
    position: relative;
}

@media(max-width: 767px) {
    .faq {
        padding: 45px 0;
    }
    .faq_title {
        font-size: 32px;
        line-height: 36px;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
    }
    .btn {
        width: 100%;
    }
}
@media(max-width: 575px) {
    .question {
        padding: 10px 40px 10px 15px;
    }
    .answer {
        padding-left: 15px;
    }
}
@media(max-width: 374px) {
    
}