.preims {
    padding-bottom: 100px;
}
.block {
    display: flex;
    align-items: flex-end;
}
.left {
    position: relative;
    margin-right: -80px;
    z-index: 1;
}
.right {
    flex: 0 1 707px;
    max-width: 707px;
    position: relative;
}
.title {
    font-size: 55px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 12px;
}
.title p {
    background-color: #F6FAFF;
    padding: 8px 14px;
    border-radius: 25px;
    max-width: max-content;
}
.title p:last-of-type {
    border: 1px solid #333844;
}
.text {
    margin-bottom: 45px;
    font-size: 24px;
    line-height: 1.4;
}
.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
    background: linear-gradient(276.43deg, #E92A91 -51.29%, #FA65B5 131.26%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 40px;
    cursor: pointer;
}
.btn:hover {
    transform: translateY(-4px);
}
.btn_off {
    pointer-events: none!important;
    filter: grayscale(1);
}
.remains {
    position: absolute;
    bottom: 120px;
    left: 150px;
}
.right img {
    /* margin-left: -100px; */
}
.remains p {
    margin-bottom: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    line-height: 1;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}
.remains span {
    color: #fff;
    font-size: 140px;
    line-height: 1;
    letter-spacing: -3.73px;
}
.right .btn {
    display: none;
}

@media(max-width: 575px) {
    .preims {
        padding-bottom: 60px;
    }
    .block {
        flex-wrap: wrap;
    }
    .left {
        margin-left: 0;
        flex: 0 1 100%;
        max-width: 100%;
    }
    .right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .title {
        font-size: 26px;
    }
    .text {
        font-size: 18px;
        margin-bottom: 6px;
    }
    .btn {
        font-size: 18px;
        padding: 20px;
        display: none;
        max-width: 100%;
        margin-top: 15px;
    }
    .right .btn {
        display: flex;
    }
    .remains {
        bottom: 50%;
        margin-bottom: 5px;
        left: 15px;
    }
    .remains p {
        font-size: 13px;
    }
    .remains span {
        font-size: 76px;
    }
}