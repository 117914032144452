.tabs {
    padding: 90px 0 50px;
}
.t1 {
    text-align: center;
    margin-bottom: 30px;
    font-family: Actay;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.1;
}
.tabs_items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 50px;
}
.tabs_item {
    border-radius: 64px;
    border: 2px solid #EDEDED;
    padding: 14px 30px;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 1.2;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.tabs_item_active {
    background-color: #FB4F54;
    border-color: #FB4F54;
    color: #fff;
}
.content {
    display: flex;
    gap: 60px;
    flex-direction: column;
    margin-bottom: 60px;
}
.content_item {
    display: flex;
    gap: 30px;
}
.content_item_img {
    flex: 0 1 458px;
    max-width: 458px;
}
.content_item_info {
    flex: 1;
}
.content_item_title {
    margin-bottom: 20px;
    font-family: Actay;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.1;
}
.content_item_text {
    font-size: 20px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.content_more {
    text-align: center;
    font-size: 23px;
    font-family: Actay;
    font-weight: bold;
    line-height: 1.1;
}

@media(max-width: 991px) {
    .content_item {
        flex-direction: column;
    }
    .content_item_img {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .tabs {
        padding: 80px 0 50px;
    }
    .t1 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .slider {
        display: flex;
        flex-direction: column;
        overflow: visible;
    }
    .slider > div:nth-of-type(1) {
        order: 2;
    }
    .slider_nav {
        display: flex;
        justify-content: center;
        gap: 16px;
        padding-top: 20px;
    }
    .slider_btn_prev {
        width: 52px;
        height: 52px;
        background-size: cover;
        background-image: url('data:image/svg+xml,<svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.7298" cy="25.7298" r="24.6578" transform="matrix(-1 0 0 1 51.459 0)" stroke="%23FB4F54" stroke-width="2.14415"/><path d="M31.0898 12.8649L17.6889 26.2658L31.0898 39.6668" stroke="%23FB4F54" stroke-width="2.14415"/></svg>');
    }
    .slider_btn_next {
        width: 52px;
        height: 51px;
        background-size: cover;
        background-image: url('data:image/svg+xml,<svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="26.2709" cy="25.7298" r="24.6578" stroke="%23FB4F54" stroke-width="2.14415"/><path d="M20.9102 12.8649L34.3111 26.2658L20.9102 39.6668" stroke="%23FB4F54" stroke-width="2.14415"/></svg>');
    }
    .content_item_title {
        font-size: 30px;
    }
    .tabs_items {
        gap: 5px;
        margin-bottom: 25px;
    }
    .tabs_item {
        font-size: 13px;
        padding: 11px 13px 8px;
        line-height: normal;
    }
}