.gifts {
    overflow: hidden;
    padding: 50px 0;
}
.titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
}
.gifts_pretitle {
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.42;
    letter-spacing: 0.12rem;
    color: #EFBD6A;
}
.gifts_title {
    text-transform: uppercase;
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.gifts_subtitle {
    font-size: 20px;
    line-height: 1.4;
    color: #6A6A6A;
}
.navigation {
    display: flex;
    gap: 16px;
}
.gifts_date {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}
.gifts_date p {
    font-size: 20px;
    line-height: 1.08;
    letter-spacing: 0.02rem;
    color: #C1C1C1;
    padding-left: 13px;
}
.gifts_date p b {
    font-weight: 600;
    color: #fff;
}
.gifts_block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 45px;
}
.gifts_item {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #242323;
}
.gifts_item:nth-of-type(13) {
    margin-bottom: 50px;
    padding-bottom: 0;
    border-bottom: none;
}
.gifts_item_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}
.gifts_item_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #fff;
    padding-right: 20px;
}
.gifts_item_name span {
    border-radius: 4px;
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #D22731;
    font-size: 14px;
    line-height: 1;
    padding: 4px 6px;
    margin-left: 5px;
}
.gifts_item_price {
    font-size: 18px;
    line-height: 1.4;
    color: #EFBD6A;
    font-weight: 600;
    text-decoration: line-through;
}
.gifts_item_text {
    font-size: 14px;
    line-height: 1.4;
    color: #C1C1C1;
    padding-right: 85px;
}
.gifts_btn {
    width: 100%;
    height: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Manrope;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.12rem;
    border-radius: 8px;
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #D22731;
    box-shadow: 0px 19px 32px 0px rgba(210, 39, 49, 0.45), 0px 4px 0px 0px #C0242D;
    cursor: pointer;
    position: relative;
    padding: 0 40px;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
}
.gifts_btn:hover {
    transform: translateY(-4px);
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #E0404A;
    box-shadow: 0px 19px 32px 0px rgba(210, 39, 49, 0.66), 0px 4px 0px 0px #C0242D;
}
.gifts_btn:active {
    background: #C83942;
    box-shadow: 0px 19px 32px 0px rgba(210, 39, 49, 0.45), 0px 4px 0px 0px #C0242D;
}
.gifts_title2 {
    font-family: InterT;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.2;
}
.gifts_subtitle2 {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
    color: #C1C1C1;
}
.gifts_subtitle2 span {
    color: #F77C42;
    text-transform: uppercase;
    font-weight: bold;
}
.gifts_subtitle2 em {
    font-style: normal;
    text-decoration: line-through;
    color: #818181;
}

.block2 {
    /* display: none!important; */
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
.slide {
    width: 380px!important;
    /* padding-left: 10px; */
}
.slide_block {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 24px;
    border-radius: 24px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
    height: auto;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    min-height: 164px;
}
.slide_img {
    flex: 0 1 110px;
    max-width: 110px;
    margin-bottom: 0;
}
.slide_info {
    flex: 1;
    order: 2;
}
.slide_info p {
    color: #565656;
    font-size: 18px;
    max-width: 235px;
    font-weight: 500;
    line-height: 1.3;
    /* min-height: 104px; */
}
.slide_info span {
    display: flex;
    margin-bottom: 12px;
    background-color: #C0D5E2;
    border-radius: 65px;
    padding: 8px 12px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    max-width: max-content;
}
.slider_btn_prev, .slider_btn_next {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #ED3046;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.slider_btn_prev:hover, .slider_btn_next:hover {
    opacity: .8;
}
.slider_btn_prev img, .slider_btn_next img {
    transition: .3s ease-in-out;
}
.slider_btn_next {
    transform: rotate(180deg);
}
.block {
    display: flex;
    align-items: center;
    gap: 40px;
}
.swiper {
    flex: 1;
}

.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 18px;
    background-color: #ED3046;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    min-height: 99px;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 40px;
    cursor: pointer;
    margin: 40px auto;
    justify-content: center;
}
.btn:hover {
    transform: translateY(-4px);
}
.promo_countdown p {
    text-align: center;
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 1.3;
    color: #565656;
}
.promo_countdown_timer > div {
    display: flex;
    justify-content: center;
    gap: 3px;
    font-weight: 600;
    color: #ED3046;
    font-size: 38px;
    line-height: 1;
}
.promo_countdown_timer > div div {
    display: flex;
    gap: 3px;
}

@media(max-width: 575px) {
    .gifts {
        padding: 40px 15px;
    }
    .titles {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 25px;
    }
    .gifts_pretitle {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .gifts_title {
        font-size: 22px;
        margin-bottom: 16px;
    }
    .gifts_subtitle {
        margin-bottom: 14px;
        font-size: 18px;
    }
    .promo_btn {
        text-align: center;
        font-size: 18px;
    }
    .gifts_date p {
        font-size: 18px;
        padding-left: 10px;
    }
    .gifts_item_name {
        font-size: 16px;
        padding-right: 5px;
    }
    .gifts_item_name span {
        font-size: 12px;
        margin-left: 0px;
    }
    .gifts_item_price {
        font-size: 16px;
    }
    .gifts_item_text {
        font-size: 14px;
        padding-right: 60px;
    }
    .gifts_btn {
        font-size: 18px;
        text-align: center;
        padding: 0 10px;
    }
    .gifts_title2 {
        font-size: 24px;
        margin-bottom: 16px;
    }
    .gifts_title2 span {
        display: block;
        margin-bottom: 8px;
        font-size: 20px;
        color: #676C72;
    }
    .gifts_subtitle2 {
        margin-bottom: 30px;
        font-size: 18px;
        color: #fff;
    }
    .gifts_item:nth-of-type(13) {
        margin-bottom: 20px;
    }
    .gifts_block {
        flex-wrap: wrap;
        gap: 12px;
        margin-bottom: 30px;
    }
    .slider_nav {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
    }
    .slide {
        width: 100%!important;
        padding-left: 0;
    }
    .slide_info p {
        font-size: 14px;
        min-height: auto;
        max-width: calc(100% - 130px);
    }
    .slide_info span {
        font-size: 12px;
    }
    .block {
        gap: 10px;
    }
    .btn {
        padding: 20px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    .block2 {
        display: flex!important;
        flex-direction: column;
        gap: 15px;
    }
    .slide_block {
        /* padding: 20px 15px;
        position: relative;
        background-color: #F4F4F4;
        border-color: #E5E5E5;
        overflow: hidden;
        border-radius: 20px;
        min-height: 164px;
        flex: 0 1 100%;
        max-width: 100%; */
    }
    .slide_img {
        position: absolute;
        right: 0;
        top: 0;
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 0;
    }
}