.code {
    position: relative;
    padding: 40px 0 100px;
}
.code_title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 52px;
    line-height: 1.1;
    margin-bottom: 12px;
    color: #111927;
}
.code_title span {
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.code_subtitle {
    text-align: center;
    margin-bottom: 45px;
    font-family: Roboto;
    font-size: 24px;
    line-height: 1.4;
    color: rgba(77, 87, 97, 0.75);
}
.code_block {
    display: flex;
    justify-content: center;
    gap: 80px;
}
.code_item {
    flex: 0 1 320px;
    max-width: 320px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.code_item::before {
    content: '';
    position: absolute;
    left: calc(50% + 30px);
    top: 55px;
    width: 100%;
    height: 1px;
    border: 1px dashed #A9ADB2;
    pointer-events: none;
}
.code_item:last-of-type:before {
    content: none;
}
.code_item_img {
    position: relative;
}
.code_item_number {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background-color: #EE4777;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -25px;
    margin-bottom: 20px;
    position: relative;
}
.code_item_text {
    font-size: 16px;
    font-family: Roboto;
    color: rgba(77, 87, 97, 0.75);
    line-height: 1.4;
}
.code_item_text b {
    font-size: 20px;
    font-weight: 600;
    color: #111927;
    display: block;
}
@media(max-width:991px) {
    .code_block {
        gap: 20px;
    }
}
@media(max-width:575px) {
    .code {
        padding: 20px 0 70px;
    }
    .code_title {
        font-size: 26px;
    }
    .code_subtitle {
        font-size: 16px;
    }
    .code_block {
        gap: 80px;
        flex-direction: column;
    }
    .code_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .code_item::before {
        height: 60px;
        width: 1px;
        top: calc(100% + 10px);
        left: 50%;
    }
}
@media(max-width:346px) {
    .code_item_text b {
        font-size: 18px;
    }
}