.logos {
    padding: 100px 0;
}
.logos_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 73px;
}
@media(max-width: 991px) {
    .logos_block {
        justify-content: center;
    }
}
@media(max-width: 767px) {
    .logos {
        padding: 60px 0 40px;
    }
    .logos_block {
        gap: 40px;
        position: relative;
        z-index: 1;
    }
}
@media(max-width: 575px) {
    .logos_block {
        gap: 30px;
    }
}