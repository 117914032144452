.healthymama {
    background-color: var(--main-bg);
    padding: 75px 0;
}
.healthymama_title {
    font-size: 48px;
    margin-bottom: 10px;
    line-height: 1.23;
    font-weight: bold;
    text-align: center;
}
.healthymama_subtitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 40px;
}
.healthymama_block {
    display: flex;
    flex-wrap: wrap;
}
.healthymama_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 35px;
    text-align: center;
}
.healthymama_item img {
    width: 78px;
    margin-bottom: 10px;
}
.healthymama_item p {
    font-size: 20px;
    line-height: 1.35;
    font-weight: 500;
    margin-bottom: 20px;
}
.healthymama .main_btn {
    min-width: 770px;
    margin: 30px auto 0;
    display: block;
}

@media(max-width: 991px) {
    .healthymama_title {
        font-size: 42px;
    }
    .healthymama_item {
        flex: 0 1 50%;
        max-width: 50%;
    }
    .healthymama .main_btn {
        min-width: 270px;
    }
    .healthymama_block {
        margin-top: 40px;
    }
}
@media(max-width: 767px) {
    .healthymama_title {
        font-size: 36px;
    }
    .healthymama_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 10px 35px;
    }
    .healthymama_item p br {
        display: none;
    }
    .healthymama_item img {
        margin-bottom: 5px;
    }
}
@media(max-width: 757px) {
    .healthymama_title {
        font-size: 24px;
    }
    .healthymama_subtitle {
        font-size: 20px;
    }
    .healthymama_item p {
        font-size: 18px;
    }
}