.block4 {
  padding: 30px 0 45px;
}
.b1 {
  margin-bottom: 60px;
}
.b1_t0 {
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  color: #7d93d7;
  line-height: 1.4;
  letter-spacing: 1.08px;
  margin-bottom: 16px;
}
.b1_t1 {
  text-align: center;
  font-weight: 500;
  font-size: 44px;
  line-height: 1.3;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}
.b1_t1 span {
  background-color: #f6faff;
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}
.b1_t2 {
  color: #717da1;
  text-align: center;
  margin-bottom: 25px;
  font-size: 21px;
  line-height: 1.4;
  letter-spacing: -0.42px;
}

.b2 {
  margin-bottom: 75px;
  border-radius: 40px;
  background: linear-gradient(
    137deg,
    #d689ff 17.94%,
    #b4a6ff 47.7%,
    #a6c1ff 82.06%
  );
  padding: 55px 85px 60px;
  position: relative;
  overflow: hidden;
}
.b2::before {
  content: "";
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 745px;
  height: 457px;
  background-image: url("https://gymteam.kinescopecdn.net/img/l/2024/um2/gift_b2.png");
  pointer-events: none;
}
.b2_t1 {
  margin-bottom: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 26px;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  position: relative;
}
.b2_t2 {
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.642px;
  position: relative;
}
.b2_t3 {
  margin-bottom: 35px;
  color: #fff;
  font-size: 21px;
  line-height: 1.4;
  letter-spacing: -0.42px;
  position: relative;
}
.b2_t3 b {
  font-weight: 600;
}
.b2_t4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.36px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 85px;
  background: rgba(255, 255, 255, 0.09);
  max-width: max-content;
  position: relative;
}

.b3 {
}
.b3_t0 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 23px;
  color: #86a4ff;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 1.3px;
}
.b3_t1 {
  text-align: center;
  border-radius: 104px;
  background: #ffebf6;
  padding: 10px 20px;
  color: #ef3f9e;
  font-size: 21px;
  line-height: 1.4;
  letter-spacing: -0.42px;
  max-width: max-content;
  margin: 0 auto 16px;
}
.b3_t2 {
  margin-bottom: 16px;
  text-align: center;
  color: #333844;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.b3_t3 {
  margin-bottom: 40px;
  color: #717da1;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.4px;
  text-align: center;
}
.b3_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}
.b3_item {
  flex: 0 1 360px;
  max-width: 360px;
  padding: 22px;
  border-radius: 20px;
  border: 1px solid #dbe8f8;
  background: #ebf1fa;
}
.b3_item_t0 {
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #b9c9e1;
  font-weight: 600;
  letter-spacing: 0.48px;
}
.b3_item_t1 {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.22;
  color: #717da1;
  font-weight: 500;
}
.b3_item_t2 {
  color: #717da1;
  font-size: 14px;
  line-height: 1.36;
}

.sticky {
  display: flex;
  gap: 35px;
  justify-content: space-between;
}
.sticky_left {
  flex: 0 1 635px;
  max-width: 635px;
}
.sticky_right {
  flex: 0 1 505px;
  max-width: 505px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.sticky_sticky {
  position: sticky;
  top: 20px;
}
.pre_1 {
  margin-bottom: 35px;
  text-transform: uppercase;
  font-size: 26px;
  color: #86a4ff;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 1.3px;
}
.s1 {
  margin-bottom: 16px;
  color: #ef3f9e;
  font-size: 21px;
  border-radius: 104px;
  background: #ffebf6;
  padding: 10px 20px;
  line-height: 1.4;
  letter-spacing: -0.42px;
  max-width: max-content;
}
.s2 {
  margin-bottom: 16px;
  color: #333844;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.s3 {
  color: #717da1;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 44px;
}
.promo_btn {
  transition: 0.2s ease-in-out;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 70px;
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.44px;
  padding: 20px 35px;
  cursor: pointer;
  min-width: 340px;
  margin: 0 auto;
}
.promo_btn:hover {
  transform: translateY(-4px);
}
.sticky_item {
  border-radius: 20px;
  border: 1px solid #dbe8f8;
  background: #ebf1fa;
  padding: 28px;
  min-height: 181px;
  position: relative;
  overflow: hidden;
}
.sticky_item span {
  display: block;
  margin-bottom: 10px;
  color: #b9c9e1;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}
.sticky_item p {
  font-size: 20px;
  line-height: 1.22;
  color: #717da1;
  max-width: 245px;
}
.sticky_item img {
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.b1_mob {
  display: none;
}
.st_mob {
  display: none;
}
.infos {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #37417c;
  line-height: 1.2;
}
.timer1 > div {
  display: flex;
  font-weight: 500;
  color: #f34fa7;
  font-size: 57px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -1px;
  justify-content: center;
}
.timer1 > div span {
  margin-right: 5px;
  margin-left: -10px;
}
@media (max-width: 575px) {
  .timer1 > div span {
    margin-left: -2px;
    margin-right: 5px;
  }
  .timer1 > div {
    font-size: 34px;
  }
  .infos {
    font-size: 18px;
    display: none;
  }
  .st_mob .infos {
    display: block;
    text-align: center;
  }
  .st_mob .timer1 {
    display: flex;
    justify-content: center;
  }
  .block4 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .t1 {
    font-size: 30px;
    margin-bottom: 24px;
  }
  .sticky {
    flex-direction: column;
  }
  .s1 {
    font-size: 18px;
    margin-bottom: 24px;
    padding: 4px 10px;
  }
  .s2 {
    margin-bottom: 15px;
    font-size: 24px;
  }
  .s3 {
    margin-bottom: 0;
    font-size: 16px;
  }
  .promo_btn {
    width: 100%;
    font-size: 19px;
    /* display: none; */
    min-width: 0;
    padding: 20px;
    margin-bottom: 16px;
  }
  .st_mob {
    display: block;
  }
  .st_mob .promo_btn {
    display: flex;
  }
  .sticky_left {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .sticky_item {
    padding: 20px;
    min-height: 0;
  }
  .sticky_item span {
    max-width: 200px;
  }
  .sticky_item p {
    font-size: 16px;
    max-width: 200px;
  }
  .sticky_item p br {
    display: block !important;
  }
  .sticky_item img {
    max-width: 210px;
    right: -40px;
  }
  .sticky_right {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .b1 {
    margin-bottom: 25px;
  }
  .b1_t0 {
    text-align: left;
    font-size: 16px;
  }
  .b1_t1 {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 1.2;
    text-align: left;
  }
  .b1_t1 span {
    padding: 0;
    border: none;
    color: #e92a91;
  }
  .b1_t2 {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: left;
  }
  .b2 {
    padding: 40px 12px 200px;
    margin-bottom: 45px;
  }
  .b2::before {
    height: 34%;
    width: 100%;
    background-size: cover;
    right: 0;
  }
  .b2_t1 {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
  .b2_t2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .b2_t3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .b2_t4 {
    font-size: 16px;
    padding: 15px;
    max-width: 245px;
    border-radius: 30px;
  }
  .pre_1 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .b3_block {
    margin-bottom: 30px;
  }
  .b3_t0 {
    font-size: 18px;
  }
  .b3_t1 {
    font-size: 18px;
  }
  .b3_t2 {
    font-size: 26px;
    text-align: left;
  }
  .b3_t3 {
    font-size: 18px;
    text-align: left;
  }
}
