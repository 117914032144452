.author {
    background-color: #F5F5F5;
    padding-top: 75px;
    padding-bottom: 65px;
}
.top {
    width: 100%;
    height: 450px;
}
.top img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.bottom_img {
    display: none!important;
}
.b1 {
    display: flex;
    gap: 30px;
    margin-bottom: 36px;
}
.b1_img {
    flex: 0 1 570px;
    max-width: 570px;
}
.b1_info {
    flex: 1;
}
.b1_info img {
    display: none!important;
}
.b1_title {
    text-transform: uppercase;
    color: #FF8787;
    font-size: 60px;
    font-weight: bold;
    line-height: 1.2;
}
.b1_subtitle {
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.2;
}
.b1_text {
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.55;
}
.b1_text b {
    font-weight: 600;
}
.b1_info ul li {
    padding-left: 22px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #C3C3C3;
    position: relative;
    font-size: 18px;
    line-height: 1.3;
}
.b1_info ul li b {
    font-weight: 600;
}
.b1_info ul li::before {
    content: '';
    width: 16px;
    height: 17px;
    position: absolute;
    left: 0;
    top: 3px;
    background-image: url('data:image/svg+xml,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.67969 1.89062L7.45312 1.11719C7.80469 0.800781 8.33203 0.800781 8.64844 1.11719L15.5039 7.9375C15.8203 8.28906 15.8203 8.81641 15.5039 9.13281L8.64844 15.9883C8.33203 16.3047 7.80469 16.3047 7.45312 15.9883L6.67969 15.2148C6.36328 14.8633 6.36328 14.3359 6.67969 13.9844L10.9336 9.94141H0.84375C0.351562 9.94141 0 9.58984 0 9.09766V7.97266C0 7.51562 0.351562 7.12891 0.84375 7.12891H10.9336L6.67969 3.12109C6.36328 2.76953 6.32812 2.24219 6.67969 1.89062Z" fill="%235D58A6"/></svg>');
}
.b1_info ul li:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.b2_title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 32px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
}
.b2_subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
}
.b2 > span {
    display: none!important;
}
.bottom {
    padding: 60px 0 80px;
}
.bottom_title {
    margin-bottom: 6px;
    text-align: center;
    text-transform: uppercase;
    font-size: 72px;
    font-weight: bold;
    line-height: 1.1;
}
.bottom_title span {
    color: #FF8787;
}
.bottom_subtitle {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 30px;
    line-height: 1.3;
    font-weight: bold;
}
.b2_btn {
    border-radius: 90px;
    background: linear-gradient(91deg, #FF8686 0%, #E85A5A 100%);
    box-shadow: 0px 4px 32px 0px rgba(255, 123, 163, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    width: 446px;
    height: 100px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    color: #fff;
    transition: .3s ease-in-out;
    margin: 0 auto 16px;
}
.b2_btn:hover {
    transform: translateY(-4px);
}
.remain {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.55;
    text-align: center;
    margin-bottom: 24px;
}
.remain span {
    color: #FF8686;
}

@media(max-width: 575px) {
    .top {
        display: none!important;
    }
    .bottom_img {
        display: block!important;
        margin-bottom: 50px;
        text-align: center;
    }
    .author {
        padding: 30px 0 50px;
    }
    .b1_img {
        display: none!important;
    }
    .b1_title {
        text-align: center;
        margin-bottom: 4px;
        font-size: 30px;
    }
    .b1_subtitle {
        font-size: 20px;
        text-align: center;
        margin-bottom: 12px;
    }
    .b1_text {
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
    }
    .b1_info img {
        display: block!important;
        margin-bottom: 20px;
    }
    .b2_subtitle {
        display: none!important;
    }
    .b2 > span {
        display: block!important;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 1.3;
    }
    .bottom {
        display: none!important;
    }
    .b2_btn {
        font-size: 18px;
        width: 300px;
        height: 76px;
        position: relative;
        margin: 0 auto 16px;
    }
    .remain {
        font-size: 20px;
    }
}