.faq {
    padding: 75px 0 60px;
}
.faq_title {
    font-size: 72px;
    line-height: 1;
    font-family: Bebas;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}
.accordion {
    max-width: 970px;
    margin: 0 auto;
}
.question {
    padding: 15px 45px 15px 20px;
    border: 1px solid var(--main-p-color);
    font-size: 18px;
    position: relative;
    font-weight: 600;
    border-radius: 10px;
    line-height: 1.3;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.question:hover {
    color: var(--main-p-color);
}
.question::before {
    content: '+';
    position: absolute;
    top: 4px;
    right: 15px;
    font-size: 36px;
    font-weight: 500;
}
.active .question {
    color: #EE4777;
    border-bottom: 1px solid #EE4777;
}
.active .question::before {
    content: '–';
    top: 2px;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    line-height: 1.4;
    margin-bottom: 20px;
    padding-left: 5px;
    transition: .2s ease-in-out;
}
.active .answer {
    margin-top: 10px;
    max-height: 1000px;
    margin-bottom: 35px;
}
.faq .main_btn_p {
    height: 70px;
    display: block;
    margin: 35px auto 25px;
}
.answer a {
    font-weight: 500;
    color: #EE4777;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}

@media(max-width: 767px) {
    .faq_title {
        font-size: 60px;
    }
}
@media(max-width: 575px) {
    .faq_title {
        font-size: 38px;
    }
    .question {
        font-size: 16px;
    }
}