.noinventory {
    background-color: #101010;
    padding: 90px 0;
    position: relative;
    overflow: hidden;
}
.noinventory::before {
    content: '';
    position: absolute;
    width: 1218px;
    height: 1218px;
    left: -609px;
    top: -312px;
    background: radial-gradient(50% 50% at 50% 50%, #1CBB9E 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.3;
    filter: blur(250px);
    pointer-events: none;
}
.noinventory::after {
    content: '';
    position: absolute;
    width: 1218px;
    height: 1218px;
    right: -609px;
    bottom: -212px;
    background: radial-gradient(50% 50% at 50% 50%, #F7720C 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.3;
    filter: blur(250px);
    pointer-events: none;
}
.noinventory_title {
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    color: #fff;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}
.noinventory_subtitle {
    color: #dfdfdf;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 35px;
    position: relative;
    z-index: 1;
}
.noinventory_subtitle span {
    display: block;
    font-weight: 600;
    color: #fff;
}
.noinventory_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    position: relative;
    z-index: 1;
}
.noinventory_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 20px;
    margin-bottom: 30px;
    position: relative;
}
.noinventory_info {
    border-radius: 10px;
    background-color: #fff;
    height: 100%;
    padding: 35px 30px;
    position: relative;
    transition: .2s ease-in-out;
}
.noinventory_info:hover {
    transform: translateY(-10px);
    box-shadow: -5px 10px 30px rgba(36, 183, 156, 0.5);
}
.noinventory_info__title {
    font-size: 35px;
    line-height: 44px;
    font-weight: bold;
    color: #24B79C;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.noinventory_info__title img {
    margin-right: 15px;
}
.noinventory_info__text {
    line-height: 25px;
    padding-right: 30px;
}
.noinventory_note {
    color: #fff;
    font-size: 25px;
    line-height: 35px;
}
.noinventory_note span {
    font-weight: bold;
    color: #F7720C;
}

@media(max-width: 1199px) {
    .noinventory_item {
        padding: 0 15px;
    }
}
@media(max-width: 991px) {
    .noinventory_title {
        font-size: 50px;
        line-height: 55px;
    }
    .noinventory_block {
        margin: 0 -15px;
    }
    .noinventory_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
}
@media(max-width: 767px) {
    .noinventory {
        padding: 45px 0;
    }
    .noinventory_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .noinventory_subtitle, .noinventory_note {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
    .noinventory_subtitle br, .noinventory_note br {
        display: none;
    }
    .noinventory_info {
        padding: 20px;
    }
    .noinventory_info__title {
        font-size: 18px;
        line-height: 22px;
    }
    .noinventory_info__text {
        font-size: 16px;
        line-height: 22px;
        padding-right: 0;
    }
}