.studentres {
  overflow: hidden;
  position: relative;
  padding-top: 60px;
}
.titles {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 50px;
}
.title {
  font-weight: 500;
  font-size: 44px;
  line-height: 1.3;
  letter-spacing: -0.5px;
  color: #333844;
}
.title em {
  color: #f34fa7;
  font-style: normal;
}
.title span {
  background-color: #f6faff;
  padding: 2px 10px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}
.slider_nav {
  display: flex;
  gap: 16px;
}
.slider_btn_prev {
  background-color: #5690f3;
  border-radius: 100%;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider_btn_next {
  background-color: #5690f3;
  border-radius: 100%;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
}
.slider {
  margin-bottom: 45px;
}
.slide {
  border: 1px solid #deeaff;
  border-radius: 38px;
  overflow: hidden;
}
.slide_info {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background-color: #fff;
}
.slide_info div {
  flex: 0 1 50%;
  max-width: 50%;
  font-size: 15px;
  color: #535363;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slider .slider_nav {
  display: none;
}
.slide_img {
  position: relative;
}
.slide_img > p {
  position: absolute;
  left: 18px;
  bottom: 18px;
  border-radius: 55.051px;
  background: rgba(246, 88, 173, 0.82);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  padding: 4px 14px;
}

@media (max-width: 575px) {
  .titles {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .title {
    font-size: 22px;
    line-height: 1.2;
    /* text-align: center; */
  }
  .titles .slider_nav {
    /* display: none; */
  }
  .slider {
    margin: 0 15px 30px;
  }
  .slide_info div {
    font-size: 13px;
  }
  .slider .slider_nav {
    display: flex;
  }
  .slider_nav {
    position: absolute;
    top: 50%;
    margin-top: 20px;
    left: 0;
    z-index: 2;
    width: 100%;
    justify-content: space-between;
  }
  .slider_btn_next {
    width: 42px;
    height: 42px;
  }
  .slider_btn_prev {
    width: 42px;
    height: 42px;
  }
  .slide_info {
    padding: 17px 8px;
  }
  .title span {
    padding: 0;
    border: none;
  }
}
