.createbody {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_3.jpg');
    background-size: cover;
    background-position: center;
    padding: 88px 0;
    position: relative;
}
.create_blue {
    background-color: var(--str1-pink);
    color: #fff;
    font-size: 30px;
    line-height: 39px;
    padding: 32px 32px 32px 39px;
    border-radius: 20px 0px 20px 20px;
    max-width: 662px;
    margin-top: 60px;
    margin-left: 115px;
    position: relative;
}
.create_blue::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 630px;
    top: -100px;
    width: 207px;
    height: 178px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str2/create_bg3.png');
}
.create_green {
    background-color: var(--str1-green);
    color: #fff;
    font-size: 30px;
    line-height: 39px;
    padding: 32px 25px 32px 39px;
    border-radius: 0px 20px 20px 20px;
    max-width: 459px;
    margin-left: 615px;
    margin-top: -35px;
    position: relative;
}
.create_blue strong, .create_green strong {
    font-weight: 600;
}

@media(max-width: 1199px) {
    .create_blue::before {
        content: none;
    }
    .createbody::before {
        width: 210px;
        height: 210px;
        background-size: contain;
        margin-left: 330px;
        top: -75px;
        background-repeat: no-repeat;
    }
    .createbody::after {
        width: 300px;
        height: 230px;
        background-size: cover;
        margin-left: -520px;
    }
    .create_green {
        margin-left: 490px;
    }
}
@media(max-width: 991px) {
    .createbody {
        margin-top: 0;
    }
    .create_blue {
        margin-left: 0;
        font-size: 22px;
        line-height: 32px;
    }
    .create_green {
        font-size: 22px;
        line-height: 32px;
        margin-left: 15px;
        margin-left: 305px;
        margin-top: -20px;
    }
    .createbody::before {
        width: 200px;
        height: 162px;
        margin-left: 235px;
        top: -25px;
    }
    .createbody::after {
        width: 200px;
        height: 150px;
        margin-left: -385px;
    }
    .create_blue::before {
        content: '';
        filter: blur(5px);
        transform: matrix(-0.98, 0.2, 0.2, 0.98, 0, 0);
        top: 35px;
        left: 10px;
        margin-left: 0;
        z-index: -1;
    }
}
@media(max-width: 767px) {
    .createbody::before {
        margin-left: 180px;
    }
    .create_blue {
        max-width: 100%;
        font-size: 16px;
        line-height: 21px;
        margin-top: 35px;
    }
    .create_green {
        margin-top: -8px;
        font-size: 16px;
        line-height: 21px;
    }
    .createbody::after {
        width: 135px;
        height: 95px;
    }
}
@media(max-width: 757px) {
    .create_blue {
        padding: 15px;
    }
    .create_green {
        margin-left: 120px;
        padding: 15px;
    }
}
@media(max-width: 575px) {
    .createbody {
        background-position: 15% 100%;
        background-size: auto;
        padding-top: 45px;
        overflow: hidden;
    }
    .createbody::after {
        margin-left: 0;
        left: 0;
    }
    .createbody::before {
        margin-left: 160px;
        top: 25px;
        width: 130px;
        height: 100px;
        transform: rotate(339deg);
    }
    
}
@media(max-width: 420px) {
    .createbody::before {
        margin-left: 110px;
    }
    .createbody::after {
        left: -20px;
    }
}