.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/promo_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 816px;
    padding: 90px 15px 0;
    position: relative;
}
.promo::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    z-index: 1;
}
.block {
    position: relative;
    background-color: #fff;
    padding: 80px 120px;
    border-radius: 58px;
    box-shadow: 0px 106px 128px 0px rgba(208, 94, 226, 0.13);
    max-width: 1400px;
    margin: 0 auto;
}
.title {
    margin-bottom: 10px;
    font-family: Muller;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.24;
    text-transform: uppercase;
    color: #534E59;
}
.title span {
    color: #8298E6;
}
.subtitle {
    margin-bottom: 36px;
    color: #534E59;
    font-family: Gilroy;
    font-size: 26px;
    line-height: 1.3;
}
.btn {
    padding: 30px 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 17px;
    background: linear-gradient(89deg, #E13DA9 -70.56%, #FE71B5 115.22%);
    margin-bottom: 20px;
    color: #fff;
    font-family: Muller;
    font-size: 22px;
    font-weight: bold;
    max-width: max-content;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
.info {
    display: flex;
    align-items: center;
    gap: 12px;
}
.info p {
    color: #534E59;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 1.3;
}
.i1 {
    pointer-events: none;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    top: -60px;
}
.i2 {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 335px;
    margin-top: -110px;
    border-radius: 100%;
    backdrop-filter: blur(8px);
}
.i3 {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 335px;
    margin-top: -10px;
    border-radius: 100%;
    backdrop-filter: blur(8px);
}
.i3 img:nth-of-type(1) {
    display: inline-block!important;
}
.i3 img:nth-of-type(2) {
    display: none!important;
}

@media(max-width:575px) {
    .promo {
        padding: 40px 15px;
        height: auto;
    }
    .promo::before {
        content: none;
    }
    .block {
        padding: 30px 20px;
        border-radius: 30px;
    }
    .title {
        margin-bottom: 8px;
        font-size: 24px;
    }
    .subtitle {
        margin-bottom: 110px;
        font-size: 18px;
    }
    .i1 {
        display: none!important;
    }
    .i2 {
        position: relative;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        max-width: 120px;
        margin-bottom: 12px;
    }
    .i3 {
        position: relative;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 14px;
        max-width: max-content;
    }
    .i3 img:nth-of-type(1) {
        display: none!important;
    }
    .i3 img:nth-of-type(2) {
        display: inline-block!important;
    }
    .block > * {
        position: relative;
        z-index: 1;
    }
    .btn {
        padding: 24px 20px;
        max-width: 100%;
        margin-bottom: 18px;
        font-size: 18px;
        text-align: center;
        position: relative;
        z-index: 0;
    }
    .btn::before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -190px;
        bottom: -95px;
        width: 375px;
        height: 444px;
        pointer-events: none;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/promo_mob.png');
    }
    .btn::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 17px;
        background: linear-gradient(89deg, #E13DA9 -70.56%, #FE71B5 115.22%);
        pointer-events: none;
    }
    .btn p {
        position: relative;
        z-index: 1;
    }
    .btn img {
        display: none!important;
    }
}
@media(max-width: 359px) {
    .title {
        font-size: 22px;
    }
}