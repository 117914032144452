.preims {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/mu3/start_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding-bottom: 85px;
}
.title {
    text-align: center;
    font-weight: 500;
    font-size: 44px;
    line-height: 1.3;
    letter-spacing: -0.5px;
    margin-bottom: 34px;
}
.title span {
    color: #43AFBE;
    background-color: #F6FAFF;
    padding: 4px 14px;
    max-width: max-content;
}
.block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 55px;
    padding: 0 15px;
}
.item {
    flex: 0 1 360px;
    max-width: 360px;
    border-radius: 40px;
    background-color: #fff;
    border: 1px solid #DEEAFF;
    padding: 28px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.info {
    background-color: #FFEEEB;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: center;
    color: #F18D7C ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 11px;
    height: 81px;
    margin-bottom: 22px;

}
.item_img {
    max-height: 229px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 25px;
}
.item_title {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.4;
    letter-spacing: -0.5px;
}
.item_text {
    font-size: 16px;
    color: rgba(51, 56, 68, 0.7);
    line-height: 1.4;
    letter-spacing: -0.5px;
}
.item input {
    display: none;
}
.item label p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    color: #333844B2;
}
.item label {
    border-top: solid 1px #d0d0d0;
    position: relative;
    width: 100%;
    display: block;
    color: #444;
    letter-spacing: 0.015rem;
    font-size: 16px;
    line-height: 1;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
    margin-top: 20px;
    padding-top: 10px;
}
.item label::before {
    content: '';
    position: absolute;
    right: 0;
    top: 21px;
    width: 14px;
    height: 2px;
    background-color: #FA6C6B;
}
.item label::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 15px;
    width: 2px;
    height: 14px;
    background-color: #FA6C6B;
}
 .item label ul {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: normal;
    color: #444;
    font-size: 16px;
    line-height: 1.45;
    padding-top: 12px;
    font-family: Manrope;
    text-transform: initial;
    transition: .3s ease-in-out;
}
.item label ul li {
    list-style: inside;
}
.item p b {
    font-weight: 600;
    color: #fff;
}
.item label div ul {
    padding-bottom: 0;
}
.item label div ul li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
}
.item label div ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #FA6C6B;
    border-radius: 100%;
}
.item input[type=checkbox]:checked + label p, 
.item input[type=checkbox]:checked + label ul {
    height: auto;
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
    color: #333844B2;
}
.item input[type=checkbox]:checked + label::after {
    opacity: 0;
}
.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
background: linear-gradient(273.53deg, #FCA98C -49.3%, #FA6568 108.58%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 40px;
    cursor: pointer;
    margin: 0 auto;
}
.btn:hover {
    transform: translateY(-4px);
}
.promo_countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
    text-align: center;
}
.promo_countdown > p {
    font-weight: 500;
    color: #3B4A77;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 2px;
    margin-bottom: 6px;
    text-transform: uppercase;
}
.promo_countdown > div {
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    color: #FA6C6B;
    letter-spacing: -1px;
}
.promo_countdown div {
    display: flex;
}

@media(max-width:575px) {
    .promo_countdown > div { 
        font-size: 36px;
    }
    .promo_countdown > p {
        font-size: 14px;
    }
    .preims {
        padding: 0 15px 50px;
    }
    .title {
        font-size: 21px;
        margin-bottom: 35px;
    }
    .title span {
        display: block;
        margin: 0 auto;
    }
    .item_title {
        font-size: 18px;
        margin-bottom: 6px;
    }
    .item_text {
        font-size: 14px;
    }
    .block {
        padding: 0;
        margin-bottom: 23px;
    }
    .btn {
        font-size: 18px;
        padding: 20px;
    }
}