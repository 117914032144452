.block2 {
    background-color: #FFEFEF;
    padding: 85px 0;
}
.img {
    text-align: center;
    margin-bottom: 2px;
}
.pt {
    text-align: center;
    margin-bottom: 16px;
    font-size: 26px;
    line-height: 1.1;
}
.t {
    font-family: Unisans;
    font-size: 45px;
    font-weight: bold;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 45px;
}
.t span {
    color: #FF466E;
}
.b {
    display: flex;
    flex-wrap: wrap;
    gap: 57px;
    margin-bottom: 85px;
}
.b_item {
    flex: 0 1 536px;
    max-width: 536px;
    display: flex;
    gap: 28px;
}
.b_item_num {
    font-family: Unisans;
    font-size: 236px;
    color: #FCD8D8;
    font-weight: bold;
    line-height: 0.9;
}
.b_item_text div {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
}
.b_item_text p {
    font-size: 18px;
    line-height: 1.4;
}
.btn {
    position: relative;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 555px;
    height: 100px;
    padding: 10px;
    border-radius: 15px;
    background: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 15.07%, rgba(255, 255, 255, 0.20) 148.09%), #FF2E5B;
    box-shadow: 0px 4px 25.5px 0px rgba(255, 46, 91, 0.43);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
@media(max-width: 575px) {
    .block2 {
        padding: 60px 0;
    }
    .pt {
        font-size: 16px;
    }
    .t {
        font-size: 28px;
    }
    .btn {
        width: 100%;
        height: 80px;
        font-size: 18px;
    }
    .b {
        gap: 24px;
        margin-bottom: 45px;
    }
    .b_item {
        flex: 0 1 100%;
        max-width: 100%;
        flex-direction: column;
        gap: 12px;
    }
    .b_item_num {
        font-size: 46px;
        line-height: 1;
    }
    .b_item_text div {
        font-size: 20px;
    }
    .b_item_text p {
        font-size: 16px;
    }
}