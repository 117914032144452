.subscribe {
    background-color: #121212;
    padding: 90px 0;
}
.subscribe_title {
    text-align: center;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 15px;
}
.subscribe_subtitle {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 35px;
}
.subscribe_choose {
    text-align: center;
    margin-bottom: 25px;
    color: #EE4777;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
}
.subscribe_credit {
    background: #ffdd2d;
    border-radius: 24px;
    margin: 30px auto 0;
    max-width: 685px;
    padding: 45px;
}
.subscribe_credit__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.subscribe_credit__text {
    font-size: 18px;
    line-height: 26px;
}
.subscribe_credit__text p {
    margin-bottom: 5px;
}

@media(max-width: 767px) {
    .subscribe {
        padding: 45px 0;
    }
    .subscribe_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .subscribe_subtitle {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 30px;
    }
    .subscribe_choose {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .subscribe_credit {
        padding: 25px;
    }
    .subscribe_credit__title {
        font-size: 24px;
        line-height: 26px;
    }
    .subscribe_credit__text {
        font-size: 16px;
        line-height: 22px;
    }
}