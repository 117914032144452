.howrecover {
    text-align: center;
    padding-bottom: 75px;
}
.howrecover_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
}
.howrecover_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.howrecover_block {
    display: flex;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto 30px;
}
.howrecover_item {
    flex: 0 1 50%;
    max-width: 50%;
    border: 1px solid var(--main-color);
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    transition: .2s ease-in-out;
}
.howrecover_item:hover {
    transform: translateY(-10px);
    box-shadow: 4px 4px 0px 1.5px var(--main-color), 4px 4px 0px 3px var(--main-color);
}
.howrecover_item p {
    font-size: 18px;
    line-height: 1.3;
    opacity: 0.8;
}
.howrecover_video {
    height: 545px;
    width: 1000px;
    margin: 0 auto 30px;
    padding-top: 10px;
    position: relative;
}
.author_block__video-preview {
    position: absolute;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/afterpregnancy/afterpregnancy_preview_min2.jpg');
    background-color: #fff;
    background-size: cover;
    transition: .2s ease-in-out .4s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.author_block__video-preview::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    background-color: #00b2ff;
    border-radius: 100%;
    margin-top: -24px;
    margin-left: -24px;
    border: 1px solid transparent;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.author_block__video-preview::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -4px;
    border: 10px solid transparent; border-left: 10px solid #fff;
    width: 10px;
    height: 10px;
    cursor: pointer;
}
.author_block__video-preview.hide {
    opacity: 0;
    pointer-events: none;
}

@media(max-width: 1199px) {
    .author_block__video {
        height: 570px;
        margin-bottom: 20px;
    }
}
@media(max-width: 1026px) {
    .howrecover_video {
        width: 100%;
    }
}
@media(max-width: 991px) {
    .howrecover_title {
        font-size: 42px;
    }
    .howrecover_video {
        height: 430px;
    }
    .howrecover_title br {
        display: none;
    }
}
@media(max-width: 767px) {
    .howrecover_title {
        font-size: 36px;
    }
    .howrecover_block {
        flex-wrap: wrap;
    }
    .howrecover_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .howrecover_video {
        height: 370px;
    }
}
@media(max-width: 575px) {
    .howrecover_title {
        font-size: 24px;
    }
    .howrecover_item {
        padding: 20px;
    }
    .howrecover_video {
        height: 240px;
    }
    .howrecover_title span {
        padding-bottom: 5px;
    }
    .author_block__video-preview {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
@media(max-width: 499px) {
    .howrecover_block {
        margin-bottom: 5px;
    }
}
@media(max-width: 359px) {
    .howrecover_video {
        height: auto;
    }
    .howrecover_block {
        margin-bottom: 30px;
    }
}