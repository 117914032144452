.preims {
    padding: 90px 0 25px;
    overflow: hidden;
    position: relative;
    background-color: #fff;
}
.preims_title {
    color: #EF3F34;
    font-family: Dela;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.02em;
}
.preims_subtitle {
    font-family: Dela;
    font-size: 52px;
    line-height: 58px;
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.02em;
}
.preims_subtitle span {
    display: none;
}
.preims_block {
    display: flex;
    align-items: center;
    margin-bottom: 90px;
}
.preims_left {
    flex: 0 1 654px;
    max-width: 654px;
}
.preims_right {
    flex: 1;
    padding-left: 20px;
}
.preims_left img {
    mix-blend-mode: darken;
}
.preims_left img:last-of-type {
    display: none!important;
}
.preims_right div {
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 28px;
}
.preims_right ul li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 22px;
}
.preims_right ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20.5px;
    width: 15px;
    height: 35px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/flame.svg');
    background-size: cover;
}
.preims_line {
    position: relative;
    display: flex;
    justify-content: space-between;
    /* padding-top: 68px; */
}
.preims_line::before {
    /* content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 13px;
    background-color: #E7E1D2;
    border-radius: 85px; */
}
.preims_line__item {
    margin-bottom: 45px;
    position: relative;
}
.preims_line__item::before {
    /* content: '01';
    position: absolute;
    left: 0;
    top: -96px;
    width: 67px;
    height: 67px;
    border-radius: 100%;
    background-color: #fff;
    color: rgba(52, 52, 50, 0.3);
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    box-shadow: 0px 4px 20px rgba(49, 29, 25, 0.08); */
}
/* .preims_line__item:nth-of-type(2)::before {content: '02';}
.preims_line__item:nth-of-type(3)::before {content: '03';}
.preims_line__item:nth-of-type(4)::before {content: '04';}
.preims_line__item:nth-of-type(5)::before {content: '05';} */
.preims_line__title {
    color: #0D8ED7;
    font-family: Dela;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.preims_line__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #505050;
}

@media(max-width: 1199px) {
    .preims_title br, .preims_subtitle br, .preims_line__text br {
        display: none;
    }
    .preims_title {
        font-size: 40px;
        line-height: 40px;
    }
    .preims_line__text {
        padding-right: 15px;
        font-size: 14px;
        line-height: 18px;
    }
}
@media(max-width: 991px) {
    .preims_title {
        margin-bottom: 385px;
        text-align: center;
        position: relative;
    }
    .preims_subtitle {
        background-color: #F8F4F1;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-bottom: 0;
        padding: 20px;
        position: relative;
    }
    .preims_line {
        flex-direction: column;
        padding-top: 0;
        /* padding-left: 120px; */
    }
    .preims_line::before {
        width: 13px;
        height: 90%;
        left: 50px;
    }
    .preims_line__item::before {
        top: -5px;
        left: -98px;
    }
}
@media(max-width: 767px) {
    .preims {
        padding-top: 50px;
    }
}
@media(max-width: 575px) {
    .preims_title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .preims_title span {
        display: none;
    }
    .preims_subtitle {
        font-size: 28px;
        line-height: 30px;
        padding: 0;
        margin-bottom: 15px;
    }
    .preims_subtitle span {
        display: inline-block;
    }
    .preims_block {
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
    .preims_left {
        text-align: center;
    }
    .preims_left img:last-of-type {
        display: inline-block!important;
    }
    .preims_left img:first-of-type {
        display: none!important;
    }
    .preims_right {
        padding-left: 0;
        margin-top: 15px;
    }
    .preims_right div {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 30px;
    }
    .preims_right ul li {
        font-size: 16px;
        line-height: 20px;
    }
    .preims_line {
        /* padding-left: 98px; */
        text-align: center;
    }
    .preims_line::before {
        left: 28px;
    }
    .preims_line__text {
        font-size: 16px;
        line-height: 20px;
    }
    .preims_title br {
        display: none;
    }
}