.year115_start {
    width: 100%;
}
.year115_start h2 {
    display: none;
}
.year115_start .sales-widget__offers {
    margin-top: 0;
    width: 100%;
}
.year115_start .chakra-button {
    outline: none!important;
    border-radius: 12px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    color: #050505;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin: 0 auto;
    transition: .2s ease-in-out;
}
.year115_start .chakra-button:hover {
    background: #fff;
    opacity: 1;
    transform: translateY(-2px);
}
.year115_start .chakra-button:active {
    background: #fff;
    opacity: 1;
}
.year115_start .chakra-button p {
    position: relative;
}
.year115_start form .chakra-button {
    margin-bottom: 5px;
}
.year115_start .sales-widget__user-form button > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-size: 14px;
    line-height: normal;
    text-transform: lowercase;
}
.year115_start .gt-offer {
    text-align: left;
}
.year115_start .gt-offer > label {
    margin-bottom: 10px;
    grid-template-columns: 0px 1fr;
}
.year115_start .gt-offer > label > .chakra-checkbox__control {
    background: #4E60C1;
    border-color: #4E60C1;
}
.year115_start .gt-offer > label > span {
    padding-left: 0;
    margin-left: 0;
}
.year115_start .gt-offer:last-of-type {
    border-bottom: none;
}
.year115_start .chakra-radio__control {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    background-color: #fff!important;
    position: relative;
    border: none!important;
    outline: none!important;
    border: 5.5px solid #DED9D5!important;
}
.year115_start .chakra-radio__control[data-checked] {
    width: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
    border: none!important;
}
.year115_start .chakra-radio__control::before {
    width: 12px!important;
    height: 12px!important;
    background-color: #fff!important;
    border-radius: 100%!important;
    content: none!important;
}
.year115_start .gt-offer__title {
    font-size: 22px;
    line-height: 26px;
    font-family: Qanelas;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 0;
    color: #fff;
}
.year115_start .gt-offer__title ~ .chakra-text {
    padding-left: 0;
    font-family: Qanelas;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.year115_start span.chakra-radio__label > .chakra-stack {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}
.year115_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}
.year115_start .gt-offer__marketing-price {
    font-family: Qanelas;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    order: 2;
    color: rgba(22, 19, 19, 0.5);
}
.year115_start .gt-offer__price {
    font-family: Qanelas;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #fff;
    margin-bottom: 5px;
}
.year115_start .sales-widget__selected-offers {
    max-width: 800px;
}
.year115_start .sales-widget__selected-offers p {
    color: #fff;
}
.year115_start .sales-widget__selected-offers > .chakra-stack {
    display: flex;
    align-items: flex-start;
}
.year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
    font-size: 20px;
    line-height: 26px;
    font-family: Qanelas;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 20px;
    margin-bottom: 10px;
    text-align: left;
}
.year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2) {
    padding-left: 20px;
    font-family: Qanelas;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack {
    display: flex;
    flex-direction: column;
}
.year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(1) {
    font-family: Qanelas;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: -0.01em;
    order: 2;
}
.year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-family: Qanelas;
    font-size: 20px;
    line-height: 22px;
    font-weight: 800;
    letter-spacing: -0.01em;
    color: #1C1C1A;
    margin-bottom: 5px;
}
.year115_start .sales-widget__user-form {
    max-width: 800px;
}
.year115_start .sales-widget__user-form input {
    background-color: #fff;
}
.year115_start .sales-widget__offers > .chakra-stack {
    min-width: 100%;
}
.year115_start .sales-widget__offers > .chakra-radio-group > .chakra-stack {
    max-width: 100%;
    margin-bottom: 20px;
}
.year115_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-end;
}
.year115_start .sales-widget__offers > .chakra-stack .chakra-divider {
    display: none;
}
.year115_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div {
    margin-bottom: 15px;
}
.year115_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-marketing-price {
    font-family: Qanelas;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    color: rgba(22, 19, 19, 0.5);
}
.year115_start .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-price {
    color: #1C1C1A;
}
.year115_start .chakra-form__error-message, .year1_login .chakra-form__error-message {
    color: #fff;
}
@media(max-width: 575px) {
    .year115_start .gt-offer, .year115_start .sales-widget__selected-offers {
        padding: 10px;
    }
    .year115_start span.chakra-radio__label > .chakra-stack, .year115_start .sales-widget__selected-offers > .chakra-stack {
        flex-direction: column;
    }
    .year115_start span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
        margin-top: 20px;
        align-items: flex-end;
        width: 100%;
    }
    .year115_start .gt-offer__title {
        font-size: 18px;
        line-height: 22px;
        padding-left: 5px;
    }
    .year115_start .gt-offer__title ~ .chakra-text {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
    }
    .year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2), .year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
        padding-left: 0;
    }
    .year115_start .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .year115_start .sales-widget__user-form button {
        margin-top: 15px;
    }
    .year115_start .sales-widget__user-form {
        padding: 0;
    }
    .year115_start .chakra-button {
        height: 60px;
        width: 90%;
    }
    .year115_start form .chakra-button {
        width: 90%;
        margin-top: 0;
    }
    .year115_start .chakra-button p {
        top: 0;
    }
    .year115_start .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .year115_start .gt-offer__marketing-price {
        margin-left: 20px;
    }
}