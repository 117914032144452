.main {
    max-width: 1070px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    color: #000;
}
.logo {
    margin-bottom: 30px;
}
.title {
    font-family: Qanelas;
    color: #121212;
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
}
.img {
    margin-bottom: 30px;
    max-width: 850px;
    min-height: 300px;
}
.title2 {
    font-family: Qanelas;
    color: #121212;
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
}
.subtitle {
    font-size: 24px;
    line-height: 28px;
    color: #121212;
    margin-bottom: 100px;
    text-align: center;
    font-family: Qanelas;
}
.ul_title {
    margin-bottom: 10px;
    font-family: Cera;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: #121212;
}
.main ul {
    margin-bottom: 90px;
}
.main ul li {
    font-family: Cera;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
}
.main ul li span {
    font-weight: 500;
    color: #EE4777;
}
.title3 {
    font-size: 20px;
    line-height: 25px;
    font-family: Cera;
    font-weight: 500;
}
.number {
    font-family: Cera;
    font-weight: bold;
    color: #EE4777;
    font-size: 96px;
    line-height: 120px;
    margin-bottom: 30px;
}
.block_main {
    position: relative;
    margin-bottom: 100px;
}
.block {
    width: 630px;
    background-color: #ECECEC;
    border-radius: 30px;
    padding: 30px;
    transition: .1s ease-in-out;
}
.block_btn_t1 {
    position: absolute;
}
.block_btn_t2 {
    position: absolute;
    opacity: 0;
}
.block_btn_t1_active {
    opacity: 0;
}
.block_btn_t2_active {
    opacity: 1;
}
.block_copy {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Cera;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
}
.block_title {
    text-align: center;
    margin-bottom: 30px;
    font-family: Cera;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
}
.block_input {
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    font-family: Cera;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.block_btn {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #EE4777;
    font-size: 20px;
    font-family: Cera;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.block_btn:hover {
    opacity: 0.8;
}
.block2 {
    display: flex;
    margin-bottom: 100px;
}
.block2_item {
    width: 190px;
    margin: 0 15px;
    text-align: center;
}
.block2_title {
    margin-bottom: 10px;
    font-family: Qanelas;
    font-size: 40px;
    line-height: 44px;
    font-weight: 800;
    color: #EE4777;
    text-transform: uppercase;
}
.block2_text {
    font-family: Cera;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
}
.block_btn2 {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #EE4777;
    font-size: 20px;
    font-family: Cera;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.block_btn2:hover {
    opacity: 0.8;
}

@media(max-width: 1199px) {
    .main {
        padding-left: 10px;
        padding-right: 10px;
    }
    .block2 {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 60px;
    }
    .block2_item {
        margin-bottom: 40px;
    }
}
@media(max-width: 991px) {
    .img {
        min-height: 100px;
    }
}
@media(max-width: 767px) {
    .title, .title2 {
        text-align: center;
    }
}
@media(max-width: 575px) {
    .main {
        padding-top: 30px;
        overflow: hidden;
    }
    .title, .title2 {
        font-size: 26px;
        line-height: 32px;
    }
    .subtitle {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 50px;
    }
    .ul_title {
        font-size: 22px;
        line-height: 26px;
        text-align: center;
    }
    .main ul {
        margin-bottom: 50px;
    }
    .title3 {
        text-align: center;
    }
    .block_main {
        width: 100%;
        margin-bottom: 50px;
    }
    .block {
        padding: 20px 10px;
        width: 100%;
    }
    .block_title {
        margin-bottom: 20px;
    }
    .block_input {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .block2 {
        flex-direction: column;
        margin-bottom: 50px;
    }
    .block2_item {
        margin-bottom: 25px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .block2_title {
        margin-bottom: 0;
    }
    .block_btn2 {
        font-size: 18px;
    }
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .1s ease-in-out;
}
.modal.active {
    opacity: 1;
    pointer-events: all;
}
.modal::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.54);
    backdrop-filter: blur(30px);
}
.modal_content {
    position: relative;
    z-index: 1;
    border-radius: 36px;
    max-width: 530px;
    margin: 0 auto;
    text-align: center;
}
.modal_content_title {
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 15px;
}
.modal_content_other {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-top: 15px;
}
.modal_content_other a {
    text-decoration: underline;
}
.modal_content_close {
    position: absolute;
    right: 25px;
    top: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.modal_content_close::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #eee;
    transform: rotate(45deg);
}
.modal_content_close::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #eee;
    transform: rotate(-45deg);
}