.corporate_24_01 {
    font-family: Qanelas;
    font-size: 16px;
    color: #121212;
}
.swiper_corporate_nav > div {
    transition: .3s ease-in-out;
}
.swiper_corporate_nav .swiper-button-disabled {
    opacity: 0.5;
}