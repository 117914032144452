.page {
    height: 100%;
    margin: 0;
    background-color: #000;
    background-attachment: fixed;
    overflow: auto;
    transition: none;
    font-family: Cera;
    padding-bottom: 40px;
}
.block {
    max-width: 850px;
    margin: 0 auto;
    padding-top: 30px;
    min-height: 100vh;
}
.img {
    height: 354px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background: linear-gradient(rgba(0, 0, 0, 0) 46.82%, rgb(0, 0, 0) 100%) center center / cover, url('https://gymteam.kinescopecdn.net/web2/paywall_bg.png'), no-repeat lightgray;
    background-position: center center;
    background-size: cover;
}
.title {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}
.subtitle {
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-weight: 300;
}
.subtitle a {
    cursor: pointer;
    color: #EE4777;
}

@media (max-width: 575px) {
    .img {
        height: 262px;
    }
    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .subtitle {
        font-size: 16px;
    }
    .block2 {
        padding: 0 15px;
    }
}