.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(18, 18, 18, 0.75);
    display: flex;
    align-items: center;
    z-index: 99;
}
.popup_block {
    max-width: 400px;
    margin: 0 auto;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;
    position: relative;
}
.popup_title {
    margin-bottom: 8px;
    color: #121212;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 42px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
}
.popup_text {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-family: Roboto;
    color: #363636;
}
.popup_btn {
    width: 272px;
    height: 60px;
    border-radius: 8px;
    background-color: #ee4777;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 2px 0px rgba(18, 18, 18, 0.05);
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02rem;
    color: #fff;
    cursor: pointer;
    margin: 0 auto 12px;
    transition: .2s ease-in-out;
}
.popup_btn:hover {
    background-color: #dd4c75;
}
.popup_count {
    text-align: center;
    color: rgba(54, 54, 54, 0.5);
    font-family: Roboto;
    font-size: 13px;
    line-height: 18px;
}
.popup_btn_close {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}
.popup_btn_close img {
    width: 20px;
    height: 20px;
}