.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/promo_bg.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    padding-top: 50px;
    height: 842px;
}
.promo_logo {
    margin-bottom: 40px;
}
.promo_pretitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.promo_pretitle span {
    background-color: #F7720C;
    border-radius: 5px;
    padding: 3px 6px;
    display: inline-block;
    margin-bottom: 3px;
}
.promo_title {
    font-size: 60px;
    line-height: 66px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 15px;
}
.promo_title span {
    color: #26CBAD;
}
.promo_subtitle {
    font-size: 25px;
    line-height: 32px;
    color: rgba(255,255,255,.75);
    margin-bottom: 30px;
}
.promo_subtitle b {
    font-weight: 600;
    color: #fff;
}
.promo_btns {
    display: flex;
    align-items: center;
}
.promo_date {
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
    color: #fff;
    margin-left: 30px;
}
.promo_items {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
}
.promo_item {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    position: relative;
    background-color: #2a2a2c;
    border-radius: 10px;
    text-align: center;
}
.promo_item p {
    margin-top: -35px;
    padding-bottom: 10px;
}
.promo_point1 {
    color: #fff;
    background: linear-gradient(130.87deg, #26CBAD 24.67%, #0CA488 78.92%);
    border-radius: 60px;
    width: 279px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 130px;
    margin-left: 95px;
    font-weight: 600;
}
.promo_point2 {
    color: #fff;
    background: linear-gradient(180deg, #FF8A31 0%, #DA670F 100%);
    border-radius: 60px;
    width: 187px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -90px;
    margin-left: 50px;
    font-weight: 600;
}
.promo_point3 {
    color: #fff;
    background: linear-gradient(180deg, #51504D 0%, #0E0E0E 100%);
    border-radius: 60px;
    width: 197px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 0px;
    margin-left: 440px;
    font-weight: 600;
}
.promo_point1 span, .promo_point2 span, .promo_point3 span {
    margin-left: 3px;
}
.promo_btn {
    width: 358px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(89.69deg, #26CBAD 14.2%, #0CA488 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: .3s ease-in-out;
}
.promo_btn::before {
    content: '';
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.6);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.promo_btn:active::before {
    background: linear-gradient(89.69deg, #46E6C8 21.91%, #24D3B3 92.1%);
    box-shadow: 0px 4px 32px rgba(36, 183, 156, 0.4), inset 0px 4px 4px #24B79C;
}
.promo_btn:hover {
    transform: translateY(-6px);
}
.promo_btn:hover::before {
    opacity: 1;
}
.promo_btn p {
    position: relative;
}

@media(max-width: 1199px) {
    .promo {
        height: auto;
        padding-bottom: 0;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        background: transparent;
        margin-left: 0;
        margin-top: 0;
        left: 0;
        top: auto;
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        margin-left: 17px;
        margin-top: 35px;
        color: #fff;
    }
    .promo_point1 span {
        color: #F7720C;
        margin-left: 0;
    }
    .promo_point2 span {
        color: #FFED34;
        margin-left: 0;
    }
    .promo_point3 span {
        color: #24B79C;
        margin-left: 0;
    }
    .promo_btns {
        flex-direction: column;
        align-items: flex-start;
    }
    .promo_btns > div:first-of-type {
        order: 1;
    }
    .promo_date {
        margin-left: 0;
        margin-bottom: 20px;
    }
}
@media(max-width: 991px) {
    .promo {
        overflow: hidden;
        padding-bottom: 15px;
    }
    .promo_subtitle {
        margin-bottom: 15px;
    }
}
@media(max-width: 767px) {
    .promo {
        padding-top: 45px;
        /* background-size: auto; */
        background-repeat: no-repeat;
        background-position: 57% 100%;
        background-color: #010101;
        position: relative;
    }
    .promo_pretitle {
        position: relative;
    }
    .promo_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
        position: relative;
    }
    .promo_subtitle {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
        position: relative;
    }
    .promo_subtitle br, .promo_title br {
        display: none;
    }
    .promo_date {
        margin-bottom: 10px;
        position: relative;
    }
    .promo_item {
        margin-right: 20px;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
}
@media(max-width: 575px) {
    .promo {
        padding-top: 25px;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/promo_bg_mob.jpg');
        background-position: 70% 100%;
        background-repeat: no-repeat;
        background-size: auto;
        padding-bottom: 0;
    }
    .promo_items {
        flex-direction: column;
    }
    .promo_logo {
        margin-bottom: 25px;
    }
    .promo_logo img {
        height: 32px;
    }
    .promo_pretitle {
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.05em;
    }
    .promo_title, .promo_subtitle {
        position: relative;
        z-index: 1;
    }
    .promo_item {
        margin-right: 0;
        width: 164px;
        height: 100%;
        justify-content: center;
        margin-bottom: 5px;
    }
    .promo_item p {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: normal;
    }
    .promo_item p {
        margin-top: -30px;
        padding-bottom: 5px;
    }
    .promo_date {
        font-size: 25px;
        line-height: 30px;
    }
    .promo_date br {
        display: block;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 12px;
        margin: 0 4px;
    }
    .promo_subtitle br {
        display: none;
    }
    .promo_points_block {
        text-align: center;
        padding: 5px 0;
        margin-top: 20px;
    }
    .promo_btn {
        width: 100%;
    }
}
@media(max-width: 374px) {
    .promo {
        padding-top: 20px;
    }
    .promo_title {
        font-size: 28px;
        line-height: 32px;
    }
    .promo_subtitle {
        font-size: 16px;
        line-height: 21px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 12px;
    }
    .promo_date {
        font-size: 28px;
        margin-bottom: 10px;
    }
}