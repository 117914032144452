#rasprozarka_2305, #htmlrasprozarka_2305 {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
}
#html_reset {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
#reset {
    position: relative;
    max-width: 100%;
    overflow-x: unset;
}
html, #rasprozarka_2305, #htmlrasprozarka_2305 {
    overflow-y: auto;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*:focus {
    outline: none;
}
::after, ::before { 
    box-sizing: inherit; 
}
html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    overscroll-behavior-x: contain;
}
body {
    overflow-x: hidden;
    font-size: 16px;
    -webkit-text-size-adjust: none;
}
.container {width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}
@media (min-width:576px){.container{max-width:748px}}
@media (min-width:768px){.container{max-width:972px}}
@media (min-width:992px){.container{max-width:1180px}}
@media (min-width:1200px){.container{max-width:1180px}}
@media (min-width:1250px){.container{max-width:1230px}}
img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block!important;
}
ul {
    list-style: none;
}
a {
    display: inline-block;
    text-decoration: none;
}
p {
    margin: 0;
}
button {
    cursor: pointer;
    border: none;
}
@media (max-width: 575px) {
    br {
        display: none !important;
    }
}

@font-face {
    font-family: 'Cera';
    src: url('../public/fonts/CeraPro-Light.woff') format('woff'), url('../public/fonts/CeraPro-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Cera';
    src: url('../public/fonts/CeraPro-Regular.woff') format('woff'), url('../public/fonts/CeraPro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Cera';
    src: url('../public/fonts/CeraPro-Medium.woff') format('woff'), url('../public/fonts/CeraPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Cera';
    src: url('../public/fonts/CeraPro-Bold.woff') format('woff'), url('../public/fonts/CeraPro-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../public/fonts/Gilroy-Regular.woff') format('woff'), url('../public/fonts/Gilroy-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../public/fonts/Gilroy-Medium.woff') format('woff'), url('../public/fonts/Gilroy-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../public/fonts/Gilroy-Semibold.woff') format('woff'), url('../public/fonts/Gilroy-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../public/fonts/Gilroy-Extrabold.woff') format('woff'), url('../public/fonts/Gilroy-Extrabold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../public/fonts/Gilroy-Bold.woff') format('woff'), url('../public/fonts/Gilroy-Bold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Stolzl';
    src: url('../public/fonts/Stolzl-Regular.woff') format('woff'), url('../public/fonts/Stolzl-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Stolzl';
    src: url('../public/fonts/Stolzl-Medium.woff') format('woff'), url('../public/fonts/Stolzl-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Stolzl';
    src: url('../public/fonts/Stolzl-Bold.woff') format('woff'), url('../public/fonts/Stolzl-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Commissioner';
    src: url('../public/fonts/Commissioner-Light.woff') format('woff'), url('../public/fonts/Commissioner-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Commissioner';
    src: url('../public/fonts/Commissioner-Regular.woff') format('woff'), url('../public/fonts/Commissioner-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Commissioner';
    src: url('../public/fonts/Commissioner-Medium.woff') format('woff'), url('../public/fonts/Commissioner-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Commissioner';
    src: url('../public/fonts/Commissioner-SemiBold.woff') format('woff'), url('../public/fonts/Commissioner-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Commissioner';
    src: url('../public/fonts/Commissioner-Bold.woff') format('woff'), url('../public/fonts/Commissioner-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Hagrid';
    src: url('../public/fonts/Hagrid-Regular.woff') format('woff'), url('../public/fonts/Hagrid-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Dela';
    src: url('../public/fonts/DelaGothicOne-Regular.woff') format('woff'), url('../public/fonts/DelaGothicOne-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Atyp';
    src: url('../public/fonts/AtypDisplay-Regular.woff') format('woff'), url('../public/fonts/AtypDisplay-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Atyp';
    src: url('../public/fonts/AtypDisplay-Medium.woff') format('woff'), url('../public/fonts/AtypDisplay-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Atyp';
    src: url('../public/fonts/AtypDisplay-Semibold.woff') format('woff'), url('../public/fonts/AtypDisplay-Semibold.woff') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Atyp';
    src: url('../public/fonts/AtypDisplay-Bold.woff') format('woff'), url('../public/fonts/AtypDisplay-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
  
@font-face {
    font-family: 'Euro';
    src: url('../public/fonts/EurostileExt-Bla.woff') format('woff'), url('../public/fonts/EurostileExt-Bla.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
  
@font-face {
    font-family: 'Nface';
    src: url('../public/fonts/NeutralFaceRegular.woff') format('woff'), url('../public/fonts/NeutralFaceRegular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nface';
    src: url('../public/fonts/NeutralFaceBold.woff') format('woff'), url('../public/fonts/NeutralFaceBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('../public/fonts/Manrope-Regular.woff') format('woff'), url('../public/fonts/Manrope-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../public/fonts/Manrope-Medium.woff') format('woff'), url('../public/fonts/Manrope-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../public/fonts/Manrope-SemiBold.woff') format('woff'), url('../public/fonts/Manrope-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../public/fonts/Manrope-Bold.woff') format('woff'), url('../public/fonts/Manrope-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../public/fonts/Manrope-ExtraBold.woff') format('woff'), url('../public/fonts/Manrope-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
  
@font-face {
    font-family: 'Play';
    src: url('../public/fonts/PlayfairDisplay-SemiBold.woff') format('woff'), url('../public/fonts/PlayfairDisplay-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Play';
    src: url('../public/fonts/PlayfairDisplay-SemiBoldItalic.woff') format('woff'), url('../public/fonts/PlayfairDisplay-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Play';
    src: url('../public/fonts/PlayfairDisplay-Bold.woff') format('woff'), url('../public/fonts/PlayfairDisplay-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Play';
    src: url('../public/fonts/PlayfairDisplay-BoldItalic.woff') format('woff'), url('../public/fonts/PlayfairDisplay-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Soyuz';
    src: url('../public/fonts/SoyuzGrotesk-Bold.woff') format('woff'), url('../public/fonts/SoyuzGrotesk-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-Regular.woff') format('woff'), url('../public/fonts/Inter-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-Medium.woff') format('woff'), url('../public/fonts/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-SemiBold.woff') format('woff'), url('../public/fonts/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-Bold.woff') format('woff'), url('../public/fonts/Inter-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
  
@font-face {
    font-family: 'InterT';
    src: url('../public/fonts/InterTight-Medium.woff') format('woff'), url('../public/fonts/InterTight-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'InterT';
    src: url('../public/fonts/InterTight-SemiBold.woff') format('woff'), url('../public/fonts/InterTight-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'InterT';
    src: url('../public/fonts/InterTight-Bold.woff') format('woff'), url('../public/fonts/InterTight-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
  
@font-face {
    font-family: 'Muller';
    src: url('../public/fonts/MullerBold.woff') format('woff'), url('../public/fonts/MullerBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Qanelas';
    src: url('../public/fonts/Qanelas-Regular.woff') format('woff'), url('../public/fonts/Qanelas-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Qanelas';
    src: url('../public/fonts/Qanelas-Medium.woff') format('woff'), url('../public/fonts/Qanelas-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Qanelas';
    src: url('../public/fonts/Qanelas-SemiBold.woff') format('woff'), url('../public/fonts/Qanelas-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Qanelas';
    src: url('../public/fonts/Qanelas-Bold.woff') format('woff'), url('../public/fonts/Qanelas-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Qanelas';
    src: url('../public/fonts/Qanelas-ExtraBold.woff') format('woff'), url('../public/fonts/Qanelas-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Regular.woff') format('woff'), url('../public/fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Medium.woff') format('woff'), url('../public/fonts/Montserrat-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-SemiBold.woff') format('woff'), url('../public/fonts/Montserrat-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat-Bold.woff') format('woff'), url('../public/fonts/Montserrat-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas';
    src: url('../public/fonts/BebasNeue-Regular.woff') format('woff'), url('../public/fonts/BebasNeue-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas';
    src: url('../public/fonts/BebasNeue-Bold.woff') format('woff'), url('../public/fonts/BebasNeue-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Regular.woff') format('woff'), url('../public/fonts/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Medium.woff') format('woff'), url('../public/fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Bold.woff') format('woff'), url('../public/fonts/Roboto-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'rgsb';
    src: url('../public/fonts/RG-SpaciousBook.woff') format('woff'), url('../public/fonts/RG-SpaciousBook.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Actay';
    src: url('../public/fonts/Actay-Regular.woff') format('woff'), url('../public/fonts/Actay-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Actay';
    src: url('../public/fonts/Actay-Bold.woff') format('woff'), url('../public/fonts/Actay-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../public/fonts/Raleway-Regular.woff') format('woff'), url('../public/fonts/Raleway-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('../public/fonts/Raleway-Medium.woff') format('woff'), url('../public/fonts/Raleway-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('../public/fonts/Raleway-Bold.woff') format('woff'), url('../public/fonts/Raleway-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Wix';
    src: url('../public/fonts/WixMadeforText-Regular.woff') format('woff'), url('../public/fonts/WixMadeforText-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Wix';
    src: url('../public/fonts/WixMadeforText-Medium.woff') format('woff'), url('../public/fonts/WixMadeforText-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Wix';
    src: url('../public/fonts/WixMadeforText-SemiBold.woff') format('woff'), url('../public/fonts/WixMadeforText-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Wix';
    src: url('../public/fonts/WixMadeforText-Bold.woff') format('woff'), url('../public/fonts/WixMadeforText-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaN';
    src: url('../public/fonts/ProximaNova-Regular.woff') format('woff'), url('../public/fonts/ProximaNova-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ProximaN';
    src: url('../public/fonts/ProximaNova-Semibold.woff') format('woff'), url('../public/fonts/ProximaNova-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'ProximaN';
    src: url('../public/fonts/ProximaNova-Bold.woff') format('woff'), url('../public/fonts/ProximaNova-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Unisans';
    src: url('../public/fonts/UniSansHeavyCAPS.woff') format('woff'), url('../public/fonts/UniSansHeavyCAPS.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Steppe';
    src: url('../public/fonts/Steppe-Regular.woff') format('woff'), url('../public/fonts/Steppe-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Steppe';
    src: url('../public/fonts/Steppe-Medium.woff') format('woff'), url('../public/fonts/Steppe-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Steppe';
    src: url('../public/fonts/Steppe-Bold.woff') format('woff'), url('../public/fonts/Steppe-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Lighthaus';
    src: url('../public/fonts/Lighthaus.woff') format('woff'), url('../public/fonts/Lighthaus.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}



@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');