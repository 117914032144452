.whobirth {
    background-color: var(--main-bg);
    padding: 75px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
}
.whobirth::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 100px;
    width: 324px;
    height: 560px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/afterpregnancy/bear-left2.jpg');
    background-size: cover;
    pointer-events: none;
    mix-blend-mode: multiply;
}
.whobirth::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 250px;
    width: 418px;
    height: 492px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/afterpregnancy/bear-right2.jpg');
    background-size: cover;
    pointer-events: none;
    mix-blend-mode: multiply;
}
.whobirth_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    text-align: center;
}
.whobirth_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.wbt_1 {
    margin-bottom: 25px;
    line-height: 1.1;
}
.wbt_2 {
    line-height: 1;
    margin-bottom: 10px;
}
.whobirth_block {
    display: flex;
    gap: 20px;
    margin-bottom: 75px;
}
.whobirth_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    text-align: center;
    padding: 15px;
    transition: .2s ease-in-out;
}
.whobirth_item:hover {
    transform: translateY(-10px);
}
.whobirth_item-number {
    font-size: 64px;
    color: #FF4252;
    font-weight: 500;
    letter-spacing: -1px;
    margin-bottom: 10px;
}
.whobirth_item-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 12px;
}
.whobirth_item p {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.whobirth_img {
    max-width: 550px;
    margin: 25px auto 35px;
    z-index: 1;
    position: relative;
}
.whobirth_img img {
    border-radius: 30px;
}
.whobirth_subtitle {
    font-size: 18px;
    line-height: 1.55;
    margin-top: 10px;
    font-weight: 500;
}

@media(max-width: 991px) {
    .whobirth_title {
        font-size: 42px;
    }
    .whobirth_block {
        gap: 10px;
    }
    .whobirth::before, .whobirth::after {
        content: none;
    }
}
@media(max-width: 767px) {
    .whobirth_title {
        font-size: 36px;
    }
    .whobirth_block {
        flex-wrap: wrap;
    }
    .whobirth_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .whobirth_title {
        font-size: 24px;
    }
    .whobirth_title br {
        display: none;
    }
    .whobirth_item p {
        margin-bottom: 0;
    }
    .whobirth_title span {
        padding-bottom: 5px;
    }
}