.inventory {
    background-color: #F3F3F3;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_2.jpg');
    background-size: cover;
    background-position: center;
    padding: 85px 0;
}
.inventory_title {
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 15px;
}
.inventory_subtitle {
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 55px;
}
.inventory_block {
    display: flex;
}
.inventory_item {
    flex: 0 1 25%;
    max-width: 25%;
    text-align: center;
    margin-bottom: 20px;
}
.inventory_item:first-of-type img {
    margin-left: 25px;
}
.inventory_item p {
    font-size: 20px;
    line-height: 24px;
    margin-top: 25px;
}

@media(max-width: 991px) {
    .inventory {
        padding-bottom: 0;
    }
    .inventory_block {
        flex-wrap: wrap;
    }
    .inventory_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 80px;
    }
    .inventory_item p {
        font-size: 18px;
        line-height: 20px;
    }
}
@media(max-width: 767px) {
    .inventory {
        padding: 45px 0;
    }
    .inventory_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .inventory_subtitle {
        font-size: 18px;
        line-height: 22px;
    }
}
@media(max-width: 575px) {
    .inventory_title br {
        display: none;
    }
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
    .inventory_subtitle {
        font-size: 18px;
    }
    .inventory_block {
        margin: 0 -10px;
    }
    .inventory_item {
        margin-bottom: 35px;
        padding: 0 10px;
    }
    .inventory_item img {
        max-height: 110px;
    }
    .inventory_item p {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
    }
    .inventory_item p br {
        display: none;
    }
}
@media(max-width: 374px) {
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
}