.gift {
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    background-color: #F3F8FF;
}
.gift_title {
    text-align: center;
    text-transform: uppercase;
    font-size: 64px;
    line-height: 70px;
    margin-bottom: 5px;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: Mplus;
    filter: hue-rotate(320deg);
}
.gift_title img:last-of-type {
    display: none!important;
}
.gift_subtitle {
    text-align: center;
    font-size: 52px;
    line-height: 62px;
    font-family: Dela;
    margin-bottom: 40px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}
.gift_slide {
    margin: 0 10px;
}
.gift_slide__img {
    margin-bottom: 15px;
}
.gift_slide__img img {
    border-radius: 8px;
}
.gift_slide__info {
    margin-bottom: 5px;;
}
.gift_slide__bef {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
}
.gift_slide__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #767575;
    margin-bottom: 10px;
}
.gift_slide__aft {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FF9D42;
    font-weight: 600;
    text-decoration: none;
}
.gift_slide__aft span {
    text-decoration: line-through;
    color: #607276;
    font-weight: normal;
}
.gift_slide__number {
    font-size: 16px;
    line-height: 18px;
    color: #FFB800;
}
.gift_slider {
    margin-bottom: 40px;
}
.gift_string {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 25px;
    width: max-content;
    animation: scroll2 10s linear infinite;
}
.gift_string p {
    color: #fff;
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    margin: 0 15px;
}
.gift_string p span {
    color: #ED54F0;
}
@keyframes scroll2 {
    0% {
        transform: translateX(-566px);
    }
    100% {
        transform: translateX(0);
    }
}
.gift_slider2 .gift_slide {
    max-width: 260px;
    margin: 0 auto;
}
.gift_slider2 .slider_nav {
    position: absolute;
    left: 0;
    margin-left: 0;
    top: 50%;
    margin-top: 300px;
    z-index: 1;
    transition: .2s ease-in-out;
    height: 60px;
    width: 100%;
    opacity: 1;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
.gift_slider2 .slider_btn_prev {
    flex: 0 1 60px;
    max-width: 60px;
    height: 60px;
    text-align: right;
    cursor: pointer;
    background-color: #FC713C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    border-radius: 100%;
}
.gift_slider2 .slider_btn_next {
    flex: 0 1 60px;
    max-width: 60px;
    height: 60px;
    text-align: right;
    cursor: pointer;
    background-color: #FC713C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    border-radius: 100%;
}
.gift_slider2 .slider_btn_next img, .gift_slider2 .slider_btn_prev img {
    width: 16px;
    height: 32px;
}
.gift_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
}
.gift_item {
    flex: 0 1 200px;
    max-width: 200px;
    margin: 0 15px 20px;
}
.promo_btn {
    width: 432px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: uppercase;
    background: #0D8ED7;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 4px 12px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: .3s ease-in-out;
    margin: 15px auto 0;
}
.promo_btn::before {
    content: '';
    background: #21A3EC;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 4px 12px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.promo_btn:active::before {
    background: #0E85C8;
    box-shadow: 0px 8px 32px rgba(40, 173, 248, 0.61), inset 0px 12px 24px #135A83;
}
.promo_btn:hover {
    transform: translateY(-6px);
}
.promo_btn:hover::before {
    opacity: 1;
}
.promo_btn p {
    position: relative;
}

@media(max-width: 991px) {
    .gift {
        padding-bottom: 150px;
    }
    .gift_string {
        top: 5px;
        bottom: auto;
    }
}
@media(max-width: 575px) {
    .gift {
        padding-top: 50px;
    }
    .gift_string p {
        font-size: 16px;
        line-height: 18px;
    }
    .gift_slide__img {
        text-align: center;
    }
    .gift_slide__info {
        text-align: center;
    }
    .gift_string img {
        width: 25px;
    }
    .gift_title {
        display: flex;
        justify-content: center;
    }
    .gift_title br {
        display: none;
    }
    .gift_subtitle {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 34px;
    }
    .gift_slide__text {
        padding-right: 0;
    }
    .gift_title img:last-of-type {
        display: block!important;
    }
    .gift_title img:first-of-type {
        display: none!important;
    }
    .promo_btn {
        display: none;
    }
}