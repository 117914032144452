.studentres {
    overflow: hidden;
    position: relative;
    padding: 85px 0 45px;
}
.pretitle {
    text-align: center;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    line-height: 1.4;
}
.pretitle b {
    font-weight: 600;
    color: #fff;
}
.title {
    text-align: center;
    font-family: Unisans;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
}
.slider_block {
    max-width: 1660px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
}
.slider_nav {
    display: flex;
    gap: 16px;
    position: absolute;
    top: 50%;
    width: calc(100% + 140px);
    justify-content: space-between;
    margin-top: -80px;
    left: -70px;
}
.slider_btn_prev {
    background-color: #EBFF5E;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next {
    background-color: #EBFF5E;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next img,
.slider_btn_prev img {
    filter: brightness(0);
}
.slider {
    margin-bottom: 45px;
}
.slide {
    border: 1px solid rgba(255, 255, 255, 0.80);
    border-radius: 38px;
    background-color: #fff;
    overflow: hidden;
}
.slide_info {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
}
.slide_info div {
    flex: 0 1 50%;
    max-width: 50%;
    font-size: 15px;
    color: #535363;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slide_info div img {
    filter: brightness(0.1);
    margin-top: -2px;
    margin-right: 2px;
}
.slider .slider_nav {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    padding: 0 15px;
}
.slide_img {
    position: relative;
}
.slide_img > p {
    position: absolute;
    left: 18px;
    bottom: 18px;
    border-radius: 55.051px;
    background: #EBFF5E;
    color: #1D1E1A;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    padding: 4px 14px;
}
.slide_img > div {
    position: absolute;
    right: 18px;
    bottom: 18px;
}
.btn {
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 546px;
    height: 100px;
    padding: 10px;
    border-radius: 600px;
    background-color: #EBFF5E;
    color: #171717;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
  
@media (max-width: 575px) {
    .studentres {
        padding-top: 20px;
    }
    .pretitle {
      font-size: 18px;
    }
    .title {
      font-size: 26px;
      text-align: center;
      margin-bottom: 30px;
    }
    .slider {
      margin: 0 0 30px;
    }
    .btn {
      width: 100%;
      font-size: 18px;
      height: 70px;
      padding: 10px;
    }
    .slide_info div {
      font-size: 13px;
    }
    .slider .slider_nav {
      display: flex;
    }
    .slider_nav {
      position: absolute;
      top: 50%;
      padding: 0 10px;
      left: 0;
      z-index: 2;
      width: 100%;
      justify-content: space-between;
    }
    .slider_btn_next {
      width: 42px;
      height: 42px;
    }
    .slider_btn_prev {
      width: 42px;
      height: 42px;
    }
    .slide_info {
      padding: 17px 8px;
    }
    .title span {
      padding: 0;
      border: none;
    }
  }
  