.trainings {
    background-color: #FAFAFA;
    padding: 50px 0 30px;
}
.trainings_title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
}
.trainings_subtitle {
    margin-bottom: 60px;
    color: rgba(77, 87, 97, 0.75);
    letter-spacing: 0.01rem;
    text-align: center;
    font-size: 28px;
    line-height: 33.6px;
}
.trainings_block {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-bottom: 25px;
    position: relative;
}
.trainings_block::before {
    content: '';
    position: absolute;
    left: 200px;
    top: 19px;
    width: calc(100% - 400px);
    border: 1px #A9ADB2;
    border-style: dashed;
}
.trainings_item {
    flex: 0 1 320px;
    max-width: 320px;
    text-align: center;
    margin-bottom: 80px;
}
.trainings_number {
    width: 40px;
    height: 40px;
    background-color: #EE4777;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    border-radius: 100%;
    margin: 0 auto 8px;
    position: relative;
}
.trainings_text {
    font-size: 16px;
    line-height: 22.4px;
    color: rgba(77, 87, 97, 0.75);
}
.trainings_info {
    padding: 32px 40px 32px;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    max-width: 666px;
    margin: 0 auto 36px;
    text-align: center;
}
.trainings_info_img {
    margin-top: -88px;
}
.trainings_info_text {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}
.trainings_title2 {
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 36px;
    font-family: Qanelas;
    line-height: 50.4px;
    text-align: center;
    text-transform: uppercase;
}
.trainings_btn {
    width: 292px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    font-family: Qanelas;
    color: #F8F8F8;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    margin: 0 auto;
}
.trainings_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
@media(max-width:1199px) {
    .trainings_block::before {
        left: 140px;
        width: calc(100% - 280px);
    }
}
@media(max-width:991px) {
    .trainings_block::before {
        left: 100px;
        width: calc(100% - 200px);
    }
}
@media(max-width:767px) {
    .trainings_block {
        flex-wrap: wrap;
        gap: 30px;
    }
    .trainings_block::before {
        content: none
    }
    .trainings_item {
        margin-bottom: 30px;
    }
}
@media(max-width:575px) {
    .trainings {
        padding-top: 0;
    }
    .trainings_title {
        font-size: 32px;
        line-height: 35.2px;
        margin-bottom: 10px;
    }
    .trainings_subtitle {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 21.6px;
    }
    .trainings_block {
        gap: 0;
    }
    .trainings_text {
        font-size: 16px;
        line-height: 22.4px;
    }
    .trainings_info {
        padding: 30px;
    }
    .trainings_info_text {
        font-size: 18px;
        line-height: 23.4px;
    }
    .trainings_title2 {
        margin-bottom: 14px;
        font-size: 24px;
        line-height: 33.6px;
    }
    .trainings_btn {
        width: 100%;
        height: 65px;
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: normal;
    }
}