.start {
    padding: 100px 0;
    background-color: #F2F2F2;
    position: relative;
    overflow: hidden;
}
.start::before {
    content: '';
    position: absolute;
    width: 2690px;
    height: 1618px;
    left: -1545px;
    top: -709px;
    background: radial-gradient(50% 50% at 50% 50%, #4444FF 0%, rgba(242, 242, 242, 0) 100%);
    opacity: 0.3;
    filter: blur(250px);
    transform: matrix(0.61, 0.78, -0.81, 0.61, 0, 0);
    pointer-events: none;
}
.start::after {
    content: '';
    position: absolute;
    width: 2518px;
    height: 1448px;
    right: -1559px;
    bottom: -924px;
    background: radial-gradient(50% 50% at 50% 50%, #F14B52 0%, rgba(242, 242, 242, 0) 100%);
    opacity: 0.4;
    filter: blur(250px);
    transform: matrix(-0.59, -0.9, 0.7, -0.62, 0, 0);
    pointer-events: none;
}
.start_block {
    background-color: #fff;
    border-radius: 20px;
    max-width: 957px;
    margin: 0 auto;
    padding-top: 65px;
    padding-bottom: 85px;
    position: relative;
    z-index: 1;
}
.start_dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.start_title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    flex: 0 1 100%;
    max-width: 100%;
    margin-top: 25px;
}
.start_title span {
    color: var(--cpopa3-red);
}
.start_date {
    letter-spacing: 0.05em;
    font-size: 25px;
    line-height: 30px;
    font-weight: 500;
    text-transform: uppercase;
}
.start_date:first-of-type {
    margin-right: 50px;
    position: relative;
}
.start_date:first-of-type::before {
    content: '';
    position: absolute;
    right: -35px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 20px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/coursepop2/marker_blue.svg');
}
.start_info {
    text-align: center;
    padding-top: 60px;
}
.start_info p {
    line-height: 25px;
    max-width: 760px;
    margin: 15px auto 0;
}
.start_info p span {
    font-size: 25px;
    line-height: 32px;
    font-weight: bold;
}
.start_info p strong {
    font-weight: 600;
}
.start_guarantee {
    text-align: center;
    max-width: 760px;
    margin: 60px auto 0;
}
.start_guarantee__title {
    font-size: 25px;
    line-height: 32px;
    font-weight: bold;
    margin: 15px 0;
}
.start_guarantee__text {
    line-height: 25px;
}
.start_parts {
    text-align: center;
    margin-top: 60px;
}
.start_parts__title {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 18px;
    font-weight: bold;
    margin-top: 15px;
}
.start_parts__text {
    line-height: 25px;
    margin-bottom: 40px;
}
.start_parts__text strong {
    font-weight: 600;
}
.start_parts_block {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto 50px;
}
.start_parts_number {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: inline-block;
    border-top: 3px solid transparent;
    border-left: 3px solid #383838;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transform: rotate(45deg);
    font-size: 31px;
    line-height: 38px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.start_parts_number > * {
    transform: rotate(-45deg);
}
.start_parts_number2 {
    border-top: 3px solid #383838;
}
.start_parts_number3 {
    border-top: 3px solid #383838;
    border-right: 3px solid #383838;
}
.start_parts_number4 {
    border: 3px solid #383838;
}
.start_parts_number::before {
    content: '';
    position: absolute;
    left: 92px;
    top: 50%;
    margin-top: -82px;
    width: 65px;
    height: 2px;
    background-color: #383838;
    transform: rotate(-45deg);
}
.start_parts_number4::before {
    content: none;
}
.start_parts_item {
    flex: 0 1 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.start_parts_item > p {
    margin-top: 20px;
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0.04em;
}
.start_parts_item > p span {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #383838;
    opacity: .6;
}
.start_parts__btn {
    max-width: 800px;
    height: 90px;
    background: linear-gradient(92.57deg, #474747 -13.7%, #2B2B2C 84.73%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 60px;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

@media(max-width: 991px) {
    .start_parts__btn {
        padding: 0 25px;
        max-width: calc(100% - 30px);
    }
}
@media(max-width: 767px) {
    .start {
        padding: 45px 0;
    }
    .start_parts_number::before {
        content: none;
    }
    .start_title {
        font-size: 32px;
        line-height: 36px;
        margin: 10px 0 30px;
    }
    .start_block {
        padding: 30px 20px 40px;
    }
    .start_date {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
    }
    .start_date:first-of-type {
        margin-right: 0;
        font-size: 20px;
        line-height: 24px;
    }
    .start_date:first-of-type::before {
        content: none;
    }
    .start_date:nth-of-type(2) {
        order: 1;
        text-transform: inherit;
        font-size: 18px;
        line-height: 22px;
        color: #989898;
        border-bottom: 1px solid #E7E7E7;
        padding-bottom: 20px;
        margin: 0 20px;
    }
    .start_info p {
        font-size: 16px;
        line-height: 21px;
    }
    .start_info p span {
        font-size: 25px;
        line-height: 31px;
    }
    .start_guarantee__title, .start_parts__title {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 5px;
    }
    .start_guarantee__text, .start_parts__text {
        font-size: 16px;
        line-height: 22px;
    }
    .start_parts_block {
        flex-wrap: wrap;
        padding-bottom: 25px;
    }
    .start_parts_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .start_parts_item > p {
        margin-top: 5px;
        font-size: 21px;
        line-height: normal;
    }
    .start_parts_item > p span {
        font-size: 16px;
    }
}
@media(max-width: 575px) {
    .start_parts__title {
        font-size: 20px;
        line-height: 22px;
    }
    .start_info p br, .start_parts__title br, .start_parts__text br, .start_title br {
        display: none;
    }
    .start_parts_block {
        margin-bottom: 0;
    }
    .start_parts__btn {
        font-size: 18px;
        padding: 0 20px;
        max-width: 100%;
        margin-bottom: 45px;
    }
    .start_title {
        font-size: 24px;
        line-height: 26px;
    }
    .start_parts__text, .start_guarantee__text, .start_info p {
        font-size: 14px;
        line-height: 18px;
    }
    .start_info p span {
        font-size: 20px;
        line-height: 22px;
    }
    .start_info p {
        font-size: 14px;
        line-height: 21px;
    }
    .start_guarantee {
        margin-top: 30px;
    }
}
@media(max-width: 374px) {
    .start_title {
        font-size: 26px;
        line-height: 30px;
    }
}

.start_form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 16px;
}
.start_form_left {
    position: relative;
    padding-left: 51px;
}
.start_form_left::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="16" fill="%235A5AFD"/><circle cx="16" cy="16" r="7.5" fill="white"/></svg>');
}
.start_form_title {
    margin-bottom: 3px;
    font-family: Qanelas;
    color: #383838;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.3;
}
.start_form_subtitle {
    font-family: Qanelas;
    font-size: 18px;
    line-height: 1.4;
    color: #383838;
}
.start_form_right {
    position: relative;
}
.start_form_discount {
    position: absolute;
    right: 0;
    top: -52px;
    width: 80px;
    height: 56px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="56" viewBox="0 0 80 56" fill="none"><g filter="url(%23filter0_d_2014_108)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.8994 23.4467C10.8381 23.4704 10.7749 23.4888 10.7105 23.5017C10.0854 23.6273 9.68052 24.2357 9.80607 24.8608L11.9419 35.494C13.2471 41.9917 19.5725 46.201 26.0701 44.8959L60.3847 38.0032C66.8823 36.6981 71.0917 30.3727 69.7865 23.875L68.7487 18.7081C68.0167 15.0641 64.4693 12.7034 60.8253 13.4354L58.719 13.8584C56.7244 14.2591 54.731 13.1653 53.9975 11.2677C53.1652 9.11461 50.7451 8.04391 48.592 8.8762L10.8994 23.4467Z" fill="%23DB5AF4"/></g><defs><filter id="filter0_d_2014_108" x="0.136248" y="0.152908" width="79.5345" height="55.8332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1.20587"/><feGaussianBlur stdDeviation="4.82348"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.486275 0 0 0 0 0.215686 0 0 0 0 0.468235 0 0 0 0.31 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2014_108"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2014_108" result="shape"/></filter></defs></svg>');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none!important;
}
.start_form_discount p {
    color: #fff;
    transform: rotate(-15deg);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
}
.start_form_prices {
    display: flex;
    gap: 12px;
    align-items: baseline;
    text-align: right;
}
.start_form_prices span {
    text-decoration: line-through;
    font-family: Qanelas;
    color: #BBBBBB;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    display: none!important;
}
.start_form_prices p {
    font-family: Qanelas;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.3;
    color: #5A5AFD;
}
.start_form_ot {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    font-family: Qanelas;
    text-align: right;
}
@media(max-width: 575px) {
    .start_form {
        padding: 0;
        flex-direction: column;
        order: 2;
        margin-bottom: 0;
        padding-top: 16px;
    }
    .start_form_left {
        padding-left: 24px;
    }
    .start_form_left::before {
        width: 18px;
        height: 18px;
        background-size: cover;
        top: 2px;
    }
    .start_form_title {
        font-size: 18px;
    }
    .start_form_subtitle {
        font-size: 14px;
    }
    .start_form_prices {
        justify-content: center;
    }
    .start_form_ot {
        text-align: center;
    }
    .start_form_discount {
        display: none;
    }
}