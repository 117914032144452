.gallery {
    background-color: var(--pink-bg3);
    padding: 60px 0;
}
.gallery_title {
    font-family: Bebas;
    font-size: 52px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
}
.gallery_subtitle {
    font-family: Bebas;
    font-size: 36px;
    line-height: 1.23;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
}
.gallery_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0 60px;
}
.gallery-item {
    flex: 0 1 20%;
    max-width: 20%;
    padding: 0 10px;
    margin-bottom: 20px;
}
.gallery-item img {
    cursor: pointer;
}
.gallery .main_btn_p {
    min-width: 300px;
    height: 70px;
    display: block;
    margin: 30px auto 0;
}
.lg-sub-html {
    display: none;
}

@media(max-width: 575px) {
    .gallery_title {
        font-size: 38px;
        margin-bottom: 15px;
    }
    .gallery_subtitle {
        font-size: 30px;
        margin-bottom: 30px;
    }
}