.inventory {
    position: relative;
    background-color: #fff;
    padding: 85px 0;
    overflow: hidden;
}
.inventory>div {
    position: relative;
    z-index: 1;
}
.inventory::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/inv_bg.png');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    transform: rotate(180deg);
}
.inventory::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/inv_bg.png');
    background-repeat: no-repeat;
    background-position: 100% 50%;
}
.inventory_title {
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 15px;
}
.inventory_subtitle {
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 55px;
}
.inventory_block {
    display: flex;
    justify-content: center;
}
.inventory_item {
    flex: 0 1 25%;
    max-width: 25%;
    text-align: center;
    margin-bottom: 25px;
}
.inventory_item p {
    font-size: 20px;
    line-height: 24px;
    margin-top: 25px;
}

@media(max-width: 991px) {
    .inventory {
        padding-bottom: 0;
    }
    .inventory_block {
        flex-wrap: wrap;
    }
    .inventory_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 80px;
    }
    .inventory_item p {
        font-size: 18px;
        line-height: 20px;
    }
}
@media(max-width: 767px) {
    .inventory {
        padding: 45px 0;
    }
    .inventory_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .inventory_subtitle {
        font-size: 18px;
        line-height: 22px;
    }
}
@media(max-width: 575px) {
    .inventory::before {
        content: none;
    }
    .inventory::after {
        opacity: 0.3;
    }
    .inventory_title br {
        display: none;
    }
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
    .inventory_subtitle {
        font-size: 18px;
    }
    .inventory_block {
        margin: 0 -10px;
    }
    .inventory_item {
        margin-bottom: 35px;
        padding: 0 10px;
    }
    .inventory_item img {
        max-height: 110px;
    }
    .inventory_item p {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
    }
    .inventory_item p br {
        display: none;
    }
}
@media(max-width: 374px) {
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
}