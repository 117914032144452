.studentres {
    overflow: hidden;
    position: relative;
}
.titles {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
}
.title {
    font-weight: 500;
    font-size: 44px;
    line-height: 1.3;
    letter-spacing: -0.5px;
}
.title p{
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    color: #57747D;
}


.title span {
    color: #F18D7C ;
}
.slider_nav {
    display: flex;
    gap: 16px;
}
.slider_btn_prev {
    background-color: #43AFBE;
    border-radius: 100%;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.slider_btn_next {
    background-color: #43AFBE;
    border-radius: 100%;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    cursor: pointer;
}
.slide_img {
    position: relative;
}
.student_name {
    position: absolute;
    bottom: 18px;
    left: 17px;
    background: #FB907F;
    padding: 4px 17px;
    border-radius: 55px;
    font-size: 19px;
    font-weight: 600;
    color: #fff;

}
.slider {
    margin-bottom: 45px;
}
.slide {
    border: 1px solid #DEEAFF;
    border-radius: 38px;
    overflow: hidden;
}
.slide_info {
    display: flex;
    flex-wrap: wrap;
    padding: 13px;
    background-color: #fff;
}
.slide_info div {
    flex: 0 1 50%;
    max-width: 50%;
    font-size: 15px;
    color: #535363;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slider .slider_nav {
    display: none;
}

@media(max-width: 575px) {
    .titles {
        flex-direction: column;
    }
    .title {
        font-size: 26px;
        text-align: center;
    }
    .titles .slider_nav {
        /* display: none; */
    }
    .slider {
        margin: 0 15px 30px;
    }
    .slide_info div {
        font-size: 13px;
    }
    .slider .slider_nav {
        display: flex;
    }
    .slider_nav {
        position: absolute;
        top: 50%;
        margin-top: 20px;
        left: 0;
        z-index: 2;
        width: 100%;
        justify-content: space-between;
    }
    .slider_btn_next {
        width: 42px;
        height: 42px;
    }
    .slider_btn_prev {
        width: 42px;
        height: 42px;
    }
}