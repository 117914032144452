.reviews {
  overflow: hidden;
  padding: 40px 15px;
  position: relative;
}
.reviews_title {
  text-align: center;
  margin-bottom: 70px;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  color: #333844;
}
.reviews_title span {
  background-color: #f6faff;
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}

.block {
  /* display: flex;
    align-items: center;
    gap: 40px; */
  max-width: 990px;
  margin: 0 auto;
  padding: 0 80px;
  position: relative;
}
.swiper {
  /* flex: 1; */
}
.nav {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  /* padding-top: 40px; */
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  margin-top: -25px;
}
.slide {
  /* text-align: center;
    max-width: max-content; */
  cursor: pointer;
}
.slide img {
  border-radius: 43px;
}

.slider_btn_prev,
.slider_btn_next {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #5690f3;
  /* box-shadow: 0px 3.49585px 3.49585px 0px rgba(0, 0, 0, 0.25); */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.slider_btn_prev:hover,
.slider_btn_next:hover {
  opacity: 0.8;
}
.slider_btn_prev img,
.slider_btn_next img {
  transition: 0.3s ease-in-out;
}
.slider_btn_next {
  transform: rotate(180deg);
}
.btn {
  transition: 0.2s ease-in-out;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 18px;
  background-color: #3070ed;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.44px;
  padding: 20px 40px;
  cursor: pointer;
  margin: 32px auto 0;
  justify-content: center;
}
.btn:hover {
  transform: translateY(-4px);
}
.reviews_title em {
  font-style: normal;
}

@media (max-width: 575px) {
  .reviews {
    padding: 60px 0;
    position: relative;
    border-radius: 40px;
  }
  .nav {
    padding-top: 0;
    position: absolute;
    width: 100%;
    bottom: 260px;
    z-index: 1;
    left: 0;
    /* padding-left: 15px; */
    /* padding-right: 15px; */

    /* position: relative; */
    /* left: 0; */
    /* top: 50%; */
    /* margin-top: -25px; */
    justify-content: space-between;
  }
  .reviews_title {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 24px;
    text-align: left;
  }
  .reviews_title span {
    padding: 0;
    border: none;
  }
  .reviews_title em {
    color: #ef3f9e;
  }
  .block {
    padding-left: 0;
    padding-right: 0;
    gap: 10px;
  }
  .slide {
    max-width: 100%;
    text-align: center;
  }
  .slider_btn_next,
  .slider_btn_prev {
    width: 50px;
    height: 50px;
    padding: 8px;
  }
  .promo_btn {
    width: 100%;
    height: 74px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px;
  }
  .btn {
    margin: 32px 10px 0;
    max-width: 100%;
  }
  .slide img {
    /* max-width: 225px; */
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.79);
  backdrop-filter: blur(8px);
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  z-index: 99;
  transition: 0.3s ease-in-out;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal_active {
  opacity: 1;
  pointer-events: all;
}
.close_btn {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #2e2e2f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -25px;
  z-index: 99;
}
.close_btn svg {
  width: 16px;
  height: 17px;
}
.close_btn svg path {
  transition: 0.3s ease-in-out;
}
.close_btn:hover {
  background: linear-gradient(
      98deg,
      rgba(255, 255, 255, 0.25) -16.4%,
      rgba(255, 255, 255, 0.1) 39.36%,
      rgba(255, 255, 255, 0) 74.55%
    ),
    #ec6a67;
}
.modal_img {
  max-width: 560px;
  margin: 0 auto;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.modal_active .modal_img {
  transform: scale(1);
}
@media (max-width: 575px) {
  .modal {
    padding: 35px 15px;
  }
  .close_btn {
    left: 0;
    top: 0;
    margin-left: 0;
    position: relative;
  }
}
