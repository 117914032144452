.programs {
    padding: 50px 0;
}
.programs_title {
    font-family: Nface;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    font-size: 35px;
    line-height: 1.3;
}
.programs_subtitle {
    color: #6A6A6A;
    font-size: 20px;
    line-height: 1.35;
    margin-bottom: 45px;
    text-align: center;
}
.programs_subtitle span {
    color: #282828;
    font-weight: 600;
}
.programs_date {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #fff;
    letter-spacing: 0.02rem;
}
.programs_date b {
    color: #C1C1C1;
    font-weight: normal;
}
.programs_date img {
    margin-right: 12px;
}
.programs_title2 {
    margin-bottom: 25px;
    font-family: InterT;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
}
.programs_block {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}
.programs_item {
    flex: 0 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    border-radius: 32px;
    border: 1px solid #ECECEC;
    background: #fff;
    padding: 24px;
}
.programs_info {
    
    display: flex;
    gap: 24px;
}
.programs_item:last-of-type .programs_info {
    margin-bottom: 0;
}
.programs_info_img {
    flex: 0 1 112px;
    max-width: 112px;
}
.programs_info_img img {
    border-radius: 16px;
}
.programs_info_img img:nth-of-type(2) {
    display: none!important;
}
.programs_info_info {
    flex: 1;
}
.programs_info_info:last-child {
    border-bottom: none;
}
.programs_info_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.2;
    gap: 10px;
}
.programs_info_info:last-child {
    border-bottom: none;
}
.programs_info_title p {
    font-family: 'Manrope';
    color: #131313;
    font-size: 20px;
}
.programs_info_title span {
    background-color:  #C0D5E2;
    border-radius: 56px;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    flex: 0 1 95px;
    max-width: 95px;
    text-align: center;
    height: max-content;
}
.programs_info_subtitle {
    color: #6A6A6A;
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 400;
}
.programs_info_text {
    border-bottom: 1px solid #ECECEC;
}
.programs_info_text:last-child {
    border-bottom: none;
}

.programs_info_text > p {
    font-weight: 500;
    line-height: 1.25;
    padding: 10px 0;
    color: #6A6A6A;
    position: relative;
    cursor: pointer;
}
.programs_info_text_inner {
    color: #6A6A6A!important;
}
.programs_info_text > p::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -10px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10.0001 4.34315V15.6569M4.34326 10H15.657" stroke="%23FF4E53" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    width: 20px;
    height: 20px;
    transition: .3s ease-in-out;
}
.programs_info_text_inner {
    padding-bottom: 0;
    transition: .3s ease-in-out;
    opacity: 0;
    max-height: 0;
    pointer-events: none;
}
.programs_block2 {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin: 60px 0;
}
.programs_item2 {
    flex: 0 1 460px;
    max-width: 460px;
}
.programs_item2_info {
    background-color: #1E2025;
    padding: 44px 34px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.programs_info2_title {
    margin-bottom: 22px;
    text-align: center;
    font-family: InterT;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.15;
    color: #fff;
    text-transform: uppercase;
}
.programs_info2 {
    display: flex;
    justify-content: center;
}
.programs_info2_item:first-of-type {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    padding-right: 26px;
}
.programs_info2_item:last-of-type {
    padding-left: 26px;
}
.programs_info2_img {
    text-align: center;
    margin-bottom: 16px;
}
.programs_info2_text p {
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
}
.programs_info2_text p span {
    margin-top: 4px;
    display: block;
    color: #C1C1C1;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.25;
}
.programs_item2_prices {
    background-color: #292B30;
    padding: 24px 34px 40px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.programs_item2_price {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.3;
    color: #fff;
    margin-bottom: 20px;
}
.programs_item2_price span {
    padding-left: 20px;
    color: #787878;
    font-weight: normal;
    text-decoration: line-through;
}
.programs_item2_btn {
    width: 100%;
    height: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: InterT;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.12rem;
    border-radius: 12px;
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #3172D3;
    box-shadow: 0px 19px 50px 0px rgba(49, 114, 211, 0.60), 0px 4px 0px 0px #2C66BC;
    cursor: pointer;
    position: relative;
    padding: 0 40px;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    margin: 0 auto;
}
.programs_item2_btn:hover {
    transform: translateY(-4px);
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #438EFF;
    box-shadow: 0px 19px 50px 0px rgba(49, 114, 211, 0.60), 0px 4px 0px 0px #2C66BC;
}
.programs_item2_btn:active {
    background: #316ECA;
    box-shadow: 0px 19px 50px 0px rgba(49, 114, 211, 0.39), 0px 4px 0px 0px #2C66BC;
}
.btn_block{
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.btn_block span {
    font-family: Unisans;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.33px;
    line-height: 1.1;
}
.remains_n_place {
    font-family: Unisans;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.3px;
    margin-bottom: 3px;
    text-align: center;
}
.remains_n_place span {
    color: #FF724D;
}
.remains_n_text {
    color: #FF724D;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
}
.btn {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 100px;
    border-radius: 60px;
    background: #FF4E53;
    color: #fff;
    letter-spacing: 1px;
    padding: 20px 79px;
    cursor: pointer;

}
.btn:hover {
    transform: translateY(-4px);
}

.countdown p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #2B3137;
    text-align: center;
}
.countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #FF4E53;
    font-size: 60px;
    font-family: Nface;
    line-height: 1.35;
    font-weight: normal;
    justify-content: center;
}
.countdown_timer > div div {
    display: flex;
    gap: 3px;
}
@media(max-width: 575px) {
    .countdown p {
        font-size: 16px;
    }
    .programs {
        padding: 25px 0;
    }
    .countdown_timer > div {
        font-size: 48px;
    }
    .btn_block{
        flex-direction: column;
    }
    .remains_n_place {
        font-size: 20px;
        text-align: center;
    }
    .btn {
        font-size: 20px;
        width: 100%;
        height: 80px;
        text-align: center;
        padding: 20px;
        max-width: 100%;
        justify-content: center;
    }
    .programs {
        background-image: none;
        padding: 50px 0 20px;
    }
    .programs_sticky {
        flex-wrap: wrap;
        gap: 25px;
    }
    .programs_pretitle {
        font-size: 18px;
    }
    .programs_title {
        font-size: 22px;
        margin-bottom: 24px;
    }
    .programs_subtitle {
        font-size: 16px;
        margin-bottom: 30px;
    }
    .programs_date {
        font-size: 15px;
    }
    .programs_sticky_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_title2 {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .programs_info_img {
        /* display: none; */
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_info_img img:nth-of-type(1) {
        display: none!important;
    }
    .programs_info_img img:nth-of-type(2) {
        display: inline-block!important;
        width: 100%;
    }
    .programs_info_title span {
        font-size: 12px;
        flex: none;
        max-width: auto;
    }
    .programs_info {
        padding: 20px;
        flex-direction: column;
    }
    .programs_info_title {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .programs_item {
        padding: 0;
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_block2 {
        flex-wrap: wrap;
        margin-bottom: 0;
        margin-top: 16px;
    }
    .programs_item2 {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .programs_info2 {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .programs_item2_info {
        padding: 20px 22px;
    }
    .programs_info2_title {
        font-size: 20px;
        text-align: left;
    }
    .programs_info2_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0!important;
        display: flex;
    }
    .programs_info2_item:first-of-type {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 16px;
        padding-bottom: 16px!important;
    }
    .programs_info2_img {
        margin-bottom: 0;
    }
    .programs_info2_text {
        padding-left: 16px;
    }
    .programs_info2_text p {
        text-align: left;
    }
    .programs_item2_prices {
        padding: 20px 20px 30px;
    }
    .programs_item2_btn {
        font-size: 18px;
        text-align: center;
    }
    .programs_item2_price {
        text-align: left;
        font-size: 20px;
    }
}