.afterall {
    padding: 75px 0 100px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/afterall_bg_2.jpg');
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
}
.afterall::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 529px;
    top: -90px;
    width: 386px;
    height: 388px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/afterall_yoga.png');
}
.afterall_block {
    display: flex;
    margin: 60px -15px 65px;
}
.afterall_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px;
    text-align: center;
}
.afterall_info__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 260px;
    margin-bottom: 30px;
}
.afterall_item:first-of-type .afterall_info__img {
    margin-top: -25px;
}
.afterall_item:first-of-type p {
    padding-top: 25px;
}
.afterall_item p {
    font-size: 30px;
    line-height: 33px;
}
.afterall_item p strong {
    font-weight: 600;
}
.afterall_text {
    text-align: center;
    font-size: 25px;
    line-height: 32px;
}
.afterall_text strong {
    font-size: 30px;
    line-height: 39px;
    font-weight: 600;
}

@media(max-width: 1199px) {
    .afterall {
        overflow: hidden;
    }
    .afterall::before {
        width: 160px;
        height: 160px;
        background-size: cover;
        margin-left: 360px;
        top: 30px;
    }
}
@media(max-width: 991px) {
    .afterall_block {
        flex-wrap: wrap;
        justify-content: center;
    }
    .afterall_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .afterall_info__img {
        margin-bottom: 0;
    }
    .afterall_item p {
        font-size: 20px;
        line-height: 22px;
    }
    .afterall_item p br {
        display: none;
    }
    .afterall_text, .afterall_text strong {
        font-size: 20px;
        line-height: 22px;
    }
    .afterall {
        padding: 45px 0;
    }
    .afterall_block {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .afterall_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .afterall_item p, .afterall_text, .afterall_text strong {
        font-size: 16px;
        line-height: 21px;
    }
}
@media(max-width: 575px) {
    .afterall_info__img {
        width: 30%;
        height: auto;
        margin: 0 auto;
    }
    .afterall_item:first-of-type p {
        padding-top: 0;
    }
    .afterall_block {
        margin-top: 50px;
    }
}