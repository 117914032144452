.velvet_start3 {
    width: 100%;
}
.velvet_start3 h2 {
    display: none;
}
.velvet_start3 .sales-widget__offers {
    margin-top: 0;
    width: 100%;
}
.velvet_start3 .chakra-button {
    outline: none;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Manrope;
    font-size: 22px;
    letter-spacing: 0.12em;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 90px;
    background: #E41F32;
    box-shadow: 0px 12.14664077758789px 32.39104080200195px 0px rgba(164, 12, 20, 0.36), 0px -4.048880100250244px 4.048880100250244px 0px rgba(164, 12, 20, 0.50) inset;
    cursor: pointer;
    position: relative;
    margin: 0 auto 20px;
}
.velvet_start3 .chakra-button:hover {
    background: #E41F32;
    box-shadow: 0px 12.14664077758789px 32.39104080200195px 0px rgba(253, 54, 73, 0.56), 0px -4.048880100250244px 4.048880100250244px 0px rgba(235, 26, 46, 0.50) inset;
}
.velvet_start3 .chakra-button:active {
    background: #C31727;
    box-shadow: 0px 12.14664077758789px 32.39104080200195px 0px rgba(164, 12, 20, 0.36), 0px -4.048880100250244px 4.048880100250244px 0px rgba(164, 12, 20, 0.50) inset;
}
.velvet_start3 .chakra-button p {
    position: relative;
}
.velvet_start3 form .chakra-button {
    margin-bottom: 5px;
}
.velvet_start3 .sales-widget__user-form button > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-size: 14px;
    line-height: normal;
    text-transform: lowercase;
}
.velvet_start3 .gt-offer {
    background-color: #fff;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(55, 59, 82, 0.2);
}
.velvet_start3 .gt-offer > label {
    margin-bottom: 10px;
}
.velvet_start3 .gt-offer > label > .chakra-checkbox__control {
    background: #8436e3;
    border-color: #8436e3;
}
.velvet_start3 .gt-offer:last-of-type {
    border-bottom: none;
}
.velvet_start3 .chakra-radio__control {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: #fff!important;
    position: relative;
    border: none!important;
    outline: none!important;
    border: 8.5px solid #DED9D5!important;
}
.velvet_start3 .chakra-radio__control[data-checked] {
    border-color: #FCA138!important;
}
.velvet_start3 .chakra-radio__control::before {
    width: 16px!important;
    height: 16px!important;
    background-color: #fff!important;
    border-radius: 100%!important;
}
.velvet_start3 .gt-offer__title {
    font-size: 22px;
    line-height: 26px;
    font-family: Manrope;
    font-weight: bold;
    letter-spacing: -0.01em;
    padding-left: 0;
    color: #0A0A0D;
}
.velvet_start3 .gt-offer__title ~ .chakra-text {
    padding-left: 0;
    font-family: Manrope;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.velvet_start3 span.chakra-radio__label > .chakra-stack {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}
.velvet_start3 span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}
.velvet_start3 .gt-offer__marketing-price {
    font-family: Manrope;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    order: 2;
    color: rgba(22, 19, 19, 0.5);
}
.velvet_start3 .gt-offer__price {
    font-family: Manrope;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #203B51;
    margin-bottom: 5px;
}
.velvet_start3 .sales-widget__selected-offers {
    max-width: 800px;
    background-color: #fff;
    padding: 40px;
}
.velvet_start3 .sales-widget__selected-offers > .chakra-stack {
    display: flex;
    align-items: flex-start;
}
.velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
    font-size: 20px;
    line-height: 26px;
    font-family: Manrope;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding-left: 20px;
    margin-bottom: 10px;
    color: #0A0A0D;
}
.velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2) {
    padding-left: 20px;
    font-family: Manrope;
    font-size: 16px;
    line-height: 26px;
    color: rgba(10, 10, 13, 0.75);
}
.velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack {
    display: flex;
    flex-direction: column;
}
.velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(1) {
    font-family: Manrope;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: -0.01em;
    order: 2;
    color: #203B51;
}
.velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) > .chakra-stack > .chakra-text:nth-of-type(2) {
    font-family: Manrope;
    font-size: 20px;
    line-height: 22px;
    font-weight: 800;
    letter-spacing: -0.01em;
    color: #1C1C1A;
    margin-bottom: 5px;
}
.velvet_start3 .sales-widget__user-form {
    max-width: 800px;
}
.velvet_start3 .sales-widget__user-form input {
    background-color: #fff;
    border-radius: 0;
}
.velvet_start3 .sales-widget__offers > .chakra-stack {
    min-width: 100%;
}
.velvet_start3 .sales-widget__offers > .chakra-radio-group > .chakra-stack {
    max-width: 100%;
    margin-bottom: 20px;
}
.velvet_start3 .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-end;
}
.velvet_start3 .sales-widget__offers > .chakra-stack .chakra-divider {
    display: none;
}
.velvet_start3 .sales-widget__offers > .chakra-stack .chakra-divider ~ div {
    margin-bottom: 15px;
}
.velvet_start3 .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-marketing-price {
    font-family: Manrope;
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.01em;
    text-decoration: line-through;
    color: rgba(22, 19, 19, 0.5);
}
.velvet_start3 .sales-widget__offers > .chakra-stack .chakra-divider ~ div .gt-offer__total-price {
    color: #1C1C1A;
}
@media(max-width: 575px) {
    .velvet_start3 .gt-offer, .velvet_start3 .sales-widget__selected-offers {
        padding: 10px;
    }
    .velvet_start3 span.chakra-radio__label > .chakra-stack, .velvet_start3 .sales-widget__selected-offers > .chakra-stack {
        flex-direction: column;
    }
    .velvet_start3 span.chakra-radio__label .chakra-stack .chakra-stack:nth-of-type(2) {
        margin-top: 20px;
        align-items: flex-end;
        width: 100%;
    }
    .velvet_start3 .gt-offer__title {
        font-size: 18px;
        line-height: 22px;
        padding-left: 5px;
    }
    .velvet_start3 .gt-offer__title ~ .chakra-text {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
    }
    .velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(2), .velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(1) > .chakra-text:nth-of-type(1) {
        padding-left: 0;
    }
    .velvet_start3 .sales-widget__selected-offers > .chakra-stack > .chakra-stack:nth-of-type(2) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
    }
    .velvet_start3 .sales-widget__user-form button {
        margin-top: 15px;
    }
    .velvet_start3 .sales-widget__user-form {
        padding: 0;
    }
    .velvet_start3 .chakra-button {
        height: 60px;
        width: 90%;
    }
    .velvet_start3 form .chakra-button {
        width: 90%;
        margin-top: 0;
    }
    .velvet_start3 .chakra-button p {
        top: 0;
    }
    .velvet_start3 .chakra-checkbox__label > .chakra-stack > .chakra-stack:nth-of-type(2) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .velvet_start3 .gt-offer__marketing-price {
        margin-left: 20px;
    }
    .velvet br {
        display: none;
    }
}