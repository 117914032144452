.slider {
    background-color: #F2F2F2;
    padding: 110px 0 45px;
}
.swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    padding-top: 45px;
}
.swiper_slide {
    /* padding: 26px;
    background-color: #272727;
    border-radius: 8px;
    min-height: 529px; */
    height: 350px;
    display: flex;
    justify-content: center;
}
.slide_title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #fff;
    margin-bottom: 13px;
}
.slide_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 25px;
}
.slide_tag {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #444444;
    border-radius: 12px;
    padding: 9px 12px;
}
.slide_res {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: #fff;
    margin-bottom: 15px;
}
.swiper_slide ul li {
    position: relative;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    padding-left: 28px;
}
.swiper_slide ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 10px;
    height: 10px;
    background-color: #EE4777;
    border-radius: 2px;
}
.slider_nav {
    display: flex!important;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
.slider_btn_prev {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #656565;
    border-radius: 100%;
    margin-right: 15px;
    cursor: pointer;
}
.slider_btn_next {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    background-color: #656565;
    border-radius: 100%;
    cursor: pointer;
}
.slider_top {
    position: relative;
}
.slider_top__title {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
}


@media(max-width: 1199px) {
    .swiper > div {
        display: flex;
    }
    /* .swiper_slide {
        min-height: auto;
        height: auto;
    } */
    .slide_title {
        font-size: 26px;
        line-height: 30px;
    }
    .swiper_slide ul li {
        font-size: 18px;
        line-height: 24px;
    }
}
@media(max-width: 991px) {
    .slider_top__title {
        font-size: 30px;
        line-height: 42px;
    }
}
@media(max-width: 768px) {
    .slider_top__title br {
        display: none;
    }
    .slider_top__title {
        font-size: 26px;
        line-height: 36px;
        padding-bottom: 70px;
    }
}
@media(max-width: 575px) {
    .swiper {
        padding-top: 30px;
    }
    .slider {
        padding: 45px 10px 0;
    }
    .swiper_slide {
        padding: 20px;
    }
    .slide_title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .slider_top__title {
        font-size: 24px;
        line-height: 26px;
    }
    .slide_res {
        font-size: 16px;
        line-height: 18px;
    }
    .swiper_slide ul li {
        font-size: 14px;
        line-height: 20px;
        padding-left: 21px;
    }
    .swiper_slide ul li::before {
        width: 8px;
        height: 8px;
        top: 8px;
    }
    .slide_tag {
        padding: 6px 8px;
        font-size: 12px;
    }
}