.form {
    padding: 85px 0 100px;
    background-color: #F6F6F6;
}
.form_block {
    background-color: #fff;
    margin: 0 auto;
    max-width: 985px;
    padding: 75px 85px 90px;
    border-radius: 32px;
}
.form_title {
    margin-bottom: 26px;
    text-align: center;
    font-family: Actay;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.1;
}
.form_block2 {
    border-radius: 20px;
    background: #F6F6F6;
    display: flex;
    gap: 15px;
    padding: 20px;
    margin-bottom: 36px;
}
.form_block2_img {
    flex: 0 1 125px;
    max-width: 125px;
}
.form_block2_info {
    flex: 1;
}
.form_block2_title {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
}
.form_block2_text {
    font-size: 18px;
    line-height: 1.1;
    margin-bottom: 10px;
}
.form_block2_text:last-of-type {
    margin-bottom: 0;
}
.countdown {
    margin-bottom: 45px;
}
.countdown > p {
    text-align: center;
    margin-bottom: 8px;
    font-family: Actay;
    color: #FB4F54;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.2;
}
.countdown div {
    display: flex;
    justify-content: center;
    font-family: Actay;
    color: #FB4F54;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
}
.form_hook {
    display: flex;
    flex-direction: column;
    gap: 28px;
}
.form_hook_item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
}
.form_hook_title {
    position: relative;
    color: #383838;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.3;
    padding-left: 43px;
}
.form_hook_title::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0;
    top: 2px;
    border: 12px solid #CDCDCD;
    border-radius: 100%;
    pointer-events: none;
    transition: .2s ease-in-out;
}
.form_hook_title::after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    left: 6px;
    top: 8px;
    background-color: #fff;
    border-radius: 100%;
}
.form_hook_title p {
    position: absolute;
    left: 100%;
    top: -15px;
    width: 60px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="60" height="43" viewBox="0 0 60 43" fill="none"><g filter="url(%23filter0_d_74_317)"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.87746 18.087C8.83219 18.1045 8.78551 18.1181 8.73793 18.1277C8.27619 18.2204 7.97707 18.6699 8.06982 19.1317L9.64567 26.977C10.6087 31.7715 15.2762 34.8776 20.0708 33.9145L45.3914 28.8285C50.1859 27.8654 53.292 23.1979 52.3289 18.4034L51.563 14.5903C51.023 11.9016 48.4055 10.1598 45.7168 10.6999L44.1628 11.012C42.691 11.3077 41.2201 10.5006 40.6789 9.10036C40.0647 7.51164 38.279 6.72159 36.6903 7.33572L8.87746 18.087Z" fill="%23FB4F54"/></g><defs><filter id="filter0_d_74_317" x="0.934292" y="0.898804" width="58.6881" height="41.199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="0.889805"/><feGaussianBlur stdDeviation="3.55922"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.309804 0 0 0 0 0.329412 0 0 0 0.3 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_74_317"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_74_317" result="shape"/></filter></defs></svg>');
}
.form_hook_title p span {
    font-family: Inter;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    transform: rotate(-15deg);
}
.form_hook_prices {
    display: flex;
    gap: 18px;
    align-items: baseline;
}
.form_hook_prices p {
    color: #383838;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.3;
}
.form_hook_prices span {
    text-decoration: line-through;
    color: #999999;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
}
.form_hook_item_active .form_hook_title::before {
    border-color: #FB4F54;
}
.video_block {
    position: relative;
    max-width: 1154px;
    margin: 0 auto;
    max-height: 608px;
}
.video_block iframe {
    border-radius: 16px;
    height: 608px;
}
@media(max-width:991px) {
    .form_block {
        padding: 50px;
    }
}
@media(max-width:767px) {
    .form {
        padding: 60px 0 85px;
    }
    .form_block {
        padding: 43px 15px 60px;
    }
    .form_title {
        font-size: 26px;
        margin-bottom: 20px;
    }
    .form_block2 {
        background-color: transparent;
        padding: 0;
        flex-direction: column;
        margin-bottom: 20px;
        align-items: center;
    }
    .form_block2_img {
        flex: 0 1 108px;
        max-width: 108px;
    }
    .form_block2_title {
        text-align: center;
        font-size: 19px;
        margin-bottom: 19px;
    }
    .form_block2_text {
        font-size: 16px;
        text-align: center;
    }
    .countdown > p {
        margin-bottom: 8px;
        font-size: 20px;
    }
    .countdown div {
        font-size: 30px;
    }
    .form_hook_title {
        font-size: 20px;
        line-height: 1;
        font-weight: normal;
        padding-left: 34px;
    }
    .form_hook_title::before {
        width: 23px;
        height: 23px;
        top: -2px;
    }
    .form_hook_title::after {
        width: 12px;
        height: 12px;
        top: 4px;
    }
    .form_hook_title p {
        left: calc(100% - 10px);
        font-size: 10px;
        top: -30px;
    }
    .form_hook_prices {
        gap: 6px;
    }
    .form_hook_prices p {
        font-size: 20px;
    }
    .form_hook_prices span {
        font-size: 13px;
    }
    .video_block iframe {
        height: auto;
        min-height: 100vw;
    }
}