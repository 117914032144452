.second {
    padding: 100px 0 30px;
}
.t1 {
    margin-bottom: 36px;
    text-align: center;
    font-family: Actay;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.1;
}
.block1 {
    display: flex;
    border-radius: 20px;
    background-color: #F6F6F6;
    gap: 50px;
    padding: 15px;
    margin-bottom: 50px;
}
.block1_left {
    flex: 1;
    padding-top: 30px;
    padding-left: 55px;
    padding-right: 40px;
}
.block1_right {
    flex: 0 1 421px;
    max-width: 421px;
}
.block1_quote {
    margin-bottom: 36px;
}
.block1_title {
    font-family: Actay;
    margin-bottom: 23px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
}
.block1_text {
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: -0.19px;
}
.t2 {
    text-align: center;
    margin-bottom: 25px;
    font-family: Actay;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.1;
}
.block2 {
    display: flex;
    gap: 26px;
    justify-content: space-between;
    margin-bottom: 46px;
    padding: 0 60px;
}
.block2_item {
    flex: 0 1 250px;
    max-width: 250px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/quote2.svg');
    height: 166px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.block2_item_title {
    text-align: center;
    font-family: Actay;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.777px;
}
.block2_item_text {
    font-family: Actay;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: -0.276px;
    text-align: center;
}
.block3 {
    display: flex;
    gap: 20px;
}
.block3_item {
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
    border-radius: 20px;
    background-color: #F6F6F6;
    padding: 22px 25px; 
}
.block3_item_img {
    margin-bottom: 100px;
}
.block3_item_title {
    font-family: Actay;
    color: #FB4F54;
    font-size: 43px;
    font-weight: bold;
    line-height: 1.1;
}
.block3_item_text {
    font-size: 20px;
    line-height: 1.2;
}
@media(max-width:1199px) {
    .block3 {
        flex-wrap: wrap;
    }
    .block3_item {
        flex: 0 1 calc(50% - 10px);
        max-width: calc(50% - 10px);
    }
}
@media(max-width:991px) {
    .block1 {
        flex-direction: column;
        gap: 40px;
    }
    .block1_left {
        padding: 0;
    }
    .block1_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .block2 {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media(max-width:767px) {
    .second {
        padding: 60px 0 0;
    }
    .t1 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .t2 {
        font-size: 30px;
    }
    .block2 {
        padding: 0;
    }
    .block2_item_text br {
        display: block!important;
    }
    .block3 {
        gap: 10px;
    }
    .block3_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 22px 20px;
    }
    .block3_item_img {
        margin-bottom: 22px;
    }
}