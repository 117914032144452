.program {
    padding: 15px 0 30px;
}
.program_title {
    font-family: Bebas;
    font-weight: bold;
    font-size: 64px;
    line-height: 1;
}
.program_title span {
    color: var(--main-p-color);
}
.program_info {
    display: flex;
    gap: 35px;
}
.program_info__left {
    flex: 0 1 50%;
    max-width: 50%;
}
.program_info__left p {
    margin: 10px 0 25px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.3;
}
.program_info__right {
    flex: 0 1 50%;
    max-width: 50%;
}
.program_info__right p {
    font-size: 18px;
    line-height: 1.55;
}

@media(max-width: 991px) {
    .program_info {
        flex-wrap: wrap;
        gap: 0;
    }
    .program_info__left, .program_info__right {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .program_title {
        font-size: 60px;
    }
}
@media(max-width: 575px) {
    .program_title {
        font-size: 38px;
    }
    .program_title br {
        display: none;
    }
    .program_info__left p {
        font-size: 20px;
    }
}