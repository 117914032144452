.nowyoumama {
    padding: 75px 0 60px;
}
.nowyoumama_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 15px;
    margin-left: 100px;
}
.nowyoumama_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.nowyoumama_subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 1.23;
    margin-left: 100px;
}
.nowyoumama_block {
    display: flex;
    gap: 30px;
    max-width: 1000px;
    margin: 0 auto;
}
.nowyoumama_item ul li {
    font-size: 18px;
    position: relative;
    margin-bottom: 40px;
    padding-left: 50px;
    font-weight: 400;
    line-height: 1.2;
    transition: 0.2s ease-in-out;
}
.nowyoumama_item ul li:hover {
    transform: translateX(5px);
}
.nowyoumama_item ul li::before {
    content: "";
    width: 50px;
    height: 50px;
    background: url('https://gymteam.kinescopecdn.net/img/l/afterpregnancy/marker.png') no-repeat;
    background-size: 70% auto;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 0;
    filter: hue-rotate(220deg);
}

@media(max-width: 1199px) {
    .nowyoumama_title, .nowyoumama_subtitle {
        margin-left: 0;
        text-align: center;
    }
}
@media(max-width: 991px) {
    .nowyoumama_title {
        font-size: 42px;
    }
}
@media(max-width: 767px) {
    .nowyoumama_title {
        font-size: 36px;
    }
    .nowyoumama_block {
        flex-wrap: wrap;
        gap: 0;
    }
    .nowyoumama_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .nowyoumama_title {
        font-size: 24px;
    }
    .nowyoumama_title span {
        padding-bottom: 5px;
    }
}