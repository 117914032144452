.aboutyou {
    padding: 75px 0;
}
.aboutyou_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 45px;
    text-align: center;
}
.aboutyou_title span {
    background: var(--main-color);
    padding: 0px 12px 10px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.aboutyou_block {
    display: flex;
    gap: 30px;
}
.aboutyou_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    background-color: var(--main-bg);
    border-radius: 10px;
    padding: 25px;
    transition: .2s ease-in-out;
}
.aboutyou_item:hover {
    transform: translateY(-10px);
    background-color: var(--hover-bg);
}
.aboutyou_item__img {
    width: 78px;
    margin: 0 auto 20px;
}
.aboutyou_item__title {
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 17px;
    font-weight: 500;
    text-align: center;
}
.aboutyou_item p {
    margin-bottom: 20px;
    line-height: 1.3;
    text-align: center;
}

@media(max-width: 991px) {
    .aboutyou_block {
        gap: 15px;
    }
    .aboutyou_item {
        padding: 18px;
    }
    .aboutyou_title {
        font-size: 42px;
    }
}
@media(max-width: 767px) {
    .aboutyou_title {
        font-size: 36px;
    }
    .aboutyou_title br {
        display: none;
    }
    .aboutyou_block {
        flex-wrap: wrap;
    }
    .aboutyou_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 575px) {
    .aboutyou_title {
        font-size: 24px;
    }
    .aboutyou_item__title {
        font-size: 20px;
        line-height: 1.1;
    }
    .aboutyou_title span {
        padding-bottom: 5px;
    }
}