.promo {
    padding-top: 110px;
}
.header {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.header_link {
    position: relative;
    font-size: 14px;
    line-height: 2.3;
    color: #959BAA;
    cursor: pointer;
}
.header_link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 1px;
    background-color: #959BAA;
}
.promo_pretitle {
    display: flex;
    position: absolute;
    width: 100%;
    max-width: 1230px;
    padding: 0 6px;
    top: 0;
    justify-content: space-between;
    margin-top: 15px;
}
.promo_left {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: left;
    color: #959BAA;
}
.promo_right {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    color: #959BAA;
    text-decoration: underline;
}
.promo_right:hover {
    cursor: pointer;
}
.main {
    display: flex;
    gap: 20px;
    margin-bottom: 90px;
}
.main_left {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.main_right {
    flex: 0 1 265px;
    max-width: 265px;
}
.text {
    flex: 0 1 690px;
    max-width: 690px;
    position: relative;
}
.pretitle {
    text-transform: uppercase;
    color: #A4A9B8;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 45px;
}
.t1 {
    font-size: 50px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 20px;
}
.t1 p {
    background-color: #F6FAFF;
    padding: 4px 14px;
    border-radius: 25px;
    max-width: max-content;
}
.t1 p:last-of-type {
    border: 1px solid #333844;
}
.st1 {
    margin-bottom: 0;
    font-size: 18px;
    color: #717DA1;
    line-height: 1.4;
    letter-spacing: -0.02rem;
}
.prices {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 20px;
}
.prices div:nth-of-type(1) {
    font-size: 21px;
    line-height: 1.4;
    letter-spacing: -1px;
    text-decoration: line-through;
    color: rgba(51, 56, 68, .4);
}
.prices div:nth-of-type(2) {
    font-weight: 500;
    color: #F34FA7;
    font-size: 34px;
    line-height: 1.4;
    letter-spacing: -1px;
}
.promo_countdown {
    margin-bottom: 24px;
}
.promo_countdown > p {
    font-weight: 500;
    color: #3B4A77;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 2px;
    margin-bottom: 6px;
    text-transform: uppercase;
}
.promo_countdown > div {
    font-weight: 500;
    font-size: 58px;
    line-height: 1;
    color: #F34FA7;
    letter-spacing: -1px;
}
.promo_countdown div {
    display: flex;
}
.st2 {
    font-size: 18px;
    color: #717DA1;
    line-height: 1.4;
    letter-spacing: -0.02rem;
}
.st2 b {
    font-weight: 600;
}
.img {
    max-width: 500px;
    margin-left: -170px;
    margin-top: -100px;
}
.img img {
    pointer-events: none;
}

.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
    background: linear-gradient(276.43deg, #E92A91 -51.29%, #FA65B5 131.26%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 40px;
    cursor: pointer;
    min-width: 337px;
    margin-top: 90px;
}
.btn:hover {
    transform: translateY(-4px);
}

.remains p {
    font-weight: 500;
    font-size: 18px;
    color: #3B4A77;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.remains span {
    font-size: 112px;
    color: #F34FA7;
    line-height: 1;
    letter-spacing: -3px;
}

.date p {
    margin-top: 14px;
    margin-bottom: 4px;
    line-height: 1.2;
}
.date span {
    font-weight: 500;
    font-size: 28px;
    line-height: 1;    
}

.img .btn,
.img .prices,
.img .st2 {
    display: none!important;
}
.img > img:nth-of-type(2) {
    display: none!important;
}

.dates {
    display: flex;
    gap: 30px;
    margin-bottom: 80px;
}
.dates_item {
    flex: 0 1 calc(50% - 15px);
    max-width: calc(50% - 15px);
    background-color: #E3EDFF;
    border-radius: 30px;
    padding: 35px 50px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.dates_item_img {
    flex: 0 1 80px;
    max-width: 80px;
}
.dates_item_text {
    flex: 1;
    max-width: calc(100% - 80px);
}
.dates_item_text p:nth-of-type(1) {
    font-size: 22px;
    line-height: 1;
}
.dates_item_text p:nth-of-type(2) {
    font-weight: 500;
    font-size: 56px;
    line-height: 1.2;
}

@media(max-width:575px) {
    .header {
        margin-bottom: 20px;
    }
    .promo {
        padding-top: 20px;
    }
    .promo_pretitle {
        position: inherit;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0px;
        justify-content: flex-start;
        margin-top: 15px;
        gap: 10px;
        margin-bottom: 20px;
    }
    .main {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 40px;
    }
    .main_left {
        flex-direction: column;
    }
    .main_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .text {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .t1 {
        font-size: 35px;
        margin-bottom: 15px;
    }
    .t1 p {
        padding: 0;
    }
    .t1 p:last-of-type {
        padding: 5px 10px;
        margin-left: -10px;
    }
    .img {
        margin-left: 0;
        margin-top: 0;
        position: relative;
    }
    .img .btn {
        display: flex!important;
        margin-top: 15px;
        font-size: 18px;
        padding: 20px;
        max-width: 100%;
        min-width: auto;
    }
    .main_left .btn {
        display: none;
    }
    .prices {
        display: none!important;
    }
    .st2 {
        display: none;
    }
    .img .st2 {
        display: block!important;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .img .prices {
        display: block!important;
        position: absolute;
        top: 60px;
        left: 15px;
    }
    .img .prices div:nth-of-type(1) {
        color: #fff;
        font-weight: 500;
        font-size: 44px;
        line-height: 1.4;
        letter-spacing: -1.3px;
        text-decoration: none;
    }
    .img .prices div:nth-of-type(2) {
        color: rgba(255, 255, 255, 0.5);
        text-decoration: line-through;
        font-size: 28px;
        line-height: 1.4;
        letter-spacing: -1.3px;
        font-weight: normal;
    }
    .img > img:nth-of-type(1) {
        display: none!important;
    }
    .img > img:nth-of-type(2) {
        display: block!important;
    }
    .promo_countdown {
        display: flex;
        gap: 16px;
        align-items: center;
    }
    .promo_countdown > p {
        font-size: 14px;
    }
    .promo_countdown > div {
        font-size: 36px;
    }
    .date {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .date p {
        margin-top: 0;
    }
    .dates {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 70px;
    }
    .dates_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 20px 24px;
        gap: 14px;
    }
    .dates_item_img {
        flex: 0 1 62px;
        max-width: 62px;
    }
    .dates_item_text {
        max-width: calc(100% - 62px);
    }
    .dates_item_text p:nth-of-type(1) {
        font-size: 17px;
    }
    .dates_item_text p:nth-of-type(2) {
        font-size: 28px;
    }
}
@media(max-width:358px) {
    .t1 {
        font-size: 28px;
    }
    .img .remains p {
        font-size: 13px;
    }
    .img .remains span {
        font-size: 66px;
    }
}