.gtage {
    padding-top: 80px;
}
.gtage_block {
    display: flex;
    align-items: flex-end;
}
.gtage_block__left {
    flex: 1;   
}
.gtage_block__right {
    flex: 0 1 474px;
    max-width: 474px;
}
.gtage_pretitle {
    color: #EE4777;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.gtage_title {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
}
.gtage_text {
    padding-bottom: 20px;
}
.gtage_text p {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    color: #4A4A4A;
}
.gtage_btn {
    width: 322px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(275.94deg, #B92655 -15.09%, #B92655 -15.08%, rgba(228, 47, 129, 0) 102.69%), #EE4777;
    border-radius: 20px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    transition: .2s ease-in-out;
    margin-bottom: 90px;
}
.gtage_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}

@media(max-width: 1199px) {
    .gtage_block {
        flex-wrap: wrap;
    }
    .gtage_block__left, .gtage_block__right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .gtage_block__right {
        text-align: center;
    }
    .gtage_title br, .gtage_text br {
        display: none;
    }
    .gtage_btn {
        margin-bottom: 0;
    }
}
@media(max-width: 767px) {
    .gtage {
        padding-top: 45px;
    }
    .gtage_pretitle {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px;
    }
    .gtage_title {
        font-size: 28px;
        line-height: 32px;
    }
    .gtage_text p {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 28px;   
    }
    .gtage_btn {
        width: 100%;
        height: 80px;
    }
}