.marforme {
    padding: 90px 0;
    background-color: #F5F5F5;
}
.marforme_title {
    margin-bottom: 50px;
    font-weight: 800;
    font-size: 60px;
    line-height: 75px;
    text-transform: uppercase;
}
.marforme_subtitle {
    margin-bottom: 50px;
    font-size: 25px;
    line-height: 30px;
    color: var(--popa1-pink);
    font-weight: 500;
}
.marforme_block {
    display: flex;
    gap: 60px;
}
.marforme_item {
    flex: 0 1 50%;
    max-width: 50%;
}
.marforme_item ul li {
    padding-left: 60px;
    padding-right: 35px;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 35px;
    position: relative;
}
.marforme_item ul li strong {
    font-weight: 600;
}
.marforme_item ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -22.5px;
    width: 45px;
    height: 45px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa1/marker_pink.svg');
}

@media(max-width: 991px) {
    .marforme_title {
        font-size: 40px;
        line-height: 52px;
    }
    .marforme_title br {
        display: none;
    }
    .marforme_block {
        flex-wrap: wrap;
        gap: 0;
    }
    .marforme_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .marforme {
        padding: 45px 0;
    }
    .marforme_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 20px;
    }
    .marforme_subtitle {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 25px;
    }
    .marforme_item ul li {
        font-size: 16px;
        line-height: 21px;
        padding-left: 30px;
        margin-bottom: 10px;
        padding-right: 0;
    }
    .marforme_item ul li br {
        display: none;
    }
    .marforme_item ul li::before {
        width: 20px;
        height: 20px;
        background-size: cover;
        margin-top: 0;
        top: 1px;
    }
}
@media(max-width: 374px) {
    .marforme_title, .marforme_subtitle {
        font-size: 30px;
        line-height: 32px;
    }
}