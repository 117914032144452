.novelties {
    padding-top: 60px;
    background-color: #F2F2F2;
}
.novelties_title {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 45px;
    text-transform: uppercase;
}
.novelties_block {
    display: flex;
    margin: 0 -15px;
}
.novelties_item {
    flex: 0 1 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px;
    margin-bottom: 20px;
}
.novelties_info {
    background-color: #fff;
    height: 100%;
    padding: 29px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.novelties_info__tag {
    background-color: #EE4777;
    border-radius: 12px;
    padding: 10px 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: max-content;
    margin-bottom: 20px;
}
.novelties_item:nth-of-type(2) .novelties_info__tag {
    background-color: #444444;
}
.novelties_info__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 15px;
}
.novelties_info__text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 30px;
}
.novelties_info__time {
    margin-top: auto;
    position: relative;
    padding-left: 34px;
    color: #707070;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}
.novelties_info__time::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 24px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/stopwatch_icon.svg');
}

@media(max-width: 1199px) {
    .novelties_info {
        padding: 20px;
    }
    .novelties_info__time {
        font-size: 16px;
        line-height: 24px;
        padding-left: 30px;
    }
}
@media(max-width: 991px) {
    .novelties_block {
        flex-wrap: wrap;
        justify-content: center;
    }
    .novelties_item {
        flex: 0 1 50%;
        max-width: 50%;
    }
}
@media(max-width: 767px) {
    .novelties_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .novelties_info {
        padding-top: 25px;
    }
}
@media(max-width: 575px) {
    .novelties_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 25px;
    }
    .novelties_info__title {
        font-size: 24px;
        line-height: 26px;
    }
}
@media(max-width: 373px) {
    .novelties_title {
        font-size: 26px;
        line-height: 30px;
    }
}
@media(max-width: 322px) {
    .novelties_info__time {
        padding-left: 26px;
    }
}