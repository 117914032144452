.inventory {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/inv_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding: 240px 0 90px;
    position: relative;
    overflow: hidden;
}
.inventory::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 23, 23, 0.84);
    pointer-events: none;
}
.inventory_title {
    position: relative;
    max-width: 725px;
    margin: 0 auto;
    color: #fff;
    font-size: 50px;
    line-height: 55px;
    text-transform: uppercase;
    font-weight: 800;
}
.inventory_title::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -585px;
    top: -225px;
    width: 342px;
    height: 343px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/inv_icon2_2.png');
}
.inventory_title p {
    position: relative;
}
.inventory_title span {
    color: var(--abs-green);
}
.inventory_subtitle {
    position: relative;
    font-size: 31px;
    line-height: 40px;
    color: #B5B5B5;
    font-weight: 500;
    max-width: 725px;
    margin: 15px auto 0;
}

@media(max-width: 991px) {
    .inventory_title {
        font-size: 40px;
        line-height: 52px;
        text-align: center;
    }
    .inventory_title::before {
        margin-left: -165px;
        top: -255px;
    }
    .inventory_subtitle {
        text-align: center;
        font-size: 18px;
    }
}
@media(max-width: 767px) {
    .inventory_title br {
        display: none;
    }
    .inventory_title::before {
        top: -280px;
    }
}
@media(max-width: 575px) {
    .inventory_title {
        font-size: 32px;
        line-height: 36px;
    }
    .inventory_subtitle {
        font-size: 18px;
    }
}
@media(max-width: 374px) {
    .inventory_title {
        font-size: 30px;
        line-height: 32px;
    }
    .inventory_subtitle {
        font-size: 16px;
    }
}