.programs {
    background-color: #FAFAFA;
    padding-top: 12px;
}
.programs_title {
    text-align: center;
    margin-bottom: 42px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
    text-transform: uppercase;
}
.programs_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -20px;
}
.programs_item {
    flex: 0 1 400px;
    max-width: 400px;
    margin-bottom: 40px;
    padding: 0 20px;
}
.programs_item_long {
    flex: 0 1 800px;
    max-width: 800px;
}
.programs_info {
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background-color: #fff;
    height: 100%;
}
.programs_info_img {
    margin-bottom: 16px;
}
.programs_info_img img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.programs_info_title {
    text-align: center;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
}
.programs_info_text {
    text-align: center;
    color: rgba(77, 87, 97, 0.75);
    font-size: 16px;
    line-height: 22px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
@media(max-width:575px) {
    .programs {
        padding-top: 0;
    }
    .programs_title {
        font-size: 32px;
        line-height: 36px;
    }
    .programs_item_long .programs_info_title {
        padding-bottom: 20px;
    }
}