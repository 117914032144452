.trainers {
    position: relative;
    padding-top: 90px;
    padding-bottom: 460px;
    overflow: hidden;
}
.trainers_title {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 15px;
}
.trainers_subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #4A4A4A;
}
.trainers_row {
  display: flex;
  gap: 30px;
  bottom: 120px;
  position: absolute;
  animation: scrolltrainers 30s linear infinite;
}
.trainers_item {
  width: 280px;
  height: 280px;
  position: relative;
}
.trainers_item__img {
  background-color: #B7B7B7;
  border-radius: 16px;
  width: 280px;
  height: 280px;
  background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/trainers80.jpg');
}
.trainer_img__1 {background-position: -10px -10px;}
.trainer_img__2 {background-position: -300px -10px;}
.trainer_img__3 {background-position: -590px -10px;}
.trainer_img__4 {background-position: -880px -10px;}
.trainer_img__5 {background-position: -1170px -10px;}
.trainer_img__6 {background-position: -10px -300px;}
.trainer_img__7 {background-position: -300px -300px;}
.trainer_img__8 {background-position: -590px -300px;}
.trainer_img__9 {background-position: -880px -300px;}
.trainer_img__10 {background-position: -1170px -300px;}
.trainer_img__11 {background-position: -10px -590px;}
.trainer_img__12 {background-position: -300px -590px;}
.trainer_img__13 {background-position: -590px -590px;}
.trainer_img__14 {background-position: -880px -590px;}
.trainer_img__15 {background-position: -1170px -590px;}
.trainer_img__16 {background-position: -10px -880px;}
.trainer_img__17 {background-position: -300px -880px;}
.trainer_img__18 {background-position: -590px -880px;}
.trainer_img__19 {background-position: -880px -880px;}
.trainer_img__20 {background-position: -10px -10px;}
.trainer_img__21 {background-position: -300px -10px;}
.trainer_img__22 {background-position: -590px -10px;}
.trainer_img__23 {background-position: -880px -10px;}
.trainer_img__24 {background-position: -1170px -10px;}
.trainer_img__25 {background-position: -10px -300px;}
.trainer_img__26 {background-position: -300px -300px;}

.trainers_item p {
  position: absolute;
  bottom: 20px;
  left: 35px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #fff;
}
@keyframes scrolltrainers {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-5890px);
  }
}

@media(max-width: 767px) {
  .trainers_subtitle br {
    display: none;
  }
}
@media(max-width: 575px) {
  .trainers {
    padding: 45px 0;
  }
  .trainers_title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 15px;
  }
  .trainers_subtitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 320px;
  }
  .trainers_row {
    bottom: 45px;
  }
}