.b1 {
  margin-bottom: 90px;
}
.b1_title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  color: #333844;
}
.b1_title span {
  background-color: #f6faff;
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}
.b1_pretitle {
  text-align: center;
  margin-bottom: 30px;
  color: #7d93d7;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 1.08px;
}
.b1_block {
  display: flex;
  gap: 60px;
  justify-content: center;
}
.b1_item {
  flex: 0 1 270px;
  max-width: 270px;
  text-align: center;
}
.b1_item img {
  margin-bottom: 25px;
}
.b1_item_title {
  font-size: 20px;
  color: rgba(51, 56, 68, 0.7);
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-bottom: 8px;
}
.b1_item_text {
  color: rgba(51, 56, 68, 0.7);
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.b2 {
  border-radius: 40px;
  background: #e3edff;
  position: relative;
  padding: 90px 85px;
  overflow: hidden;
  margin-bottom: 70px;
}
.b2_title {
  max-width: 562px;
  margin-bottom: 24px;
  font-size: 44px;
  color: #333844;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.b2_subtitle {
  max-width: 562px;
  color: #717da1;
  font-size: 21px;
  line-height: 1.4;
  letter-spacing: -0.42px;
}
.b2 img:nth-of-type(1) {
  position: absolute;
  right: -60px;
  bottom: -30px;
}
.b2 img:nth-of-type(2) {
  display: none !important;
}

.b3 {
  padding-bottom: 50px;
  margin-bottom: 80px;
  border-bottom: 1px solid #dfe5ed;
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  flex-wrap: wrap;
}
.b3_btn {
  flex: 0 1 100%;
  max-width: 100%;
}
.b3_left {
  flex: 1;
}
.b3_right {
  /* flex: 0 1 541px; */
  /* max-width: 541px; */
  position: absolute;
  right: -110px;
  top: -130px;
}
.b3_title {
  margin-bottom: 20px;
  color: #333844;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.b3_text {
  color: #717da1;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
}
.b3 .btn {
  margin: 0;
}

.b4 {
  margin-bottom: 100px;
}
.b4_pretitle {
  text-align: center;
  margin-bottom: 25px;
  color: #7d93d7;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 1.08px;
}
.b4_title {
  text-align: center;
  margin-bottom: 25px;
  color: #333844;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.b4_subtitle {
  margin-bottom: 50px;
  text-align: center;
  color: #717da1;
  font-size: 21px;
  line-height: 1.4;
  letter-spacing: -0.42px;
}
.b4_block {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.b4_item {
  border-radius: 30.481px;
  background: #fff;
  padding: 40px 30px;
  height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  flex: 0 1 362px;
  max-width: 362px;
}
.b4_item_place {
  margin-bottom: 5px;
  font-size: 17px;
  color: #707070;
  line-height: 1.35;
  position: relative;
}
.b4_item_text {
  font-size: 24px;
  color: #333844;
  font-size: 24px;
  line-height: 1.2;
  position: relative;
}
.b4_item img {
  position: absolute;
  right: 0;
  top: 0;
}

.b5 {
  margin-bottom: 100px;
}
.b5_title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  color: #333844;
}
.b5_title span {
  background-color: #f6faff;
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}
.b5_block {
  display: flex;
  gap: 36px;
}
.b5_img {
  flex: 0 1 590px;
  max-width: 590px;
}
.b5_img img {
  border-radius: 32px;
}
.b5_text {
  flex: 1;
}
.b5_text ul {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.b5_text ul li {
  position: relative;
  color: #333844;
  font-size: 18px;
  line-height: 1.35;
  padding-left: 70px;
}
.b5_text ul li span {
  display: block;
  margin-bottom: 4px;
  color: #ef3f9e;
  font-size: 32px;
  line-height: 1.3;
}
.b5_text ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 31px;
  height: 31px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none"><rect width="30.6025" height="30.6025" rx="8" fill="%23EF3F9E"/><rect x="7" y="6" width="17" height="17" rx="8" fill="white"/></svg>');
}
.b5_text ul li::after {
  content: "";
  position: absolute;
  left: 15px;
  top: 35px;
  width: 2px;
  height: 100%;
  background-color: #ef3f9e;
}
.b5_text ul li:last-of-type:after {
  content: none;
}

.b6 {
  margin-bottom: 70px;
}
.b6_title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  color: #333844;
}
.b6_title span {
  background-color: #f6faff;
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #333844;
}
.b6_subtitle {
  margin-bottom: 50px;
  text-align: center;
  color: #717da1;
  font-size: 22px;
  line-height: 1.4;
  letter-spacing: -0.44px;
}
.b6 .b8_block {
  margin-bottom: 30px;
}
.btn {
  transition: 0.2s ease-in-out;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 70px;
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.44px;
  padding: 20px 35px;
  cursor: pointer;
  min-width: 340px;
  margin: 0 auto;
}
.btn:hover {
  transform: translateY(-4px);
}

.b7 {
  background-image: url("https://gymteam.kinescopecdn.net/img/l/2024/um2/b7_1.jpg");
  background-size: cover;
  background-position: 50% 50%;
  padding: 60px 0;
}
.b7_title {
  margin-bottom: 40px;
  font-size: 56px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  color: #fff;
}
.b7_title span {
  padding: 4px 14px;
  border-radius: 25px;
  max-width: max-content;
  border: 1px solid #fff;
  margin-left: -14px;
}
.b7 ul {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 30px;
}
.b7 ul li {
  color: #fff;
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.4px;
}
.b7 ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #fff;
}
.b7_text {
  margin-bottom: 50px;
  color: #fff;
  font-size: 22px;
  line-height: 1.4;
  letter-spacing: -0.44px;
}
.b7 .btn {
  margin: 0;
}
.b7_block {
  border-radius: 40px;
  background: #e3edff;
  padding: 25px;
  max-width: max-content;
  margin-bottom: 40px;
}
.b7_block_title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #333844;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.b7_block_text {
  /* font-size: 21px; */
  color: #717da1;
  line-height: 1.4;
  letter-spacing: -0.42px;
}
.b8 {
  padding: 100px 0;
}
.b8_block {
  border-radius: 40px;
  background: #e3edff;
  padding: 48px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.b8_left {
  flex: 0 1 269px;
  max-width: 269px;
}
.b8_right {
  flex: 1;
}
.b8_title {
  margin-bottom: 20px;
  font-size: 44px;
  font-weight: 500;
  color: #333844;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.b8_text {
  font-size: 21px;
  color: #717da1;
  line-height: 1.4;
  letter-spacing: -0.42px;
}

@media (max-width: 575px) {
  .b1 {
    margin-bottom: 75px;
  }
  .b1_title {
    font-size: 26px;
    margin-bottom: 35px;
  }
  .b1_title span {
    padding: 0;
    border: none;
  }
  .b1_block {
    flex-wrap: wrap;
    gap: 35px;
  }
  .b1_item {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .b1_pretitle {
    font-size: 16px;
    margin-bottom: 25px;
  }
  .b2 {
    padding: 50px 12px 0;
    margin-bottom: 60px;
  }
  .b2_title {
    margin-bottom: 15px;
    font-size: 24px;
  }
  .b2_subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .b2 img:nth-of-type(1) {
    display: none !important;
  }
  .b2 img:nth-of-type(2) {
    display: inline-block !important;
  }
  .b3 {
    margin-bottom: 60px;
    flex-direction: column;
    gap: 0;
    padding-bottom: 20px;
  }
  .b3_title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .b3_text {
    font-size: 16px;
    margin-bottom: 0;
  }
  .b3_right {
    flex: 0 1 100%;
    max-width: 100%;
    position: relative;
    right: 0;
    top: 0;
  }
  .b4 {
    margin-bottom: 70px;
  }
  .b4_pretitle {
    font-size: 16px;
    margin-bottom: 14px;
  }
  .b4_title {
    margin-bottom: 14px;
    font-size: 26px;
  }
  .b4_subtitle {
    margin-bottom: 32px;
    font-size: 16px;
  }
  .b4_block {
    flex-wrap: wrap;
  }
  .b4_item {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .b4_item {
    height: 190px;
    padding: 20px;
  }
  .b4_item img {
    max-width: 145px;
  }
  .b4_item_place {
    font-size: 16px;
  }
  .b4_item_text {
    font-size: 20px;
  }
  .b5 {
    margin-bottom: 70px;
  }
  .b5_title {
    font-size: 26px;
    margin-bottom: 24px;
  }
  .b5_title span {
    padding: 0;
    border: none;
  }
  .b5_block {
    flex-wrap: wrap;
  }
  .b5_img {
    flex: 0 1 100%;
    max-width: 100%;
    order: 2;
  }
  .b5_text ul li {
    font-size: 16px;
    padding-left: 50px;
  }
  .b5_text ul li span {
    font-size: 32px;
  }
  .b6 {
    margin-bottom: 0;
  }
  .b6_title {
    margin-bottom: 14px;
    font-size: 26px;
    text-align: left;
  }
  .b6_title span {
    padding: 0;
    border: none;
  }
  .b6_subtitle {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: left;
  }
  .btn {
    font-size: 19px;
    padding: 20px;
    min-width: 100%;
    justify-content: center;
  }
  .b7_title {
    font-size: 26px;
  }
  .b7_title span {
    padding: 0;
    border: none;
    margin-left: 0;
  }
  .b7 ul li {
    font-size: 18px;
  }
  .b7_text {
    font-size: 18px;
  }
  .b7_block_title {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .b8 {
    padding: 60px 0;
  }
  .b8_block {
    padding: 20px;
    flex-direction: column;
  }
  .b8_left {
    flex: 0 1 60%;
    max-width: 60%;
  }
  .b8_title {
    font-size: 26px;
  }
  .b8_text {
    font-size: 18px;
  }
}
