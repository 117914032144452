.makeyouself {
    padding-bottom: 75px;
}
.makeyouself_title {
    font-size: 48px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 45px;
}
.makeyouself_title span {
    background: var(--main-color);
    padding: 0px 12px 5px 12px;
    border-radius: 15px;
    color: #ffffff;
    line-height: 1.1;
    display: inline-block;
}
.makeyouself_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.makeyouself_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
}
.makeyouself_item-info {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 25px 25px 20px;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: .2s ease-in-out;
}
.makeyouself_item-info:hover {
    transform: translateY(-10px);
}
.makeyouself_item-description {
    font-size: 20px;
    font-weight: 500;
}
.makeyouself_item-number {
    width: 140px;
    height: 140px;
    position: absolute;
    right: -55px;
    bottom: -70px;
    padding: 30px 25px;
    margin-bottom: 10px;
    font-size: 38px;
    font-weight: 500;
    line-height: 1;
    transition: .2s ease-in-out;
    border-radius: 50%;
    color: #fff;
    background: #ff5463;
}
.makeyouself_item:hover .makeyouself_item-number {
    background-color: var(--main-color);
}
.makeyouself_more {
    display: flex;
    margin-top: 30px;
}
.makeyouself_more-img {
    flex: 0 1 25%;
    max-width: 25%;
    text-align: right;
    padding-right: 15px;
    margin-left: 8.333333%;
}
.makeyouself_more-description {
    flex: 0 1 50%;
    max-width: 50%;
    padding-left: 15px;
}
.makeyouself_more-description p {
    font-size: 30px;
    line-height: 1.23;
    font-weight: 500;
    margin-bottom: 10px;
}
.makeyouself_more-description p:last-of-type {
    line-height: 1;
}
.makeyouself_more-description p span {
    font-weight: normal;
    font-size: 20px;
}

@media(max-width: 1199px) {
    .makeyouself_item-info {
        padding-left: 15px;
        padding-right: 80px;
    }
    .makeyouself_item-description br {
        display: none;
    }
}
@media(max-width: 991px) {
    .makeyouself_title {
        font-size: 42px;
    }
    .makeyouself_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .makeyouself_item-info {
        padding-left: 25px;
    }
    .makeyouself_more-description p br {
        display: none;
    }
}
@media(max-width: 767px) {
    .makeyouself_title {
        font-size: 36px;
    }
    .makeyouself_more {
        flex-wrap: wrap;
    }
    .makeyouself_more-img {
        margin-left: 0;
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
        padding-right: 0;
    }
    .makeyouself_more-description {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
        margin-top: 15px;
        padding-left: 0;
    }
}
@media(max-width: 575px) {
    .makeyouself_title {
        font-size: 24px;
    }
    .makeyouself_item-description {
        font-size: 18px;
    }
    .makeyouself_more-description p {
        font-size: 20px;
    }
}