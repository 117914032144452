.createbody {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_4.jpg');
    background-size: cover;
    background-position: center;
    padding: 80px 0 90px;
    position: relative;
    overflow: hidden;
}
.createbody::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 550px;
    top: 0;
    width: 399px;
    height: 280px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa2/createbody_1.png');
    pointer-events: none;
}
.createbody::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 465px;
    height: 282px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_popa2/createbody_2.png');
    pointer-events: none;
}
.createbody_title {
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
}
.createbody_title span {
    color: var(--popa2-pink);
}
.createbody_subtitle {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 35px;
    text-align: center;
}
.createbody_subtitle strong {
    font-weight: 600;
}
.create_pink {
    background-color: var(--popa2-pink);
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    padding: 32px 32px 32px 39px;
    border-radius: 20px 20px 0 20px;
    max-width: 560px;
    margin-left: 115px;
    position: relative;
}
.create_yellow {
    background-color: var(--popa2-yellow);
    font-size: 24px;
    line-height: 32px;
    padding: 32px 25px 32px 39px;
    border-radius: 0px 20px 20px 20px;
    max-width: 461px;
    margin-left: 600px;
    margin-top: -35px;
    position: relative;
}
.create_pink strong, .create_yellow strong {
    font-weight: 600;
}

@media(max-width: 1199px) {
    .createbody::before {
        width: 210px;
        height: 210px;
        background-size: contain;
        margin-left: 370px;
        background-repeat: no-repeat;
    }
    .createbody::after {
        width: 300px;
        height: 180px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .create_yellow {
        margin-left: 490px;
    }
}
@media(max-width: 991px) {
    .createbody {
        margin-top: 0;
    }
    .createbody_subtitle br {
        display: none;
    }
    .createbody::after, .createbody::before {
        content: none;
    }
    .create_pink {
        margin-left: 0;
        font-size: 22px;
        line-height: 32px;
    }
    .create_yellow {
        font-size: 22px;
        line-height: 32px;
        margin-left: 15px;
        margin-left: 305px;
        margin-top: -20px;
    }
    .createbody::before {
        width: 200px;
        height: 162px;
        margin-left: 235px;
        top: -25px;
    }
    .createbody::after {
        width: 200px;
        height: 150px;
        margin-left: -385px;
    }
    .create_pink::before {
        content: '';
        filter: blur(5px);
        transform: matrix(-0.98, 0.2, 0.2, 0.98, 0, 0);
        top: 35px;
        left: 10px;
        margin-left: 0;
        z-index: -1;
    }
}
@media(max-width: 767px) {
    .createbody {
        padding: 45px 0;
    }
    .createbody_title {
        font-size: 32px;
        line-height: 36px;
    }
    .createbody_subtitle {
        font-size: 18px;
        line-height: 22px;
    }
    .create_pink {
        max-width: 100%;
        font-size: 16px;
        line-height: 21px;
        margin-top: 35px;
    }
    .create_yellow {
        margin-top: -8px;
        font-size: 16px;
        line-height: 21px;
    }
    .createbody::after {
        width: 135px;
        height: 95px;
    }
}
@media(max-width: 575px) {
    .createbody_title br {
        display: none;
    }
    .create_pink {
        padding: 15px;
    }
    .create_yellow {
        margin-left: 60px;
        padding: 15px;
    }
    .createbody {
        background-position: 35% 100%;
        background-size: auto;
        padding-top: 45px;
        overflow: hidden;
    }
    .createbody::after {
        margin-left: 0;
        left: 0;
    }
    .createbody::before {
        margin-left: 160px;
        top: 25px;
        width: 130px;
        height: 100px;
        transform: rotate(339deg);
    }
    .createbody_subtitle {
        font-size: 16px;
        line-height: 21px;
    }
}
@media(max-width: 374px) {
    .createbody_title {
        font-size: 30px;
        line-height: 32px;
    }
}