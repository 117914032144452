.land2 {
    padding: 60px 0 100px;
    background-color: #F7F7F7;
    font-family: Roboto;
    color: #111927;
}
.logo {
    text-align: center;
    margin-bottom: 48px;
}
.wrapper {
    margin: 0 auto;
    max-width: 640px;
}
.block {
    margin-bottom: 72px;
}
.block_img img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.block_info {
    background-color: #fff;
    padding: 24px 32px 44px;
    text-align: center;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
.block_title {
    color: rgba(17, 25, 46, 0.89);
    font-family: Qanelas;
    font-weight: 800;
    font-size: 32px;
    line-height: 1.08;
    margin-bottom: 8px;
}
.block_subtitle {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.45;
}
.block_subtitle span {
    font-weight: 600;
    color: #EE4777;
}
.block_subtitle em {
    font-style: normal;
    text-decoration: line-through;
    color: #878787;
}
.block_countdown {
    margin-bottom: 16px;
}
.block_countdown p {
    color: #4D5761;
    font-size: 16px;
    line-height: 1.4;
}
.block_countdown_timer {
    font-family: Qanelas;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.3;
    color: #111927;
}
.block_countdown_timer > div {
    display: flex;
    justify-content: center;
}
.block_countdown_timer > div span {
    margin-left: -5px;
    margin-right: 2px;
}
.block_btn {
    border-radius: 12px;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 331px;
    height: 80px;
    color: #fff;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin: 0 auto;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.block_btn:hover {
    transform: translateY(-2px);
}
.title2 {
    margin-bottom: 60px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 32px;
    text-align: center;
    color: rgba(17, 25, 46, 0.89);
    text-transform: uppercase;
    line-height: 1.3;
}
.block2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 74px;
}
.block2_item {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 15px;
    margin-bottom: 60px;
}
.block2_item:last-of-type {
    flex: 0 1 100%;
    max-width: 100%;
    margin-bottom: 0;
}
.block2_info {
    border-radius: 16px;
    border: 1px solid #E8EAEF;
    background: #FEFEFE;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 0 27.5px 32px;
    height: 100%;
}
.block2_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    margin: -32px auto 8px;
    filter: drop-shadow(0px 0px 23.495237350463867px rgba(18, 18, 18, 0.05));
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid rgba(232, 234, 239, 1);
}
.block2_info p {
    color: #4D5761;
    font-size: 16px;
    line-height: 1.35;
    text-align: center;
}

.block3 {
    text-align: center;
    border-radius: 20px;
    border: 1px solid #EAECF0;
    background: #EE4777;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 30px 45px 40px;
}
.block3_title {
    color: #fff;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 32px;
    line-height: 34px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.block3_subtitle {
    color: #fff;
    margin-bottom: 20px;
    font-family: Qanelas;
    font-weight: 500;
    font-size: 32px;
}
.block3_subtitle span {
    color: #FECAD9;
    font-weight: 600;
    text-decoration: line-through;
}
.block3_countdown {
    margin-bottom: 26px;
}
.block3_countdown p {
    color: #EAA1B6;
    font-size: 18px;
    line-height: 25.2px;
}
.block3_countdown_timer {
    font-family: Qanelas;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #fff;
}
.block3_countdown_timer > div {
    display: flex;
    justify-content: center;
}
.block3_countdown_timer > div span {
    margin-left: -5px;
    margin-right: 2px;
}

@media(max-width: 575px) {
    .land2 {
        padding: 40px 0;
    }
    .wrapper {
        padding: 0 15px;
    }
    .block_info {
        padding: 15px;
    }
    .block_title {
        font-size: 26px;
    }
    .block_subtitle {
        font-size: 18px;
        line-height: 1.3;
    }
    .block_subtitle span {
        display: block;
        margin-bottom: 8px;
    }
    br {
        display: none!important;
    }
    .block_btn {
        width: 100%;
        padding: 0 10px;
        font-size: 20px;
    }
    .block2 {
        margin-bottom: 60px;
    }
    .block2_item {
        flex: 0 1 100%;
        max-width: 100%;
    }

    .block3 {
        padding: 15px;
    }
    .block3_title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .block3_subtitle {
        font-size: 22px;
        line-height: 28px;
    }
}