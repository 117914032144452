.knowledge {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_str1/window_bg_2.jpg');
    background-size: cover;
    background-position: center;
    padding: 90px 0 80px;
    overflow: hidden;
}
.knowledge_block {
    position: relative;
    padding-left: 37%;
}
.knowledge_block::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -735px;
    top: -165px;
    width: 679px;
    height: 762px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/bodydream.jpg');
    pointer-events: none;
    mix-blend-mode: multiply;
}
.knowledge_block ul {
    padding-left: 10%;
    padding-right: 25%;
    padding-bottom: 15px;
}
.knowledge_block ul li {
    position: relative;
    padding-left: 42px;
    margin-bottom: 44px;
    font-size: 20px;
    line-height: 26px;
}
.knowledge_block ul li strong {
    font-weight: bold;
}
.knowledge_block ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -26px;
    width: 27px;
    height: 52px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/slimstomach/marker_star_pink.svg');
}
.knowledge_info {
    background-color: var(--abs-green);
    border-radius: 0 20px 20px 20px;
    color: #fff;
    padding: 30px 46px 35px 34px;
    font-size: 30px;
    line-height: 39px;
    max-width: 562px;
}
@media(max-width: 991px) {
    .knowledge_block {
        padding-left: 0;
        padding-top: 420px;
    }
    .knowledge_info {
        margin: 0 auto;
    }
    .knowledge_block::before {
        width: 538px;
        height: 600px;
        background-size: cover;
        margin-left: 0;
        left: 50%;
        margin-left: -269px;
        top: 55%;
        margin-top: 0;
    }
    .knowledge_block ul {
        padding-left: 10%;
        padding-right: 10%;
    }
    .knowledge {
        padding-top: 45px;
    }
    .knowledge_block::before {
        top: -155px;
    }
    .knowledge_block ul {
        padding: 0;
    }
    .knowledge_block ul li {
        font-size: 18px;
        line-height: 21px;
    }
    .knowledge_info {
        font-size: 18px;
        line-height: 21px;
    }
}
@media(max-width: 575px) {
    .knowledge_block ul {
        padding-left: 5%;
        padding-right: 5%;
    }
    .knowledge_block ul li {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 20px;
    }
    .knowledge_block::before {
        margin-left: -315px;
    }
    .knowledge_info {
        padding: 20px;
    }
    .knowledge {
        position: relative;
    }
}
@media(max-width: 374px) {
    
}