.start {
    padding-top: 90px;
}
.reg {
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 4px 12px -2px rgba(16, 24, 40, 0.02);
    border-radius: 16px;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
}
.reg_left {
    background-color: #FAFAFA;
    flex: 0 1 486px;
    max-width: 486px;
}
.reg_left img {
    mix-blend-mode: darken;
}
.reg_right {
    flex: 1;
    padding: 0 70px;
}
.reg_right_logo img {
    width: 168px;
    margin-bottom: 25px;
}
.reg_right_title {
    font-family: Qanelas;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 800;
}
.reg_right_text {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 25px;
}
.reg_right_text a {
    color: #D8376A;
}
.reg_right > a {
    color: rgba(77, 87, 97, 0.7);
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 25px;
    text-decoration: underline;
}
.reg_right > p {
    color: rgba(77, 87, 97, 0.7);
    font-size: 18px;
    line-height: 25px;
}
.reg_right_form {
    margin-bottom: 25px;
}
.reg_right_btn {
    width: 100%;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.59deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #F8F8F8;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    pointer-events: none;
    filter: grayscale(.6);
}
.reg_right_btn:hover {
    background: linear-gradient(90.59deg, rgba(185, 38, 85, 0.7) 6.98%, rgba(228, 47, 129, 0.7) 99.58%), #FF99B6;
}
.reg_left img, .reg_left {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.download {
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 4px 12px -2px rgba(16, 24, 40, 0.02);
    border-radius: 16px;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-bottom: 45px;
    margin-top: 45px;
}
.download_left {
    flex: 1;
    padding: 0 60px;
}
.download_right {
    flex: 0 1 458px;
    max-width: 458px;
}
.download_title {
    margin-bottom: 8px;
    font-family: Qanelas;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
}
.download_subtitle {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 34px;
    color: #4D5761;
}
.download_item {
    border-bottom: 1px solid #E1E1E1;
}
.download_item:last-of-type {
    border-bottom: none;
}
.download_item p {
    padding: 20px 0;
    font-size: 24px;
    line-height: 32px;
}
.download_item > div {
    margin-bottom: 20px;
}
.download_item > div img:first-of-type {
    margin-right: 12px;
}
.download_item > div img {
    transition: .2s ease-in-out;
}
.download_item > div img:hover {
    filter: brightness(0);
}
.download_right img {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.subs {
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 4px 12px -2px rgba(16, 24, 40, 0.02);
    border-radius: 16px;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
}
.subs_left {
    flex: 0 1 485px;
    max-width: 485px;
}
.subs_right {
    flex: 1;
    padding: 0 70px;
}
.subs_title {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-family: Qanelas;
    font-size: 48px;
    line-height: 52px;
    font-weight: 800;
}
.subs_subtitle {
    margin-bottom: 30px;
    color: #4D5761;
    font-size: 24px;
    line-height: 32px;
}
.subs_left img {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.subs_link {
    width: 100%;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.59deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #F8F8F8;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    margin-top: 30px;
}
.subs_link:hover {
    background: linear-gradient(90.59deg, rgba(185, 38, 85, 0.7) 6.98%, rgba(228, 47, 129, 0.7) 99.58%), #FF99B6;
}

.trainers {
    background-color: #fafafa;
    text-align: center;
    position: relative;
    padding-bottom: 100px;
    padding-bottom: 40px;
    margin-top: -40px;
}
.trainers::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 150%;
    height: 150px;
    background: #fafafa;
    transform: rotate(-3deg);
    pointer-events: none;
    z-index: 1;
}
.trainers img {
    max-width: 1100px;
    mix-blend-mode: darken;
}
@media(max-width: 1099px) {
    .trainers img {
        max-width: 100%;
    }
}
@media(max-width: 991px) {
    .start {
        padding-top: 40px;
    }
    .promo_btn, .subs_link {
        text-align: center;
        font-size: 18px;
    }
    .trainers {
        padding-bottom: 30px;
        margin-top: -10px;
    }
    .trainers::before {
        height: 50px;
        bottom: 0;
    }
    .reg {
        padding: 40px 15px;
        flex-wrap: wrap;
    }
    .reg_left {
        display: none;
    }
    .reg_right {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0;
        text-align: center;
    }
    .reg_right_logo img {
        margin-bottom: 16px;
        width: 112px;
    }
    .reg_right_title {
        font-size: 26px;
        line-height: 28px;
    }
    .reg_right_text {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px;
    }
    .reg_right > a, .reg_right > p {
        font-size: 16px;
        line-height: 22px;
    }
    .reg_right_btn {
        text-align: center;
        font-size: 18px;
    }
    .download {
        flex-wrap: wrap;
        padding-top: 25px;
    }
    .download_left {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0 20px 15px;
    }
    .download_right {
        flex: 0 1 100%;
        max-width: 100%;
        height: 300px;
        position: relative;
        overflow: hidden;
    }
    .download_title {
        margin-bottom: 5px;
        font-size: 26px;
        line-height: 32px;
    }
    .download_right img {
        border-radius: 0!important;
    }
    .download_subtitle {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px;
    }
    .download_item p {
        font-size: 16px;
        line-height: 20px;
        padding: 14px 0;
    }
    .subs {
        flex-wrap: wrap;
    }
    .subs_left {
        display: none;
    }
    .subs_right {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 30px 15px;
        text-align: center;
    }
    .subs_title {
        margin-bottom: 5px;
        font-size: 26px;
        line-height: 28px;
    }
    .subs_subtitle {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 22px;
    }
}