.tarif {
    padding: 50px 0;
}
.title {
    font-family: Nface;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;
}
.title span {
    color: #FE6807;
}
.subtitle {
    text-align: center;
    margin-bottom: 45px;
    font-size: 20px;
    line-height: 1.4;
    color: #6A6A6A;
}
.block {
    display: flex;
    justify-content: center;
    gap: 30px;
}
.block_item {
    flex: 0 1 575px;
    max-width: 575px;
    border-radius: 24px;
    background: #fff;
    padding: 28px;
    display: flex;
    flex-direction: column;
}
.block_item:nth-of-type(2) {
    background: #E3FF4D;
    border: 2px solid #FF4E53;
}
.block_item_title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 20px;
}
.block_item:nth-of-type(2) .block_item_title {
    color: #FF4E53;
}
.block_item:nth-of-type(2) .block_item_title span {
    display: block;
    color: #282828;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
}
.block_item_tags {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
}
.block_item_tags div {
    border-radius: 60px;
    background-color: #C0D5E2;
    padding: 12px;
    font-weight: 500;
    line-height: 0.6;
}
.block_item:nth-of-type(2) .block_item_tags div {
    background-color: #CBEC16;
}
.block_item p {
    position: absolute;
    border-radius: 22px;
    background: #3E3E3E;
    padding: 4px 12px;
    font-size: 14px;
    top: 0;
    right: 0;
}
.block_item ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}
.block_item ul li {
    position: relative;
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 12px;
}
.block_item:nth-of-type(2) ul li {
    border-color: #CBEC16;
}
.block_item ul li:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
}
.block_item ul li::before {
    /* content: ''; */
    position: absolute;
    left: 0;
    top: 8px;
    width: 7px;
    height: 7px;
    background-color: #FE6807;
    border-radius: 100%;
}
.block_item:nth-of-type(1) ul li:nth-of-type(6),
.block_item:nth-of-type(1) ul li:nth-of-type(7) {
    text-decoration: line-through;
    color: #aaa;
}
.block_item:nth-of-type(2) ul li:nth-of-type(6),
.block_item:nth-of-type(2) ul li:nth-of-type(7) {
    font-weight: 600;
}
.block_item_p {
    color: #6A6A6A;
    margin-bottom: 8px;
    margin-top: auto;
}
.block_item_prices {
    display: flex;
    align-items: center;
    font-size: 32px;
    color: #171717;
    font-weight: 600;
    line-height: 1.1;
    position: relative;
    margin-bottom: 20px;
}
.block_item:nth-of-type(2) .block_item_prices {
    /* color: #fff; */
}
.block_item_prices span {
    /* text-decoration: line-through; */
    font-size: 32px;
    color: #DCDCDC;
    padding-left: 16px;
    font-weight: normal;
    position: relative;
}
.block_item:nth-of-type(2) .block_item_prices span {
    color: #CBEC16;
}
.block_item_prices span::before {
    /* text-decoration: line-through; */
    content: "";
    width: 90%;
    height: 2px;
    background-color: #BBBABA;
    position: absolute;
    top: 50%;
}
.block_item:nth-of-type(2) .block_item_prices span::before{
    content: "";
    width: 90%;
    height: 2px;
    background-color: #CBEC16;
    position: absolute;
    top: 50%;
}
.block_item_prices b {
    font-family: Manrope;
}
.block_item_prices em {
    font-style: normal;
    position: absolute;
    right: 0;
    top: -4px;
    font-size: 32px;
    font-family: Manrope;
    color: #C0D5E2;
    line-height: 1.4;
    font-weight: 600;
}
.block_item:nth-of-type(2) .block_item_prices em {
    color: #FF4E53;
}
.btn {
    border-radius: 60px;
    background: #C0D5E2;
    cursor: pointer;
    height: 86px;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Manrope;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    text-transform: uppercase;
    transition: .3s ease-in-out;
}
.block_item:nth-of-type(2) .btn {
    background-color: #FF4E53;
    color: #fff;
}
.bold_li {
    font-weight: 900;
}
.btn:hover {
    transform: translateY(-4px);
}
.promo_countdown {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .promo_countdown p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #2B3137;
  }
  .promo_countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #FF4E53;
    font-size: 60px;
    font-family: Nface;
    line-height: 1.35;
    font-weight: normal;
  }
  .promo_countdown_timer > div div {
    display: flex;
    gap: 3px;
  }
@media(max-width: 575px) {
    .tarif {
        padding: 40px 0;
    }
    .title {
        font-size: 22px;
    }
    .subtitle {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .block {
        flex-direction: column;
    }
    .block_item {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 20px;
    }
    .block_item p {
        position: relative;
        width: max-content;
    }
    .block_item_title span {
        margin: 6px 0;
    }
    .block_item_prices em {
        top: -46px;
    }
    .block_item_tags {
        flex-direction: column;
    }
    .block_item_prices {
        font-size: 24px;
    }
    .btn {
        font-size: 18px;
        text-align: center;
        width: 100%;
        height: 80px;
    }
    .promo_countdown {
        order: 3;
        text-align: center;
        margin-bottom: 10px;
    }
    .promo_countdown_timer > div {
        justify-content: center;
        font-size: 48px;
    }
}