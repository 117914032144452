.faq {
    padding: 75px 0;
    position: relative;
}
.faq_block {
    display: flex;
}
.faq_left {
    flex: 1;
}
.faq_right {
    flex: 0 1 630px;
    max-width: 630px;
}
.faq_titles {
    position: sticky;
    top: 80px;
}
.faq_title {
    margin-bottom: 40px;
    font-family: Unisans;
    font-size: 65px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
}
.accordion {
    max-width: 685px;
    margin: 0 auto;
}
.question {
    padding: 24px 50px 24px 0;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid rgba(71, 71, 71, .2);
    transition: .2s ease-in-out;
    color: rgba(255, 255, 255, 0.8);
}
.question::before {
    content: '';
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="%23EBFF5E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    width: 24px;
    height: 24px;
    /* border: 2px solid #fff; */
    /* border-radius: 100%; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 0;
    /* font-size: 17px; */
    /* font-weight: 300; */
    transition: .2s ease-in-out;
}
.question:hover {
    border-color: #EBFF5E;
}
.active .question {
    color: #EBFF5E;
    border-color: #EBFF5E;
}
.active .question::before {
    transform: rotate(45deg);
    color: #EBFF5E;
    border-color: #EBFF5E;
}
.question:hover::before {
    color: #EBFF5E;
    border-color: #EBFF5E;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 1.5;
    padding-left: 0;
    color: #97959E;
    transition: .2s ease-in-out;
}
.active .answer {
    padding-top: 14px;
    max-height: 550px;
    padding-bottom: 0;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}
.faq ul li {
    list-style-type: disc;
    margin-bottom: 10px;
    margin-left: 30px;
}
.answer a {
    color: #EBFF5E;
    font-weight: 500;
}
.promo_btn {
    transition: .2s ease-in-out;
    width: 430px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 600px;
    background: #EBFF5E;
    color: #171717;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    padding: 32px;
    cursor: pointer;
    text-transform: uppercase;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.oferta {
    display: flex;
    flex-direction: column;
}
.oferta a {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 600;
    max-width: max-content;
    margin-top: 10px;
    transition: .2s ease-in-out;
}
.oferta a:hover {
    color: #EBFF5E;
}
.faq_btn {
    border-radius: 18px;
    background-color: #764BFF;
    display: flex;
    gap: 12px;
    padding: 22px 70px 22px 26px;
    width: max-content;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 60px auto 0;
    transition: .2s ease-in-out;
}
.faq_btn p {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.05;
    letter-spacing: -0.04rem;
}
.faq_btn:hover {
    transform: translateY(-4px);
}
.faq_right .promo_btn {
    display: none;
}
@media(max-width: 767px) {
    .faq_btn {
        width: 100%;
    }
    .faq {
        padding: 0 0 40px;
    }
    .faq_title {
        margin-bottom: 30px;
        padding-left: 0;
    }
    .faq_title img {
        display: none!important;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
    }
    .btn {
        width: 100%;
    }
}
@media(max-width: 575px) {
    .faq {
        background-position: 50% 50%;
        padding: 50px 0 0;
    }
    .question {
        padding: 10px 45px 10px 0;
    }
    .question::before {
        right: 0;
    }
    .answer {
        padding-left: 0;
    }
    .faq_block {
        flex-wrap: wrap;
    }
    .faq_left, .faq_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .faq_title {
        position: relative;
        top: 0;
        font-size: 40px;
    }
    .faq_title br {
        display: none;
    }
    .faq ul li {
        margin-left: 17px;
    }
    .faq::before {
        content: none;
    }
    .faq_titles {
        position: relative;
        top: 0;
    }
    .promo_btn {
        width: 100%;
        font-size: 20px;
    }
    .faq_right .promo_btn {
        display: flex;
    }
    .faq_left .promo_btn {
        display: none;
    }
}

@media(max-width:344px) {
    .faq_btn p {
        font-size: 18px;
    }
}