.oferta {
    max-width: 970px;
    margin: 120px auto 80px;

    font-size: 20px;
    line-height: 1.55;
    font-family: 'Open Sans',sans-serif,sans,arial;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    color: #000;
}
.oferta h1 {
    text-align: center;
    font-size: 36px;
    line-height: 1.23;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    margin-bottom: 50px;
}
.oferta > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-weight: bold;
}
.oferta > p {
    margin-bottom: 30px;
}
.oferta h2 {
    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}
.oferta a {
    color: #ee4777;
    text-decoration: none;
}
.oferta a:hover, .oferta a:focus {
    color: #ee4777;
    text-decoration: underline;
}
.oferta ul {
    display: block;
    list-style-type: disc;
    padding-inline-start: 40px;
}
.table {
    font-variant-numeric: lining-nums tabular-nums;
    border-collapse: collapse;
    width: 100%;
}
.table thead th {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    line-height: 1rem;
    font-size: 0.75rem;
    color: #A0AEC0;
    width: 33%;
    border-bottom: 1px solid #b7b7b7;
}
.table tbody td {
    text-align: center;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.25rem;
    font-size: 1.1rem;
    border-bottom: 1px solid #b7b7b7;
}
.oferta_title {
    display: block!important;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 500;
}

@media(max-width: 767px) {
    .oferta {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .oferta h1 {
        font-size: 28px;
        margin-bottom: 30px;
    }
    .oferta p {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .oferta h2 {
        margin-top: 20px;
        line-height: 1.1;
    }
}