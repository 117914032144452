.yourbody {
    padding-top: 86px;
    overflow: hidden;
}
.yourbody_block {
    display: flex;
}
.yourbody_block__left {
    flex: 0 1 414px;
    max-width: 414px;
}
.yourbody_block__right {
    flex: 1;
    padding-left: 100px;
}
.yourbody_title {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.yourbody_subtitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #4A4A4A;
}
.yourbody_block__right ul {
    padding-bottom: 70px;
}
.yourbody_block__right ul li {
    padding-left: 28px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
    position: relative;
}
.yourbody_block__right ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    width: 10px;
    height: 10px;
    background-color: #EE4777;
    border-radius: 2px;
}
.yourbody_phone {
    position: relative;
    height: 100%;
    overflow: hidden;
}
.yourbody_phone::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -265px;
    bottom: -1px;
    width: 530px;
    height: 700px;
    z-index: 1;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/yourbody_phone.png');
    pointer-events: none;
}
.yourbody_phone::after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -186px;
    top: 0;
    width: 372px;
    height: 10783px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/progs_min2.jpg');
    pointer-events: none;
    background-size: cover;
    animation: scrollphone 180s linear infinite;
}
@keyframes scrollphone {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-91.7%);
    }
}

@media(max-width: 1199px) {
    .yourbody_title br, .yourbody_subtitle br, .yourbody_block__right ul li br {
        display: none;
    }
    .yourbody_block__right {
        padding-left: 65px;
    }
    .yourbody_block__right ul {
        padding-bottom: 30px;
    }
}
@media(max-width: 991px) {
    .yourbody_block {
        flex-wrap: wrap;
    }
    .yourbody_block__left {
        flex: 0 1 100%;
        max-width: 100%;
        order: 1;
        height: 846px;
        margin-bottom: 30px;
    }
    .yourbody_block__right {
        flex: 0 1 100%;
        max-width: 100%;
        padding-left: 0;
    }
    .yourbody_phone_mob {
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .yourbody_phone_mob::before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -207px;
        bottom: 0;
        width: 414px;
        height: 846px;
        z-index: 1;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/progs_mob.jpg');
        pointer-events: none;
    }
}
@media(max-width: 575px) {
    .yourbody {
        padding-top: 45px;
    }
    .yourbody_title {
        font-size: 32px;
        line-height: 36px;
    }
    .yourbody_title br, .yourbody_block__right ul li br {
        display: none;
    }
    .yourbody_subtitle {
        font-size: 20px;
        line-height: 26px;
    }
    .yourbody_block__right ul li {
        font-size: 18px;
        line-height: 26px;
    }
    .yourbody_block__left {
        height: 613px;
    }
    .yourbody_phone_mob::before {
        margin-left: -150px;
        width: 300px;
        height: 613px;
        background-size: cover;
    }
}
@media(max-width: 329px) {
    .yourbody_title {
        font-size: 30px;
        line-height: 34px;
    }
}