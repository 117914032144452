.react-quiz-container {
  margin: 20px;
  max-width: 500px;
}

.react-quiz-container .questionPic {
  margin-bottom: 35px;
}

.react-quiz-container .startQuizWrapper {
  margin-top: 10px;
}

.questionBody {
  transition: 0.35s ease-out opacity;
  opacity: 1;
}

.questionBody.questionBodyTransition {
  opacity: 0;
}

.react-quiz-container .btn {
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 11px 12px;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-quiz-container .questionWrapper .btn {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  font-size: 15px;
  display: block;
  white-space: normal;
  text-align: unset;
}

.react-quiz-container .questionWrapper .btn.correct {
  background: green;
  color: white;
}

.timerBtn {
  background: green;
  border: 0 !important;
  width: 80px;
  color: #fff;
  padding: 5px;
  top: -35px;
  border-radius: 10px;
  position: relative;
  float: right;
  cursor: pointer;
}

.timerPauseScreen {
  font-size: 30px;
}

.react-quiz-container .questionModal .alert {
  padding: 20px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
}

.react-quiz-container .correct {
  background: green;
}

.react-quiz-container .incorrect {
  background: red;
  color: white;
}

.react-quiz-container .unanswered {
  background: grey;
  color: white;
}

/* .react-quiz-container .questionWrapper img {
  width: 100%;
} */

.react-quiz-container .answerBtn {
  width: 100%;
}

.react-quiz-container .selected {
  background: #007bff;
  color: white;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  font-size: 15px;
  border-radius: 2px;
  line-height: 1.35135;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper h3 {
  background-color: #fafafa;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.9);
  margin: 0px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper .explanation {
  padding: 20px;
  margin: 0px 20px 20px 20px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin: 20px;
}

.react-quiz-container .result-answer {
  padding: 0px 20px;
}

.react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
}

.react-quiz-container .tag-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  display: none;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection {
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.react-quiz-container .number-of-selection {
  background: #673ab7;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #3f51b5;
}

.react-quiz-container .selection-tag.multiple {
  background: #ff9800;
}

.react-quiz-container .selection-tag.segment {
  background: #3db4b9;
  margin-left: 5px;
}

.react-quiz-container .questionBtnContainer {
  display: flex;
  justify-content: space-between;
}

.react-quiz-container .quiz-result-filter {
  position: relative;
  width: 120px;
  bottom: 15px;

  .filter-dropdown-select {
    padding: 8px;
    margin-top: 2px;
    text-transform: capitalize;
    border: 2px solid #c2c5c7;
    border-radius: 7px;
    background-color: #fcfcfc;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    bottom: 10px;
    position: relative;
    height: 12px;
  }

  .filter-dropdown-select:after {
    transition: all 0.3s ease;
    content: "▼";
    position: absolute;
    top: 57%;
    right: 10px;
    transform: translateY(-50%);
    color: #a5a5a5;
    font-size: 11px;
  }

  .filter-dropdown-select:hover {
    border: 2px solid #51aae6;
    box-shadow: 0 0 6px rgba(109, 179, 250, 0.5);
  }

  .dropdown-options {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 85%;
    z-index: 1;
    left: 0;
    width: 100%;
    background-color: #fcfcfc;
    box-shadow: 0 0 10px rgba(141, 143, 145, 0.5);
    border-radius: 10px;
  }

  .filter-dropdown-select.open {
    border: 2px solid #51aae6;
    box-shadow: 0 0 6px rgba(109, 179, 250, 0.5);
  }

  .dropdown-options .dropdown-options-item {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  .dropdown-options .dropdown-options-item:hover {
    background-color: rgba(226, 226, 226, 0.852);
    border-radius: 5px;
  }

  .dropdown-options .dropdown-options-item:focus {
    background-color: #d7f1f9;
    font-weight: bold;
  }

  .filter-dropdown-select .selected-option.selected-open {
    color: #c6c2c2;
    font-weight: lighter;
  }

  .filter-dropdown-select .selected-option {
    font-size: 16px;
    position: relative;
    bottom: 2px;
  }

  .filter-dropdown-select:focus + .dropdown-options {
    max-height: 200px;
  }

  .dropdown-options .dropdown-options-item.selected {
    background-color: #d7f1f9;
    color: #333;
    font-weight: bold;
  }

  .dropdown-options.open {
    max-height: 200px;
  }
}

/* скрываем лишние элементы в AuthWidget */
.react-quiz-container .questionBody .abid-5071,
.react-quiz-container .questionBody .abid-5073,
.react-quiz-container .questionBody .abid-5075,
.react-quiz-container .questionBody .abid-5078\.5,
.react-quiz-container .questionBody .abid-5081 {
  display: none;
}

/* растягиваем AuthWidget */
.react-quiz-container .questionBody .abid-5072\.3,
.react-quiz-container .questionBody .abid-10056,
.react-quiz-container .questionBody .abid-10057\.1 {
  width: 100%;
  padding: 0;
}

/* отступ для поля ввода email AuthWidget */
.react-quiz-container .questionBody .abid-5072\.3 {
  padding-top: 30px;
}

/* стили для слоя с индикатором загрузки экрана */
.react-quiz-container .questionBody .abid-5092 {
  border-radius: 10px;
}

/* стили для списков в блоке с типом html */
.react-quiz-container ol {
  list-style-position: inside;
}

.react-quiz-container ul {
  list-style-type: none;
}

.react-quiz-container ul li:before {
  padding-right: 8px;
  content: "• ";
}

.questionTextBottom {
  margin-top: 15px;
}