.promo {
    height: 921px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/stretch/promo_3.jpg');
    background-size: cover;
    background-position: 0% 50%;
    padding-top: 115px;
}
.promo > div {
    padding-left: 80px;
}
.promo_logo {
    margin-bottom: 40px;
}
.promo_logo img {
    width: 168px;
}
.promo_title {
    font-family: Qanelas;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 58px;
    line-height: 64px;
    color: #D8376A;
}
.promo_subtitle {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 32px;
}
.promo_text {
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 32px;
}
.promo_btn {
    width: 462px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.59deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-family: Qanelas;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #F8F8F8;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
}
.promo_btn:hover {
    background: linear-gradient(90.59deg, rgba(185, 38, 85, 0.7) 6.98%, rgba(228, 47, 129, 0.7) 99.58%), #FF99B6;
}
.promo_img {
    display: none;
}
.promo_btn2, .promo_text2 {
    display: none;
}
@media(max-width: 991px) {
    .promo {
        background-position: 25% 50%;
        padding-top: 75px;
        padding-bottom: 75px;
        height: auto;
    }
    .promo_title {
        font-size: 46px;
        line-height: 50px;
    }
    .promo_subtitle {
        font-size: 22px;
        line-height: 30px;
    }
}
@media(max-width: 575px) {
    .promo {
        padding-top: 10px;
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/stretch/mob-bg.jpg');
        padding-bottom: 0;
        background-position: 0% 0%;
        background-size: cover;
        text-align: center;
        background-repeat: no-repeat;
    }
    .promo_img {
        display: block;
    }
    .promo_logo {
        margin-bottom: 18px;
    }
    .promo_logo img {
        width: 161px;
    }
    .promo_title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .promo_subtitle {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .promo_text {
        font-size: 20px;
        line-height: 26px;
        padding-left: 15px;
        padding-right: 15px;
        display: none;
    }
    .promo_btn {
        width: auto;
        text-align: center;
        font-size: 18px;
        margin-left: 15px;
        margin-right: 15px;
        display: none;
    }
    .promo > div {
        padding-left: 0;
        padding-right: 0;
    }
    .promo_text2 {
        display: block;
        text-align: center;
        margin-top: 20px;
    }
    .promo_btn2 {
        display: flex;
        margin-bottom: 30px;
    }
}