.start {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/velvet/3/start_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding: 140px 0 85px;
    position: relative;
    overflow: hidden;
}
.start_block {
    background-color: #fff;
    border-radius: 16px;
    padding: 60px 45px;
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4.180266380310059px 12.54079818725586px -2.0901331901550293px rgba(16, 24, 40, 0.02), 0px 12.54079818725586px 25.08159637451172px -4.180266380310059px rgba(16, 24, 40, 0.04);
}
.start_block > p {
    color: rgba(0, 0, 25, 0.6);
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 45px;
    text-align: center;
}
.start_top {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
.start_title {
    font-family: Forum;
    font-size: 60px;
    line-height: 66px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: #000019;
    flex: 1;
}
.start_top_right {
    flex: 0 1 305px;
    max-width: 305px;
    background-color: #FCA138;
    border-radius: 16px;
    border: 1px solid #EAECF0;
    padding: 24px;
}
.start_text {
    font-size: 24px;
    line-height: 32px;
    color: #000019;
    font-weight: 500;
    margin-bottom: 30px;
}
.start_text span {
    color: #E41F32;
    font-weight: 600;
}
.start_text i {
    font-style: normal;
    text-decoration: line-through;
    color: #9C9C9C;
}
.start_dates {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.start_date {
    color: #000019;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
}
.start_date img {
    margin-right: 5px;
}
.start_date:first-of-type {
    margin-right: 22px;
    padding-right: 22px;
    border-right: 1px solid rgba(10, 12, 16, 0.6);
}
.start_start {
    color: #29282B;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 19px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.start_start img {
    margin-right: 10px;
}
.programs_counts {
    display: flex;
    justify-content: center;
    color: #5492DA;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.programs_counts > div {
    display: flex;
    margin-left: 10px;
}
.start_credit {
    flex: 0 1 50%;
    max-width: 50%;
    border: 1px solid #E3E9F4;
    border-radius: 16px;
    padding: 30px;
}
.start_credit > p:first-of-type {
    margin-bottom: 40px;
    margin-top: 0;
}
.start_credit__title {
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #000019;
}
.start_credit__title span {
    font-family: Inter;
}
.start_credit__text {
    font-size: 18px;
    line-height: 26px;
}
.start_credit__text p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 25, 0.6);
}
.start_credit__text p:last-of-type {
    margin-bottom: 0;
}
.start_prizes {
    margin-bottom: 10px;
}
.start_prizes__item {
    padding: 10px 0;
    border-bottom: 1px solid rgba(74, 72, 79, 0.2);
    font-size: 18px;
    line-height: 24px;
}
.start_parts {
    flex: 0 1 50%;
    max-width: 50%;
    border: 1px solid #E3E9F4;
    border-radius: 16px;
    padding: 30px;
    position: relative;
}
.start_parts > p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 25, 0.6);
}
.start_parts > p:first-of-type {
    margin-bottom: 40px;
}
.start_parts__title {
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #000019;
}
.start_parts__text {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 40px;
}
.start_parts__text span {
    font-family: Inter;
}
.start_parts__text strong {
    font-weight: 600;
}
.start_parts_block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    padding: 30px;
    border-radius: 16px;
    transition: .2s ease-in-out;
}
.start_parts:hover .start_parts_block {
    opacity: 1;
    pointer-events: all;
}
.start_parts_number {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: inline-block;
    border-top: 2px solid transparent;
    border-left: 2px solid #383838;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.start_parts_number > * {
    transform: rotate(-45deg);
    color: #1C1C1A;
}
.start_parts_number2 {
    border-top: 2px solid #383838;
}
.start_parts_number3 {
    border-top: 2px solid #383838;
    border-right: 2px solid #383838;
}
.start_parts_number4 {
    border: 2px solid #383838;
}
.start_parts_number::before {
    /* content: ''; */
    position: absolute;
    left: 92px;
    top: 50%;
    margin-top: -82px;
    width: 65px;
    height: 2px;
    background-color: #383838;
    transform: rotate(-45deg);
}
.start_parts_number4::before {
    content: none;
}
.start_parts_item {
    flex: 0 1 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.start_parts_item > p {
    margin-top: 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #1C1C1A;
}
.start_parts_item > p span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    color: #383838;
    opacity: .6;
}
.start_parts_item > p b {
    font-family: Inter;
    font-style: normal;
}
.start_parts__btn {
    max-width: 800px;
    height: 90px;
    background: linear-gradient(92.57deg, #474747 -13.7%, #2B2B2C 84.73%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 60px;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}
.start_widget {
    padding: 0 50px;
}
.programs_start {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #3D404F;
    margin-bottom: 50px;
}
.programs_start img {
    margin-right: 10px;
}
.programs_start__item {
    background: #FAF0E8;
    border-radius: 8px;
    margin: 0 7.5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
}
.start_title b {
    display: inline-block;
}
.promo_countdown {
    color: #FFB800;
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.promo_countdown > div {
    display: flex;
    justify-content: center;
}
.promo_countdown span {
    margin-left: -5px;
    margin-right: 5px;
    top: -3px;
    position: relative;
}
.start_countdown {
    text-align: center;
}
.start_countdown > p {
    margin-bottom: 6px;
    font-size: 20px;
    line-height: 22px;
    color: #fff;
    text-align: center;
}
.start_countdown_timer {
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
    color: #fff;
}
.start_countdown_timer > div {
    display: flex;
}
.start_countdown_timer span {
    margin-left: -10px;
    margin-right: 3px;
}
.start_add_title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #1C1C1A;
}
.start_add_subtitle {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #1C1C1A;
}
.start_new {
    display: flex;
    gap: 14px;
}
.start_parts_mark {
    position: absolute;
    right: 30px;
    top: 22px;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.start_parts:hover .start_parts_mark {
    opacity: 0;
    transform: translateX(20px);
}
@media(max-width: 991px) {
    .start_block {
        padding: 40px;
    }
    .start_title {
        font-size: 42px;
        line-height: 46px;
    }
    .start_parts__btn {
        padding: 0 25px;
        max-width: calc(100% - 30px);
    }
}
@media(max-width: 767px) {
    .start {
        padding: 60px 0 60px;
    }
    .frame1 {
        width: 90px;
        height: 50px;
        margin-left: -45px;
        background-size: cover;
        top: 0;
    }
    .frame2 {
        width: 70px;
        height: 35px;
        margin-left: -35px;
        background-size: cover;
    }
    .start_block {
        padding: 20px 10px 40px;
        border-radius: 30px;
    }
    .start_pretitle {
        display: block;
        background-color: #F450F7;
        font-weight: 500;
        color: #fff;
        letter-spacing: -0.04rem;
        font-size: 18px;
        line-height: 18px;
        width: 275px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        margin: 0 auto 14px;
    }
    .start_title {
        font-size: 20px;
        line-height: 22px;
    }
    .start_title em {
        display: block;
        margin-bottom: 12px;
        font-size: 34px;
        line-height: 34px;
        text-transform: uppercase;
    }
    .start_subtitle {
        display: none;
    }
    .start_date {
        flex-direction: column;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        color: rgba(10, 12, 16, 0.6);
    }
    .start_date img {
        margin-bottom: 8px;
    }
    .start_date:first-of-type {
        border-right: none;
        padding-right: 5px;
        margin-right: 5px;
    }
    .start_parts_number::before {
        content: none;
    }
    .start_guarantee__title, .start_parts__title {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 5px;
    }
    .start_guarantee__text, .start_parts__text {
        font-size: 16px;
        line-height: 22px;
    }
    .start_parts_block {
        flex-wrap: wrap;
        padding-bottom: 25px;
        margin-bottom: 0;
    }
    .start_parts_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .start_parts_item > p {
        margin-top: 5px;
        font-size: 21px;
        line-height: normal;
    }
    .start_parts_item > p br {
        display: block;
    }
    .start_parts_item > p span {
        font-size: 16px;
    }
    .start_widget {
        padding: 0;
    }
    .programs_start {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
    .programs_start__item {
        margin-bottom: 15px;
        justify-content: center;
        font-size: 16px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding: 10px;
    }
    .programs_start__item span {
        display: contents;
    }
    .programs_counts {
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
    .programs_counts > div {
        margin-left: 0;
    }
}
@media(max-width: 575px) {
    .start {
        padding: 100px 0 40px;
    }
    .start_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 15px;
    }
    .start_text {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 25px;
    }
    .start_parts__title, .start_guarantee__title, .start_credit__title {
        font-size: 18px;
        line-height: 22px;
    }
    .start_credit__title, .start_parts__title {
        margin-bottom: 10px;
    }
    .start_parts__text, .start_guarantee__text, .start_credit__text, .start_credit p {
        font-size: 14px;
        line-height: 20px;
    }
    .start_parts__text br, .start_guarantee__text br, .start_credit__text br {
        display: none;
    }
    .start_parts > p:first-of-type {
        font-size: 14px;
        line-height: 18px;
    }
    .start_countdown, .start_countdown p, .start_block > p {
        text-align: left;
    }
    .start_countdown > p {
        font-size: 18px;
        line-height: 20px;
    }
    .start_countdown_timer {
        font-size: 30px;
        line-height: 33px;
    }
    .start_top {
        flex-wrap: wrap;
    }
    .start_new {
        flex-wrap: wrap;
    }
    .start_credit, .start_parts {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 16px;
    }
    .start_parts_mark {
        display: none;
    }
    .start_parts_block {
        position: relative;
        opacity: 1;
        pointer-events: all;
        padding: 0;
        height: auto;
    }
}

.withbtns {
    display: flex;
    min-height: 100vh;
}
.btns {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.btn {
    cursor: pointer;
    background-color: #fff;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 32px;
    font-weight: 500;
    transition: .3s ease-in-out;
}
.btn.active {
    background-color: #E41F32;
    color: #fff;
}

@media(max-width:575px) {
    .withbtns {
        flex-direction: column;
        gap: 32px;
    }
    .start {
        padding-top: 40px;
    }
}