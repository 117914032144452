.promo {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/promo_bg2.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    padding-top: 25px;
    height: 615px;
    background-color: #02020F;
}
.promo::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90.85deg, #161719 67.47%, rgba(22, 23, 25, 0) 99.21%);
    z-index: -1;
}
.promo_logo img {
    height: 130px;
    margin-left: -27px;
    margin-bottom: -40px;
    margin-top: -40px;
}
@media(max-width: 767px) {
    .promo_logo img {
        height: 90px;
        margin-left: 0;
        margin-bottom: -35px;
        margin-top: -30px;
    }
}
.promo_pretitle {
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
}
.promo_title {
    font-size: 60px;
    line-height: 75px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
}
.promo_title span {
    color: var(--fat-red);
}
.promo_subtitle {
    font-size: 25px;
    line-height: 32px;
    color: rgba(255,255,255,.75);
    margin-bottom: 20px;
    position: relative;
}
.promo_subtitle strong {
    font-weight: 600;
}
.promo_btns {
    display: flex;
    align-items: center;
}
.promo_date {
    font-size: 33px;
    line-height: 42px;
    font-weight: 600;
    color: #fff;
    margin-left: 40px;
}
.promo_items {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
}
.promo_item1 {
    width: 181px;
    height: 146px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
    margin-top: 12px;
}
.promo_item2 {
    width: 181px;
    height: 146px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item3 {
    width: 181px;
    height: 146px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}
.promo_item1 p, .promo_item2 p, .promo_item3 p {
    position: absolute;
    bottom: 10px;
}
.promo_item1 p {
    bottom: 22px;
}
.promo_point1 {
    color: #fff;
    background: linear-gradient(180deg, #46B1FF 0%, #005FA5 100%);
    border-radius: 60px;
    width: 135px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: 80px;
    font-weight: 600;
}
.promo_point2 {
    color: #fff;
    background: linear-gradient(180deg, #5A31FF 0%, #261AA8 100%);
    border-radius: 60px;
    width: 180px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -190px;
    margin-left: 400px;
    font-weight: 600;
}
.promo_point3 {
    color: #fff;
    background: linear-gradient(180deg, #F02A2C 0%, #9C0002 100%);
    border-radius: 60px;
    width: 169px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 145px;
    margin-left: 360px;
    font-weight: 600;
}


@media(max-width: 1199px) {
    .promo {
        height: auto;
        background-position: 50% 100%;
    }
    .promo::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 220px;
        background: linear-gradient(0deg, rgba(5, 5, 18, 0) 0%, #050512 73.2%);
        pointer-events: none;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        background: transparent;
        margin-left: 0;
        margin-top: 0;
        left: 0;
        top: auto;
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        margin-left: 17px;
        margin-top: 20px;
    }
    .promo_point1 span {
        color: rgba(210, 53, 185, 1);
    }
    .promo_point2 span {
        color: rgba(107, 196, 84, 1);
    }
    .promo_point3 span {
        color: rgba(66, 66, 255, 1);
    }
    .promo_btns {
        flex-direction: column;
        align-items: flex-start;
    }
    .promo_btns > div:first-of-type {
        order: 1;
    }
    .promo_date {
        margin-left: 0;
        margin-bottom: 20px;
    }
}
@media(max-width: 991px) {
    .promo {
        overflow: hidden;
    }
    .promo_subtitle {
        margin-bottom: 15px;
    }
}
@media(max-width: 767px) {
    .promo {
        text-align: center;
        padding-top: 55px;
        background-size: auto;
        background-position: 60% 100%;
        background-repeat: no-repeat;
        background-color: #161719;
        position: relative;
    }
    .promo_pretitle {
        font-size: 16px;
        line-height: 18px;
    }
    .promo_title {
        font-size: 42px;
        line-height: 42px;
    }
    .promo_subtitle {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 30px;
    }
    .promo_btns {
        align-items: center;
    }
    .promo_date {
        position: relative;
    }
    .promo::before {
        height: 510px;
    }
    .promo::after {
        background: linear-gradient(0deg, #02020F 0%, rgba(2, 2, 15, 0) 100%);
        height: 325px;
        bottom: 0;
        top: auto;
        z-index: 0;
        pointer-events: none;
    }
    .promo_items {
        flex-direction: column;
        padding-left: 60px;
    }
    .promo > * {
        position: relative;
        z-index: 1;
    }
}
@media(max-width: 575px) {
    .promo_title {
        font-size: 35px;
        margin-bottom: 10px;
    }
    .promo_items {
        flex-wrap: wrap;
        gap: 0;
        margin: -80px -10px 25px;
    }
    .promo_item1, .promo_item2, .promo_item3 {
        width: 50%;
        height: auto;
        padding: 0 10px;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 14px;
    }
    .promo_date {
        text-align: center;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 14px;
        margin: 25px 5px 0;
    }
    .promo {
        background-position: 61% 100%;
        padding-bottom: 10px;
    }
    .promo::before {
        height: 410px;
    }
    .promo_subtitle br {
        display: none;
    }
    .promo_items {
        padding-left: 0;
    }
}
@media(max-width: 440px) {
    .promo {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/promo_bg_m_2x.png');
        padding-top: 20px;
        background-size: cover;
        background-position: center center;
    }
    .promo::before {
        content: none;
    }
    .promo_subtitle {
        margin-bottom: 60px;
    }
    .promo_items {
        margin-bottom: 100px;
    }
}
@media(max-width: 374px) {
    .promo_title {
        font-size: 30px;
        line-height: 40px;
    }
    .promo_subtitle {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 30px;
    }
    .promo_items {
        margin-top: -60px;
    }
    .promo_item1 p, .promo_item2 p, .promo_item3 p {
        font-size: 12px;
    }
    .promo_point1, .promo_point2, .promo_point3 {
        font-size: 13px;
        margin-top: 20px;
    }
    .promo_date {
        font-size: 28px;
        margin-bottom: 10px;
    }
}
.promo_logo {
    margin-bottom: 40px;
}
@media(max-width: 767px) {
    .promo_logo {
        margin-bottom: 25px;
    }
}