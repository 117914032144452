.preims {
  padding: 90px 0 0;
  overflow: hidden;
  position: relative;
}
.preims_title {
  color: #333844;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.preims_title span {
  color: #f168ab;
}
.preims_subtitle {
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.38;
  letter-spacing: -0.7px;
  color: #717da1;
  font-weight: 500;
}
.preims_subtitle span {
  display: none;
}
.preims_block {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}
.preims_left {
  flex: 0 1 553px;
  max-width: 553px;
}
.preims_right {
  flex: 1;
  padding-left: 40px;
}
.preims_left img {
  /* mix-blend-mode: darken; */
}
.preims_left img:last-of-type {
  display: none !important;
}
.preims_right ul li {
  position: relative;
  padding-left: 32px;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 1.6;
  color: #717da1;
}
.preims_right ul li strong {
  font-weight: normal;
}
.preims_right ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 22px;
  height: 22px;
  background-image: url("https://gymteam.kinescopecdn.net/img/l/2024/bikinifest/arrow.png");
  background-size: cover;
}
.preims_line {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 70px;
  /* justify-content: space-between; */
  /* padding-top: 68px; */
}
.preims_line::before {
  /* content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 13px;
    background-color: #E7E1D2;
    border-radius: 85px; */
}
.preims_line__item {
  margin-bottom: 45px;
  position: relative;
}
.preims_line__item::before {
  /* content: '01';
    position: absolute;
    left: 0;
    top: -96px;
    width: 67px;
    height: 67px;
    border-radius: 100%;
    background-color: #fff;
    color: rgba(52, 52, 50, 0.3);
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    box-shadow: 0px 4px 20px rgba(49, 29, 25, 0.08); */
}
/* .preims_line__item:nth-of-type(2)::before {content: '02';}
.preims_line__item:nth-of-type(3)::before {content: '03';}
.preims_line__item:nth-of-type(4)::before {content: '04';}
.preims_line__item:nth-of-type(5)::before {content: '05';} */
.preims_line__title {
  color: #f168ab;
  font-family: ProximaN;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.1;
  text-transform: uppercase;
}
.preims_line__text {
  color: #414141;
  font-size: 16px;
  line-height: 1.3;
}
.preims_line__text strong {
  font-weight: normal;
}

@media (max-width: 1199px) {
  .preims_title br,
  .preims_subtitle br,
  .preims_line__text br {
    display: none;
  }
  .preims_title {
    font-size: 40px;
    line-height: 40px;
  }
  .preims_line__text {
    padding-right: 0;
    font-size: 14px;
    line-height: 1.3;
  }
}
@media (max-width: 991px) {
  .preims_title {
    margin-bottom: 385px;
    text-align: center;
    position: relative;
  }
  .preims_subtitle {
    /* background-color: #F8F4F1; */
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-bottom: 0;
    padding: 20px;
    position: relative;
  }
  .preims_line {
    flex-wrap: wrap;
    padding-top: 0;
    gap: 10px;
    /* padding-left: 120px; */
  }
  .preims_line__item {
    flex: 0 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-bottom: 10px;
  }
  .preims_line::before {
    width: 13px;
    height: 90%;
    left: 50px;
  }
  .preims_line__title {
    font-size: 30px;
  }
  .preims_line__item::before {
    top: -5px;
    left: -98px;
  }
}
@media (max-width: 767px) {
  .preims {
    padding-top: 30px;
  }
}
@media (max-width: 575px) {
  .preims_title {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: left;
  }
  .preims_title span {
    /* display: none; */
  }
  .preims_subtitle {
    font-size: 28px;
    line-height: 30px;
    padding: 0;
    margin-bottom: 15px;
  }
  .preims_subtitle span {
    display: inline-block;
  }
  .preims_block {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .preims_left {
    text-align: center;
    order: 2;
  }
  .preims_left img:last-of-type {
    display: inline-block !important;
  }
  .preims_left img:first-of-type {
    display: none !important;
  }
  .preims_right {
    padding-left: 0;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .preims_title {
    font-size: 22px;
    margin-bottom: 18px;
  }
  .preims_subtitle {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: normal;
  }
  .preims_right ul li {
    font-size: 16px;
    line-height: 20px;
  }
  .preims_line {
    /* padding-left: 98px; */
    text-align: center;
  }
  .preims_line::before {
    left: 28px;
  }
  .preims_title br {
    display: none;
  }
}
