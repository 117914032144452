.joinus {
    background-color: #F2F2F2;
    padding: 80px 0 100px;
    position: relative;
    overflow: hidden;
}
.joinus::before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: 50px;
    bottom: 0;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/joinus_bg.jpg');
    width: 721px;
    height: 698px;
    pointer-events: none;
    mix-blend-mode: darken;
}
.joinus_title {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 30px;
    position: relative;
}
.joinus ul {
    margin-bottom: 40px;
}
.joinus ul li {
    padding-left: 22px;
    margin-bottom: 15px;
    position: relative;
    font-size: 20px;
    line-height: 28px;
}
.joinus ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 10px;
    height: 10px;
    background-color: #EE4777;
    border-radius: 2px;
}
.joinus_btn {
    width: 322px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(275.94deg, #B92655 -15.09%, #B92655 -15.08%, rgba(228, 47, 129, 0) 102.69%), #EE4777;
    border-radius: 20px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.5);
    transition: .2s ease-in-out;
    position: relative;
}
.joinus_btn:hover {
    box-shadow: 0px 4px 20px rgba(225, 59, 115, 0.75);
    transform: translateY(-2px);
}
.joinus_panel {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #fff;
    border-radius: 20px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    padding: 20px 30px;
    max-width: 370px;
    margin-left: 250px;
    margin-top: 165px;
}

@media(max-width: 1439px) {
    .joinus::before {
        width: 600px;
        height: 581px;
        background-size: cover;
        margin-left: -10px;
    }
    .joinus_panel {
        margin-left: 125px;
        margin-top: 195px;
    }
}
@media(max-width: 1199px) {
    .joinus::before {
        width: 500px;
        height: 484px;
        margin-left: 15px;
    }
    .joinus_panel {
        margin-left: 100px;
        margin-top: 220px;
    }
}
@media(max-width: 991px) {
    .joinus {
        padding-top: 45px;
        padding-bottom: 500px;
    }
    .joinus::before {
        left: 50%;
        margin-left: -250px;
    }
    .joinus_panel {
        left: 50%;
        margin-left: -185px;
        top: inherit;
        margin-top: inherit;
        bottom: 50px;
    }
    .joinus ul li br {
        display: none;
    }
}
@media(max-width: 575px) {
    .joinus {
        padding-bottom: 45px;
    }
    .joinus_title {
        font-size: 32px;
        line-height: 36px;
    }
    .joinus ul {
        margin-bottom: 430px;
    }
    .joinus ul li {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .joinus ul li::before {
        top: 8px;
    }
    .joinus::before {
        width: 300px;
        height: 290px;
        margin-left: -150px;
        bottom: 230px;
    }
    .joinus_btn {
        width: 100%;
        height: 80px;
    }
    .joinus_panel {
        padding: 22px;
        max-width: calc(100% - 30px);
        margin-left: 0;
        left: 15px;
        bottom: 150px;
    }
}
@media(max-width: 375px) {
    .joinus_panel {
        font-size: 22px;
        line-height: 26px;
    }
}
@media(max-width: 350px) {
    .joinus_title {
        font-size: 28px;
        line-height: 32px;
    }
}