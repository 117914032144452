.author {
    padding: 50px 0;
}
.block {
    background-color: #fff;
    padding: 60px;
    border-radius: 24px;
}
.author_block {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    gap: 50px;
}
.author_img {
    flex: 0 1 420px;
    max-width: 420px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 16px;
}
.author_img img {
    border-radius: 16px;
    flex: 0 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
}
.author_info {
    flex: 1;
}
.author_title {
    font-family: Nface;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 1.3;
    text-transform: uppercase;
}
.author_preims {
    display: flex;
    gap: 40px;
    justify-content: space-between;
}
.author_item {
    flex: 0 1 calc(20% - 160px / 5);
    position: relative;
}
.author_item::before {
    /* content: ''; */
    position: absolute;
    left: 0;
    top: -45px;
    width: 34px;
    height: 34px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none"><circle cx="16.8182" cy="16.8182" r="16.3182" fill="%23F4F7FF" stroke="%23C8DEFF"/><path d="M9.07642 15.4909L15.5264 21.7483L24.5598 11.8882" stroke="%23ED3046" stroke-width="2" stroke-linecap="round"/></svg>');
}
.author_item_name {
    margin-bottom: 4px;
    color: #FF4E53;
    font-family: Nface;
    font-size: 32px;
    line-height: 1;
}
.author_item_text {
    font-size: 16px;
    line-height: 1.4;
    color: #6A6A6A;
}
.author_imgmob {
    display: none;
}
.author_list li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 24px;
    font-size: 16px;
    line-height: 1.35;
    color: #565656;
    transition: .3s ease-in-out;
}
.author_list li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 15px;
    height: 15px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><g clip-path="url(%23clip0_1723_989)"><path d="M8 0.452637C8.25463 4.48461 11.468 7.69803 15.5 7.95264C11.468 8.20726 8.25463 11.4206 8 15.4526C7.74539 11.4206 4.53197 8.20726 0.5 7.95264C4.53197 7.69803 7.74539 4.48461 8 0.452637Z" fill="%23FF4E53"/></g><defs><clipPath id="clip0_1723_989"><rect width="15" height="15" fill="white"/></clipPath></defs></svg>');
}
.author_list li:last-of-type {
    margin-bottom: 0;
}
.author_list li b {
    font-weight: 600;
}
.author_list li:hover {
    transform: translateX(3px);
}

.author_imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}
.author_imgs_item {
    background-color: #191919;
    border: 1px solid #2B2B2B;
    border-radius: 8px;
    padding: 15px;
    transition: .3s ease-in-out;
}
.author_imgs_item p {
    text-align: center;
    color: #fff;
    margin-top: 8px;
    font-size: 14px;
    color: #DCDCDC;
}
.author_imgs_item:hover {
    z-index: 2;
    transform: scale(1.1);
    box-shadow: 0px 8px 64px 0px #6664B666;
}
.author_imgs:has(.author_imgs_item:hover) .author_imgs_item {
    filter: grayscale(1)
}
.author_imgs_item:hover {
    filter: grayscale(0)!important;
}
@media(max-width: 575px) {
    .author {
        background-image: none;
        padding: 40px 0;
    }
    .author_title {
        font-size: 22px;
        margin-bottom: 12px;
    }
    .author_preims {
        flex-wrap: wrap;
        gap: 20px;
    }
    .author_block {
        flex-direction: column;
    }
    .author_imgmob {
        display: block;
        margin-bottom: 32px;
    }
    .author_imgmob img {
        border-radius: 8px;
    }
    .author_info {
        padding-left: 0;
    }
    .author_item {
        flex: 0 1 calc(50% - 10px);
        max-width: calc(50% - 10px);
    }
    .author_item:nth-of-type(2) {
        /* display: none!important; */
    }
    .author_item::before {
        position: relative;
        display: block;
        top: 0;
        margin-bottom: 15px;
    }
    .author_item:last-of-type {
        border-bottom: none;
    }
    .author_item_name {
        flex: 0 1 109px;
        max-width: 109px;
        font-size: 20px;
    }
    .author_item_text {
        flex: 1;
        font-size: 14px;
    }
    .author_block {
        margin-bottom: 40px;
    }
    .author_imgs {
        gap: 10px;
    }
    .author_imgs_item {
        width: 162px;
    }
    .author_img {
        flex: 0 1 100%;
        max-width: 100%;
        gap: 5px;
    }
    .author_img img {
        /* display: none!important; */
        flex: 0 1 calc(50% - 2.5px);
        max-width: calc(50% - 2.5px);
    }
    .author_img img:first-of-type {
        display: block!important;
    }
    .block {
        padding: 20px;
    }
}
@media(max-width: 363px) {
    .author_imgs_item {
        width: 150px;
    }
}
@media(max-width: 340px) {
    .author_imgs_item {
        width: 140px;
    }
}