.bonus {
    padding: 100px 0 60px;
}
.bonus_title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 40px;
    font-family: Unisans;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1;
}
.bonus_title span {
    color: #8356CF;
}
.bonus_title em {
    font-family: Manrope;
    font-style: normal;
}
.bonus_title b {
    text-decoration: line-through;
    color: rgba(40, 40, 40, 0.31);
}
.bonus_subtitle {
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    line-height: 1.4;
}
.bonus_block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 50px;
}
.bonus_item {
    flex: 0 1 360px;
    max-width: 360px;
    border-radius: 40px;
    border: 1px solid #D4D6F1;
    background-color: #F1F2F9;
    padding: 30px 30px 40px;
}
.bonus_item_img {
    position: relative;
    margin-bottom: 22px;
}
.bonus_item_img_num {
    position: absolute;
    left: 18px;
    top: 27px;
    font-size: 46px;
    font-family: Unisans;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: #fff;
}
.bonus_item_img_access {
    position: absolute;
    bottom: 30px;
    left: 18px;
    border-radius: 60px;
    background-color: #EBFF5E;
    color: #758028;
    font-weight: 600;
    line-height: 1.25;
    padding: 3px 18px;
}
.bonus_item_img_price {
    position: absolute;
    bottom: 30px;
    right: 18px;
    font-size: 18px;
    text-decoration: line-through;
    text-transform: uppercase;
    letter-spacing: 0.72px;
    line-height: 1.11;
    font-weight: 600;
    color: #fff;
}
.bonus_item_img_tag {
    position: absolute;
    right: 18px;
    top: 27px;
    padding: 3px 18px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.34);
    font-weight: 600;
    line-height: 1.25;
}
.bonus_item_name {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    color: #8356CF;
}
.bonus_item p {
    color: #262626;
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 10px;
}
.bonus_item p:last-of-type {
    margin-bottom: 0;
}
.bonus_btn {
    transition: .2s ease-in-out;
    width: 550px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 600px;
    background: linear-gradient(85deg, rgba(255, 255, 255, 0.00) 15.07%, rgba(255, 255, 255, 0.20) 148.09%), #8356CF;
    box-shadow: 0px 4px 25.5px 0px rgba(143, 103, 212, 0.55);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    padding: 32px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 0 auto;
}
.bonus_btn:hover {
    transform: translateY(-4px);
}
@media(max-width: 575px) {
    .bonus {
        padding: 30px 0;
    }
    .bonus_title {
        font-size: 30px;
    }
    .bonus_subtitle {
        font-size: 16px;
    }
    .bonus_block {
        gap: 20px;
    }
    .bonus_item {
        padding: 30px 20px 40px;
    }
    .bonus_item_name {
        font-size: 22px;
    }
    .bonus_item p {
        font-size: 16px;
    }
    .bonus_btn {
        font-size: 16px;
        width: 100%;
        text-align: center;
        height: 70px;
    }
}