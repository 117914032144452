.promo {
    padding-top: 110px;
}
.header {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.header_link {
    position: relative;
    font-size: 14px;
    line-height: 2.3;
    color: #959BAA;
    cursor: pointer;
}
.header_link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 1px;
    background-color: #959BAA;
}

.main {
    display: flex;
    gap: 50px;
    margin-bottom: 90px;
}
.main_left {
    /* flex: 1; */
    display: flex;
    /* justify-content: flex-end; */
    padding-left: 40px;
}
.main_right {
    /* flex: 0 1 547px; */
    /* max-width: 547px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-top: -100px; */
    flex: 0 1 200px;
    max-width: 200px;
}
.text {
    /* flex: 0 1 490px; */
    /* max-width: 490px; */
    position: relative;
}
.inf1 {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 40px;
}
.inf1 p {
    font-size: 22px;
    line-height: 1.35;
}
.inf1 p b {
    font-weight: 600;
}
.t1 {
    font-size: 50px;
    letter-spacing: -0.5px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 21px;
}
.t1 p {
    background-color: #F6FAFF;
    padding: 8px 14px;
    border-radius: 25px;
    max-width: max-content;
}
.t1 p:nth-of-type(2) {
    border: 1px solid #333844;
}
.t2 {
    margin-bottom: 68px;
    color: #717DA1;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: -0.02rem;
}
.img {
    max-width: 500px;
    margin-left: -100px;
    margin-top: -245px;
}
.img img {
    pointer-events: none;
}

.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
    background: linear-gradient(276.43deg, #E92A91 -51.29%, #FA65B5 131.26%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 40px;
    cursor: pointer;
    min-width: 360px;
}
.btn:hover {
    transform: translateY(-4px);
}

.remains p {
    font-weight: 500;
    font-size: 18px;
    color: #3B4A77;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.remains span {
    font-size: 112px;
    color: #F34FA7;
    line-height: 1;
    letter-spacing: -3px;
}

.date p {
    margin-top: 14px;
    margin-bottom: 4px;
    line-height: 1.2;
}
.date span {
    font-weight: 500;
    font-size: 28px;
    line-height: 1;    
}

.more {
    max-width: 1122px;
    margin: 0 auto 85px;
    background-color: #E3EDFF;
    border-radius: 40px;
    display: flex;
    gap: 55px;
    align-items: center;
    padding: 45px 80px 60px 45px;
}
.more_img {
    flex: 0 1 280px;
    max-width: 280px;
}
.more_text p:nth-of-type(1) {
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.more_text {
    flex: 1;
}
.more_text p:nth-of-type(1) b {
    font-weight: 500;
}
.more_text p:nth-of-type(2) {
    padding-left: 20px;
    border-left: 2px solid #5690F3;
    color: rgba(51, 56, 68, .7);
    font-size: 18px;
    line-height: 1.4;
}
.img .btn,
.img .remains,
.text > img {
    display: none!important;
}
.img > img:nth-of-type(2) {
    display: none!important;
}
.btn_off {
    pointer-events: none!important;
    filter: grayscale(1);
}

@media(max-width:575px) {
    .header {
        margin-bottom: 20px;
    }
    .promo {
        padding-top: 20px;
    }
    .main {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 40px;
    }
    .main_left {
        flex-direction: column;
        padding-left: 0;
    }
    .main_right {
        flex: 0 1 100%;
        max-width: 100%;
        display: none;
    }
    .text {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .inf1 {
        margin-bottom: 10px;
    }
    .inf1 img {
        display: none!important;
    }
    .inf1 p {
        font-size: 18px;
    }
    .t1 {
        font-size: 30px;
        margin-bottom: 0;
    }
    .t1 p {
        padding: 4px 8px;
    }
    .t1 p:last-of-type {
        padding: 5px 10px;
        margin-left: -10px;
    }
    .t2 {
        margin-bottom: 30px;
        margin-top: 20px;
        font-size: 18px;
    }
    .img {
        margin-left: 0;
        margin-top: 0;
        position: relative;
    }
    .img .btn {
        display: flex!important;
        margin-top: 15px;
        font-size: 18px;
        padding: 20px;
        max-width: 100%;
    }
    .main_left .btn {
        display: none;
        min-width: 100%;
    }
    .remains {
        display: none!important;
    }
    .text > img {
        display: none!important;
        /* display: inline-block!important; */
        margin-top: -20px;
    }
    .img .remains {
        display: block!important;
        position: absolute;
        top: 80px;
        left: 15px;
    }
    .img .remains p {
        color: #fff;
        font-size: 15px;
    }
    .img .remains span {
        color: #fff;
        font-size: 82px;
    }
    .img > img:nth-of-type(1) {
        display: none!important;
    }
    .img > img:nth-of-type(2) {
        display: block!important;
    }
    .date {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .date p {
        margin-top: 0;
    }
    
    .more {
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 20px;
        gap: 13px;
        margin-bottom: 50px;
    }
    .more_img {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .more_text p:nth-of-type(1) {
        font-size: 22px;
    }
    .more_text p:nth-of-type(2) {
        font-size: 16px;
        padding-left: 16px;
    }
}
@media(max-width:358px) {
    .t1 {
        font-size: 28px;
    }
    .img .remains p {
        font-size: 13px;
    }
    .img .remains span {
        font-size: 66px;
    }
}