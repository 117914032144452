.readyform {
    padding: 75px 0 60px;
    text-align: center;
}
.readyform_title {
    font-size: 48px;
    line-height: 1;
    font-weight: bold;
}
.readyform_subtitle {
    font-size: 36px;
    line-height: 1;
    font-weight: bold;
    color: var(--main-color);
    margin-bottom: 15px;
}
.readyform_note {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 30px;
}
.readyform_dates {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.55;
}
.readyform_countdown p {
    margin: 0 auto 20px;
    font-size: 21px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
    max-width: 770px;
}
.readyform_countdown .countdown {
    justify-content: center;
}
.readyform_countdown .countdown > div {
    color: var(--main-color);
    font-size: 40px;
}
.readyform_countdown .countdown > div span {
    color: #32313b;
    font-size: 20px;
}
.readyform_start_info {
    text-align: center;
    padding-top: 60px;
}
.readyform_start_info p {
    line-height: 25px;
    max-width: 760px;
    margin: 15px auto 0;
}
.readyform_start_info p span {
    font-size: 25px;
    line-height: 32px;
    font-weight: bold;
}
.readyform_start_info p strong {
    font-weight: 600;
}

@media(max-width: 991px) {
    .readyform_title {
        font-size: 42px;
    }
}
@media(max-width: 767px) {
    .readyform_title {
        font-size: 36px;
    }
    .readyform_subtitle {
        font-size: 28px;
    }
    .readyform_start_info p {
        font-size: 16px;
        line-height: 21px;
    }
    .readyform_start_info p span {
        font-size: 25px;
        line-height: 31px;
    }
}
@media(max-width: 575px) {
    .readyform_title {
        font-size: 24px;
        margin-bottom: 5px;
    }
    .readyform_subtitle {
        font-size: 20px;
    }
    .readyform_note {
        font-size: 18px;
    }
    .readyform_start_info p br {
        display: none;
    }
    .readyform_start_info p {
        font-size: 14px;
        line-height: 18px;
    }
    .readyform_start_info p span {
        font-size: 20px;
        line-height: 22px;
    }
    .readyform_start_info p {
        font-size: 14px;
        line-height: 21px;
    }
}