.start_parts {
    text-align: center;
    color: #3D3D3D;
    margin: 60px 0;
}
.start_parts__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 7px;
    font-weight: bold;
    margin-top: 15px;
}
.start_parts__text {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 40px;
}
.start_parts_block {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 75px;
    border-bottom: 1px solid #A2A2A2;
}
.start_parts_number {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: inline-block;
    border-top: 3px solid transparent;
    border-left: 3px solid #3D3D3D;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transform: rotate(45deg);
    font-size: 31px;
    line-height: 38px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.start_parts_number > * {
    transform: rotate(-45deg);
}
.start_parts_number2 {
    border-top: 3px solid #3D3D3D;
}
.start_parts_number3 {
    border-top: 3px solid #3D3D3D;
    border-right: 3px solid #3D3D3D;
}
.start_parts_number4 {
    border: 3px solid #3D3D3D;
}
.start_parts_number::before {
    content: '';
    position: absolute;
    left: 92px;
    top: 50%;
    margin-top: -82px;
    width: 65px;
    height: 2px;
    background-color: #3D3D3D;
    transform: rotate(-45deg);
}
.start_parts_number4::before {
    content: none;
}
.start_parts_item {
    flex: 0 1 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.start_parts_item > p {
    margin-top: 20px;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
}
.start_parts_item > p span {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}
@media(max-width: 991px) {
    .start_parts_number::before {
        content: none;
    }
}
@media(max-width: 767px) {
    .start_parts__title {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 5px;
    }
    .start_parts__text {
        font-size: 16px;
        line-height: 22px;
    }
    .start_parts_block {
        flex-wrap: wrap;
        padding-bottom: 25px;
    }
    .start_parts_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 30px;
    }
    .start_parts_item > p {
        margin-top: 5px;
        font-size: 21px;
        line-height: normal;
    }
    .start_parts_item > p span {
        font-size: 16px;
    }
}