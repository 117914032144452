.second {
    position: relative;
    overflow: hidden;
    /* padding-top: 150px; */
    /* padding-bottom: 110px; */
    padding-top: 72px;
}
.line {
    width: 10000px;
    z-index: 0;
    position: absolute;
    top: 0;
    overflow: hidden;
}
.line_text {
    border-top: 1px solid #FFC0CE;
    border-bottom: 1px solid #FFC0CE;
    background: #FFC0CE;
    padding: 20px 0;
    align-items: center;
    display: flex;
    gap: 20px;
    overflow: hidden;
    animation: linemove 15s linear infinite;
}
.line_text p {
    text-transform: uppercase;
    color: #DA5A75;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
}
@keyframes linemove {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-1000px);
    }
}

.block {
    display: flex;
    gap: 24px;
}
.item {
    flex: 0 1 calc(33.333333% - 19.2px);
    max-width: calc(33.333333% - 19.2px);
    text-align: center;
    position: relative;
    padding: 0 12px;
}
.item::before {
    content: '';
    position: absolute;
    right: -13px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #474747;
}
.item:last-of-type::before {
    content: none;
}
.item_t1 {
    margin-bottom: 8px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.2;
    font-size: 28px;
    font-weight: 600;
}
.item_t2 {
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.8);
}
@media(max-width: 575px) {
    .second {
        padding-top: 72px;
        /* padding-bottom: 70px; */
        /* padding-top: 120px; */
    }
    .block {
        flex-wrap: wrap;
        gap: 0;
        margin-bottom: 0px;
    }
    .item {
        flex: 0 1 50%;
        max-width: 50%;
        padding: 0 10px 32px;
        text-align: left;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(71, 71, 71, .2);
    }
    .item::before {
        content: none;
    }
    .item_t1 {
        font-size: 22px;
        text-align: center;
    }
    .item_t2 {
        font-size: 16px;
        text-align: center;
    }
    .item {
        flex: 0 1 100%;
        max-width: 100%;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .item:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}