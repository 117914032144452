.programs {
    padding: 90px 0 60px;
    position: relative;
}
.t1 {
    text-align: center;
    margin-bottom: 10px;
    font-family: Unisans;
    font-size: 46px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1;
    color: #fff;
}
.t1 span {
    color: #EBFF5E;
}
.st1 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 22px;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.8);
}
.st1 b {
    font-weight: 600;
    color: #fff;
}
.top {
    display: flex;
    gap: 80px;
    margin-bottom: 45px;
}
.dts {
    display: flex;
    justify-content: center;
    gap: 8px;
    flex: 1;
}
.dts_item {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5000px;
    border: 1px solid #474747;
    background: #262626;
    padding: 8px 14px;
}
.dts_item_text {
    font-size: 19px;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
}
.programs_block {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    margin: 0 auto 60px;
    max-width: 1260px;
    padding: 0 15px;
}
.programs_item {
    max-width: 375px;
}
.programs_item_full {
    flex: 0 1 100%;
    max-width: 100%;
}
.programs_info {
    border-radius: 20px;
    border: 1px solid #474747;
    background: #1D1E1A;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s ease-in-out;
}
.programs_item_full .programs_info {
    max-width: 560px;
    margin: 0 auto;
    border-radius: 20px;
    background: #FFF;
    border: none;
}
.programs_item_full .programs_tag {
    border-radius: 60px;
    border: 1px solid #ECECEC;
    background: #EFEFEF;
    padding: 10px 22px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    color: #818181;
    height: 40px;
}
.programs_item_full .programs_img__info_title {
    color: #262626;
}
.programs_item_full .programs_img img {
    right: 40px;
    top: 40px;
}
.programs_item_full .programs_more label {
    font-weight: 600;
}
.programs_item_full .programs_more label,
.programs_item_full .programs_more label p,
.programs_item_full .programs_more label div,
.programs_item_full .programs_text ul li,
.programs_item_full .programs_text ul li b {
    color: #262626;
}
.programs_item_full .programs_more label::before,
.programs_item_full .programs_more label::after {
    background-color: #262626;
}
.programs_info:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}
.programs_img {
    position: relative;
    text-align: right;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    /* background-color: #1E1A1B; */
    /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/fitsummer/programs_mask.png'); */
    height: 224px;
}
.programs_img > * {
    position: relative;
    z-index: 1;
}
.programs_img_bg {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/fitsummer/programs_mask.png');
    mix-blend-mode: luminosity;
    display: none!important;
}
.programs_img img {
    pointer-events: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.programs_img__info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.programs_tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: auto;
}
.programs_tag {
    padding: 0 8px;
    background-color: #fff;
    color: #171717;
    border-radius: 19.333px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    line-height: 2;
}
.programs_tag_new {
    color: #fff;
    background-color: #FF5C00;
}
.programs_img__info_title {
    letter-spacing: 0.03rem;
    color: #fff;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}
.programs_img__info_title span {
    display: block;
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 600;
}
.programs_img__info_price {
    position: relative;
    max-width: max-content;
    color: #fff;
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
}
.programs_img__info_price span {
    font-family: Manrope;
}
.programs_img__info_price::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    height: 3px;
    width: 100%;
    background-color: #EBFF5E;
}
.programs_text {
    padding: 16px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.programs_text ul {
    padding-bottom: 20px;
    margin-bottom: auto;
}
.programs_text ul li {
    font-size: 16px;
    line-height: 1.54;
    color: rgba(255, 255, 255, 0.8);
}
.programs_text ul li b {
    color: #fff;
}
.programs_more {
    border-top: 1px solid #474747;
    padding: 15px 0 2px;
}
.programs_more input {
    display: none;
}
.programs_more label {
    position: relative;
    width: 100%;
    display: block;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.015rem;
    font-size: 16px;
    line-height: 1;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
}
.programs_more label::before {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 14px;
    height: 2px;
    background-color: #EBFF5E;
}
.programs_more label::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 2px;
    width: 2px;
    height: 14px;
    background-color: #EBFF5E;
}
.programs_more p, .programs_more label div {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: normal;
    color: #fff;
    font-size: 16px;
    line-height: 1.45;
    padding-top: 12px;
    font-family: Manrope;
    text-transform: initial;
    transition: .3s ease-in-out;
}
.programs_more p b {
    font-weight: 600;
    color: #fff;
}
.programs_more label div ul {
    padding-bottom: 0;
}
.programs_more label div ul li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
}
.programs_more label div ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #EBFF5E;
    border-radius: 100%;
}
.programs_more input[type=checkbox]:checked + label p, 
.programs_more input[type=checkbox]:checked + label div {
    height: auto;
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
}
.programs_more input[type=checkbox]:checked + label::after {
    opacity: 0;
}
.promo_btn {
    transition: .2s ease-in-out;
    width: 430px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 600px;
    background: #EBFF5E;
    color: #171717;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    padding: 32px;
    cursor: pointer;
    text-transform: uppercase;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.remains {
    text-align: center;
    padding: 0 15px;
    font-size: 26px;
    line-height: 1.2;
    color: #37417C;
}
.remains span {
    font-size: 40px;
    font-weight: bold;
}
.pr1 {
    text-align: center;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
.pr11 {
    text-align: center;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 15px;
}
.pr1_1 {
    font-size: 44px;
    font-weight: 600;
    line-height: 1;
}
.pr1_2 {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
    line-height: 1.2;
    text-decoration: line-through;
}
.pr1_3 {
    position: relative;
}
.pr1_3 p {
    color: #171717;
    position: absolute;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    top: 12px;
    left: 14px;
}
.pr1_4 {
    font-size: 18px;
    line-height: 1.1;
    color: #fff;
}
.pr2 {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 15px;
}
.pr2_perc {
    font-family: Unisans;
    font-size: 66px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.2px;
}
.pr2:last-of-type {
    margin-bottom: 0;
}
.pr3 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.pr3_1 {
    color: #131313;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.743px;
}
.pr3_2 {
    color: #131313;
    font-family: Bebas;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1.938px;
}
.promo_countdown p {
    font-weight: 500;
    font-size: 18px;
    padding: 0 15px;
    color: #37417C;
    line-height: 1.2;
    text-align: center;
}
.promo_countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #F1719D;
    font-size: 56px;
    line-height: 1.2;
    font-weight: bold;
    justify-content: center;
}
.promo_countdown_timer > div div {
    display: flex;
    gap: 3px;
}
.dts_item img {
    max-width: 32px;
}
@media(max-width: 575px) {
    .promo_countdown_timer > div {
        font-size: 40px;
    }
    .top {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 30px;
    }
    .programs {
        padding: 10px 0 40px;
    }
    .programs_block {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    .t1 {
        font-size: 28px;
        margin-bottom: 10px;
    }
    .st1 {
        margin-bottom: 25px;
        font-size: 18px;
    }
    .dts {
        gap: 8px;
        padding: 0;
        flex-direction: column;
        align-items: center;
    }
    .dts_item_text {
        font-size: 14px;
    }
    .pr1_2 {
        font-size: 24px;
    }
    .pr1_3  {
        top: -68px;
        left: 50px;
        transform: rotate(-10deg);
    }
    .pr2 {
        flex-direction: column;
        gap: 16px;
    }
    .promo_btn {
        width: 100%;
        font-size: 18px;
        height: 70px;
        padding: 10px;
    }
    .pr3 {
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
    .pr3_1 br {
        display: block!important;
    }
    .dts_item {
        max-width: max-content;
    }
}
@media(max-width: 380px) {
    .programs_img__info_title span {
        margin-bottom: 6px;
        font-size: 20px;
    }
}




.second {
    padding: 0;
}
.block {
    display: flex;
    align-items: center;
    gap: 80px;
}
.left {
    flex: 1;
    text-align: center;
}
.right {
    flex: 0 1 500px;
    max-width: 500px;
    position: relative;
}
.right p {
    color: #37417C;
    background-color: #fff;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.4;
    padding: 10px 25px;
    left: 24px;
    bottom: 44px;
    position: absolute;
    border-radius: 50px;
}
.title {
    margin-bottom: 20px;
    font-family: Muller;
    color: #37417C;
    font-weight: bold;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -0.28px;
}
.title span {
    color: #EF6EAD;
}
.subtitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    color: #818496;
}
.h_mob {
    display: none!important;
}
@media(max-width:575px) {
    .h_mob {
        display: block!important;
        margin-bottom: 25px;
    }
    .second {
        padding-bottom: 0;
    }
    .block {
        flex-direction: column;
        gap: 24px;
    }
    .left {
        text-align: left;
    }
    .right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .title {
        font-size: 30px;
    }
    .subtitle {
        font-size: 18px;
    }
    .right p {
        font-size: 16px;
        left: 5px;
        width: calc(70% - 10px);
        padding: 4px;
        bottom: 20px;
        text-align: center;
    }
    .right img {
        max-width: 70%;
    }
    .programs_item_full .programs_img {
        height: 300px;
    }
    .programs_item_full .programs_img img {
        max-width: 126px;
        top: 80px;
    }
    .pr2_perc {
        font-size: 32px;
    }
}