.auth {
    /* background-color: #F4F5FA; */
    /* color: #fff; */
    padding: 90px 15px;
}
.auth_title {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-family: ProximaN;
}
.auth_widget {
    display: flex;
    justify-content: center;
    color: #000;
}
.done {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F4F5FA;
}
.done_block {
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    padding: 60px 85px;
}
.done_img {
    text-align: center;
    margin-top: -115px;
    margin-bottom: 18px;
}
.done_img img {
    max-width: 106px;
}
.done_title {
    margin-bottom: 24px;
    text-align: center;
    font-family: Stolzl;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 56px;
    line-height: 1;
    letter-spacing: -0.02rem;
    color: #000019;
}
.done_subtitle {
    margin-bottom: 4px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 0.04rem;
    color: #FC310F;
}
.done_subtitle2 {
    color: rgba(77, 77, 77, .7);
    font-size: 18px;
    line-height: 1.45;
    text-align: center;
    letter-spacing: 0.02rem;
}
.oferta {
    background-color: #F4F5FA;
    text-align: center;
}
.oferta a {
    color: #FC310F;
    font-weight: 600;
    font-size: 22px;
    line-height: 1.35;
}
@media(max-width: 575px) {
    footer {
        margin-top: 20px;
    }
    .oferta {
        padding-top: 20px;
    }
    .done {
        padding: 60px 15px;
    }
    .auth_title {
        font-size: 24px;
        line-height: 28px;
    }
    .done_block {
        padding: 20px;
        padding-top: 60px;
    }
    .done_title {
        font-size: 36px;
    }
    .done_subtitle {
        font-size: 18px;
    }
    .done_subtitle2 {
        font-size: 16px;
    }
}