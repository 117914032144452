.preims {
    padding-top: 180px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/stretch/preims2.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 828px;
}
.block {
    padding-left: 550px;
}
.title {
    margin-bottom: 12px;
    font-family: Qanelas;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    font-size: 56px;
    line-height: 62px;
}
.subtitle {
    margin-bottom: 30px;
    color: #fff;
    font-size: 24px;
    line-height: 34px;
}
.block li {
    position: relative;
    padding-left: 36px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #fff;
}
.block li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
    width: 25px;
    height: 24px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/stretch/marker.svg');
}
.block li:last-of-type {
    margin-bottom: 0;
}
@media(max-width: 991px) {
    .preims {
        /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/stretch/preims_mob.jpg'); */
        height: auto;
        background-size: 130%;
        background-repeat: no-repeat;
        background-color: #041121;
        background-position: 0% 0%;
        padding-top: 100px;
        padding-bottom: 60px;
    }
    .block {
        padding-left: 0;
    }
    .title {
        margin-bottom: 12px;
        font-size: 26px;
        line-height: 32px;
        margin-top: 45%;
    }
    .subtitle {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 24px;
    }
    .block li {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
    }
}
@media(max-width: 575px) {
    .preims {
        /* background-size: auto; */
    }
}