.line {
    width: 10000px;
    z-index: 0;
    top: 0;
    overflow: hidden;
}
.line_text {
    border-top: 1px solid #474747;
    border-bottom: 1px solid #474747;
    background: #262626;
    padding: 20px 0;
    align-items: center;
    display: flex;
    gap: 20px;
    overflow: hidden;
    animation: linemove 30s linear infinite;
}
.line_text p {
    text-transform: uppercase;
    color: #EBFF5E;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
}
@keyframes linemove {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-890px);
    }
}
.results {
    padding: 100px 0 125px;
}
.results_title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-family: Unisans;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
}
.results_title span {
    color: #EBFF5E;
}
.results_block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 46px 36px;
    counter-reset: counter;
    margin-bottom: 115px;
}
.results_block_item {
    counter-increment: counter;
    position: relative;
    flex: 0 1 360px;
    max-width: 360px;
    border-radius: 50px;
    background-color: #262626;
    overflow: hidden;
}
.results_block_item::before {
    content: "#" counter(counter);
    position: absolute;
    left: 30px;
    top: 30px;
    font-family: Unisans;
    color: #EBFF5E;
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
}
.results_block_item p {
    padding: 24px 32px 34px;
    font-size: 24px;
    line-height: 1.3;
}
.block {
    margin: 0 auto;
    max-width: 760px;
    padding: 48px 58px 68px;
    border-radius: 80px;
    background: #262626;
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.06) inset;
    backdrop-filter: blur(50px);
}
.block_title {
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
}
.block_title b {
    font-weight: 600;
    color: #fff;
}
.block_remains {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.block_remains_text {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
}
.block_remains_number {
    font-family: Unisans;
    font-weight: bold;
    line-height: 1;
    font-size: 58px;
}
.oh {
    overflow: hidden;
}
@media(max-width:575px) {
    .results {
        padding: 60px 0 35px;
    }
    .results_title {
        font-size: 26px;
        margin-bottom: 22px;
    }
    .results_block {
        gap: 20px;
        margin-bottom: 95px;
    }
    .results_block_item p {
        font-size: 22px;
    }
    .results_block_item::before {
        font-size: 38px;
    }
    .block {
        padding: 20px 15px;
        border-radius: 40px;
    }
    .block_title {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .block_remains {
        margin-top: 28px;
    }
}