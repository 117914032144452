.BirthdayUsm {
    font-family: Steppe;
}
.birthday_usm_form h2 {
    display: none;
}
.birthday_usm_form .chakra-radio-group {
    display: none;
}
.birthday_usm_form .sales-widget__selected-offers {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
}
.birthday_usm_form .chakra-stack,
.birthday_usm_form .chakra-text {
    font-family: Steppe;
}
.birthday_usm_form .sales-widget__user-form {
    width: 100%;
    max-width: 100%;
}
.birthday_usm_form .sales-widget__offers {
    width: 100%;
}
.birthday_usm_form button {
    padding: 35px 67px;
    border-radius: 8px;
    background-color: #FB4F54!important;
    width: 100%;
    font-family: Actay;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: .2s ease-in-out;
}
.birthday_usm_form button:hover {
    transform: translateY(-4px);
}
.birthday_usm_form .sales-widget__offers > .chakra-stack {
    min-width: 0;
}
.birthday_usm_preview {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/preview.png');
    background-color: #F8F9FB;
    background-size: cover;
    transition: .2s ease-in-out .4s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    cursor: pointer;
    background-position: 15% 50%;
}
.birthday_usm_preview.hide {
    opacity: 0;
    pointer-events: none;
}
@media(max-width:767px) {
    .birthday_usm_form button {
        max-width: 100%;
        padding: 32px;
    }
    .birthday_usm_form .sales-widget__selected-offers > .chakra-stack {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
    .birthday_usm_preview {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/preview3.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
}