.createbody {
    padding: 75px 0 60px;
}
.createbody_title {
    font-weight: 800;
    font-size: 50px;
    line-height: 55px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.createbody_title span {
    color: var(--fat-blue);
}
.createbody_subtitle {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 35px;
}
.createbody_subtitle strong {
    font-weight: 600;
}
.createbody_block {
    display: flex;
    gap: 40px;
}
.createbody_item {
    flex: 0 1 50%;
    max-width: 50%;
}
.createbody_item ul li {
    position: relative;
    margin-bottom: 14px;
    padding-left: 45px;
    font-size: 20px;
    line-height: 26px;
    padding-right: 35px;
}
.createbody_item ul li strong {
    font-weight: 600;
}
.createbody_item ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 15px;
    height: 15px;
    background-color: var(--fat-red);
    border-radius: 100%;
}

@media(max-width: 991px) {
    .createbody_block {
        flex-wrap: wrap;
        gap: 0;
    }
    .createbody_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
}
@media(max-width: 767px) {
    .createbody {
        padding: 50px 0;
    }
    .createbody_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .createbody_subtitle {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
    .createbody_item ul li {
        padding-left: 25px;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
    }
    .createbody_item ul li::before {
        width: 10px;
        height: 10px;
        top: 4px;
        background-color: var(--fat-blue);
    }
}
@media(max-width: 575px) {
    .createbody_title br {
        display: none;
    }
}
@media(max-width: 374px) {
    .createbody_title {
        font-size: 30px;
        line-height: 34px;
    }
}