.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(19, 19, 19, 0.95);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_block {
    max-width: 300px;
    margin-left: 15px;
    margin-right: 15px;
}
.modal_title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    color: #fff;
}
.modal_subtitle {
    font-size: 18px;
    color: #E4E4E4;
    line-height: 1.25;
    text-align: center;
}
.modal_title br {
    display: block!important;
}