.newprograms {
    padding-top: 85px;
    background-color: #F2F2F2;
}
.newprograms_title {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.newprograms_subtitle {
    color: #4A4A4A;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 40px;
}
.newprograms_block {
    display: flex;
    position: relative;
}
.newprograms_block::before {
    content: '';
    position: absolute;
    left: 0;
    top: 68px;
    width: 100%;
    height: 4px;
    background-color: #DFDFDF;
    border-radius: 4px; 
}
.newprograms_item {
    flex: 0 1 25%;
    max-width: 25%;
    position: relative;
}
.newprograms_item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 60px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 100%;
    border: 5px solid #EE4777;
}
.newprograms_item__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 60px;
}
.newprograms_item ul li {
    padding-left: 28px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
    position: relative;
}
.newprograms_item ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 10px;
    height: 10px;
    background-color: #8E8E8E;
    border-radius: 2px;
}

@media(max-width: 991px) {
    .newprograms_block {
        flex-direction: column;
        padding-left: 34px;
    }
    .newprograms_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .newprograms_block::before {
        width: 4px;
        height: calc(100% - 50px);
        top: 40px;
        left: 2px;
    }
    .newprograms_item__title {
        margin-bottom: 15px;
        margin-top: 30px;
    }
    .newprograms_item::before {
        top: 41px;
        left: -40px;
    }
    .newprograms_item ul li br {
        display: none;
    }
    .newprograms_subtitle {
        margin-bottom: 0;
    }
}
@media(max-width: 575px) {
    .newprograms {
        padding-top: 45px;
    }
    .newprograms_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .newprograms_subtitle {
        font-size: 20px;
        line-height: 26px;
    }
    .newprograms_item__title {
        font-size: 27px;
        line-height: 30px;
    }
    .newprograms_item ul li {
        font-size: 18px;
        line-height: 26px;
    }
    .newprograms_item ul li::before {
        top: 8px;
    }
    .newprograms_item::before {
        top: 35px;
    }
}
@media(max-width: 349px) {
    .newprograms_title {
        font-size: 28px;
        line-height: 32px;
    }
}