.newcourse {
    background-color: #F3F8FF;
    padding-top: 65px;
    padding-bottom: 90px;
}
.newcourse_title {
    font-family: Dela;
    text-align: center;
    font-size: 52px;
    line-height: 58px;
    margin-bottom: 25px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}
.newcourse_title span {
    color: #EF3F34;
}
.newcourse_subtitle {
    text-align: center;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 25px;
    font-weight: 500;
}
.newcourse_t1 {
    margin: 0 auto 10px;
    max-width: 416px;
    background-color: #EF3F34;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 4px;
    color: #fff;
}
.newcourse_t1 img {
    margin-right: 10px;
}
.newcourse_t2 {
    margin: 0 auto 45px;
    max-width: 416px;
    background-color: #0D8ED7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 4px;
    color: #fff;
}
.newcourse_t2 img {
    margin-right: 10px;
}
.newcourse_title2 {
    font-family: Dela;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.02em;
}
.newcourse_block {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;
    justify-content: center;
}
.newcourse_item {
    flex: 0 1 400px;
    max-width: 400px;
    padding: 0 20px;
    margin-bottom: 60px;
}
.newcourse_info {
    background: #FFFFFF;
    border: 1px solid #E2F3EE;
    box-shadow: 0px 4px 32px rgba(208, 237, 229, 0.5);
    border-radius: 13px;
    height: 100%;
    padding: 25px;
    padding-bottom: 10px;
}
.newcourse_info_name {
    background-color: #0D8ED7;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 106px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.newcourse_info_title {
    font-family: Dela;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
}
.newcourse_info_time {
    margin-bottom: 10px;
}
.newcourse_info_text {
    margin-bottom: 20px;
}
.newcourse_info p {
    font-size: 18px;
}
.newcourse_title3 {
    text-align: center;
    margin-bottom: 10px;
    font-family: Dela;
    color: #0D8ED7;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}
.newcourse_subtitle3 {
    font-weight: 500;
    text-align: center;
    margin-bottom: 60px;
    font-size: 25px;
    line-height: 32px;
}
.newcourse_block2 {
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 30px;
    padding: 55px 30px;
    padding-left: 440px;
    position: relative;
}
.newcourse_block2::before {
    content: '';
    position: absolute;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/newcourse.jpg');
    width: 372px;
    height: 319px;
    left: 25px;
    top: 30px;
}
.newcourse_right_t {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #EF3F34;
    letter-spacing: 0.1em;
    margin-bottom: 12px;
    text-transform: uppercase;
}
.newcourse_right_t2 {
    font-family: Dela;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.newcourse_right_t3 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
}

@media(max-width: 575px) {
    .newcourse {
        padding-top: 45px;
        padding-bottom: 10px;
    }
    .newcourse_title {
        margin-bottom: 15px;
        font-size: 36px;
        line-height: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .newcourse_subtitle {
        font-size: 20px;
        line-height: 26px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .newcourse_t1, .newcourse_t2 {
        margin-left: 15px;
        margin-right: 15px;
        font-size: 16px;
        line-height: 18px;
    }
    .newcourse_title2 {
        font-size: 24px;
        line-height: 26px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .newcourse_info_title {
        font-size: 20px;
        line-height: 24px;
    }
    .newcourse_info p {
        font-size: 16px;
    }
    .newcourse_info_text {
        margin-bottom: 15px;
    }
    .newcourse_item {
        margin-bottom: 10px;
    }
    .newcourse_title3 {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
        margin-top: 40px;
    }
    .newcourse_subtitle3 {
        font-size: 18px;
        line-height: 24px;
    }
    .newcourse_block2 {
        padding: 40px 20px;
        padding-left: 20px;
    }
    .newcourse_block2::before {
        width: 300px;
        height: 256px;
        left: 10px;
        bottom: 20px;
        top: inherit;
        background-size: cover;
    }
    .newcourse_right_t {
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
    }
    .newcourse_right_t2 {
        text-align: center;
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 26px;
    }
    .newcourse_right_t3 {
        margin-bottom: 240px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
}