.ref_form > .chakra-stack {
    background-color: transparent;
    padding: 0;
    width: 100%;
}
.ref_form {
    font-family: Qanelas!important;
}
.ref_form input[name=phone] {
    width: auto;
}