.onmarafon {
    padding: 80px 0 40px;
    background-color: #F5F5F5;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2023/universalpop/onm_bg.png');
    background-repeat: no-repeat;
    background-position: 100% 50%;
}
.onmarafon_title {
    font-size: 50px;
    line-height: 55px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 45px;
}
.onmarafon_title span {
    color: #24B79C;
}
.onmarafon_block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.onmarafon_item {
    flex: 0 1 25%;
    max-width: 25%;
    padding: 0 10px;
    margin-bottom: 40px;
}
.onmarafon_item2 {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 10px;
    margin-bottom: 40px;
}
.onmarafon_item_info {
    background-color: #fff;
    padding: 30px;
    height: 50%;
    position: relative;
}
.onmarafon_item_info:first-of-type::before {
    content: '';
    position: absolute;
    left: 15px;
    bottom: 0;
    width: calc(100% - 30px);
    height: 1.5px;
    background-color: #E7E7E7;
}
.onmarafon_item_info:first-of-type {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.onmarafon_item_info:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
.onmarafon_item2 img {
    border-radius: 20px;
}
.onmarafon_item_title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
}

@media(max-width: 991px) {
    .onmarafon_item_title {
        font-size: 20px;
        line-height: 29px;
    }
    .onmarafon_item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 0;
        text-align: center;
    }
    .onmarafon_item2 {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 0;
        text-align: center;
    }
    .onmarafon_item:first-of-type .onmarafon_item_info:last-of-type, .onmarafon_item:nth-of-type(2) .onmarafon_item_info:first-of-type {
        border-radius: 0;
    }
    .onmarafon_item:first-of-type .onmarafon_item_info:last-of-type::before {
        content: '';
        position: absolute;
        left: 15px;
        bottom: 0;
        width: calc(100% - 30px);
        height: 1.5px;
        background-color: #E7E7E7;
    }
}
@media(max-width: 767px) {
    .onmarafon {
        padding: 45px 0;
    }
    .onmarafon_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .onmarafon_item2 {
        margin-top: 20px;
    }
    .onmarafon_item_title {
        font-size: 16px;
        line-height: 21px;
        margin-top: 10px;
    }
}
@media(max-width: 575px) {
    .onmarafon_block {
        margin: 0 -10px;
    }
    .onmarafon_item {
        padding: 0 10px;
    }
    .onmarafon_title br {
        display: none;
    }
}
@media(max-width: 374px) {
    .onmarafon_title {
        font-size: 30px;
        line-height: 32px;
    }
}