.promo {
  padding-top: 100px;
}
.header {
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.header_link {
  position: relative;
  font-size: 14px;
  line-height: 2.3;
  color: #959baa;
  cursor: pointer;
}
.header_link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  height: 1px;
  background-color: #959baa;
}

.main {
  display: flex;
  gap: 20px;
  margin-bottom: 90px;
}
.main_left {
  display: flex;
  /* padding-left: 40px; */
  width: 100%;
  justify-content: space-between;
}
.main_right {
  /* display: flex; */
  display: none !important;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 1 260px;
  max-width: 260px;
}
.right_text {
  font-size: 18px;
  line-height: 1.37;
  color: #717da1;
}
.right_text b {
  font-weight: 600;
}
.text {
  position: relative;
}
.t1 {
  font-size: 50px;
  letter-spacing: -0.5px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 22px;
  margin-left: -12px;
}
.t1 p {
  background-color: #f6faff;
  padding: 6px 12px;
  border-radius: 18px;
  max-width: max-content;
}
.t1 p:nth-of-type(3) {
  border: 1px solid #333844;
}
.t2 {
  color: #717da1;
  font-size: 18px;
  line-height: 1.41;
  letter-spacing: -0.42px;
  margin-bottom: 40px;
}
.t2 b {
  font-weight: 600;
}
.prices {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 21px;
}
.prices p:nth-of-type(1) {
  text-decoration: line-through;
  color: rgb(51, 56, 68, 0.4);
  font-size: 21px;
  line-height: 1.37;
  letter-spacing: -1px;
}
.prices p:nth-of-type(2) {
  color: #f34fa7;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.37;
  letter-spacing: -1px;
}
.img {
  max-width: 455px;
  margin-left: -100px;
  margin-top: -80px;
}
.img img {
  pointer-events: none;
}
.btns {
  display: flex;
  gap: 20px;
  align-items: center;
}
.btn {
  transition: 0.2s ease-in-out;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 70px;
  background: linear-gradient(276deg, #e92a91 -51.29%, #fa65b5 131.26%);
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.44px;
  padding: 20px 35px;
  cursor: pointer;
  min-width: 340px;
}
.btn:hover {
  transform: translateY(-4px);
}
.countdown {
  /* margin-bottom: 30px; */
}
.countdown > p {
  text-transform: uppercase;
  color: #3b4a77;
  font-size: 16px;
  line-height: 1.31;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 12px;
}
.countdown_timer div {
  color: #f34fa7;
  font-size: 46px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.752px;
  text-transform: uppercase;
  display: flex;
  gap: 12px;
  align-items: baseline;
  justify-content: center;
}
.countdown_timer div div {
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.countdown_timer div div span {
  font-size: 16px;
  text-transform: lowercase;
  font-weight: normal;
}
.main_right .btn,
.text > img,
.img .prices {
  display: none !important;
}
.img > img:nth-of-type(2) {
  display: none !important;
}

.dates {
  display: flex;
  gap: 30px;
  margin-bottom: 80px;
}
.dates_item {
  flex: 0 1 calc(50% - 15px);
  max-width: calc(50% - 15px);
  background-color: #e3edff;
  border-radius: 30px;
  padding: 35px 50px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.dates_item_img {
  flex: 0 1 80px;
  max-width: 80px;
}
.dates_item_text {
  flex: 1;
  max-width: calc(100% - 80px);
}
.dates_item_text p:nth-of-type(1) {
  font-size: 22px;
  line-height: 1;
}
.dates_item_text p:nth-of-type(2) {
  font-weight: 500;
  font-size: 56px;
  line-height: 1.2;
}

.more {
  margin: 0 auto 85px;
  background-color: #e3edff;
  border-radius: 40px;
  display: flex;
  gap: 55px;
  align-items: center;
  padding: 45px 80px 60px 45px;
}
.more_img {
  flex: 0 1 280px;
  max-width: 280px;
}
.more_text p:nth-of-type(1) {
  font-size: 30px;
  letter-spacing: -0.5px;
  line-height: 1.3;
  margin-bottom: 20px;
}
.more_text {
  flex: 1;
}
.more_text p:nth-of-type(1) b {
  font-weight: 500;
}
.more_text p:nth-of-type(2) {
  padding-left: 20px;
  border-left: 2px solid #5690f3;
  color: rgba(51, 56, 68, 0.7);
  font-size: 18px;
  line-height: 1.4;
}

.tags {
  margin-bottom: 80px;
}
.tags_top {
  display: flex;
  gap: 64px;
  margin-bottom: 50px;
}
.tags_top_title {
  color: #333844;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.498px;
}
.tags_top_imgs {
  display: flex;
  gap: 16px;
}
.tags_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 1000px;
}
.tags_list p {
  border-radius: 69px;
  border: 1px solid #deeaff;
  background: #e3edff;
  padding: 7px 22px;
  color: #333844;
  font-size: 24px;
  line-height: 1.4;
}
.tags_list p.tags_list_pink {
  border: 1px solid #ffdaee;
  background: rgba(246, 88, 173, 0.16);
}
.remain_info {
  font-size: 19px;
  color: #3b4a77;
  line-height: 1.3;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 28px;
}
.remain_info div:nth-of-type(1) {
  font-size: 34px;
  color: #ef3f9e;
  font-weight: 500;
  line-height: 1.3;
}
.remain_info span {
  font-size: 36px;
  color: #ef3f9e;
  font-weight: 500;
  line-height: 1.3;
}
.remain_info b {
  font-weight: 600;
}
.remain_count {
  color: #333844;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.36px;
  text-transform: uppercase;
}
.remain_count span {
  color: #ef3f9e;
  font-size: 38px;
  line-height: 1.37;
  letter-spacing: 0;
  font-weight: 500;
  text-transform: lowercase;
  display: block;
}
.info {
  margin: 0 auto 60px;
  max-width: 740px;
}
.info_t1 {
  color: #333844;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  margin-bottom: 40px;
}
.info_t2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 35px;
}
.info_t2 p {
  opacity: 0.7;
  color: #333844;
  font-size: 18px;
  line-height: 1.4;
}
.info_t2 p b {
  font-weight: 600;
}
.info_t3 {
  border-radius: 20px;
  background: #f6e0f2;
  padding: 30px;
  margin-bottom: 35px;
}
.info_t3 div {
  margin-bottom: 5px;
  font-size: 24px;
  color: #dd7db1;
  font-weight: 600;
  line-height: 1.4;
}
.info_t3 p {
  color: #675063;
  opacity: 0.7;
  font-size: 18px;
  line-height: 1.4;
}
.info_t4 {
  display: flex;
  gap: 30px;
  margin-bottom: 35px;
}
.info_t4 img {
  max-width: 256px;
}
.info_t4 p {
  font-size: 18px;
  opacity: 0.7;
  color: #333844;
  line-height: 1.4;
}
.info_t4 p b {
  font-weight: 600;
}
.info_t5 {
  font-size: 18px;
  opacity: 0.7;
  color: #333844;
  line-height: 1.4;
}
.info_t5 b {
  font-weight: 600;
}

.video {
  display: flex;
  gap: 16px;
  margin-bottom: 80px;
}
.video_text {
  flex: 1;
}
.video_block {
  flex: 0 1 542px;
  max-width: 542px;
  position: relative;
  overflow: hidden;
  border-radius: 28px;
  height: 305px;
}
.video_text_t1 {
  margin-bottom: 12px;
  font-size: 44px;
  color: #333844;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
}
.video_text_t2 {
  margin-bottom: 40px;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.44px;
  /* color: #717DA1; */
  font-weight: 500;
}
.video_text_t2 span {
  color: #f34fa7;
}
.video_text_pt1 {
  margin-bottom: 12px;
  font-size: 44px;
  color: #333844;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: -0.5px;
  text-align: center;
}
.video_text_pt2 {
  margin-bottom: 40px;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.44px;
  color: #717da1;
  text-align: center;
}
.video_text ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.video_text ul li {
  /* color: #717DA1; */
  padding-left: 16px;
  position: relative;
  font-size: 20px;
  line-height: 1.41;
  letter-spacing: -0.42px;
}
.video_text ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  background-color: #333844;
  border-radius: 100%;
}
.video_text_pt3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}
.video > .btn {
  display: none !important;
}
.img .remain_info {
  display: none !important;
}

@media (max-width: 575px) {
  .header {
    margin-bottom: 20px;
  }
  .promo {
    padding-top: 20px;
  }
  .main {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
  }
  .main_left {
    flex-direction: column;
    padding-left: 0;
  }
  .main_right {
    flex: 0 1 100%;
    max-width: 100%;
    display: flex !important;
  }
  .text {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .t1 {
    font-size: 28px;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .t1 p {
    padding: 2px 4px;
    padding: 0;
  }
  .t1 p:last-of-type {
    padding: 5px 10px;
    margin-left: -10px;
  }
  .t2 {
    margin-bottom: 0;
    font-size: 18px;
    /* max-width: 240px; */
  }
  .img {
    margin-left: 0;
    margin-top: -10px;
    /* margin-top: 0; */
    position: relative;
  }
  .main_right .countdown {
    display: flex!important;
  }
  .main_right .btn,
  .video .btn {
    display: flex !important;
    margin-top: 15px;
    font-size: 19px;
    padding: 20px;
    max-width: 100%;
    min-width: 100%;
  }
  .main_left .btn {
    display: none;
    min-width: 100%;
  }
  .main_left .remain_count,
  .main_left .remain_info {
    display: none !important;
  }
  .text > img {
    display: none !important;
    margin-top: -20px;
  }
  .img > img:nth-of-type(1) {
    display: none !important;
  }
  .img > img:nth-of-type(2) {
    display: block !important;
  }
  .btn {
    margin: 0 !important;
  }
  .countdown {
    margin-top: 20px;
    display: flex;
    order: 2;
    margin-bottom: 0;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    display: none!important;
  }
  .countdown > div {
    display: flex;
    gap: 2px;
    justify-content: space-between;
  }
  .countdown > p {
    font-size: 15px;
    margin-bottom: 0;
    text-align: center;
  }
  .countdown > p br {
    /* display: block!important; */
  }
  .countdown_timer div div {
    font-size: 36px;
    letter-spacing: 0;
  }
  .countdown_timer {
    display: flex;
  }
  .dates {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 45px;
  }
  .dates_item {
    flex: 0 1 100%;
    max-width: 100%;
    padding: 20px 24px;
    gap: 14px;
  }
  .video_text_pt3 .btn {
    min-width: 0;
  }
  .dates_item_img {
    flex: 0 1 62px;
    max-width: 62px;
  }
  .dates_item_text {
    max-width: calc(100% - 62px);
  }
  .dates_item_text p:nth-of-type(1) {
    font-size: 17px;
  }
  .dates_item_text p:nth-of-type(2) {
    font-size: 28px;
  }
  .more {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 20px;
    gap: 13px;
    margin-bottom: 50px;
  }
  .more_img {
    flex: 0 1 100%;
    max-width: 160px;
    /* max-width: 100%; */
  }
  .more_text p:nth-of-type(1) {
    font-size: 22px;
  }
  .more_text p:nth-of-type(2) {
    font-size: 16px;
    padding-left: 16px;
  }
  .prices {
    display: none !important;
  }
  .img .prices {
    display: block !important;
    position: absolute;
    top: 50%;
    left: 25px;
    margin-top: -45px;
  }
  .img .prices p:nth-of-type(1) {
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.37;
    letter-spacing: -1.2px;
    text-decoration: none;
  }
  .img .prices p:nth-of-type(2) {
    color: rgba(255, 255, 255, 0.9);
    font-size: 26px;
    line-height: 1.37;
    letter-spacing: -1.2px;
    text-decoration: line-through;
  }
  .tags {
    margin-bottom: 45px;
  }
  .tags_top {
    margin-bottom: 24px;
  }
  .tags_top_title {
    font-size: 26px;
  }
  .tags_list p {
    padding: 7px 18px;
    font-size: 16px;
  }
  .remain_info span {
    font-size: 19px;
  }
  .remain_count {
    margin-top: 20px;
    text-align: center;
    font-size: 15px;
  }
  .remain_count span {
    font-size: 36px;
  }
  .video {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .video_block {
    flex: 0 1 100%;
    max-width: 100%;
    height: 200px;
    /* height: auto; */
  }
  .video_text_t1 {
    font-size: 26px;
  }
  .video_text_pt1 {
    font-size: 26px;
    text-align: left;
  }
  .video_text_t2 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .video_text_pt2 {
    font-size: 18px;
    text-align: left;
  }
  .video_text .btn {
    display: none !important;
  }
  .video_text ul li {
    font-size: 16px;
  }
  .video > .btn {
    display: flex !important;
  }
  .info_t1 {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .info_t2 p {
    font-size: 16px;
  }
  .info_t3 div {
    font-size: 24px;
  }
  .info_t3 p {
    font-size: 16px;
  }
  .info_t4 {
    flex-direction: column;
  }
  .info_t4 img {
    order: 2;
    max-width: 100%;
  }
  .info_t4 p {
    font-size: 16px;
  }
  .info_t5 p {
    font-size: 16px;
  }
  .img .remain_info {
    display: flex !important;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -50px;
  }
  .img .remain_info div:nth-of-type(1) {
    color: #fff;
    letter-spacing: -1.59px;
    font-size: 54px;
    font-weight: 500;
    line-height: 1.37;
  }
  .img .remain_info div:nth-of-type(2) {
    color: #d7d3ff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.32px;
    text-transform: uppercase;
  }
  .img .remain_info div:nth-of-type(2) br {
    display: block !important;
  }
}
