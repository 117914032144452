.gift {
    padding: 40px 0;
}
.titles {
    display: flex;
    margin-bottom: 50px;
}
.title {
    margin-right: -120px;
    margin-top: 20px;
    position: relative;
}
.title p {
    background-color: #F6FAFF;
    padding: 8px 14px;
    border-radius: 25px;
    max-width: max-content;
    font-size: 65px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    font-weight: 500;
}
.title p:nth-of-type(2) {
    border: 1px solid #333844;
}
.titles_img {
    flex: 0 1 707px;
    max-width: 707px;
    border-radius: 40px;
    overflow: hidden;
}
.title div {
    font-size: 22px;
    line-height: 1.4;
    margin-top: 20px;
}
.tags {
    margin-bottom: 65px;
}
.tags_title {
    font-weight: 500;
    font-size: 44px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin-bottom: 28px;
}
.tags_items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.tags_items > div {
    font-size: 24px;
    line-height: 1.4;
    padding: 7px 22px;
    background-color: #E3EDFF;
    border-radius: 70px;
    border: 1px solid #DEEAFF;
}

.last {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.last_pretitle {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    color: #86A4FF;
    margin-bottom: 23px;
}
.last_title {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 16px;
}
.last_text {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 63px;
    color: #717DA1;
}
.guides {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.guide_item {
    display: flex;
    flex-direction: column;
    background-color: #EBF1FA;
    border: 1px solid #DBE8F8;
    border-radius: 20px;
    max-width: 360px;
    padding: 22px;
    min-height: 461px;
}
.guide_title {
    color: #B9C9E1;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 5px;
}
.guide_subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #717DA1;
}
.guide_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    color: #717DA1;
}
@media(max-width: 575px) {
    .gift {
        padding: 10px 0 40px;
    }
    .titles {
        flex-direction: column;
        margin-bottom: 25px;
    }
    .titles_img {
        flex: 0 1 100%;
        max-width: 100%;
        border-radius: 20px;
    }
    .title {
        text-align: center;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .title p {
        font-size: 26px;
        text-align: center;
        margin: 0 auto;
    }
    .title div {
        font-size: 18px;
    }
    .tags {
        margin-bottom: 40px;
    }
    .tags_title {
        margin-bottom: 20px;
        font-size: 22px;
    }
    .tags_items {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 16px;
    }
    .tags_items div {
        padding: 0;
        padding-left: 32px;
        position: relative;
        background-color: transparent;
        border: none;
        font-size: 16px;
    }
    .tags_items div::before {
        content: '';
        background-image: url('data:image/svg+xml,<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14_57" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="82" height="82"><path d="M0 19.9698C0 8.9408 8.9408 0 19.9698 0C30.9989 0 39.9397 8.9408 39.9397 19.9698V39.9397H19.9698C8.94079 39.9397 0 30.9989 0 19.9698Z" fill="%23D9D9D9"/><path d="M82 19.9698C82 8.9408 73.0592 0 62.0302 0C51.0011 0 42.0603 8.9408 42.0603 19.9698V39.9397H62.0302C73.0592 39.9397 82 30.9989 82 19.9698Z" fill="%23D9D9D9"/><path d="M0 62.0302C0 73.0592 8.9408 82 19.9698 82C30.9989 82 39.9397 73.0592 39.9397 62.0302V42.0603H19.9698C8.94079 42.0603 0 51.0011 0 62.0302Z" fill="%23D9D9D9"/><path d="M82 62.0302C82 73.0592 73.0592 82 62.0302 82C51.0011 82 42.0603 73.0592 42.0603 62.0302V42.0603H62.0302C73.0592 42.0603 82 51.0011 82 62.0302Z" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_14_57)"><rect x="-46.7627" y="-14.8969" width="174.526" height="156.897" fill="%23CAD9F4"/></g></svg>');
        width: 22px;
        height: 22px;
        background-size: cover;
        left: 0;
        top: 0;
        position: absolute;
    }

    .last {
        flex-direction: column;
        padding: 40px 0 30px;
        overflow: hidden;
    }
    .last_img {
        order: 2;
        margin: 0;
        flex: 0 1 100%;
        max-width: 100%;
        position: relative;
    }
    .last_img img {
        min-width: 470px;
        position: absolute;
        left: 50%;
        margin-left: -235px;
    }
    .last_text {
        text-align: center;
        padding: 0 10px;
    }
    .last_text p:nth-of-type(1) {
        font-size: 26px;
    }
    .last_text p:nth-of-type(2) {
        font-size: 18px;
    }
}