.promoblock {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/afterpregnancy/promo_min.jpg');
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    min-height: 82vh;
    position: relative;
}
.promo_logo {
    margin-bottom: 40px;
}
.promo_logo img {
    height: 130px;
    margin-left: -27px;
    margin-bottom: -40px;
    margin-top: -40px;
}
.promoblock_title {
    font-size: 64px;
    color: #fff;
    line-height: 1;
    margin-bottom: 5px;
    font-weight: bold;
}
.promoblock_subtitle {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #fff;
}
.promoblock_info {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.55;
    margin-bottom: 30px;
}
.promoblock_info span {
    background: var(--main-color);
    border-radius: 5px;
    padding: 5px;
}
.promoblock_countdown {
    color: #fff;
    margin-bottom: 30px;
}
.promoblock_countdown p {
    font-size: 21px;
    border-bottom: 1px solid #D765FF;
    max-width: 500px;
    line-height: 2;
}
.promoblock_block {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 400px;
}
.promoblock_block__title {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    line-height: 25px;
    margin-bottom: 8px;
}
.promoblock_block__text {
    background: #FFF;
    box-shadow: 0px 0px 20px rgba(247, 166, 166, 0.7);
    border-radius: 10px;
    font-size: 20px;
    line-height: 28px;
    padding: 15px 15px 15px 30px;
}
.promoblock_block__text strong {
    font-weight: 600;
}

@media(max-width: 1199px) {
    .promoblock {
        background-image: url('https://gymteam.kinescopecdn.net/img/l/afterpregnancy/promo_m_min.jpg');
        background-position: 50% 50%;
        min-height: 1065px;
        padding: 30px 0;
        text-align: center;
    }
    .promoblock_title {
        font-size: 34px;
        line-height: 1;
    }
    .promoblock_title br {
        display: none;
    }
    .promoblock_subtitle {
        font-size: 20px;
    }
    .promoblock_info {
        max-width: 500px;
        margin: 0 auto 20px;
    }
    .promoblock_info br {
        display: none;
    }
    .promoblock_info span {
        background-color: transparent;
        padding: 0;
        font-weight: bold;
    }
    .promoblock_countdown p {
        margin: 0 auto;
    }
    .countdown {
        justify-content: center;
    }
    .promoblock_block {
        left: 50%;
        margin-left: -200px;
        top: 30%;
    }
    .promoblock::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.0);
        pointer-events: none;
        z-index: 0;
    }
    .promoblock .container {
        position: relative;
    }
}
@media(max-width: 991px) {
    .promoblock_info {
        margin-bottom: 10px;
    }
    .promoblock {
        min-height: 1125px;
    }
    .promoblock_block {
        left: 50%;
        margin-left: -200px;
        top: 30%;
        padding-top: 10px;
    }
    .promo_logo {
        margin-bottom: 25px;
        text-align: center;
    }
    .promo_logo img {
        height: 90px;
        margin-left: 0;
        margin-bottom: -35px;
        margin-top: -30px;
    }
}
@media(max-width: 767px) {
    .promoblock_title {
        font-size: 30px;
    }
    .promoblock_subtitle {
        margin-bottom: 10px;
    }
}
@media(max-width: 575px) {
    .promoblock_title {
        font-size: 24px;
    }
    .promoblock_info {
        line-height: 1;
    }
    .promoblock_block__text {
        font-size: 18px;
        padding: 5px;
    }
    .promoblock_block {
        width: 300px;
        margin-left: -150px;
        /* top: 25%; */
    }
}
@media(max-width: 372px) {
    .promoblock_block {
        /* top: 29%; */
    }
}