.fatlost_2309 {
    font-family: Roboto;
    color: #111927;
}
.fatlost_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-text {
    font-family: Roboto;
    font-size: 18px;
    line-height: 23.4px;
    color: rgba(77, 87, 97, 0.75);
}
.fatlost_login {
    margin-top: 0;
}
.fatlost_login {
    display: flex;
    justify-content: center;
}
.fatlost_login > div {
    background-color: transparent;
    padding: 0;
}
.fatlost_login form button svg {
    display: none;
}
.fatlost_login form button {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(91deg, #B92655 6.98%, #E42F81 99.58%), #EE4777;
    border-radius: 12px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    margin-top: 15px!important;
}
.fatlost_login form button p {
    font-size: 18px;
    letter-spacing: 0.06em;
    font-weight: bold;
    margin-bottom: 0;
    color: #f8f8f8;
}
@media(max-width:575px) {
    .fatlost_login > .chakra-stack > .chakra-stack > .chakra-stack > .chakra-text {
        display: none;
    }
    .fatlost_login form button p {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: normal;
    }
    .fatlost_login > div {
        width: 100%;
    }
}